import { ModalUploadImageComponent } from './../../modals/modal-upload-image/modal-upload-image.component';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { ModalVersionsArtworkToInstitutionComponent } from 'src/app/modals/modal-versions-artwork-to-institution/modal-versions-artwork-to-institution.component';
import { ModalArtistRoyaltiesToInstitutionComponent } from 'src/app/modals/modal-artist-royalties-to-institution/modal-artist-royalties-to-institution.component';
import { ModalInstitutionRoyaltiesToInstitutionComponent } from 'src/app/modals/modal-institution-royalties-to-institution/modal-institution-royalties-to-institution.component';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';



@Component({
  selector: 'app-dashboard-institution',
  templateUrl: './dashboard-institution.component.html',
  styleUrls: ['./dashboard-institution.component.scss']
})
export class DashboardInstitutionComponent implements OnInit {
  
  public disabledMode: boolean = false;
  public viewMode: any = "overview";
  public overviewMode: any = "artworks"
  public artworksData: any;
  public artworkLastCreated: any;
  public artworks: any;
  public plansYearly: any;
  public plansMonthly: any;
  public showPage: any = false
  
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public modalService: NgbModal,
    public api: ApiService,
    public auth: AuthService,
    public helper: HelperService,
    
    ) { 
      this.route.params.subscribe(params => this.viewMode = params['viewMode']);
      
      router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          this.api.get('subscriptions/plans').then(data => {
            this.plansMonthly = data.filter(res => res.type == 'monthly')
            this.plansYearly = data.filter(res => res.type == 'yearly')
          })
          
          this.auth.currentAuthenticatedUser('user/'+this.auth.userData.id).then(async res => {
            this.auth.updateSubscriptionData().then(value=>{
              if(value == '/subscribe'){
                window.location.href = '/subscribe'
              }
              this.disabledMode = false;
              if(value == 'active'){
                this.showPage = true
                if(this.viewMode == 'overview') {
                  this.api.get('institutions/artworks').then((response) => {
                    this.artworks = response
                    this.artworksData = response.data
                    this.artworksData.filter((value: any)=>{
                      if(!this.artworkLastCreated || this.artworkLastCreated.created_at < value.created_at) {
                        this.artworkLastCreated = value;
                      }
                    })
                  })
                }
              }
            })
          })
        }
        
      })
    }
    
    async ngOnInit() {
      
    }
    
    setOverviewMode(mode){
      this.overviewMode = mode;
    }
    
    openModalUploadImage() {
      const modalRef = this.modalService.open(ModalUploadImageComponent);
    }
    
    VersionEdition(){
      const modalRef = this.modalService.open(ModalVersionsArtworkToInstitutionComponent)
    }
    
    ArtistsRoyalties(){
      const modalRef = this.modalService.open(ModalArtistRoyaltiesToInstitutionComponent)
    }
    
    InstitutionRoyalties(){
      const modalRef = this.modalService.open(ModalInstitutionRoyaltiesToInstitutionComponent)
    }
    
    
  }
  
  