import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { ModalArtistRoyaltiesComponent } from 'src/app/modals/modal-artist-royalties/modal-artist-royalties.component';
import { ModalPlatformFeeComponent } from 'src/app/modals/modal-platform-fee/modal-platform-fee.component';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import Swal from 'sweetalert2';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-create-artworks',
  templateUrl: './create-artworks.component.html',
  styleUrls: ['./create-artworks.component.scss']
})
export class CreateArtworksComponent implements OnInit {
  
  constructor(
    public api: ApiService,
    public auth: AuthService,
    private formBuilder: FormBuilder,
    public helper: HelperService,
    public modalService: NgbModal,
    public route: ActivatedRoute,
    ) { }
    
    @Input() public disabledMode: boolean = false;
    @Input() public mode: any;
    public Editor = ClassicEditor;

    @Input() link: any;
    @Input() artwork: any = false
    @Input() version: any = false
    
    public artworkCreateMode: string = '';
    public defaultArtistRoyalty: string = '5';
    public artistId: any = false; 
    public artworkCreateStep: number = 1;
    public formArtwork: FormGroup;
    public files: NgxFileDropEntry[] = [];
    public fileList: any[] = [];
    public styles: any[] = [];
    public artists: any[] = [];
    public plans: any;
    public plansMonthly: any;
    public plansYearly: any;
    public showUpload: boolean = false;
    public totalArtworksCreate: any = 0
    
    
    ngOnInit(): void {

      this.api.get('subscriptions/plans').then(data => {
        this.plans = data;
        this.plansMonthly = data.filter(res => res.type == 'monthly')
        this.plansYearly = data.filter(res => res.type == 'yearly')
      });

      this.route.queryParams.subscribe(params => {this.artistId = params.artist_id;});

      this.api.get('institutions/artists').then((response) => {
        this.artists = response;
        this.artists = this.artists.filter(item => item.status == 2)
      });

      this.api.get('artworks/styles').then((response) => {
        this.styles = response;
      });

      this.disabledMode = true
      if(this.auth.userData.subscription.token - this.auth.userData.subscription.token_used <= 0){
        this.artworkCreateStep = 0;
      }
      
      this.files = null;
      this.fileList = [];
      this.artworkCreateStep = 1;
      this.showUpload = false;
      this.restartCreateProcess();
      this.deleteAllFile();
      
      if(this.artwork != false &&  this.version != false){
        this.artwork.version = this.version
      }
    }
    
    public onReady( editor ) {
      editor.ui.getEditableElement().parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
    }
    
    restartCreateProcess() {
      this.artworkCreateStep = 1;
      if(this.auth.userData.subscription.token - this.auth.userData.subscription.token_used <= 0){
        this.artworkCreateStep = 0;
      }
      this.fileList = [];
      this.files = [];
      this.showUpload = false;
      this.artworkCreateMode = '';
    }
    
    deleteFile(i){
      this.fileList.splice(i, 1);
      let artworksArray = this.formArtwork.controls.artworks as FormArray;
      artworksArray.removeAt(i)
      if (artworksArray.length == 0) {
        this.showUpload = false;
        this.artworkCreateStep = 1;
      }
    }
    
    btnCancel(){
      this.artworkCreateStep = 1
    }
    
    deleteAllFile(){
      this.formArtwork = this.formBuilder.group({
        artworks: this.formBuilder.array([])
      });
    }
    
    createArtwork() {
      let formData = new FormData();
      this.totalArtworksCreate = 0
      // let result = Object.assign({}, this.formArtwork.value);
      let artworks = this.formArtwork.controls.artworks as FormArray;
      for (let i in artworks.controls) {
        let artwork = artworks.controls[i];
        
        for (let o in artwork.value) {
          formData.append(o + '-' + i, artwork.value[o]);
          if(o == 'editions'){
            this.totalArtworksCreate += parseInt(artwork.value[o])
          }
        }
        
        formData.append('artworkCreateMode-' + i, this.artworkCreateMode);
        formData.append('uploadedData-' + i, this.fileList[i].file);
      }
      
      if(this.mode == 'institution'){
        this.api.post('institutions/artworks', formData).then(() => {
          this.artworkCreateStep++;
        })
      }else if(this.mode == 'creator'){
        this.api.post('artworks', formData).then(() => {
          this.artworkCreateStep++;
        })
      }
    }
    
    showUploadStep(status: boolean) {
      if(this.artworkCreateMode != ''){
        this.showUpload = status;
      }
    }
    
    private addFormArtwork() {
      
      var defaultForm :any = {
        title: ['', Validators.required],
        width: ['', Validators.required],
        height: ['', Validators.required],
        depth: [''],
        style_id: ['', Validators.required],
        unit: ['', Validators.required],
        medium: ['', Validators.required],
        artist_royalty: [this.defaultArtistRoyalty, Validators.required],
        tags: ['', Validators.required],
        year: ['', Validators.required],
        edition: ['', Validators.required],
        description: ['', Validators.required],
        price: ['', Validators.required],
      }
      
      if(this.mode == 'institution'){
        defaultForm = {
          title: ['', Validators.required],
          width: ['', Validators.required],
          height: ['', Validators.required],
          depth: [''],
          style_id: ['', Validators.required],
          artist_id: ['', Validators.required],
          unit: ['', Validators.required],
          medium: ['', Validators.required],
          artist_royalty: [this.defaultArtistRoyalty, Validators.required],
          tags: ['', Validators.required],
          year: ['', Validators.required],
          edition: ['', Validators.required],
          description: ['', Validators.required],
          price: ['', Validators.required],
        }
      }

      let artworksArray = this.formArtwork.controls.artworks as FormArray;
      let arraylen = artworksArray.length;
      let newUsergroup: FormGroup = this.formBuilder.group(defaultForm)
      artworksArray.insert(arraylen, newUsergroup);
      
    }
    
    
    btnBack(){
      if(this.artworkCreateStep == 2 ){
        this.artworkCreateStep = 1;
      }else if(this.artworkCreateStep == 3){
        this.artworkCreateStep = 2;
      }else if(this.artworkCreateStep == 4){
        this.artworkCreateStep = 3;
      }else if(this.artworkCreateStep == 5){
        this.artworkCreateStep = 4
      }
    }
    
    onBlurEvent(event: any, idx) {
      const currentYear = new Date().getFullYear();
      const defaultYear = new Date().getFullYear() - 500;
      
      if(event.target.value > currentYear || event.target.value < defaultYear) {
        Swal.fire(`The year has to be ${currentYear} or less`)
        // console.log(event.target.value)
        
        this.formArtwork.controls.artworks['controls'][idx].controls.year.value = '';
        console.log(this.formArtwork.controls.artworks['controls'][idx].controls.year.value)
        event.target.value = '';
      }
    }
    
    nextCreateStep() {
      if (this.artworkCreateStep < 3) {
        if (this.artworkCreateStep === 2) {
          var mandatoryFields1 = []
          if(this.mode == 'institution'){
            mandatoryFields1 = ['title', 'width', 'height', 'style_id', 'artist_id', 'unit', 'medium', 'tags', 'year', 'edition', 'description'];
          }
          if(this.mode == 'creator'){
            mandatoryFields1 = ['title', 'width', 'height', 'style_id', 'unit', 'medium', 'tags', 'year', 'edition', 'description'];
          }
          if (!this.verifyMandatoryFields(mandatoryFields1)) {
            return false;
          }
        }
        this.artworkCreateStep++;
      } else if (this.artworkCreateStep === 3) {
        let mandatoryFields2 = ['artist_royalty', 'price'];
        if (!this.verifyMandatoryFields(mandatoryFields2)) {
          return false;
        }
        this.artworkCreateStep++;
      }else if (this.artworkCreateStep === 4) {
        let mandatoryFields2 = ['artist_royalty', 'price'];
        if (!this.verifyMandatoryFields(mandatoryFields2)){
          return false;
        }
        this.createArtwork();
      }
    }
    
    verifyMandatoryFields(fields) {
      for (let i in this.formArtwork.controls.artworks['controls']) {
        let formGroup = this.formArtwork.controls.artworks['controls'][i];
        for (let i2 in formGroup.controls) {
          if (fields.indexOf(i2) >= 0 && !formGroup.controls[i2].value) {
            Swal.fire({ title: 'Ops', text: 'All the fields are mandatory!', icon: 'error' });
            return false;
          }
        }
      }
      return true;
    }
    
    
    
    
    private createFormArtwork() {
      this.formArtwork = this.formBuilder.group({
        artworks: this.formBuilder.array([])
      });
    }
    
    
    
    setItemStyle(id, idx) {
      this.formArtwork.controls.artworks['controls'][idx].controls.style_id.value = id;
    }
    
    setItemArtist(id, idx) {
      this.formArtwork.controls.artworks['controls'][idx].controls.artist_id.value = id;
    }
    
    setArtist(id){
      for (let item of this.formArtwork.controls.artworks['controls']){
        item.controls.artist_id.value = id
      }
    }
    
    getSelectedStyleId(idx) {
      let selectedStyleId = this.formArtwork.controls.artworks['controls'][idx].controls.style_id.value;
      for (let i in this.styles) {
        if (this.styles[i].id == selectedStyleId) {
          return this.styles[i].title;
        }
      }
      return '';
    }
    
    getSelectedArtistId(idx) {
      let selectedArtistId = this.formArtwork.controls.artworks['controls'][idx].controls.artist_id.value;
      for (let i in this.artists) {
        if (this.artists[i].creator_id == selectedArtistId) {
          return this.artists[i].creator.full_name;
        }
      }
      return '';
    }
    
    nextStep(step: any){
      if(this.fileList.length > 0){
        this.artworkCreateStep = step
      }
      if(step === 2){
        if(this.artistId !== false){
          this.setArtist(this.artistId)
        }
      }
    }
    
    
    public async  dropped(files: NgxFileDropEntry[]) {
      this.files = files;
      this.fileList = [];
      this.totalArtworksCreate = 0
      this.deleteAllFile()
      for await(const droppedFile of files) {
        this.api.loading = true 
        this.totalArtworksCreate ++
        if(this.auth.userData.subscription.token < this.auth.userData.subscription.token_used + this.totalArtworksCreate){
          continue
        }
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            
            const reader = new FileReader();
            reader.readAsDataURL(file);
            
            reader.onload = (event) => {
              let format = '' 
              if(file.type.indexOf('image')> -1){
                format = 'image';
              } else if(file.type.indexOf('video')> -1){
                format = 'video';
              }
              
              this.fileList.push({ type: format, relativePath: droppedFile.relativePath, file: file, formatedSize: (file.size / 1000 / 1000).toFixed(1) + 'Mb', imageUrl: (<FileReader>event.target).result, blob: this.helper.sanitize(window.URL.createObjectURL(file)) });
              
              if((file.size / 1000 / 1000) > 100){
                this.api.loading = false
                this.totalArtworksCreate = 0
                this.deleteAllFile()
                Swal.fire('Opss!','The maximum size allowed is 100Mb, send an email to hello@alphaa.io','warning' )
                return false
              }
              
              this.api.loading = false 
              this.addFormArtwork();
              
            };
            
          });
        } else {
          const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        }
      }
    }
    
    continuePayment(plan){
      if(typeof this.auth.userData.id == 'undefined'){
        return window.location.href = '/auth/register'
      }else{
        return window.location.href = '/subscribe?viewMode=payment&plan='+plan.id
      }
    }
    
    filterPlan(plan : any, user: any) {
      if(user.subscription){
        return plan.id >= user.subscription.plan.id
      }else{
        return true
      }
    }
    
    formatPlanToken(tokens){
      if(tokens > 200){ 
        return "∞"
      }else{
        return tokens
      }
    }
    
    restartCreateProcessAndReload() {
      this.auth.updateSubscriptionData().then(res=>{
        this.artworkCreateStep = 1;
        if(this.auth.userData.subscription.token - this.auth.userData.subscription.token_used <= 0){
          this.artworkCreateStep = 0;
        }
        this.fileList = [];
        this.files = [];
        this.showUpload = false;
        this.artworkCreateMode = '';
      })
    }
    
    openPlatformFee() {
      const modalRef = this.modalService.open(ModalPlatformFeeComponent,{
        windowClass: 'myCustomModalClass'
      });
    }
    
    openArtistRoyalties() {
      const modalRef = this.modalService.open(ModalArtistRoyaltiesComponent,{
        windowClass: 'myCustomModalClass'
      });
    }
    
    
    onQuantityTokens(event: any, idx) {
      const quantityTokens = this.auth.userData.subscription.token - (this.auth.userData.subscription.token_used+0);
      
      if(event.target.value > quantityTokens) {
        Swal.fire({
          text: `You have only ${quantityTokens} tokens left. Do you want to upgrade your plan?`, 
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#252dff',
          cancelButtonColor: '#c00303',
          confirmButtonText: 'Yes'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/subscribe"
          }else {
            this.formArtwork.controls.artworks['controls'][idx].controls.edition.value = '';
            event.target.value = '';
          }
        })
      }
    }
    
    
  }
  