import { Injectable } from '@angular/core';
import { CanLoad, UrlSegment, Route, UrlTree, Router, CanActivate, GuardsCheckEnd, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad, CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
    ) {
      
    }
    canLoad(): Observable<boolean> {
      return this.guard();
      
    }
    canActivate(): Observable<boolean> {
      return this.guard();
    }
    guard() {
        return this.authService.isAuthenticated.pipe(
          filter(val => val !== null),
          take(1),
          map(isAuthenticated => {
            if (isAuthenticated) {
              return true;
            } else {
              this.router.navigate(['/auth/login']);
            }
          }),
          )
        }
      }
      