import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalAddToShippingComponent } from 'src/app/modals/modal-add-to-shipping/modal-add-to-shipping.component';
@Component({
  selector: 'app-trade-status',
  templateUrl: './trade-status.component.html',
  styleUrls: ['./trade-status.component.scss']
})
export class TradeStatusComponent implements OnInit {

  constructor(
    public modalService: NgbModal,

  ) { }

  ngOnInit(): void {
  }


  openAddShipping() {
    const modalRef = this.modalService.open(ModalAddToShippingComponent,
      {
        windowClass: 'myCustomModalTrade'
      });
  }
}
