import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalEmbedProfileComponent } from 'src/app/modals/modal-embed-profile/modal-embed-profile.component';
import { ModalShareEmbedComponent } from 'src/app/modals/modal-share-embed/modal-share-embed.component';

@Component({
  selector: 'app-embed-button',
  templateUrl: './embed-button.component.html',
  styleUrls: ['./embed-button.component.scss']
})
export class EmbedButtonComponent implements OnInit {
  @Input() mode: any;
  
  constructor(
    public modalService: NgbModal
  ) { }

  ngOnInit(): void {
  }

  embedProfile(){
    const modalRef = this.modalService.open(ModalEmbedProfileComponent,
      {
        windowClass: 'share-embed'
      })
  }

  embedArtwork(){
    const modalRef = this.modalService.open(ModalShareEmbedComponent)
  }
}
