<section *ngIf="institution" class="">
    <div class="container-fluid">
        <div class="row mt-sm-4 mx-5">
            <div class="col-lg-6 flex-start p-0">
                <p class="mb-0">Institutions</p>
                <h1 class="font-weight-bold text-secondary mb-5">{{ institution.full_name }}</h1>
            </div>
        </div>
    </div>

    <div>
        <img *ngIf="!institution.cover" style=" width: 100%; height: 23em; object-fit: cover;" src="/assets/img/Rectangle 2.svg" alt="">
        <img *ngIf="institution.cover" style=" width: 100%; height: 23em; object-fit: cover;" src="{{institution.cover}}" alt="">
        <div class="profile-institution">
            <img *ngIf="!institution.avatar"  src="/assets/img/avatar.png" alt="" style="width: 154px; border-radius: 50%;" required>
            <img *ngIf="institution.avatar" src="{{institution.avatar}}" alt="" style="width: 154px; border-radius: 50%;" required>
        </div>
    </div>
    
    <div class="row my-4 mx-5">
        <div class="col-lg-6" *ngIf="institution.about">
            <h5 class="font-weight-bold text-secondary">About</h5>
            <div [innerHTML]="institution.about"></div>
        </div>

        <div  class="offset-md-3 col-md-3 d-flex flex-column justify-content-start">
            <h5 *ngIf="institution.contact_information" class="text-secondary font-weight-bold">Location</h5>
            <p *ngIf="institution.contact_information">{{institution.contact_information.city}} - {{institution.contact_information.country}}</p>
            <!-- <div>
                <h5 class="text-secondary font-weight-bold">Follow {{ institution.full_name }}</h5>
            </div>  -->
        </div>
    </div>

    <div class="row mb-4  my-sm-4 mx-5">
        <div class="col-md-12">
            <h3 class="text-secondary font-weight-bold">Artists</h3>
        </div>
        <div class="col-md-12">
            <app-institution-from-artists [invites]="invites"></app-institution-from-artists>
        </div>
    </div>

    <div class="row  my-sm-4 mx-5">
        <div class="col-md-12">
            <app-artworks-list [endpoint]="'institutions/'+institution.id+'/artworks/'"></app-artworks-list>
        </div>
    </div>
</section>