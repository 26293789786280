<main id="dashboard-creator">
    <section class="p-1 overview">
        <div class="container-fluid">
            <div class="row mx-sm-4">
                <div class="col-lg-12 pt-lg-5">
                    <p class="text-grey font-weight-light mb-0">Your Vault</p>
                    <h1 class="text-primary font-weight-bold display-4 mb-4">Trades</h1>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row mx-sm-4">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body pt-3">
                            <app-trade-box></app-trade-box>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>