import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-artwork2',
  templateUrl: './artwork2.component.html',
  styleUrls: ['./artwork2.component.scss']
})
export class Artwork2Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}