<form [formGroup]="formCreateList" (ngSubmit)="createList(formCreateList)">
    <div class="modal-header justify-content-center">
        <h2 class="text-secondary font-weight-bold">Create list</h2>
        <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0 p-2">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
    <div class="modal-body text-center">
        <p class="mt-2 text-left text-grey-light font-weight-light ml-3">Create collections to organize your artworks</p>
        <div class="col-12">
            <input formControlName="title" type="text" class="form-control bg-light" placeholder="Collection name" />
        </div>
        <div class="col-12">
            <textarea formControlName="description" class="form-control bg-light mt-3" rows="4" placeholder="Description"></textarea>
        </div>
    </div>
    <div class="modal-footer justify-content-center">
        <button  type="submit" class="w-75 btn btn-lg btn-primary btn-create font-weight-regular">Create</button>
    </div>
</form>


