import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-trade-box',
  templateUrl: './trade-box.component.html',
  styleUrls: ['./trade-box.component.scss']
})
export class TradeBoxComponent implements OnInit {
  
  @Input() tradeMode: any = 'buying';
  public buy: any = false;
  public sale: any = false;
  
  constructor(public api: ApiService, public route: ActivatedRoute,) {}
  
  ngOnInit(): void {
    if(this.tradeMode === 'buying'){
      this.api.get('transfers/buy').then((response) => {
        this.buy = response
        this.sale = false
      })
    }
    
    if(this.tradeMode === 'selling'){
      this.api.get('transfers/sale').then((response) => {
        this.sale = response
        this.buy = false
      })
    }
  }
  
  changeMode(mode){
    this.tradeMode = mode
    this.ngOnInit()
  }
  
}
