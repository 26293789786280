<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12 px-0" id="about">
            <div class="img-about collect d-sm-flex flex-sm-column justify-content-center align-items-center">
                <div class="about-text mt-sm-4 pt-sm-5">
                    <h1 class="text-white font-weight-bold text-center mb-0 display-4">The one stop shop solution <br> for artist and institutions to issue NFTs <br> associated to physical artworks.</h1>
                </div>
                <div class="about-text text-center px-sm-5 mb-sm-3 mt-sm-4 text-center">
                    <p class="text-white text-center">Alphaa.io is a platform that allows artists and art collectors to authenticate, <br> certificate and transfer ownership of Digital and Physical Art.</p>
                </div>
                <div class="about-text text-center px-0 d-flex justify-content-center">
                    <a class="btn btn-lg btn-primary d-flex align-items-center justify-content-center cursor-pointer" (click)="onClick('how-it-works')">How it works</a>
                </div>
                <span class="about-overlay"></span>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid bg-digital d-flex">
    <div class="row mt-sm-5 pt-sm-5">
        <div class="col-12 mt-5 pt-5 px-5 section-digital">
            <h1>Alpha’a Inc. has been <br> making a name for itself in <br> the art world since 2017. <br> Alphaa.io is the next step.</h1>
            <div class="row">
                <div class="col-9">
                    <p class="mt-4 text-grey-link font-weight-light">
                        Alphaa.io combines our experience in the art world with our <br> cutting edge technology. Our certification tool allows artists <br> to seamlessly organize their catalogue by issuing NFTs associated with their physical works.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid pb-5 mb-5">
    <div class="row">
        <div class="col-lg-12 section-meet-team text-center mt-sm-5 pt-sm-5">
            <h1 class="text-primary font-weight-regular mt-5 pt-5">Meet the team</h1>
            <p class="text-grey-light font-weight-light mt-3">Our team has sold paintings to global buyers with eight-figure budgets, and <br> curated shows from the Artivism Challenge at Art Basel Miami to the <br> Artivism Challenge, Rainforest edition, in New York City. </p>
        </div>
    </div>
    <div class="row mt-sm-5 pt-sm-5 mx-sm-4 meet-team">
        <div class="col-lg-6 px-5">
            <div class="mb-4">
                <img src="/assets/img/ceo-about.png" alt="">
                <h2 class="text-primary font-weight-bold mt-4 pt-3 mb-0">Manuela Seve</h2>
                <h3 class="text-secondary font-weight-bold">CEO</h3>
                <p class="text-grey-light font-weight-light mt-4">
                    Alpha’s founder and CEO started her career in finance working as a fundamental equity analyst at Gavea Investments in Rio. After five years in the institutional investment space, working alongside the head of Brazil’s central bank, Manuela started an
                    entrepreneurial think tank called Quinto, together with colleagues from a wide range of sectors including tech, private equity and environmentalism. At Quinto, she led a team in developing a tech-enabled, crowdfunded art application
                    that launched in 2014 at the Art Rio fair.
                </p>
            </div>
            <div class="mt-sm-5 pt-sm-4 mb-4">
                <img src="/assets/img/cto-about.png" alt="">
                <h2 class="text-primary font-weight-bold mt-4 pt-3 mb-0">Haroldo Tinoco</h2>
                <h3 class="text-secondary font-weight-bold">CTO</h3>
                <p class="text-grey-light font-weight-light mt-4">
                    Alpha's CTO has worked in tech communications for nearly 20 years. In addition to his work with Alpha’a, Haroldo leads a digital communications firm called UpGlobal, where he has created web applications used by some of television’s largest franchises,
                    including The Voice and Big Brother. UpGlobal also supports early-stage startups and has incubated a number of successful early-stage startups. He is dedicated to art-related philanthropic efforts, and in mid-2015, led a project transforming
                    an abandoned building in downtown São Paulo into a major design and architecture hub.
                </p>
            </div>
        </div>
        <div class="col-lg-6 px-5">
            <div class="mb-4">
                <img src="/assets/img/coo-about.png" alt="">
                <h2 class="text-primary font-weight-bold mt-4 pt-3 mb-0">Renata Tome</h2>
                <h3 class="text-secondary font-weight-bold">COO</h3>
                <p class="text-grey-light font-weight-light mt-4">
                    Alpha’s COO has spent her entire career dedicated to making art more accessible for more people and promoting the work of working artists, even when spending her 9-5 at the art world’s biggest institutions. She earned her master’s in Modern and Contemporary
                    Art History and Markets at Christie’s in New York, and began her career working at the esteemed auction house upon graduating in 2014. Here, she worked within the Latin American Art department, where she connected with New York’s top
                    art buyers and a range of working artists, as well as.
                </p>
            </div>
            <div class="mt-sm-5 pt-sm-4 mb-4">
                <img src="/assets/img/cmo-about.png" alt="" style="width: 13em;">
                <h2 class="text-primary font-weight-bold mt-4 pt-3 mb-0">Pedro Cesario</h2>
                <h3 class="text-secondary font-weight-bold">CMO</h3>
                <p class="text-grey-light font-weight-light mt-4">
                    With a 15-year-old entrepreneurial history, a half-billion-dollar investment in digital media, an exit to WPP, and a range of expertise that goes from strategizing campaigns for Fortune 100 brands, creating SAS media solutions for SMB`s, directing tech
                    conferences, and leading creative endeavors in music and arts, Pedro Cesario has emerged as a one-of-kind talent of the new ad-tech industry. His ability to bring together numbers and art, business and creativity, is set to become
                    a strategic asset cementing Alpha`s global outreach.
                </p>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid section-how-it-works py-5" id="how-it-works">
    <div class="row mt-5 pt-5">
        <div class="col-lg-12 d-flex justify-content-center mt-5">
            <h2 class="text-secondary text-center display-4">How it works</h2>
        </div>
    </div>
    <div class="row mt-5 p-3 plans-card">
        <div class="col-sm-10 col-md-12 col-lg-4 mb-4">
            <div class="card text-center">
                <div class="card-body d-flex flex-column justify-content-center">
                    <h6 class="text-primary font-weight-bold">Scan</h6>
                    <p class="card-text border-0 text-secondary">
                        Each physical edition is printed with a unique QR code that can be scanned to guarantee authenticity, verify ownership and transfer artwork between collectors.
                    </p>
                    <div class="mt-5">
                        <img width="35%" src="https://www.gov.br/inss/pt-br/centrais-de-conteudo/imagens/qr-code-novo-fw-300x300-png/@@images/image" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-10 col-md-12 col-lg-4 mb-4">
            <div class="card text-center">
                <div class="card-body d-flex flex-column justify-content-center">
                    <h6 class="text-primary font-weight-bold">Trade</h6>
                    <p class="card-text text-dark border-0">
                        Every collector who purchases a physical or digital edition receives a unique identifier, gaining access to the newly acquired work's digital token (NFT).
                    </p>
                    <div class="mt-sm-5 pt-sm-3 qr-code">
                        <p class="mt-5 font-weight-light text-grey-light">Transaction Data</p>
                        <h2 class="text-secondary">A2K3••••••O918</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-10 col-md-12 col-lg-4 mb-4">
            <div class="card text-center">
                <div class="card-body d-flex flex-column justify-content-center">
                    <h6 class="text-primary font-weight-bold">Track</h6>
                    <p class="card-text border-0 text-secondary">
                        The NFT or digital token system ensures that an artwork's digital and physical owner match in order to guarantee market value.
                    </p>
                    <div class="mt-5">
                        <img src="/assets/img/grafico-home-two.png" alt="" width="90%">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>