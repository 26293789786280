<div *ngIf="tradeBuyerStep == 0">
    <div class="modal-header">
        <h4 class="modal-title font-weight-bold" id="modal-basic-title">You must login</h4>
        <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
    <div class="modal-body px-5">
        <app-auth-forms [form]="authFormMode" [redirect]="false" (onSubmit)="submitForm($event)"></app-auth-forms>
    </div>
</div>

<div *ngIf="tradeBuyerStep == 1">
    <div class="modal-header">
        <h4 class="modal-title font-weight-bold" id="modal-basic-title">1. Request sent by {{ institution.full_name }}</h4>
        <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
    <ngb-progressbar type="primary" [value]="tradeBuyerStep * 33" style="height: 0.3em"></ngb-progressbar>
    <div class="modal-body px-5">
        <h4>Do you want to allow the {{ institution.full_name }} institution to manage your artwork {{artwork.title}}?</h4>
        <div style="box-shadow: 0px 20px 50px 0px rgba(0,0,0,0.15); border-radius: 9px; background-color: #FFF;">
            <div style="padding:30px; border-bottom: 1px solid #EEE; text-align: center;">
              <img style="width: 100%; border-radius: 9px;" src="{{ artwork.img }}" alt="">
              <br>
              <br>
              <b>Artwork</b><br>
              <span style="color: #787D7E">{{ artwork.title }}</span><br>
              <br>
              <b>Artist</b><br>
              <span style="color: #787D7E">{{ artwork.artist.full_name }}</span><br>
              <br>
            </div>
          </div>
    </div>
    <div class="modal-footer d-flex justify-content-center">
        <button type="button"  class="btn-lg btn btn-danger cursor-pointer" (click)="buttonClose()">Cancel</button>
        <button type="button"  class="btn-lg btn btn-primary cursor-pointer" (click)="acceptInvite()">Accept</button>
    </div>
</div>