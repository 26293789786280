import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-institution-artwork-add-to-folder',
  templateUrl: './modal-institution-artwork-add-to-folder.component.html',
  styleUrls: ['./modal-institution-artwork-add-to-folder.component.scss']
})
export class ModalInstitutionArtworkAddToFolderComponent implements OnInit {
  
  @Input() artwork: any;
  public newFolder: string;
  public folders: any;
  public selectedFolder: any;
  
  constructor(
    public api: ApiService,
    public modalService: NgbModal,
    public router: Router,
    ) { }
    
    ngOnInit(): void {
      this.api.get('artworks/folders').then((response) => {
        this.folders = response
      })
    }
    
    async createFolders() {
      let payload = {
        title: this.newFolder,
        description: '...'
      }
      
      this.api.post('artworks/folders', payload).then((response) => {
        Swal.fire(
          'Added!',
          'Your artwok has been added to your folder!',
          'success',
          )
          .then((response) => {
            if (response.isConfirmed) {
              this.api.get('artworks/folders').then((response) => {
                this.folders = response
              })
            }
          })
        })
      }
      
      async createAddtoFolder(folder_id: number, artwork_id:number) {
        let payload = {
          artwork_id: artwork_id,
        }
        
        this.api.post('artworks/folders/'+folder_id, payload).then((response) => {
          Swal.fire(
            'Added!',
            'Your folder has been Added!.',
            'success',
            )
            .then((response) => {
              this.buttonClose()
            })
          })
        }
        
        
        buttonClose(){
          const modalRef = this.modalService.dismissAll();
        }
      }
      