<div class="row">
    <div class="col">
        <div ngbDropdown class="d-inline-block">
            <button  type="button" class="buttonDropDown" id="dropdownBasic1" ngbDropdownToggle><img src="../../../assets/icons/threepoints.png" alt=""></button>
            <div ngbDropdownMenu="dropdownBasic" class="boxMenuDropdrown">
                <a (click)="openTransaction()" ngbDropdownItem class="dropDownContent borderRadiusUpBox text-grey-light font-weight-light cursor-pointer">Trade</a>
                <a (click)="openAddtoList(artwork_version_id)" ngbDropdownItem class="dropDownContent borderRadiusDownBox text-grey-light font-weight-light cursor-pointer">Add to list</a>
            </div>
        </div>
    </div>
</div>