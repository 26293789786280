<div class="text-center py-4">
    <h4>Edit this collection</h4>
    <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0">
        <img src="/assets/icons/close.svg">
    </button>
    <p class="mt-4">You can creat collections to organize you artworks.</p>
    <div class="col-sm-9 col-12 mx-auto p-0">
        <input type="text" class="form-control" placeholder="Collection name" disabled />
    </div>
    <div class="col-sm-9 col-12 mx-auto p-0">
        <textarea class="form-control mt-4" rows="4" placeholder="Description" disabled></textarea>
    </div>
    <div class="mt-4">
        <button type="button"  class="btn btn-primary ml-2 px-5 mt-3 cursor-pointer">Done</button>
    </div>
</div>