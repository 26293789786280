import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCreateAccountStripeComponent } from 'src/app/modals/modal-create-account-stripe/modal-create-account-stripe.component';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-create-account-stripe',
  templateUrl: './create-account-stripe.component.html',
  styleUrls: ['./create-account-stripe.component.scss']
})
export class CreateAccountStripeComponent implements OnInit {
  
  constructor(
    public modalService: NgbModal,
    public auth: AuthService,
    public api: ApiService,
    ) { }
    
    ngOnInit(): void {
    }
    
    createStripe(){
      let modal = this.modalService.open(ModalCreateAccountStripeComponent)
    } 
  }

  