<div class="container-fluid dash-initial px-sm-5 pb-5">
    <div class="row">
        <div class="col-lg-12 d-sm-flex justify-content-between">
            <div class="mt-4 ml-1">
                <p class="mb-0 text-grey font-weight-light">Your Vault</p>
                <h1 class="text-primary font-weight-bold">Purchased Works</h1>
            </div>
        </div>
    </div>
    <hr>
    <app-artworks-list [dropDown]="'collector'" [endpoint]="'collector/artworks'"></app-artworks-list>
</div>