    <div *ngIf="tradeBuyerStep == 0">
    <div class="modal-header">
        <h4 class="modal-title font-weight-bold" id="modal-basic-title">You must login</h4>
        <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
    <div class="modal-body px-5">
        <app-auth-forms [form]="authFormMode" [redirect]="false" (onSubmit)="submitForm($event)"></app-auth-forms>
        
    </div>
</div>

<div *ngIf="tradeBuyerStep == 1">
    <div class="modal-header d-flex justify-content-center">
        <h4 class="modal-title font-weight-bold" id="modal-basic-title">1. Trade initiated by QR Code <span *ngIf="version.owner_id != null">{{version.owner.full_name}}</span></h4>
        <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
    <ngb-progressbar type="primary" [value]="tradeBuyerStep * 33" style="height: 0.3em"></ngb-progressbar>
    <div class="modal-body px-5">
        <p class="text-grey-light font-weight-light">Nice! you are about to buy an artwork. If everything looks good, continue to payment to finish the trade process.</p>
        <div class="d-flex justify-content-between">
            <div class="col-md-5">
                <div>
                    <h5 class="font-weight-bold">Artwork</h5>
                    <p class="text-grey-light font-weight-light">{{version.artwork.title}}</p>
                </div>
                <div>
                    <h5 class="font-weight-bold">Artist</h5>
                    <p class="text-grey-light font-weight-light">{{version.artwork.artist.full_name}}</p>
                </div>
                <div *ngIf="version.owner_id != null">
                    <h5 class="font-weight-bold">Owner</h5>
                    <p class="text-grey-light font-weight-light">{{version.owner.full_name}}</p>
                </div>
                <div>
                    <h5 class="font-weight-bold">Type</h5>
                    <p class="text-grey-light font-weight-light">{{version.artwork.digital ? 'Digital' : 'Physical'}}</p>
                </div>
                <div>
                    <h5 class="font-weight-bold">Edition</h5>
                    <p class="text-grey-light font-weight-light">{{ version.version }}/{{ version.artwork.edition }}</p>
                </div>
            </div>
            <div class="col-md-7">
                <!-- <img width="173" src="{{ version.artwork.img }}" style="border-radius: 9px;object-fit: cover;" alt=""> -->
                <app-preview-artwork [img]="version.artwork.img" [preview]="version.artwork.img" [styleBox]="'width: 100%;max-height: auto;height: auto'" [styleImage]="'width: 100%;max-height: auto;height: auto;border-radius: 9px; object-fit: cover'" ></app-preview-artwork>
            </div>
        </div>
        <hr class="p-0">
        <div>
            <div class="d-flex justify-content-between">
                <h5 class="font-weight-bold">Price</h5>
                <p class="text-grey-light font-weight-light">US$ {{(version.price/100).toFixed(2)}}</p>
            </div>
            <div *ngIf="version.owner_id != null" class="d-flex justify-content-between">
                <h5  class="font-weight-bold">Platform fees</h5>
                <p class="text-grey-light font-weight-light">US$ {{((version.artwork.platform_fee/100) * (version.price/100) / 100).toFixed(2)}}</p>
            </div>
            
            <div class="d-flex justify-content-between">
                <h5 class="font-weight-bold">Payment Gateway fees</h5>
                <p>US$ {{((4.5) * (version.price/100) / 100).toFixed(2)}}</p>
            </div>
            
            <div *ngIf="version.owner_id != null" class="d-flex justify-content-between">
                <h5 class="font-weight-bold">Artist royalties</h5>
                <p class="text-grey-light font-weight-light">US$ {{((version.artwork.author_fee/100) * (version.price/100) / 100).toFixed(2)}}</p>
            </div>
            <div *ngIf="version.artwork.institution_fee > 0 && version.owner_id != null" class="d-flex justify-content-between">
                <h5 class="font-weight-bold">Institution royalties</h5>
                <p class="text-grey-light font-weight-light">US$ {{((version.artwork.institution_fee/100) * (version.price/100) / 100).toFixed(2)}}</p>
            </div>
            <div class="d-flex justify-content-between">
                <h5 class="font-weight-bold text-primary">Total</h5>
                <p class="font-weight-bold text-primary">US$ {{ (total).toFixed(2)}}</p>
            </div>
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-start">
        <a class="text-primary"> Cancel trade</a>
        <button type="button"  class="btn btn-primary cursor-pointer" (click)="tradeBuyerStep = 2">Continue to payment</button>
    </div>
</div>

<div class="container" *ngIf="tradeBuyerStep == 2">
    <div class="row">
        <div class="col-lg-12 col-md-10 col-sm-10 text-center mx-auto p-3">
            <div class="p-2">
                <h3 class="font-weight-bold text-secondary">2. Payment <span class="font-weight-bold text-primary"> US$ {{ (total).toFixed(2)}}</span></h3>
                <!-- <ngb-progressbar type="primary" [value]="tradeBuyerStep * 33" style="height: 0.3em"></ngb-progressbar> -->
                <div class="col mt-4">
                    <button type="button" class="btn btn-secondary text-white font-weight-regular px-4">Card</button>
                </div>
                <div class="col mt-4">
                    <input class="ínput-price bg-light border-0 w-100" [(ngModel)]="cardNumber" placeholder="Card number" type="tel" style="height: 50px; font-size: 18px;" required checked>
                </div>
                <div class="d-flex mt-4">
                    <div class="col">
                        <input class="ínput-price bg-light border-0 w-100" [(ngModel)]="cardMonth" placeholder="MM" type="tel" style="height: 50px; font-size: 18px;" required checked>
                    </div>
                    <div class="col">
                        <input class="ínput-price bg-light border-0 w-100" [(ngModel)]="cardYear" placeholder="YYYY" type="tel" style="height: 50px; font-size: 18px;" required checked>
                    </div>
                    <div class="col">
                        <input class="ínput-price bg-light border-0 w-100" [(ngModel)]="cardCvv" placeholder="CVV" type="tel" style="height: 50px; font-size: 18px;" required checked>
                    </div>
                </div>
                <div class="w-100 mt-4 col">
                    <input class="ínput-price bg-light border-0 w-100" [(ngModel)]="cardName" placeholder="Card name" type="text" style="height: 50px; font-size: 18px;" required checked>
                </div>
                <hr>
                <div class="row mt-5">
                    <div class="col-2 mt-2">
                        <a class="btn text-primary text-center font-weight-regular p-0" (click)="tradeBuyerStep = 1">Back</a>
                    </div>
                    <div class="col-7 px-1">
                        <button type="button" (click)="paymentTransaction(transaction)" class="btn btn-primary text-white font-weight-regular p-2 w-100 ml-4">Accept</button>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-center mt-3">
                    <img src="/assets/icons/shield-check.png" alt="secure payment">
                    <p class="font-weight-light text-center mb-0">Secure Payment</p>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="container" *ngIf="tradeBuyerStep == 2">
    <div class="row">
        <div class="col-lg-12 col-md-10 col-sm-10 text-center mx-auto py-3 px-0">
            <div>
                <div>
                    <h3 class="font-weight-bold text-secondary px-5">2. Choose a payment method. <span class="font-weight-bold text-primary"> US$  {{ (total).toFixed(2)}}</span></h3>
                    <ngb-progressbar type="primary" [value]="tradeBuyerStep * 33" style="height: 0.3em"></ngb-progressbar>
                    <div class="row justify-content-start d-flex align-items-center mt-5">
                        <div class="col-lg-2 mt-2">
                            <a (click)="tradeBuyerStep = 1" class="btn text-primary text-center font-weight-regular">Back</a>
                        </div>
                        <div class="col-lg-7 ml-4">
                            <button type="button" class="btn btn-primary text-white font-weight-regular p-2 w-100" (click)="paymentTransaction( version )">Pay</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 d-flex justify-content-center align-items-center py-3">
                <img src="/assets/icons/shield-check.png" style="width: 16px;">
                <p class="text-grey-light font-weight-light mb-0 ml-2">Secure Payment</p>
            </div>
        </div>
    </div>
</div> -->

<div *ngIf="tradeBuyerStep == 3">
    <div class="modal-header">
        <h4 class="modal-title font-weight-bold ml-5 px-5" id="modal-basic-title">Payment successful!</h4>
        <a  class="close cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <img src="/assets/icons/close.svg" alt="">
        </a>
    </div>
    <ngb-progressbar type="primary" [value]="tradeBuyerStep * 33" style="height: 0.3em"></ngb-progressbar>
    <div class="modal-body px-5">
        <div class="text-center ">
            <img src="/assets/img/purchased-green.png" alt="">
            <h4>Artwork transfered.</h4>
            <h4>Trade complete.</h4>
            <p>January 20th, 2021 - 11:35:41 AM</p>
        </div>
        <div class="">
            <p>Transaction Signature</p>
            <input type="text" value="{{ transaction.hash_token }}" class="form-control">
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-center">
        <button type="button"  class="btn btn-primary cursor-pointer">See your artwork</button>
    </div>
</div>