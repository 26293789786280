<div class="row" id="import-artworks">
    <div class="col-lg-12 text-center">
        <h4 class="secondary-color mt-4">Importing your Artworks</h4>
        <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
</div>
<hr>
<div class="row mb-5">
    <div class="col-12 text-center">
        <img src="assets/img/update.png" alt="update">
        <h4 class="secondary-color mt-2">"User Name"</h4>
        <div class="row">
            <div class="offset-2 col-8 text-center">
                <p class="mt-1">Art Vault importing in-progress. Don't worry, we're handling each artwork with museum-worthy, white-glove care.</p>
            </div>
        </div>
    </div>
</div>
<hr>
<div class="row">
    <div class="col-3 text-center">
        <button type="button" class="border-0 mt-1 bg-transparent text-primary">Skip</button>
    </div>
    <div class="col-6 text-center">
        <button type="button"  class="btn btn-primary px-5 mb-3 cursor-pointer">Import</button>
    </div>
</div>



<!-- <div class="row"  id="importing-artworks">
    <div class="col-11 text-center">
        <h4 class="secondary-color pl-5 mt-4">Importing your artworks</h4>
    </div>
    <div class="col-1 pr-3">
        <a href="" class="h5 text-grey">x</a>
    </div>
</div>
<hr>
<div class="row mb-5">
    <div class="col-12 text-center p-5">
        <div class="row">
            <div class="offset-2 col-8 text-center">
                <p class="mt-2">Art Vault importing in-progress. Don't worry, we're handling each artwork with museum-worthy, white-glove care.</p>
            </div>
        </div>
        <div class="row mt-3 mb-1">
            <div class="offset-10 col-2">
                12/49
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="progress">
                    <p><ngb-progressbar type="success" textType="white" [value]="25" [showValue]="true"></ngb-progressbar></p>
                </div>
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-2">
                <p class="text-grey text-left">In progress</p>
            </div>
        </div>
    </div>
</div>
<hr>
<div class="row">
    <div class="col-3 text-center">
        <button type="button" class="border-0 mt-1 bg-transparent text-primary">Cancel</button>
    </div>
    <div class="col-6 text-center">
        <button type="button"  class="btn btn-primary px-5 mb-3 cursor-pointer">Import</button>
    </div>
</div> -->



<!-- <div class="row" id="import-complete">
    <div class="col-11 text-center">
        <h4 class="text-secondary mt-4">Installation complete</h4>
    </div>
    <div class="col-1 pr-3">
        <a href="" class="h5 text-grey">x</a>
    </div>
</div>
<hr>
<div class="row mb-5">
    <div class="col-12 text-center">
        <img src="assets/img/update.png" alt="update">
        <h4 class="text-secondary mt-2">49/49 artworks have been imported</h4>
        <div class="row">
            <div class="offset-2 col-8 text-center">
                <p class="mt-2">On January 20th, 2021 - 11:35:41 AM</p>
            </div>
        </div>
    </div>
</div>
<hr>
<div class="row">
    <div class="offset-2 col-8 text-center">
        <button type="button"  class="btn btn-primary px-5 mb-3 w-100 cursor-pointer">Go to Dashboard</button>
    </div>
</div> -->