import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';
import { ModalUploadImageComponent } from '../modal-upload-image/modal-upload-image.component';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-modal-institution-add-artists',
  templateUrl: './modal-institution-add-artists.component.html',
  styleUrls: ['./modal-institution-add-artists.component.scss']
})
export class ModalInstitutionAddArtistsComponent implements OnInit {
  
  @Input() invite: any;
  public about: any;
  public infoRoyalty: boolean;
  public infoEscrwo: boolean;
  public InstitutionAddStep: number = 0;
  public coverImage: string = '/assets/img/cover.svg';
  public artists: any = [
    {
      institution_fee_amount: 5,
      institution_escrow_amount: 10
    }
  ];
  public Editor = ClassicEditor;
  
  constructor(
    public modalService: NgbModal,
    public router: Router,
    public api: ApiService,
    private auth: AuthService,
    ) { }
    
    ngOnInit(): void {
      if(this.invite){
        this.about = this.invite.creator.about
        this.coverImage = this.invite.creator.cover
        this.invite.institution_escrow_amount = this.invite.institution_escrow_amount/100
        this.invite.institution_fee_amount = this.invite.institution_fee_amount/100
      }
    }
    
    public onReady( editor ) {
      editor.ui.getEditableElement().parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
    }
    
    addAnotherArtist(){
      this.artists.push({
        institution_fee_amount: 5,
        institution_escrow_amount: 10
      });
    }
    
    removeArtist(index: any){
      this.artists.splice(index,1);
    }
    
    openModalUploadCoverImage() {
      const modalRef = this.modalService.open(ModalUploadImageComponent);
      modalRef.componentInstance.typeUpload = 'cover';
      modalRef.componentInstance.user = this.invite.creator;
    }
    
    updateInvite(){
      var invite = JSON.parse(JSON.stringify(this.invite))
      delete invite.institution
      delete invite.creator
      invite.institution_escrow_amount = this.invite.institution_escrow_amount*100
      invite.institution_fee_amount = this.invite.institution_fee_amount*100
      this.api.put('institutions/invite/'+invite.id, invite).then((response) => {
        let payload = {user:{about: this.about}, contact_info: this.invite.creator.contact_information ? this.invite.creator.contact_information: {}}
        console.log(payload)
        this.api.put('users/'+this.invite.creator.id, payload).then((response) => {
            Swal.fire('Updated!', 'Your invite has been successfully updated', 'success')
            .then((response) => {
              if (response.isConfirmed) {
                window.location.reload()
              }
            })
        })
      })
    }
    
    sendInvite(){
      this.api.post('institutions/'+this.auth.userData.id+'/inviteArtist', this.artists).then((response) => {
        let text = ''
        for(let erro of response){
          text += erro.email + "<br>"
        }
        if(response.length > 0){
          Swal.fire({
            title: 'Ops!',
            html: "<p>These users are not registered or are not artists:<br>"+text+"</p>",
            icon: 'warning'
          }).then((response)=>{
            this.buttonClose()
          })
        }else{
          Swal.fire(
            'Invited!',
            'Your invitations have been sent successfully',
            'success'
            )
            .then((response) => {
              if (response.isConfirmed) {
                this.buttonClose()
              }
            })
          }
        })
      }
      
      buttonClose(){
        const modalRef = this.modalService.dismissAll();
      }
    }
    