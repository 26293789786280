<div class="text-center pb-4" *ngIf="viewMode === 'step-one'">
    <div class="row">
        <div class="col-lg-12 pt-4">
            <h4 class="text-secondary font-weight-bold">First thing first.</h4>
            <h4 class="text-primary font-weight-bold">Create an Artwork</h4>
            <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0">
                <img src="/assets/icons/close.svg">
            </button>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-lg-12 text-justify px-5">
            <p class="text-grey mt-4 px-4">Please, elect if you want to create a Physical or Digital Artwork.</p>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 pr-0">
            <a (click)="selectTypeArt(false)" class="nav-link cards-options">
                <div class="card" style="width: 13rem;height: 9.2rem;">
                    <div class="mt-3">
                        <h5 class="card-title text-secondary font-weight-bold">Physical</h5>
                        <p class="text-grey">Lorem ipsum dolor sit amet, consectetur.</p>
                    </div>
                </div>
            </a>

        </div>
        <div class="col-lg-6 pl-0">
            <a (click)="selectTypeArt(true)" class="nav-link">
                <div class="card" style="width: 13rem;height: 9.2rem;">
                    <div class="mt-3">
                        <h5 class="card-title text-secondary font-weight-bold">Digital</h5>
                        <p class="text-grey">Lorem ipsum dolor sit amet, consectetur.</p>
                    </div>
                </div>
            </a>
        </div>
    </div>
    <hr>
    <div class="mt-4 text-left">
        <a href="#" class="btn btn-outline pl-4 pr-4 text-primary">Go to Dashboard</a>
        <a (click)="goToStepTwo()" class="btn btn-primary font-weight-bold pl-5 pr-5" style="width: 10em;">Next</a>
    </div>
</div>


<div class="text-center pb-4" *ngIf="viewMode === 'step-two'">
    <div class="row">
        <div class="col-lg-12 text-right">
            <button type="button" class="btn btn-outline">
                <img src="/assets/icons/close.svg">
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <h4 class="text-secondary font-weight-bold">First thing first.</h4>
            <h4 class="text-primary font-weight-bold">Create an Artwork</h4>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-lg-12 text-justify px-5">
            <p class="text-grey mt-4 px-4">Drop the artworks you want to present, to apply. Formats accepted: JPG, PNG and PDF</p>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 pr-0">
            <a href="#" class="nav-link">
                <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)">
                    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                        Optional custom content that replaces the the entire default content.
                        <button type="button"  (click)="openFileSelector()">Browse Files</button>
                    </ng-template>
                </ngx-file-drop>
                <div class="upload-table">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                            </tr>
                        </thead>
                        <tbody class="upload-name-style">
                            <tr *ngFor="let item of files; let i=index">
                                <td><strong>{{ item.relativePath }}</strong></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </a>
        </div>
    </div>
    <hr>
    <div class="mt-4 text-left">
        <a href="#" class="btn btn-outline pl-4 pr-4 text-primary">Cancel</a>
        <a href="#" class="btn btn-primary font-weight-bold pl-5 pr-5" style="width: 18em;">Next</a>
    </div>
</div>