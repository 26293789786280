import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { countries } from '../data/countries';
import { ModalUploadImageComponent } from '../modals/modal-upload-image/modal-upload-image.component';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { genres} from '../data/genres'
import { races } from '../data/races';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  
  public formProfile: FormGroup;
  public coverImage: string = '/assets/img/cover.svg';
  public countries = countries;
  public Editor = ClassicEditor;
  public genres = genres;
  public errorFormatNumberTwoFactor = false;
  public races = races;
  public inputValue = '';
  public urlBase = environment.URL_BASE;
  @Input() mode: string = 'profile';
  
  constructor(
    public formBuilder: FormBuilder,
    public auth: AuthService,
    private api: ApiService,
    public modalService: NgbModal,
    ){
      this.genres = genres;
      this.countries = countries;
      this.races = races;
      this.createFormProfile()
    }
    
    ngOnInit(): void {
      if(this.auth.userData.cover){
        this.coverImage = this.auth.userData.cover
      }
    }
    
    async portalSubscription() {
      await this.api.get('stripe/portalSubscription').then(res => {
        window.open(res.url)
      })
    }
    
    public onReady( editor ) {
      editor.ui.getEditableElement().parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
    }
    
    openModalUploadCoverImage() {
      const modalRef = this.modalService.open(ModalUploadImageComponent);
      modalRef.componentInstance.typeUpload = 'cover';
    }
    
    async updateProfile({valid, value}: {valid: boolean; value: any}) {
      this.errorFormatNumberTwoFactor = false
      var phone = value.phone_number
      if(value.phone_number){
        if(phone.substr(0, 1) != '+'){
          this.errorFormatNumberTwoFactor = true
          return false
        }
      }
      let payload = {
        user: {
          full_name: value.full_name,
          about: value.about,
          phone_number: phone,
          confirm_two_factor_mobile: value.confirm_two_factor_mobile,
        },
        contact_info: {
          found_date: value.found_date,
          email: value.email,
          phone: value.phone,
          street: value.street,
          city: value.city,
          gender: value.gender,
          race: value.race,
          country: value.country,
          state: value.state,
          zip_code: value.zip_code,
          instagram: value.instagram,
          twitter: value. twitter,
          facebook: value.facebook,
          whatsapp: value.whatsapp,
          linkedin: value.linkedin,
          blog: value.blog,
        },
      }
      this.api.put('users/'+this.auth.userData.id, payload).then(async (response) => {
        await this.auth.currentAuthenticatedUser('user/'+this.auth.userData.id).then(async res => {
          await this.auth.updateSubscriptionData().then(val => {
            console.log(val)
          })
        })
      })
    }
    
    private createFormProfile() {
      this.formProfile = this.formBuilder.group({
        full_name: [this.auth.userData.full_name ? this.auth.userData.full_name : null, Validators.required],
        about: [this.auth.userData.about ? this.auth.userData.about : null, Validators.required],
        found_date: [this.auth.userData.contact_information?.found_date ? this.auth.userData.contact_information.found_date : null],
        email: [this.auth.userData.contact_information?.email ? this.auth.userData.contact_information.email : this.auth.userData.email],
        confirm_two_factor_mobile: [this.auth.userData.confirm_two_factor_mobile ? this.auth.userData.confirm_two_factor_mobile : null],
        phone_number: [this.auth.userData.phone_number ? this.auth.userData.phone_number : null],
        phone: [this.auth.userData.contact_information?.phone ? this.auth.userData.contact_information.phone : null],
        street: [this.auth.userData.contact_information?.street ? this.auth.userData.contact_information.street : null],
        city: [this.auth.userData.contact_information?.city ? this.auth.userData.contact_information.city : null],
        gender: [this.auth.userData.contact_information?.gender ? this.auth.userData.contact_information.gender : null],
        race: [this.auth.userData.contact_information?.race ? this.auth.userData.contact_information.race : null],
        country: [this.auth.userData.contact_information?.country ? this.auth.userData.contact_information.country : null],
        state: [this.auth.userData.contact_information?.state ? this.auth.userData.contact_information.state : null],
        zip_code: [this.auth.userData.contact_information?.zip_code ? this.auth.userData.contact_information.zip_code : null],
        instagram: [this.auth.userData.contact_information?.instagram ? this.auth.userData.contact_information.instagram : null],
        twitter: [this.auth.userData.contact_information?.twitter ? this.auth.userData.contact_information.twitter : null],
        facebook: [this.auth.userData.contact_information?.facebook ? this.auth.userData.contact_information.facebook : null],
        whatsapp: [this.auth.userData.contact_information?.whatsapp ? this.auth.userData.contact_information.whatsapp : null],
        linkedin: [this.auth.userData.contact_information?.linkedin ? this.auth.userData.contact_information.linkedin : null],
        blog: [this.auth.userData.contact_information?.blog ? this.auth.userData.contact_information.blog : null],
      })
    }
    
    onKey(event) {
      this.inputValue = event.target.value;
    }
    
    copyToClipboard() {
      const content = (document.getElementById('url') as HTMLElement).innerText;
      navigator['clipboard'].writeText(content).then().catch(e => console.error(e));
    }
  }
  