<div class="container-fluid">
    <div class="row">
        <h1 class="font-weight-bold m-5">Privary policy</h1>
    </div>
    <div class="row">
        <div class="col-10 m-4">
            <p class="text-grey-link font-weight-light">
                Welcome to Alphaa.io, owned and operated by Alpha’a Inc. These Terms of Service regulate your access and application of the Alphaa.io website, our APIs, mobile app, and any other features, tools, software, or services provided by Alphaa.io. This includes but is not limited to using our services to create, trade, and view NFTs and using our technology to connect directly with others to buy, trade, or transfer NFTs on public blockchains.
            </p>
            <p class="text-grey-link font-weight-light">
                NFT in these Terms refers to any blockchain-tracked, non-fungible token that can be sold and traded. Types of NFT data units include: physical artworks associated with digital files such as photos, videos, and digital artwork.
            </p>
            <p class="text-grey-link font-weight-light">
                By signing up you are agreeing with our terms of services and acknowledging that the information that you provide on our services is accurate and that you are acting on behalf of yourself.
            </p>
            <p class="text-grey-link font-weight-light">
                Alphaa.io does not make any claims about the identity, legitimacy, functionality, or authenticity of our users or the NFTs on our services. It is your responsibility to verify any information about the NFTs and the third-party seller you buy and trade with.
            </p>
        </div>
            <!-- <div class="col-6">
                <h5>Related Contents</h5>

                <p class="text-primary">Terms of Use</p>
                <p class="text-primary">About Us</p>
            </div> -->
    </div>
</div>
