import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCollectionComponent } from 'src/app/modals/modal-collection/modal-collection.component';
import { ModalEditListComponent } from 'src/app/modals/modal-edit-list/modal-edit-list.component';
import { ModalCreateListComponent } from 'src/app/modals/modal-create-list/modal-create-list.component';

import { ApiService } from 'src/app/services/api.service';



@Component({
  selector: 'app-lists',
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.scss']
})
export class ListsComponent implements OnInit {
  viewMode: any;
  public lists : any;
  public list : any;
  public id : number;
  
  constructor(
    public api: ApiService,
    public route: ActivatedRoute,
    public modalService: NgbModal
    ) {}
    
    ngOnInit(): void {
      this.viewMode = this.route.snapshot.params.gallery ? "details" : "list"
      this.id = this.route.snapshot.params.gallery
      if(this.viewMode == "list"){
        this.api.get('artworks/lists').then((response) => {
          this.lists = response
        })
      }else{
        this.api.get('artworks/lists/'+this.id).then((response) => {
          this.list = response
        })
      }
    }  
    
    openCreateCollection(){
      const modalRef = this.modalService.open(ModalCollectionComponent);
    }
    
    openEditList(instance){
      const modalRef = this.modalService.open(ModalEditListComponent);
      modalRef.componentInstance.list = instance;

    }
    
    openCreateList(){
      const modalRef = this.modalService.open(ModalCreateListComponent);
    }
    
  }
  