import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalInstitutionRemoveComponent } from 'src/app/modals/modal-institution-remove/modal-institution-remove.component';
import { ModalInstitutionRequestManagementComponent } from 'src/app/modals/modal-institution-request-management/modal-institution-request-management.component';
import { ModalInstitutionAddArtistsComponent } from 'src/app/modals/modal-institution-add-artists/modal-institution-add-artists.component';


@Component({
  selector: 'app-dropdown-institution-artists',
  templateUrl: './dropdown-institution-artists.component.html',
  styleUrls: ['./dropdown-institution-artists.component.scss']
})
export class DropdownInstitutionArtistsComponent implements OnInit {
  
  @Input() invite: any;
  
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public modalService: NgbModal,
    ) { }
    
    ngOnInit(): void {
    }
    
    editArtists() {
      const modalRef = this.modalService.open(ModalInstitutionAddArtistsComponent);
      modalRef.componentInstance.invite = this.invite
    }
    
    requestManager(){
      const modalRef = this.modalService.open(ModalInstitutionRequestManagementComponent)
      modalRef.componentInstance.invite = this.invite

    }
    
    removeArtists(){
      const modalRef = this.modalService.open(ModalInstitutionRemoveComponent)
      modalRef.componentInstance.invite = this.invite
    }
  }
  