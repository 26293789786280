import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-top-selling-artists',
  templateUrl: './top-selling-artists.component.html',
  styleUrls: ['./top-selling-artists.component.scss']
})
export class TopSellingArtistsComponent implements OnInit {
  @Input() img: any;
  public creatorTopSelling: any; 
  
  constructor(
    public api: ApiService,
    public auth: AuthService,
    ) {}
    
    ngOnInit(): void {
      this.api.get('institutions/creatorTopSelling').then((response) => {
        this.creatorTopSelling = response
      })
    }
    
  }
  