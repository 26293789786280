<div class="row" *ngIf="auth.profileView == 'creator'">
  <div class="col p-0">
    <div ngbDropdown class="d-inline-block">
      <button class="buttonDropDown cursor-pointer" id="dropdownBasic1" ngbDropdownToggle><img src="../../../assets/icons/threepoints.png" alt=""></button>
      <div ngbDropdownMenu="dropdownBasic" class="boxMenuDropdrownCreator">
        <a ngbDropdownItem class="dropDownContent borderRadiusUpBox cursor-pointer text-grey-light font-weight-light" (click)="openTransaction()">Trade</a>
        <hr class="m-0 p-0 gray">
        <a ngbDropdownItem class="dropDownContent cursor-pointer text-grey-light font-weight-light" (click)="openAddtoFolder(artwork_id)">Add to Folder</a>
        <!-- <hr class="m-0 p-0 gray"> -->
        <!-- <a ngbDropdownItem class="dropDownContent text-grey-light font-weight-light" (click)="requestNewPrice(artwork_id)">Request New Price</a> -->
        <hr class="m-0 p-0 gray">
        <a ngbDropdownItem class="dropDownContent borderRadiusDownBox cursor-pointer text-grey-light font-weight-light" (click)="viewArtwork(artwork_id)">View Artworks</a>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="auth.profileView == 'institution'">
  <div class="col-12">
    <div ngbDropdown class="d-inline-block">
      <button class="buttonDropDown cursor-pointer" id="dropdownBasic1" ngbDropdownToggle><img src="../../../assets/icons/threepoints.png" alt=""></button>
        <div ngbDropdownMenu="dropdownBasic" class="boxMenuDropdrown">
          <a ngbDropdownItem class="dropDownContent borderRadiusUpBox cursor-pointer text-grey-link font-weight-light cursor-pointer" (click)="AddFolder()">Add to folder</a>
          <hr class="m-0 p-0 gray">
          <a ngbDropdownItem *ngIf="artwork.allowed_institution !== auth.userData.id && artwork.allowed_institution !== null" class="dropDownContent cursor-pointer text-grey-link font-weight-light" (click)="RequestManagement()">Request management</a>
          <hr class="m-0 p-0 gray">
          <a ngbDropdownItem [disabled]="artwork.allowed_institution !== auth.userData.id && artwork.artist_id !== auth.userData.id" [class.disabled]="artwork.allowed_institution !== auth.userData.id && artwork.artist_id !== auth.userData.id" class="dropDownContent cursor-pointer text-grey-link font-weight-light" (click)="EditArtwork()">Edit</a>
          <hr class="m-0 p-0 gray">
          <a ngbDropdownItem [disabled]="artwork.allowed_institution !== auth.userData.id && artwork.artist_id !== auth.userData.id" [class.disabled]="artwork.allowed_institution !== auth.userData.id && artwork.artist_id !== auth.userData.id" class="dropDownContent borderRadiusDownBox cursor-pointer text-grey-link font-weight-light" (click)="ChangePrice()">Change price/royalties</a>
      </div>
    </div>
  </div>
</div>