import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardService } from 'ngx-clipboard';
import { ModalInstitutionAddArtistsComponent } from 'src/app/modals/modal-institution-add-artists/modal-institution-add-artists.component';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dash-institution',
  templateUrl: './dash-institution.component.html',
  styleUrls: ['./dash-institution.component.scss']
})
export class DashInstitutionComponent implements OnInit {
  
  @Input() disabledChart: boolean = false;
  
  public viewMode: any;
  
  
  constructor(
    public auth: AuthService,
    public api: ApiService,
    private route: ActivatedRoute,
    public router: Router,
    public modalService: NgbModal,
    private _clipboardService: ClipboardService,
    ) { 
      this.route.params.subscribe(params => this.viewMode = params['viewMode']);
    }
    
    ngOnInit(): void {
    }

    shareProfile(){
      this._clipboardService.copy(environment.URL_BASE+'/institution/'+this.auth.userData.slug)
      Swal.fire({
        icon: 'success',
        title: "Copied profile link",
        text: "Copy this link into your social media, website etc for a free template to sell your artwork"
      })
    }
    
    setOverviewMode(mode: string){
      this.viewMode = mode
    }
    
    openAddArtist(){
      const modalRef = this.modalService.open(ModalInstitutionAddArtistsComponent, {
        windowClass: 'myCustomModalClass'
      });
    }
 

  }
  