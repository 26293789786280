import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-return-stripe-register',
  templateUrl: './return-stripe-register.component.html',
  styleUrls: ['./return-stripe-register.component.scss']
})
export class ReturnStripeRegisterComponent implements OnInit {
  public account: any
  
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public api: ApiService,
    public auth: AuthService,
    ) { }
    
    ngOnInit(): void {
      this.route.queryParams.subscribe(params => {
        this.account = params['account']
      });
      
      if (typeof this.account !== 'undefined') {
        let payload = {
          user: {
            stripe_account_complete: true,
          },
          contact_info: {}
        }
        this.api.put('users/'+this.auth.userData.id, payload).then(async (response) => {
          await this.auth.currentAuthenticatedUser('user/'+this.auth.userData.id).then(async res => {
          })
        })
      }
    }
  }
  