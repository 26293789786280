<div class="card-header text-center p-4">
    <h3 class="text-secondary font-weight-bold mb-0">{{title}}</h3>
    <button type="button" (click)="buttonClose()" aria-label="Close" 
        class="btn btn-outline position-absolute right-0 top-0 p-1">
        <img src="/assets/icons/close.svg">
    </button>
</div>
<div class="card-body d-flex justify-content-center align-items-center p-2">
    <div style="position:relative">
        <iframe class="modal-video" [src]="urlSafe" title="{{title}}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
</div>

