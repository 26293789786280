import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-create-folders',
  templateUrl: './modal-create-folders.component.html',
  styleUrls: ['./modal-create-folders.component.scss']
})
export class ModalCreateFoldersComponent implements OnInit {
  public formCreateFolders: FormGroup;


  constructor(
    public router: Router,
    public api: ApiService,
    public route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.createFormFolders()
  }

  async createFolders({valid, value}: {valid: boolean; value: any}) {
    if (valid) {
      let payload = {
        title: value.title,
        description: value.description
      }

      this.api.post('artworks/folders', payload).then((response) => {
        const modalRef = this.modalService.dismissAll();
          location.reload()
      })
    }
  }



  private createFormFolders() {
    this.formCreateFolders = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required]
    })
  }

  buttonClose(){
    const modalRef = this.modalService.dismissAll();
   }
}
