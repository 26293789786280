<div class="row">
    <div class="col-lg-12 text-center p-3">
        <h4 class="text-secondary font-weight-bold">Add to folder</h4>
        <p class="mt-4 text-grey-light font-weight-light">You can then add a folder or delete one</p>
        <button type="button" (click)="buttonClose()" aria-label="Close" 
            class="btn btn-outline position-absolute right-0 top-0">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
</div>

<div class="col-lg-10 mx-auto">
    <ng-select [(ngModel)]="selectedFolder">
        <ng-option *ngFor="let folder of folders" [value]="folder.id">{{folder.title}}</ng-option>
    </ng-select>
</div>
<div class="row d-flex justify-content-center">
    <div class="col-sm-6 pb-3 px-4">
        <div class="mt-4">
            <button type="button" (click)="createAddtoFolder(selectedFolder, artwork_id)" class="cursor-pointer btn-lg btn btn-primary font-weight-regular w-100">Add </button>
        </div>
    </div>
</div>