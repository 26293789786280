<div class="container-fluid">
    <div class="row ml-2 mr-2">
        <div class="col-12 mt-4">
            <p class="mb-1 text-grey">Your Vault / Collection name</p>
            <h1 class="text-primary font-weight-bold mb-1">Artwork Name</h1>
            <p class="text-grey mb-5">Artists Name</p>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row ml-2 mr-2 pb-5">
        <div class="col-md-4 col-lg-3 mb-5">
            <app-versionSpecs></app-versionSpecs>
        </div>
        <div class="col-md-8 col-lg-9">
            <div class="card">
                <div class="row card-header bg-transparent">
                    <div class="col-sm-8 col-md-12 col-lg-5 mt-2 mb-2">
                        <a href="" id="titles-card-artworks" class="text-grey font-weight-normal h4 mr-3 card-title">Value</a>
                        <a href="" id="titles-card-artworks" class="text-grey font-weight-normal h4 mr-3 card-title">Record</a>
                        <a href="" id="titles-card-artworks" class="text-grey font-weight-normal h4 card-title">About</a>
                    </div>
                    <div class="col-sm-4 col-md-12 offset-lg-3 col-lg-4 mt-2">
                        <img src="../../../assets/img/qrcode.jpeg" class="mr-3" width="40px" alt="">
                        <button type="button"  class="btn btn-primary pl-4 pr-4 cursor-pointer">Trade</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>