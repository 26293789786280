import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';


@Component({
  selector: 'app-institution-artworks-from-artist',
  templateUrl: './institution-artworks-from-artist.component.html',
  styleUrls: ['./institution-artworks-from-artist.component.scss']
})
export class InstitutionArtworksFromArtistComponent implements OnInit {
  @Input() artworks: any;
  @Input() artist: any;
  
  constructor(
    public router: Router,
    public auth: AuthService,
    public api: ApiService,
    public helper: HelperService,
    ) {
      
    }
    
    ngOnInit(): void {
    }
    
    submitArtworkForThisArtist(){
      this.router.navigate(['dashboard-institution/artworks-create'], {queryParams: {artist_id: this.artist.id}})
    }
    
    verifyInsitituioOfArtist(){
      for(let invite of this.artist.invites){
        if(invite.institution_id == this.auth.userData.id){
          return true
        }
      }
      return false
    }
  }
  