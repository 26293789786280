<div class="row">
    <div class="col-12">
      <div ngbDropdown class="d-inline-block">
        <button  type="button" class="buttonDropDown" id="dropdownBasic1" ngbDropdownToggle><img src="../../../assets/icons/threepoints.png" alt=""></button>
        <div ngbDropdownMenu="dropdownBasic" class="boxMenuDropdrown">
          <a ngbDropdownItem class="dropDownContent borderRadiusUpBox text-grey-link font-weight-light cursor-pointer">Edit Artists</a>
          <hr class="m-0 p-0 gray">
          <a ngbDropdownItem class="dropDownContent text-grey-link font-weight-light cursor-pointer">Remove Artist</a>
          <hr class="m-0 p-0 gray">
          <a ngbDropdownItem class="dropDownContent borderRadiusDownBox text-grey-link font-weight-light cursor-pointer" (click)="requestManagement()">Request management</a>
        </div>
      </div>
    </div>
  </div>