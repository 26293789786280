import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-by',
  templateUrl: './order-by.component.html',
  styleUrls: ['./order-by.component.scss']
})
export class OrderByComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  setValue(value: any){
    console.log(value)
  }
}
