import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-modal-add-to-list',
  templateUrl: './modal-add-to-list.component.html',
  styleUrls: ['./modal-add-to-list.component.scss']
})
export class ModalAddToListComponent implements OnInit {

  @Input() public artwork_version_id: number;
  public lists: any;
  public selectedList: any;
  public addTolist: any;
  public id: number;

  constructor(
    public router: Router,
    public api: ApiService,
    public route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.api.get('artworks/lists').then((response) => {
      this.lists = response
    })
  }

  async createAddtoList(list_id: number, artwork_version_id:number) {
      let payload = {
        artwork_version_id: artwork_version_id,
      }

      this.api.post('artworks/lists/'+list_id, payload).then((response) => {
          const modalRef = this.modalService.dismissAll();
          location.reload()
      })
    }
 
    buttonClose(){
      const modalRef = this.modalService.dismissAll();
     }
}