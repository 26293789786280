import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { windowWhen } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-modal-institution-remove',
  templateUrl: './modal-institution-remove.component.html',
  styleUrls: ['./modal-institution-remove.component.scss']
})
export class ModalInstitutionRemoveComponent implements OnInit {
  
  public invite: any
  
  constructor(
    public modalService: NgbModal,
    public router: Router,
    public api: ApiService,
    ) { }
    
    ngOnInit(): void {
    }
    
    removeInviteCreator(){
      this.api.delete('institutions/invite/'+this.invite.id).then((response) => {
        Swal.fire(
          'Removed!',
          'Your invite has been removed.',
          'success',
          )
          .then((response) => {
            if (response.isConfirmed) {
              this.buttonClose()
              window.location.reload()
            }
          })
        })
      }
      
      buttonClose(){
        const modalRef = this.modalService.dismissAll();
      }
    }
    