<div class="text-center pb-0">
    <div class="row">
        <div class="col-lg-12 py-3">
            <div class="text-right">
                <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0">
                    <img src="/assets/icons/close.svg">
                </button>
            </div>
            <h2 class="text-secondary font-weight-bold mb-5">Institution royalties</h2>
            <hr>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 mt-2">
            <img src="/assets/icons/edition.png" alt="edition">
            <h3 class="font-weight-normal text-secondary mt-2">What are and how they work?</h3>
            <p class="text-grey mt-4 px-5">Be involved in your artist's career for the long term by setting institution royalties for each artwork they sell. Institutions can set a royalty of up to ten percent to receive a percentage of sales from all secondary market sales. Instead of auction houses and collectors keeping all revenue from resale transactions, blockchain-verification and tracking ensure that artists and institutions always receive royalties from secondary market sales.</p>
        </div>
    </div>
    <div class="row d-flex justify-content-center">
        <div class="col-lg-7 mt-4">
            <div class="mt-4">
                <button  (click)="buttonClose()" type="submit" class="btn btn-lg btn-primary font-weight-regular w-100">Understood</button>
            </div>
        </div>
    </div>

</div>
