 <div class="row justify-content-around my-1">
    <div class="col-lg-12">
        <h1 class="font-weight-bold text-secondary">Your Artists</h1>
    </div>
</div>
<div class="row mt-2">
    <div class="col-xl-5 col-lg-5">
        <div class="row tags-dash">
            <a (click)="filterArtists(2)" class="nav-link text-grey font-weight-light cursor-pointer" [class.active]="activeArtists === 2">Accepted</a>
            <a (click)="filterArtists(1)" class="nav-link text-grey font-weight-light cursor-pointer" [class.active]="activeArtists === 1">Proposed</a>
            <a (click)="filterArtists(0)" class="nav-link text-grey font-weight-light cursor-pointer" [class.active]="activeArtists === 0">All</a>
        </div>
    </div>
    <div class="col-xl-7 col-lg-7">
        <div class="row mt-2">
            <div class="col-lg-9 col-md-7 d-flex align-items-center pr-xl-0 InputSearch">
                <input class="form-control input-search w-100" (change)="searchArtists()" [(ngModel)]="searchArtist" type="text" placeholder="Search" checked>
                <button (click)="searchArtists()" class="btn btn-primary d-flex justify-content-center align-items-center cursor-pointer btn-search position-absolute right-0">Search</button>
            </div>
            <div class="col-3 d-md-flex justify-content-end">
                <!-- <app-order-by-institution></app-order-by-institution> -->
            </div>
        </div>
    </div>
</div>
<div class="row">
    <app-institution-from-artists [invites]="invites"></app-institution-from-artists>
</div>