<div class="container-fluid">
    <div class="row mx-sm-2">
        <div class="col mt-4 pt-2">
            <p class="mb-1 text-grey font-weight-light"><a [routerLink]="['/purchased-works']">Your Vault</a> / {{ version?.artwork.title }}</p>
            <h1 class="text-primary font-weight-bold mb-0" style="margin-top: -10px;">{{ version?.artwork.title }}</h1>
            <p class="text-grey font-weight-light mb-4 pb-2" style="margin-top: -5px;">{{ version?.artwork.artist.full_name }}</p>
        </div>
    </div>
</div>
<div class="container-fluid" *ngIf="version">
    <div class="row ml-md-2 mr-md-2 pb-5">
        <div class="col-md-4 col-lg-3 mb-5">
            <app-versionSpecs [version]="version" [artwork]="version?.artwork" [descriptionArtworkDesktop]="descriptionArtworkDesktop"></app-versionSpecs>
        </div>
        <div class="offset-lg-1 col-lg-8" *ngIf="innerWidth > 767.98">
            <div class="card">
                <div class="row card-header bg-transparent d-flex justify-content-between">
                    <div class="mt-2 mb-2 d-flex titles-version">
                        <a [routerLink]="['/versions/'+version.id+'/value']" routerLinkActive="selected" id="titles-card-versions"  class="text-grey font-weight-normal h4 card-title nav-link">Value</a>
                        <a [routerLink]="['/versions/'+version.id+'/record']" routerLinkActive="selected" id="titles-card-versions" class="text-grey font-weight-normal h4 card-title nav-link">Record</a>
                        <a [routerLink]="['/versions/'+version.id+'/about']" routerLinkActive="selected" id="titles-card-artworks" class="text-grey font-weight-normal h4 card-title nav-link">About</a>
                    </div>
                    <div class="d-flex mt-2 text-right justify-content-end align-items-center" *ngIf="auth.isAuthenticated.getValue() && auth.userData.id == version.owner_id">
                        <qrcode [qrdata]="'https://beta.alphaa.io/?tradeQr='+version.nft_token" [width]="70" [errorCorrectionLevel]="'M'" [elementType]="'svg'"></qrcode>
                        
                        <!-- <img src="../../../assets/img/qrcode.jpeg" class="mr-3" width="40px" alt=""> -->
                        <button type="button" (click)="openTransaction()"  class="btn btn-primary ml-3 pl-4 pr-4 cursor-pointer">Trade</button>
                    </div>
                </div>
                <div class="card-body" *ngIf="viewMode === 'value'">
                    <div>
                        <h5 class="font-weight-bold text-secondary">Current Value</h5>
                        <h1 class="text-primary font-weight-normal"> {{ helper.amountCurrencyFormat(version.price) }}</h1>
                    </div>
                    <div class="mt-5" *ngIf="chartOptions.chart">
                        <h4 class="font-weight-bold text-secondary">Fluctuation</h4>
                        <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions" style="width: 100%; height: 300px; display: block;"></highcharts-chart>
                    </div>
                </div>
                <div class="card-body" *ngIf="viewMode === 'record'">
                    <div class="row">
                        <div class="col-lg-12">
                            <h4 class="text-secondary font-weight-bold">Details</h4>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-lg-12 col-md-6">
                            <div class="row">
                                <div class="col-lg-3 pr-0">
                                    <p class="text-secondary font-weight-bold">Privacy setting</p>
                                </div>
                                <div class="col-lg-8 pl-0">
                                    <p class="text-secondary">Private</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-6">
                            <div class="row">
                                <div class="col-lg-3 pr-0">
                                    <p class="text-secondary font-weight-bold">Artist</p>
                                </div>
                                <div class="col-lg-8">
                                    <p class="text-grey font-weight-light mb-2">{{ version.artwork.artist.full_name }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-3 pr-0">
                                    <p class="text-secondary font-weight-bold">Hash Token</p>
                                </div>
                                <div class="col-lg-8 col-md-6">
                                    <p class="text-grey font-weight-light mb-2">{{ version.fake_token }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- <div class="row mt-3">
                        <div class="col-lg-12 mt-4">
                            <h3 class="text-secondary font-weight-bold">In progress</h3>
                            <p class="text-grey font-weight-light"> Lorem ipsun dolor sit amet Lorem ipsin dolor sit amet.</p>
                        </div>
                    </div> -->
                    
                    <!-- <app-trade-status></app-trade-status> -->
                    
                    <!-- <hr> -->
                    <!-- <div class="row mt-3">
                        <div class="col-lg-12 mt-4">
                            <h5 class="text-secondary font-weight-bold">Recent</h5>
                            <p class="text-grey font-weight-light"> Lorem ipsun dolor sit amet Lorem ipsin dolor sit amet.</p>
                        </div>
                    </div> -->
                    <hr>
                    <div class="row align-items-center">
                        <div class="col-md-8 col-lg-8">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 mt-sm-2 d-flex flex-row flex-nowrap align-items-center align-content-center justify-content-center">
                                    <img src="/assets/img/purchased-green.png" style="width: 3em;" alt="update">
                                </div>
                                <div class="col-lg-9 col-md-9">
                                    <h5 class="mb-0 mt-3 text-secondary">Purchased by <span class="font-weight-bold">{{ version.owner.full_name }}</span></h5>
                                    <p class="text-grey">{{ helper.formatDateTime(version.updated_at) }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-4">
                            <button type="button" (click)="openYourSmartContract(version)" class="border-0 rounded text-grey smart-contract">
                                <img src="/assets/icons/transaction.png">
                                Smart Contract
                            </button>
                        </div>
                        <hr>
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-12 mt-5">
                            <h4 class="text-secondary font-weight-bold mb-0">History</h4>
                            <p class="text-grey font-weight-light">Through alphaa.io's digital NFT certificates the full artwork history <br> and transaction log can be easily viewed</p>
                        </div>
                    </div>
                    <div *ngFor="let history of histories">
                        <hr>
                        <div class="row align-items-center">
                            <div class="col-lg-8 col-md-8">
                                <div class="row">
                                    <div class="col-lg-2 pr-0 d-flex flex-row flex-nowrap align-items-center align-content-center justify-content-center">
                                        <img src="assets/img/purchased.png" alt="purchased">
                                    </div>
                                    <div class="col-lg-9 col-md-9">
                                        <h5 class="mb-0 mt-3 text-secondary">{{ textStatus(history.status) }} by <span class="font-weight-bold">{{ textSubject(history) }}</span></h5>
                                        <p class="text-grey">{{ helper.formatDateTime(history.created_at) }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 px-0">
                                <button type="button" (click)="openTransactionTwo(version)" class="btn text-grey cursor-pointer d-flex align-items-center px-0"><img src="/assets/icons/transaction.png" class="pr-1"> Transaction Data</button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <hr>
                        <div class="row align-items-center">
                            <div class="col-lg-8 col-md-8">
                                <div class="row">
                                    <div class="col-lg-2 pr-0 d-flex flex-row flex-nowrap align-items-center align-content-center justify-content-center">
                                        <img src="assets/img/create.png" alt="create">
                                    </div>
                                    <div class="col-lg-9 col-md-9">
                                        <h5 class="mb-0 mt-3 text-secondary">Created by <span class="font-weight-bold">{{ version.artwork.artist.full_name }}</span></h5>
                                        <p class="text-grey">{{ helper.formatDateTime(version.created_at) }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 px-0">
                                <button type="button" (click)="openTransactionTwo(version)" class="btn text-grey px-0 cursor-pointer d-flex align-items-center"><img src="/assets/icons/transaction.png" class="pr-1"> Transaction Data</button>
                            </div>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="card-body" *ngIf="viewMode === 'about'">
                    <div class="row">
                        <div class="col-lg-12">
                            <h4 class="text-secondary font-weight-bold">About This Artwork</h4>
                            <div class="row">
                                <div class="col-lg-9 mt-2">
                                    <b>More about this artwork</b>
                                    <p [innerHTML]="version.about" class="text-grey"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="col-lg-12 px-0" id="details-artwork" *ngIf="innerWidth < 767.98">
            <div class="d-flex justify-content-between titles-details-artwork border-bottom">
                <div class="d-flex titles-version mt-2 px-2">
                    <a [routerLink]="['/versions/'+version?.id+'/value']" routerLinkActive="selected" id="titles-card-versions" class="text-grey font-weight-normal h4 card-title nav-link px-1">Value</a>
                    <a [routerLink]="['/versions/'+version?.id+'/record']" routerLinkActive="selected" id="titles-card-versions" class="text-grey font-weight-normal h4 card-title nav-link px-1 ml-3">Record</a>
                    <a [routerLink]="['/versions/'+version?.id+'/about']" routerLinkActive="selected" id="titles-card-artworks" class="text-grey font-weight-normal h4 card-title nav-link px-1 ml-3">About</a>
                </div>
            </div>
            <div class="pt-4" *ngIf="viewMode === 'value'">
                <div class="px-3">
                    <h4 class="font-weight-bold text-secondary">Current Value</h4>
                    <h1 class="text-primary font-weight-normal"> {{ helper.amountCurrencyFormat(version?.price) }}</h1>
                </div>
                <div class="mt-4 pt-2 px-3" *ngIf="chartOptions.chart">
                    <!-- <h4 class="font-weight-bold text-secondary">Fluctuation</h4>
                        <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions" style="width: 100%; height: 300px; display: block;"></highcharts-chart> -->
                        <hr>
                    </div>
                    <div class="px-3">
                        <ngb-accordion #acc="ngbAccordion">
                            <ngb-panel id="toggle-1" title="Artwork information">
                                <ng-template ngbPanelContent>
                                    <app-versionSpecs [version]="version" [artwork]="version?.artwork" [showImage]="false" [descriptionArtworkMobile]="descriptionArtworkMobile"></app-versionSpecs>
                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion>
                    </div>
                </div>
                <div class="pt-4 px-3" *ngIf="viewMode === 'record'">
                    <div class="col-12 px-0">
                        <h4 class="text-secondary font-weight-bold">Details</h4>
                        <!-- <p class="text-grey-light font-weight-light">Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet t Lorem ipsum dolor</p> -->
                    </div>
                    <div class="col-12 px-0">
                        <div class="row">
                            <div class="col-12 pr-md-0">
                                <p class="text-secondary font-weight-bold mb-0">Privacy setting</p>
                                <p class="text-secondary mb-2">Private</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 px-0">
                        <div class="row">
                            <div class="col-12 pr-md-0">
                                <p class="text-secondary font-weight-bold mb-0">Artist</p>
                                <p class="text-secondary mb-2">{{ version?.artwork.artist.full_name }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 px-0">
                        <div class="row">
                            <div class="col-12 pr-md-0">
                                <p class="text-secondary font-weight-bold mb-0">Hash Token</p>
                                <p class="text-secondary mb-2 text-break">{{ version?.fake_token }}</p>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="col-12 mt-4 pt-3 px-0">
                        <h4 class="text-secondary font-weight-bold mb-0">Recent Activity</h4>
                    </div>
                    <div class="col-12 artwork-description">
                        <div class="row">
                            <div class="col-2">
                                <img src="/assets/icons/purchased-green-icon.png" class="purchased-icon" alt="update">
                            </div>
                            <div class="col-9">
                                <p class="text-secondary mb-0 ">Purchased by <span class="font-weight-bold">{{ version?.owner.full_name }}</span></p>
                                <p class="text-grey font-weight-light">{{ helper.formatDateTime(version?.updated_at) }}</p>
                                <button (click)="openYourSmartContract(version)" class="view-smart-contract d-flex align-items-center btn btn-outline-primary cursor-pointer">
                                    <img src="/assets/icons/transaction-blue.png">
                                    <p class="text-primary font-weight-regular mb-0 pl-2">View Smart Contract</p>
                                </button>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="col-12 mt-4 px-0">
                        <h4 class="text-secondary font-weight-bold mb-0">History</h4>
                    </div>
                    <div *ngFor="let history of histories">
                        <div class="col-12 history-description">
                            <div class="row">
                                <div class="col-2">
                                    <img class="history-icon" src="assets/icons/history-purchased.png" alt="purchased">
                                </div>
                                <div class="col-9">
                                    <h5 class="mb-0 text-secondary">{{ textStatus(history.status) }} by <span class="font-weight-bold">{{ textSubject(history) }}</span></h5>
                                    <p class="text-grey font-weight-light mb-0">{{ helper.formatDateTime(history.created_at) }}</p>
                                    <button (click)="openTransactionTwo(version)" class="btn d-flex align-items-center cursor-pointer" style="padding: 2px 0px;">
                                        <img src="/assets/icons/transaction.png">
                                        <p class="text-grey-light font-weight-light mb-0 pl-1">Transaction Data</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="col-12 history-description">
                            <div class="row">
                                <div class="col-2">
                                    <img class="history-icon" src="assets/icons/history-create.png" alt="create">
                                </div>
                                <div class="col-9">
                                    <p class="text-secondary mb-0">Created by <span class="font-weight-bold">{{ version?.artwork.artist.full_name }}</span></p>
                                    <p class="text-grey font-weight-light mb-0">{{ helper.formatDateTime(version?.created_at) }}</p>
                                    <button type="button" (click)="openTransactionTwo(version)" class="btn d-flex align-items-center cursor-pointer" style="padding: 2px 0px;">
                                        <img src="/assets/icons/transaction.png">
                                        <p class="text-grey-light font-weight-light mb-0 pl-1">Transaction Data</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="pt-4" *ngIf="viewMode === 'about'">
                    <div class="col-12">
                        <h4 class="text-secondary font-weight-bold mb-0">About This Artwork</h4>
                        <div class="row">
                            <div class="col-9 mt-2">
                                <b>More about this artwork</b>
                                <p class="text-grey">
                                    {{ version?.about }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card" *ngIf="auth.isAuthenticated.getValue() && auth.userData.id == version.owner_id">
                    <div class="card-header p-3">
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                                <qrcode [qrdata]="'https://beta.alphaa.io/?tradeQr='+version?.nft_token" [width]="50" [errorCorrectionLevel]="'M'" [elementType]="'svg'"></qrcode>
                            </div>
                            <div class="col-9 px-0">
                                <button type="button" (click)="openTransaction()" class="btn btn-primary cursor-pointer w-100" style="height: 40px;">Trade</button>
                            </div>
                            <div>
                                <a class="cursor-pointer" (click)="share()">
                                    <img src="/assets/icons/share-icon.png" alt="">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>