<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12 p-0">
            <div id="bg-hand" class="d-flex flex-column align-items-center justify-content-center">
                <div>
                    <h1 class="text-white text-center title-nft">Start issuing NFTs now</h1>
                </div>
                <div class="d-flex justify-content-center">
                    <div class="col-lg-12 px-lg-5 mx-lg-5">
                        <p class="text-white font-weight-light text-center px-lg-5 mx-sm-5 mx-1 mt-2">
                            Alphaa.io offers a unique, hybrid NFT certification model, creating <br> 
                            digital clones associated with original and physical artworks. All physical <br> 
                            prints are accompanied by a digital token for easy authentication <br> 
                            and future royalties on all secondary market transactions.
                        </p>
                    </div>
                </div>
                <div>
                    <a *ngIf="!auth.isAuthenticated.getValue()" class="btn btn-lg btn-primary cursor-pointer d-flex align-items-center justify-content-center btn-home-creator" [routerLink]="['/auth/register']">Sign up</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row align-items-center">
        <div class="col-lg-12 my-sm-5">
            <div class="row p-sm-5 my-5">
                <div class="col-sm-12 col-md-12 col-lg-6 px-sm-5 d-flex flex-column justify-content-center">
                    <h3 class="secondary-color-light font-weight-bold">Sell directly to buyers</h3>
                    <h2 class="text-secondary display-4" style="font-weight: 400;">Get Paid On All <br> Secondary Market Sales Forever</h2>
                        <p class="text-grey-link mt-3 text-description">
                            Our platform automatically issues NFTs for each physical artwork you upload, ensuring post-sale
                            tracking for you and authenticity for your buyer. This means you have on-demand access to the
                            chain of ownership for any artwork you sell with Alphaa.io, and
                            guaranteed resale royalties.
                        </p>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 d-flex justify-content-center">
                        <img width="80%" src="/assets/img/certificate.jpg" class="img-certificate" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="container-fluid bg-imgs d-flex align-items-center justify-content-center">
        <div class="row">
            <div class="col-lg-12 d-flex justify-content-center align-items-center">
                <div class="card d-flex justify-content-center text-center py-5 px-2 cardCreate">
                    <div>
                        <h2 class="text-secondary display-4 mt-5" style="font-weight: 400;">You Create, We Validate</h2>
                    </div>
                    <div class="row d-flex justify-content-center">
                        <div class="col-lg-8">
                            <p class="mt-3 text-grey-link text-center text-description">
                                We take care of all of the logistics of certifying so that the artists can focus on what
                                matters most: creating their work. Artists can also use Alphaa.io to certify and sell prints
                                of works already in a gallery. Artists receive a percentage of sales
                                from all secondary market sales. Instead of auction houses and collectors keeping all
                                revenue from resale transactions, blockchain-verification and tracking ensure that artists
                                always receive royalties from secondary market
                                sales.
                            </p>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center align-items-center">
                        <button *ngIf="!auth.isAuthenticated.getValue()" [routerLink]="['/auth/register']"
                        class="btn btn-lg btn-primary d-flex justify-content-center cursor-pointer align-items-center btn-home-creator mt-3 mb-5">Sign
                        up</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 bg-grey">
                <div class="d-flex flex-column align-items-center mt-5">
                    <h4 class="text-primary font-weight-bold pt-5">Artist Dashboard</h4>
                    <h2 class="text-center text-display-4">Your Dashboard, Your Data
                    </h2>
                    <p class="text-center text-grey-link mt-sm-3">
                        Within the artist dashboard, you can track the ownership and transaction <br> activity for each
                        artwork you've sold on the platform. <br> Transaction tracking offers insights into collector <br>
                        behavior and data on your revenue from
                        second-market royalties.
                    </p>
                    <img src="/assets/img/preview-dashboard.png" alt="preview dashboard">
                </div>
            </div>
        </div>
    </div>
    
    <div>
        <div class="container-fluid px-sm-5">
            <div class="row px-sm-4">
                <div class="col-lg-12 text-center mt-5 pt-5">
                    <h4 class="mb-0 mt-4 text-primary font-weight-bold">Pricing</h4>
                    <h1 class="text-plan-subscribe">Choose your plan </h1>
                </div>
            </div>
        </div>
        <app-subscripton-plans></app-subscripton-plans>
    </div>
    
    <div class="container-fluid bg-primary footer-home">
        <div class="row justify-content-center pb-4 pt-5">
            <div class="col-lg-5 my-md-5 py-md-5">
                <div class="mt-xl-5 pt-xl-5">
                    <h3 class="font-weight-bold text-white mt-3 pt-1">Alpha’a.io</h3>
                </div>
                <div>
                    <h4 class="text-white font-weight-light">The future of buying <br>and selling digital art.</h4>
                    <p class="text-white font-weight-light">Certify ownership and view transaction history, <br> guaranteeing
                        easy trading.</p>
                    </div>
                    <div class="pt-3">
                        <a [routerLink]="['/about']" class="btn btn-lg btn-white border-0 text-primary font-weight-bold cursor-pointer btn-home-about">About us</a>
                    </div>
                </div>
                
                <div class="col-lg-4" id="alpha-platform">
                    <img src="/assets/img/laptop.png" class="laptop" alt="notbook" style="width: 143.7%;margin-top: -23px;">
                </div>
            </div>
        </div>