import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from 'src/app/services/helper.service';


@Component({
  selector: 'app-your-smart-contract',
  templateUrl: './your-smart-contract.component.html',
  styleUrls: ['./your-smart-contract.component.scss']
})
export class YourSmartContractComponent implements OnInit {
  
  @Input() public version: any;
  @Input() public artwork: any;
  
  
  constructor(
    public router: Router,
    public api: ApiService,
    public helper: HelperService,
    public route: ActivatedRoute,
    public modalService: NgbModal
    ) { }
    
    ngOnInit(): void {
      if(this.artwork){
        this.version = this.artwork.version
        this.version.artwork = this.artwork
      }
    }
    
    buttonClose(){
      const modalRef = this.modalService.dismissAll();
    }
  }
  