import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-modal-request-demo',
  templateUrl: './modal-request-demo.component.html',
  styleUrls: ['./modal-request-demo.component.scss']
})
export class ModalRequestDemoComponent implements OnInit {
  
  public formDemo: FormGroup

  constructor(
    public modalService: NgbModal,
    public formBuilder: FormBuilder,
    public api: ApiService,
    ) { 
      this.createFormDemo()
    }
    
    ngOnInit(): void {
    }
    
    onSubmit({ valid, value }: { valid: boolean; value: any }){
      console.log(value)
      console.log(valid)
      if (valid) {
        let payload = {
          name: value.name,
          email: value.email,
          phone: value.phone,
          company: value.company,
          job_title: value.job_title,
          number_employees: value.number_employees
        }

        console.log(payload)

        this.api.post('requestDemo',payload).then((result) => {
            console.log(result)
        }).catch((err) => {
          
        });
      }
    }
    
    createFormDemo(){
      this.formDemo = this.formBuilder.group({
        name: [null, [Validators.required, Validators.minLength(3)]],
        email: [null, [Validators.required, Validators.minLength(3)]],
        phone: [null, [Validators.required, Validators.minLength(3)]],
        company: [null, [Validators.required]],
        job_title: [null, [Validators.required]],
        number_employees: [null, [Validators.required]],
      })
    }

    buttonClose(){
      const modalRef = this.modalService.dismissAll();
    }
  }
  