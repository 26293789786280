import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-event-artwork-details',
  templateUrl: './event-artwork-details.component.html',
  styleUrls: ['./event-artwork-details.component.scss']
})
export class EventArtworkDetailsComponent implements OnInit {
  artwork: any;
  artwork_id: any;
  event: any;
  slug: any;
  
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public modalService: NgbModal,
    public api: ApiService,
    public helper: HelperService,
  ) {
    this.slug = this.route.snapshot.params.slug;
    this.artwork_id = this.route.snapshot.params.artwork_id;
   }

  ngOnInit(): void {
    this.api.get('event/'+this.slug).then((response) => {
      this.event = response
    })
    this.api.get('artworks/'+this.artwork_id).then((response) => {
      this.artwork = response
    })

  }

}
