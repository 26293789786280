import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCreateFoldersComponent } from 'src/app/modals/modal-create-folders/modal-create-folders.component';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-folders-box',
  templateUrl: './folders-box.component.html',
  styleUrls: ['./folders-box.component.scss']
})
export class FoldersBoxComponent implements OnInit {
  public folders: any;
  @Input() public title: any = true;
  
  constructor(
    public modalService: NgbModal,
    public api: ApiService,
    ) { }
    
    
    ngOnInit(): void {
      this.api.get('artworks/folders').then((response) => {
        this.folders = response
      })
    }
    
    openCreateFolders() {
      const modalRef = this.modalService.open(ModalCreateFoldersComponent);
    }
  }
  