import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-profile-institution',
  templateUrl: './profile-institution.component.html',
  styleUrls: ['./profile-institution.component.scss']
})
export class ProfileInstitutionComponent implements OnInit {
  slug: any;
  invites: any;
  artworks: any;
  institution: any;
  
  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    public router: Router,
    public modalService: NgbModal,
    public api: ApiService,
    ) { 
      router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          this.route.params.subscribe(params => this.slug = params['slug']);

          this.api.get('institutions/'+ this.slug).then((response) => {
            this.institution = response
            this.api.get('institutions/'+this.institution.id+'/artists/').then((response) => {
              this.invites = response
            })
          })
          
        }
      })
    }
    
    ngOnInit(): void {
    }
    
    
  }
  