import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-invite-institution',
  templateUrl: './modal-invite-institution.component.html',
  styleUrls: ['./modal-invite-institution.component.scss']
})
export class ModalInviteInstitutionComponent implements OnInit {
  public invite: any = {};
  public success: any = '';
  public tradeBuyerStep: number = 1;
  public authFormMode: string = 'login';
  
  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private api: ApiService
    ) { }
    
    ngOnInit(): void {
      if(typeof this.auth.userData.id === 'undefined'){
        this.tradeBuyerStep = 0
      }else if(this.invite.status != 1 || this.auth.userData.id != this.invite.creator_id ) {
        this.buttonClose()
      }
      
      if (this.success === true) {
        this.tradeBuyerStep = 3
      }
    }
    
    paymentTransaction(data) {
      this.api.put('transfers/' + data.hash_token + '/capture', {}).then((response) => {
        document.location.href = response.links[1].href;
      })
    }
    
    submitForm(evt) {
      switch (evt.form) {
        case 'two-factor':
        this.twoFactor(evt);
        break;
        case 'authenticate-register':
        this.twoFactor(evt);
        break;
      }
    }
    
    
    twoFactor(evt) {
      evt.promise.then((response) =>{
        this.route.queryParams.subscribe(params => {
          this.tradeBuyerStep = 1;
        })
      })
    }
    
    refuseInvite(){
      this.api.put('institutions/invite/'+this.invite.id, {status: 3}).then((data) => {
        Swal.fire(
          'Refused!',
          'The invitation was successfully refulsed',
          'success',
          )
          .then((response) => {
            if (response.isConfirmed) {
              this.buttonClose()
            }
          })
        })
      }
      
      acceptInvite(){
        this.api.put('institutions/invite/'+this.invite.id, {status: 2}).then((data) => {
          Swal.fire(
            'Accepted!',
            'The invitation was successfully accepted',
            'success',
            )
            .then((response) => {
              if (response.isConfirmed) {
                this.buttonClose()
              }
            })
          })
        }
        
        buttonClose() {
          const modalRef = this.modalService.dismissAll();
        }
      }
      