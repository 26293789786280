<section>
    <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0 p-2">
        <img src="/assets/icons/close.svg">
    </button>
    <div class="modal-header justify-content-center">
        <div class="row">
            <div class="col-lg-12 text-center">
                <h4 class="text-secondary font-weight-bold mt-2">Transaction Data</h4>

            </div>
        </div>
    </div>
    <div class="modal-body text-center">
        <div class="row">
            <div class="col-lg-12 text-center">
                <img src="assets/img/update.png" alt="update">
                <h4 *ngIf="version" class="text-secondary font-weight-light mt-2">Update By <span class="font-weight-bold">{{version.artwork.artist.full_name}}</span></h4>
                <h4 *ngIf="artwork" class="text-secondary font-weight-light mt-2">Update By <span class="font-weight-bold">{{artwork.artist.full_name}}</span></h4>
                <p *ngIf="version" class="font-weight-light text-grey-light mt-2">{{ helper.formatDate(version.created_at) }}</p>
                <p *ngIf="artwork" class="font-weight-light text-grey-light mt-2">{{ helper.formatDate(artwork.created_at) }}</p>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-10">
                <p class="text-grey-link font-weight-light text-left mb-1" *ngIf="version"> Transaction Signature</p>
                <input *ngIf="version" type="text" class="bg-light input-hash w-100" value="{{ version.fake_token }}">
                <input *ngIf="artwork" type="text" class="bg-light input-hash w-100" value="{{ artwork.fake_token }}">
            </div>
        </div>
    </div>
    <div class="modal-footer justify-content-center">
        <div class="col-lg-11">
            <button type="button" (click)="openTradeDigital()" class="btn-lg btn btn-primary cursor-pointer w-100">Done</button>
        </div>
    </div>
</section>