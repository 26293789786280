import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-request-manegement-artwork',
  templateUrl: './modal-request-manegement-artwork.component.html',
  styleUrls: ['./modal-request-manegement-artwork.component.scss']
})

export class ModalRequestManegentArtworkComponent implements OnInit {
  public artwork: any = {};
  public institution: any = {};
  public artwork_id: any;
  public institution_id: any;
  public success: any = '';
  public tradeBuyerStep: number = 1;
  public authFormMode: string = 'login';
  
  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private api: ApiService
    ) { }
    
    ngOnInit(): void {
      if(typeof this.auth.userData.id === 'undefined'){
        this.tradeBuyerStep = 0
      }else{
        this.api.get('artworks/' + this.artwork_id).then((data) => {
          this.artwork = data
          console.log(this.auth.userData.id)
          console.log(this.artwork)
          if(this.auth.userData.id != this.artwork.artist_id ) {
            this.buttonClose()
          }else{
            this.api.get('institutions/' + this.institution_id).then((data) => {
              this.institution = data
            })
          }
        })
      }
      
      if (this.success === true) {
        this.tradeBuyerStep = 3
      }
    }
    
    submitForm(evt) {
      switch (evt.form) {
        case 'two-factor':
        this.twoFactor(evt);
        break;
        case 'authenticate-register':
        this.twoFactor(evt);
        break;
      }
    }
    
    
    twoFactor(evt) {
      evt.promise.then((response) =>{
        this.route.queryParams.subscribe(params => {
          this.tradeBuyerStep = 1;
        })
      })
    }

    acceptInvite(){
      this.artwork.allowed_institution = this.institution.id
      delete this.artwork.tags
      this.api.put('artworks/'+this.artwork.id, this.artwork).then((data) => {
        Swal.fire(
          'Accepted!',
          'The request was successfully accepted',
          'success',
          )
          .then((response) => {
            if (response.isConfirmed) {
              this.buttonClose()
            }
          })
        })
      }
      
      buttonClose() {
        const modalRef = this.modalService.dismissAll();
      }
    }
    