import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-subscripton-plans',
  templateUrl: './subscripton-plans.component.html',
  styleUrls: ['./subscripton-plans.component.scss']
})
export class SubscriptonPlansComponent implements OnInit {
  public id: number
  public plansMonthly: any
  public plansYearly: any
  public seletedPlan: any
  public subscription_id: any
  public ba_token: any
  public token: any
  public typeActive: string = 'monthly'
  public msg: string = ''
  viewMode: any;
  public card: any = {};
  public years = []
  @Input() type: any;
  
  
  public months = [
    { id: 1, month: 'january'},
    { id: 2, month: 'February'},
    { id: 3, month: 'March'},
    { id: 4, month: 'April'},
    { id: 5, month: 'May'},
    { id: 6, month: 'June'},
    { id: 7, month: 'July'},
    { id: 8, month: 'August'},
    { id: 9, month: 'September'},
    { id: 10, month: 'October'},
    { id: 11, month: 'November'},
    { id: 12, month: 'December'}
  ];
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public api: ApiService,
    private config: NgSelectConfig,
    public auth: AuthService,
    private gtmService: GoogleTagManagerService,
    ) { 
      this.config.notFoundText = 'Custom not found';
      this.config.appendTo = 'body';
      // set the bindValue to global config when you use the same 
      // bindValue in most of the place. 
      // You can also override bindValue for the specified template 
      // by defining `bindValue` as property
      // Eg : <ng-select bindValue="some-new-value"></ng-select>
      this.config.bindValue = 'value';
    }
    
    ngOnInit(): void {
      
      let date = new Date()
      for(let i = date.getFullYear(); i<date.getFullYear()+40; i++){
        this.years.push({key:i, value:i})
      }
      
      this.card.exp_month = date.getMonth()+1;
      this.card.exp_year = date.getFullYear();
      
      if(this.auth.userData.full_name){
        this.auth.updateSubscriptionData().then(result => {
          console.log(result)
        })
      }
      
      this.viewMode = this.route.snapshot.params.activePlans ? this.route.snapshot.params.activePlans : "initial"
      
      this.route.queryParams.subscribe(params => {
        this.subscription_id = params['subscription_id']
        this.ba_token = params['ba_token']
        this.token = params['token']
      });
      
      if(this.viewMode == 'success'){
        this.msg = '<br><h1 class="font-weight-bold text-secondary mb-5">Your payment is confimed <br>Now you can use all the benefits of your subscription plan</h1>'
        if (typeof this.subscription_id !== 'undefined') {
          this.api.post('subscriptions/preSave', { external_id: this.subscription_id }).then((response) => {
            this.msg = '<br><h1 class="font-weight-bold text-secondary mb-5">Waiting for payment confirmation, then you can start creating</h1>'
            if(response.plan_id !== null) {
              this.msg = '<br><h1 class="font-weight-bold text-secondary mb-5">Your payment is confimed <br>Now you can use all the benefits of your subscription plan</h1>'
            }
            this.auth.updateSubscriptionData().then(result => {
              console.log(result)
            })
          })
        }
      }
      
      this.api.get('subscriptions/plans').then((response) => {
        this.plansMonthly = response.filter(res => res.type == 'monthly')
        this.plansYearly = response.filter(res => res.type == 'yearly')
        this.route.queryParams.subscribe(params => {
          if(params['viewMode']){
            this.viewMode = params['viewMode'];
          }
          if(params['plan']){
            this.seletedPlan = response.filter(res => res.id == params['plan'])[0]
          }
        });
        // console.log(this.seletedPlan)
      })
      
      this.id = this.route.snapshot.params.activePlans
    }
    
    async nextForPayment() {
      const gtmTag: any = {
        event: 'subscription_convertion',
        pageName: this.route.url,
        plan_title: this.seletedPlan.name,
        plan_price: this.seletedPlan.price,
      };
      if(this.auth.userData){
        gtmTag.uid = this.auth.userData.id,
        gtmTag.email = this.auth.userData.email,
        gtmTag.name = this.auth.userData.full_name
      }
      await this.gtmService.pushTag(gtmTag);
      this.api.get('subscriptions/subscriber/' + this.seletedPlan.id).then((response) => {
        if(response.links){
          document.location.href = response.links[0].href;
        }
        if(response.plan_id){
          this.auth.updateSubscriptionData().then(result => {
            console.log(result)
          })
          document.location.href  = '/subscribe/success'
        }
      })
    }
    
    backPlans() {
      this.viewMode = 'initial';
    }
    
    async paymentWithStripe() {
      this.api.post('subscriptions/subscriber/' + this.seletedPlan.id, this.card).then((response) => {
        if(response){
          if(!response.error){
            Swal.fire('Accepted!', 'Your subscription has been accepted.', 'success')
            .then((response) => {
              if (response.isConfirmed) {
                document.location.href  = '/subscribe/success'
              }
            })
          }else{
            Swal.fire('Opss..', response.message, 'error')
            .then((response) => {
              if (response.isConfirmed) {
                // window.location.href = "/dashboard/artworks"
              }
            })
          }
        }
      })
    }
    
    filterPlan(plan : any, user: any) {
      if(user.subscription){
        return plan.sequence >= user.subscription.plan.sequence
      }else{
        return true
      }
    }
    
    formatPlanToken(tokens){
      if(tokens > 1000000){ 
        return "∞"
      }else{
        return tokens
      }
    }
    
    async continuePayment(plan) {
      if(!this.auth.isAuthenticated.getValue()){
        document.location.href  = '/auth/login'
      }else if(this.auth.isAuthenticated.getValue()){
        return window.location.href = '/subscribe?viewMode=payment&plan='+plan.id
      }
    }
    
    BtnVisitDashboard(){
      if(this.auth.institutionActive.getValue() == true){
        this.auth.switchProfileView('institution', false)
        document.location.href  = '/dashboard-institution/overview'
      }else if(this.auth.subscriptionActive.getValue() == true){
        this.auth.switchProfileView('creator', false)
        document.location.href  = '/dashboard-creator/overview'
      }
    }
    
    BtnCreateArtwork(){
      if(this.auth.institutionActive.getValue() == true){
        this.auth.switchProfileView('institution', false)
        document.location.href  = '/dashboard-institution/artworks-create'
      }else if(this.auth.subscriptionActive.getValue() == true){
        this.auth.switchProfileView('creator', false)
        document.location.href  = '/dashboard-creator/artworks-create'
      }
    }
    
    // onNumberCvv(event: any){
    //   if(event.target.value > 3){
    //     Swal.fire({
    //       text: `The code cvv only can has to three numbers`, 
    //       icon: 'warning',
    //       confirmButtonColor: '#252dff',
    //       confirmButtonText: 'Ok'
    //     }).then((result) => {
    //       if (result.isConfirmed) {
    //         event.target.value = '';
    //       }
    //     })
    //   }
    // }
  }
  