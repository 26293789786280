<section *ngIf="showPage" class="px-sm-3 overview pb-5">
    <div class="container-fluid text-welcome">
        <div class="row my-sm-4 mx-2">
            <div class="col-12 p-1">
                <p class="text-grey font-weight-light mb-0">Your Vault</p>
                <h1 class="text-primary font-weight-bold display-4 mb-1">Welcome, {{ auth.userData.full_name }}</h1>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row mx-sm-4">
            <div class="col-12 px-sm-0">
                <div class="card">
                    <div class="card-header px-3 pb-0">
                        <app-dash-institution [disabledChart]="disabledMode"></app-dash-institution>
                    </div>
                    <div class="card-body" *ngIf="viewMode === 'overview'">
                        <div class="row">
                            <div class="col-lg-6">
                                <app-info-dashboard [mode]="'institution'"></app-info-dashboard>
                            </div>
                            <div class="col-lg-6">
                                <app-latest-artwork-created [link]="'/dashboard-institution/artworks-create'"  [artworkLastCreated]="artworkLastCreated"></app-latest-artwork-created>
                            </div>
                        </div>
                        <hr class="mb-0" style="margin-top: 58px;">
                        <div class="row justify-content-between px-3">
                            <div class="col-lg-5 mb-0">
                                <app-trades-progress-institution></app-trades-progress-institution>
                            </div>
                            <hr class="linha-vertical my-0">
                            <div class="col-lg-6 px-md-0 px-0">
                                <app-top-selling-artists></app-top-selling-artists>
                            </div>
                        </div>
                        <hr class="mt-0">
                        <div class="mx-3">
                            <div class="row">
                                <h1 class="font-weight-bold text-secondary mb-3">Recent Activity</h1>
                            </div>
                            <div class="row d-flex titles-recent-activity">
                                <h2 class="text-decoration-none cursor-pointer font-weight-light text-grey mr-4 cursor-pointer" [class.active]="overviewMode == 'artworks'" (click)="setOverviewMode('artworks')">Artworks</h2>
                                <h2 class="text-decoration-none cursor-pointer font-weight-light text-grey cursor-pointer" [class.active]="overviewMode == 'folders'" (click)="setOverviewMode('folders')">Folders</h2>
                            </div>
                        </div>
                        <div *ngIf="overviewMode === 'artworks'">
                            <app-artworks-list [dropDown]="'institution'" *ngIf="artworks" [items]="artworks" [title]="false" [filters]="false" [endpoint]="'institutions/artworks'"></app-artworks-list>
                        </div>
                        <div *ngIf="overviewMode === 'folders'">
                            <app-folders-box [title]="false"></app-folders-box>
                        </div>
                    </div>
                    
                    <div class="card-body" *ngIf="viewMode === 'artists'">
                        <app-artists-dashboard-institution></app-artists-dashboard-institution>
                    </div>
                    
                    <div class="card-body" *ngIf="viewMode === 'artworks'">
                        <app-artworks-list [dropDown]="'institution'" [endpoint]="'institutions/artworks'"></app-artworks-list>
                    </div>
                    
                    <div class="card-body" *ngIf="viewMode === 'folders'">
                        <app-folders-box></app-folders-box>
                    </div>
                    
                    <div class="card-body" *ngIf="viewMode === 'trades'">
                        <app-trade-box></app-trade-box>
                    </div>
                    
                    <div class="card-body px-0" *ngIf="viewMode === 'profile'">
                        <app-profile></app-profile>
                    </div>
                    
                    <div class="card-body pt-0 px-0" *ngIf="viewMode === 'artworks-create'">
                        <app-create-artworks [link]="'/dashboard-institution/artworks'" [mode]="'institution'"></app-create-artworks>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>