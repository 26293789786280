import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddTrackModalComponent } from 'src/app/modals/add-track-modal/add-track-modal.component';
import { ModalCreateArtworkComponent } from 'src/app/modals/modal-create-artwork/modal-create-artwork.component';
import { AuthService } from 'src/app/services/auth.service';
import { ClipboardService } from 'ngx-clipboard'
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ApiService } from 'src/app/services/api.service';


@Component({
  selector: 'app-dash-create',
  templateUrl: './dash-create.component.html',
  styleUrls: ['./dash-create.component.scss']
})
export class DashCreateComponent implements OnInit {
  
  public viewMode: any;
  
  @Input() disabledChart: boolean = false;
  
  constructor(
    public api: ApiService,
    public auth: AuthService,
    private route: ActivatedRoute,
    public modalService: NgbModal,
    private _clipboardService: ClipboardService,
    ) {
      this.route.params.subscribe(params => this.viewMode = params['viewMode']);
    }
    
    ngOnInit(): void {
    }
    
    setOverviewMode(mode: string){
      this.viewMode = mode
    }
    
    shareProfile(){
      this._clipboardService.copy(environment.URL_BASE+'/artist-detail/'+this.auth.userData.slug)
      Swal.fire({
        icon: 'success',
        title: "Copied profile link",
        text: "Copy this link into your social media, website etc for a free template to sell your artwork"
      })
    }
    
    openCreateArtworks() {
      const modalRef = this.modalService.open(ModalCreateArtworkComponent,
        {
          windowClass: 'customModalCreateArtwork'
        });
      }
      openCreateFolders() {
        const modalRef = this.modalService.open(AddTrackModalComponent);
      }
      buttonClose() {
        const modalRef = this.modalService.dismissAll(AddTrackModalComponent);
      }
    }
    