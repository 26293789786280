<div class="container-fluid">
    <div class="d-flex mb-3 mt-5 pt-2">
        <div class="col-lg-12 d-flex justify-content-between">
            <div>
                <a [routerLink]="['/dashboard-institution/artists']" class="nav-link font-weight-regular text-grey-link px-sm-4 pb-0 px-0">< To all artists</a>
                <h1 class="font-weight-bold text-secondary px-sm-4"> {{ artist.full_name }} </h1>
            </div>
            <div class="d-flex mr-3">
                <app-embed-button [mode]="mode" class="mb-2" style="margin-right: -2em;"></app-embed-button>
                <app-share-button [mode]="mode" [socials]="'all'"></app-share-button>
            </div>
        </div>
    </div>
    <div class="row align-items-start justify-content-between my-3 pt-4 px-sm-4">
        <div class="col-lg-8 d-sm-flex align-items-start justify-content-between">
            <div class="col-lg-3 mb-3 pl-0">
                <img *ngIf="artist.avatar" [src]="artist.avatar" alt="image the artist">
                <img *ngIf="!artist.avatar" src="assets/img/avatar.png" alt="image the artist">
            </div>
            <div class="col-lg-8">
                <h5 class="font-weight-bold text-secondary mb-1"> About </h5>
                <div [innerHTML]="artist.about"></div>
            </div>
        </div>
        <div class="col-lg-3 ml-3">
            <div class="" *ngIf="artist.contact_information">
                <h5 class="font-weight-bold text-secondary mb-0"> Location </h5>
                <h5 class="font-weight-regular text-grey-link mb-0"> {{artist.contact_information.city}}, {{artist.contact_information.country}} </h5>
            </div>            
            <!-- <div class="mt-4">
                <h5 class="font-weight-bold texte-secondary mb-0">Technique</h5>
                <h5 class="font-weight-regular text-grey-link mb-0">IIIustration, painting</h5>
            </div> -->
            <div class="mt-4" *ngIf="artist.contact_information.blog != null && artist.contact_information.blog != 0 || artist.contact_information.instagram != null && artist.contact_information.instagram != 0 || artist.contact_information.facebook != null && artist.contact_information.facebook != 0 || artist.contact_information.twitter != null && artist.contact_information.twitter != 0">
                <h5 class="font-weight-bold texte-secondary mb-0">Follow "{{ artist.full_name }}</h5>
                <app-icons-redes [artist]="artist"></app-icons-redes>
            </div>
        </div>
    </div>
</div>