import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-institution-royalties',
  templateUrl: './modal-institution-royalties.component.html',
  styleUrls: ['./modal-institution-royalties.component.scss']
})
export class ModalInstitutionRoyaltiesComponent implements OnInit {
  // modalService: any;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  buttonClose(){
    const modalRef = this.modalService.dismissAll();
   }

}
