import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { shortCountries } from 'src/app/data/countries';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-modal-create-account-stripe',
  templateUrl: './modal-create-account-stripe.component.html',
  styleUrls: ['./modal-create-account-stripe.component.scss']
})
export class ModalCreateAccountStripeComponent implements OnInit {

  public country: any
  public countries: any = shortCountries

  constructor(
    private modalService: NgbModal,
    private auth: AuthService,
    public api: ApiService
  ) { }

  ngOnInit(): void {
  }

  createAccount(){
    this.api.post('stripe/account', {uid: this.auth.userData.id, country: this.country}).then(response => {
      document.location.href = response.url
    })
  }

  buttonClose(){
    const modalRef = this.modalService.dismissAll();
   }
}
