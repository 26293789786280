<div class="card-header text-center">
    <h3 class="text-secondary font-weight-bold mb-0">Problem with order “Seller name”</h3>
    <button type="button" (click)="buttonClose()" aria-label="Close" 
    class="btn btn-outline position-absolute right-0 top-0 p-1">
    <img src="/assets/icons/close.svg">
</button>
</div>

<div class="card-body">
    <div *ngIf="buyer == true">
        <p class="text-grey-light font-weight-light px-2">Do you have a problem with your order? Please answer these questions to contact the seller to request a refund or for any other inquiries.</p>
        <div class="row">
            <div class="col-lg-9 pl-4 pr-0">
                <div>
                    <p>Did you already pay for the arwork?</p>
                </div>
            </div>
            <div class="col-lg-3 pl-0 pr-4">
                <div class=" d-flex px-0 justify-content-between">
                    <p class="text-grey-link font-weight-light">No</p>
                    <label class="switch">
                        <input [(ngModel)]="payed" type="checkbox" checked>
                        <span class="slider round"></span>
                    </label>
                    <p class="text-grey-link font-weight-light">Yes</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-9 pl-4 pr-0">
                <div>
                    <p>Did the seller ship the artwork?</p>
                </div>
            </div>
            <div class="col-lg-3 pl-0 pr-4">
                <div class=" d-flex px-0 justify-content-between">
                    <p class="text-grey-link font-weight-light">No</p>
                    <label class="switch">
                        <input [(ngModel)]="shipped" type="checkbox" checked>
                        <span class="slider round"></span>
                    </label>
                    <p class="text-grey-link font-weight-light">Yes</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-9 pl-4 pr-0">
                <div>
                    <p>Did the courrier deliver the artwork?</p>
                </div>
            </div>
            <div class="col-lg-3 pl-0 pr-4">
                <div class=" d-flex px-0 justify-content-between">
                    <p class="text-grey-link font-weight-light">No</p>
                    <label class="switch">
                        <input [(ngModel)]="delivered" type="checkbox" checked>
                        <span class="slider round"></span>
                    </label>
                    <p class="text-grey-link font-weight-light">Yes</p>
                </div>
            </div>
        </div>
        <div class="col-md-12 mb-3">
            <textarea [(ngModel)]="comment" class="bg-light border-0 col py-4" placeholder="Add any additional information here." type="text"
            style="height: 122px;" required checked></textarea>
        </div>
    </div>
    
    <div *ngIf="buyer == false">
        <p class="text-grey-light font-weight-light px-2">Message about contacting alphaa to dispute ipsum dolor sit amet, consectetur adipiscing elit mauris pretium.</p>
        <div class="row">
            <div class="col-lg-9 pl-4 pr-0">
                <div>
                    <p>Did you already pay for the arwork?</p>
                </div>
            </div>
            <div class="col-lg-3 pl-0 pr-4">
                <div class=" d-flex px-0 justify-content-between">
                    <p class="text-grey-link font-weight-light">No</p>
                    <label class="switch">
                        <input [(ngModel)]="payed" type="checkbox" checked>
                        <span class="slider round"></span>
                    </label>
                    <p class="text-grey-link font-weight-light">Yes</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-9 pl-4 pr-0">
                <div>
                    <p>Did the seller ship the artwork?</p>
                </div>
            </div>
            <div class="col-lg-3 pl-0 pr-4">
                <div class=" d-flex px-0 justify-content-between">
                    <p class="text-grey-link font-weight-light">No</p>
                    <label class="switch">
                        <input [(ngModel)]="shipped" type="checkbox" checked>
                        <span class="slider round"></span>
                    </label>
                    <p class="text-grey-link font-weight-light">Yes</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-9 pl-4 pr-0">
                <div>
                    <p>Did the courrier deliver the artwork?</p>
                </div>
            </div>
            <div class="col-lg-3 pl-0 pr-4">
                <div class=" d-flex px-0 justify-content-between">
                    <p class="text-grey-link font-weight-light">No</p>
                    <label class="switch">
                        <input [(ngModel)]="delivered"  type="checkbox" checked>
                        <span class="slider round"></span>
                    </label>
                    <p class="text-grey-link font-weight-light">Yes</p>
                </div>
            </div>
        </div>
        <div class="col-md-12 mb-3">
            <textarea [(ngModel)]="comment"  class="bg-light border-0 col py-4" placeholder="Add all extra information you want" type="text"
            style="height: 122px;" required checked></textarea>
        </div>
    </div>
</div>
<div class="card-footer bg-white">
    <div class="row">
        <div class="col-lg-3">
            <div class="text-left">
                <button type="button"  class="btn btn-outline text-primary mt-2 cursor-pointer">Cancel</button>
            </div>
        </div>
        <div class="col-lg-6">
            <button type="button"  (click)="send()" class="btn-lg btn btn-primary font-weight-regular w-100 cursor-pointer">Contact Alpha’a</button>
        </div>
    </div>
</div>
