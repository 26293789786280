import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/services/auth.service";
import { environment } from "src/environments/environment";
import { ClipboardService } from "ngx-clipboard";
import Swal from "sweetalert2";
@Component({
  selector: "app-modal-share-embed",
  templateUrl: "./modal-share-embed.component.html",
  styleUrls: ["./modal-share-embed.component.scss"],
})
export class ModalShareEmbedComponent implements OnInit {
  public showCode: boolean = false;
  public urlBase = environment.URL_BASE;
  public urlSafeCropped: SafeResourceUrl;
  public urlSafeNoCropped: SafeResourceUrl;
  private urlCropped: string;
  private urlNoCropped: string;
  public codeCropped: string;
  public codeNoCropped: string;

  constructor(
    public modalService: NgbModal,
    public auth: AuthService,
    public sanitizer: DomSanitizer,
    private _clipboardService: ClipboardService
  ) {}

  ngOnInit(): void {
    this.codeCropped = `<iframe src="'https://s3.amazonaws.com/embed.alphaa.io/artwork.html?artwork_id=${this.auth.userData.id} width="316" height="313" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>`;
    this.codeNoCropped = `<iframe src="'https://s3.amazonaws.com/embed.alphaa.io/artwork-non-cropped.html?artwork_id=${this.auth.userData.id} width="316" height="313" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>`;

    this.urlCropped = `https://s3.amazonaws.com/embed.alphaa.io/artwork.html?artwork_id=${this.auth.userData.id}`;
    this.urlNoCropped = `https://s3.amazonaws.com/embed.alphaa.io/artwork-non-cropped.html?artwork_id=${this.auth.userData.id}`;

    this.urlSafeCropped = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.urlCropped
    );
    this.urlSafeNoCropped = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.urlNoCropped
    );
  }

  // btnShowCode() {
  //   if (this.urlCropped === this.urlCropped) {
  //     this.codeCropped = this.codeCropped;
  //   } if (this.urlNoCropped === this.urlNoCropped) {
  //     this.codeNoCropped = this.codeNoCropped;
  //   }
  // }

  copyEmbed() {
    if (this.urlSafeCropped) {
      this._clipboardService.copy(this.codeCropped);
      Swal.fire({
        icon: "success",
        title: "Copied artwork embed link",
        text: "Copy this link into your social media, website etc for a free template to sell your artwork",
      });
    } if (this.urlSafeNoCropped) {
      this._clipboardService.copy(this.codeNoCropped);
      Swal.fire({
        icon: "success",
        title: "Copied artwork embed link",
        text: "Copy this link into your social media, website etc for a free template to sell your artwork",
      });
    }
  }

  buttonClose() {
    const modalRef = this.modalService.dismissAll();
  }
}