import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalAddArtworkComponent } from  'src/app/modals/modal-add-artwork/modal-add-artwork.component';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-your-art',
  templateUrl: './your-art.component.html',
  styleUrls: ['./your-art.component.scss']
})
export class YourArtComponent implements OnInit {

  constructor() {}
  
  ngOnInit(): void {
  }
  
}
