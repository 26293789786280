<div>
    <div class="modal-header justify-content-center">
        <h2 class="text-secondary font-weight-bold mt-2">Request management</h2>
        <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0 p-1">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
    <div class="container modal-body">
        <div class="row px-4 mt-3">
            <p class="px-5 font-weight-light text-grey-light mx-3 invited-p">Request an editor profile so you can seamlessly edit your artist profiles. </p>
        </div>
    </div>
    <div class="container mt-4">
        <div class="row modal-footer d-flex justify-content-start px-0">
            <div class="col-lg-4 px-0">
                <button  type="submit"
                    class="w-100 btn btn-md text-primary font-weight-regular text-left">Cancel</button>
            </div>
            <div class="col-lg-3 px-0 ml-3">
                <button  (click)="requestManagementArtworks()" type="submit" class="w-100 btn btn-lg btn-primary font-weight-regular">Request</button>
            </div>
        </div>
    </div>
</div>