<form [formGroup]="formDemo" (ngSubmit)="onSubmit(formDemo)">
    <div class="card-header text-center p-4">
        <h3 class="text-secondary font-weight-bold mb-0">Request Demo</h3>
        <button type="button" (click)="buttonClose()" class="btn btn-outline position-absolute right-0 top-0 p-2 cursor-pointer">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
    <div class="card-body">
        <div class="container px-0">
            <div class="row">
                <div class="col-lg-12">
                    <div class="">
                        <input class="form-control bg-light border-0 col py-4" style="height: 50px;" formControlName="name" placeholder="Name" type="text" required checked>
                    </div>
                    <div class="mt-3">
                        <input class="form-control bg-light border-0 col py-4" style="height: 50px;" formControlName="email" placeholder="Email" type="text" required checked>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="mt-3">
                        <input class="form-control bg-light border-0 col py-4" style="height: 50px;" formControlName="company" placeholder="Company" type="text" required checked>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="mt-3">
                        <input class="form-control bg-light border-0 col py-4" style="height: 50px;" formControlName="phone" placeholder="Phone" type="tel" required checked>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="mt-3">
                        <input class="form-control bg-light border-0 col py-4" style="height: 50px;" formControlName="job_title" placeholder="Job titles" type="text" required checked>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="mt-3">
                        <input class="form-control bg-light border-0 col py-4" style="height: 50px;" formControlName="number_employees" placeholder="Number employees" type="tel" required checked>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer bg-white py-4">
        <div class="row justify-content-center">
            <div class="col-lg-10">
                <button type="submit" class="btn-lg btn btn-primary font-weight-regular d-flex justify-content-center align-items-center cursor-pointer w-100">Request</button>
            </div>
        </div>
    </div>
</form>