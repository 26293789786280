<div class="modal-header justify-content-center">
    <h2 class="text-secondary font-weight-bold py-2">Artist royalties</h2>
    <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0 p-1">
        <img src="/assets/icons/close.svg">
    </button>
</div>
<div class="modal-body text-center px-0">
    <div class="row">
        <div class="col-lg-12 mt-2">
            <img src="/assets/icons/edition.png" alt="edition">
            <h3 class="font-weight-normal text-secondary mt-2">What are and how they work?</h3>
            <p class="text-grey font-weight-light mt-4 px-5">Each time you sell an artwork, you will receive a percentage of the sale, with no limit on potential royalties made on collector-to-collector sales. Instead of auction houses and collectors keeping all revenue from resale transactions, blockchain-verification and tracking ensure that artists always receive royalties from secondary market sales. You can set a percentage of up to 10%. The standard percentage artists usually set is 5%.</p>
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-center">
        <div class="col-lg-7">
                <button class="btn btn-lg btn-primary font-weight-regular cursor-pointer w-100">Understood</button>
        </div>
    </div>
</div>