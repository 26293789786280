import { Component, OnInit } from '@angular/core';
import {NgbModal, NgbRatingConfig} from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-trade-rating',
  templateUrl: './modal-trade-rating.component.html',
  styleUrls: ['./modal-trade-rating.component.scss'],
  providers: [NgbRatingConfig],
})
export class ModalTradeRatingComponent implements OnInit {
  
  currentRate = 3;
  comment: any;
  public transaction: any = {};
  
  
  constructor(
    public config: NgbRatingConfig, 
    public api: ApiService,
    private modalService: NgbModal,


    ) {
      config.max = 5;
    }
    
    ngOnInit(): void {
    }
    
    saveFeedback() {
      var data =  {
        rating: this.currentRate, 
        comment: this.comment,
        transaction_id: this.transaction.id
      }
      this.api.post('feedbacks', data).then((response) => {
        console.log(response);
        Swal.fire(
          'Ok!',
          'Your Feedback has been sent successfully.',
          'success',
          )
          .then((response) => {
            if (response.isConfirmed) {
              this.modalService.dismissAll();
            }
          })
        });
        
      }
      
      buttonClose() {
        const modalRef = this.modalService.dismissAll();
      }
    }
    