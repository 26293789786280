import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-modal-add-to-shipping',
  templateUrl: './modal-add-to-shipping.component.html',
  styleUrls: ['./modal-add-to-shipping.component.scss']
})
export class ModalAddToShippingComponent implements OnInit {

  constructor(
    public modalService: NgbModal

  ) { }

  ngOnInit(): void {
  }


  
  buttonClose(){
    const modalRef = this.modalService.dismissAll();
   }
}
