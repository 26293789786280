import { Component, HostListener, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Observer } from 'rxjs/internal/types';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { ImageService } from 'src/app/services/image.service';

@Component({
  selector: "app-versionSpecs",
  templateUrl: "./versionSpecs.component.html",
  styleUrls: ["./versionSpecs.component.scss"],
})
export class VersionSpecsComponent implements OnInit {
  
  @Input() artwork: any 
  @Input() version: any
  @Input() descriptionArtworkDesktop: any;
  @Input() descriptionArtworkMobile: any;
  @Input() showImage: boolean = true;
  public img: any = '';
  public style: any = '';
  public innerWidth: any;
  data: string;
  progress_bar_value: number = 0;
  loading: boolean = false;
  
  constructor(
    private api: ApiService,
    private auth: AuthService,
    ) { }
    
    base64Image: any;
    
    ngOnInit(): void {
      if (this.artwork && this.version) {
        this.artwork.version = this.version
      }
      
      this.innerWidth = window.innerWidth;
    }
    
    @HostListener('window:resize', ['$event'])
    onResize(event) {
      this.innerWidth = window.innerWidth;
    }
    
    toDataURL(url) {
      return fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
    }
    
    async download() {
      setInterval( async function() {
        this.progress_bar_value += 15;
      }.bind(this),100 );
      setTimeout(async function () {
        this.loading = true;
        const a = document.createElement("a");
        a.href = await this.toDataURL(this.artwork.img);
        a.download = this.artwork.title.toLowerCase().replace(/\s/g, "-");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        this.loading = false;
      }.bind(this),  100);
    }
  }
  