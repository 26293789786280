import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {
  
  public activeArtworkStatusFilter: any = "all";
  public artworkSearchFilter: any = "";
  public artworks: any;
  public event: any;
  public slug: any;
  
  
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public modalService: NgbModal,
    public api: ApiService,
    public helper: HelperService,
    
    ) {
      this.slug = this.route.snapshot.params.slug;
    }
    
    ngOnInit(): void {
      this.api.get('event/'+this.slug).then((response) => {
        this.event = response
        this.artworks = response.artworks
      })
      console.log(this.event)
    }
    
    
    filterArtworks(artwork) {
      if (this.artworkSearchFilter) {
        if (artwork.title.toLocaleLowerCase().indexOf(this.artworkSearchFilter.toLocaleLowerCase()) < 0) return false
      }
      if (this.activeArtworkStatusFilter === 'all') return true;
      if (this.activeArtworkStatusFilter == 'digital' && artwork.digital == true) return true;
      if (this.activeArtworkStatusFilter == 'physical' && artwork.digital == false) return true;
      return false;
    }
  }
  