<div class="py-4">
    <div class="row">
        <div class="col-lg-12 text-center">
            <h4 class="modal-title"> <strong>e-mail address Confirmed</strong> </h4>

        </div>
    </div>
    <hr>
    <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0">
        <img src="/assets/icons/close.svg">
    </button>
    <div class="mt-2 text-center py-4">
        <img src="./assets/icons/certo-2.svg" class="pb-3" alt="">
        <div>
            <h4 class="font-weight-light">Welcome <span class="font-weight-bold">"User Name"</span>
            </h4>
        </div>
        <div>
            <p class="text-grey-light font-weight-light px-5">Your email address is confirmed continue to Alphaa.io to create profile.</p>
        </div>
    </div>
    <div class="mt-5">
        <hr>
        <div class="text-center">
            <a class="btn-lg btn btn-primary font-weight-regular pl-5 pr-5">Next </a>
        </div>
    </div>
</div>
