import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-trade-lists',
  templateUrl: './trade-lists.component.html',
  styleUrls: ['./trade-lists.component.scss']
})
export class TradeListsComponent implements OnInit {
  @Input() progress: any;
  @Input() confirm: any;
  @Input() label: any;

  constructor() {}

  ngOnInit(): void {
  }

}
