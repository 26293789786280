import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalEditFolderComponent } from 'src/app/modals/modal-edit-folder/modal-edit-folder.component';
import { ModalCreateFoldersComponent } from 'src/app/modals/modal-create-folders/modal-create-folders.component';
import { AuthService } from 'src/app/services/auth.service';



@Component({
  selector: 'app-folders',
  templateUrl: './folders.component.html',
  styleUrls: ['./folders.component.scss']
})
export class FoldersComponent implements OnInit {
  viewMode: any;
  public folders: any;
  public folder: any;
  public id: number;


  constructor(
    public api: ApiService,
    public auth: AuthService,
    public route: ActivatedRoute,
    public modalService: NgbModal

  ) { }

  ngOnInit(): void {
    this.viewMode = this.route.snapshot.params.gallery ? "details" : "folder"
    this.id = this.route.snapshot.params.gallery
    if (this.viewMode == "folder") {
      this.api.get('artworks/folders').then((response) => {
        this.folders = response
      })
    } else {
      this.api.get('artworks/folders/' + this.id).then((response) => {
        this.folder = response
      })
    }
  }

  openEditFolder(folder) {
    const modalRef = this.modalService.open(ModalEditFolderComponent);
    modalRef.componentInstance.folder = folder
  }

  openCreateFolders() {
    const modalRef = this.modalService.open(ModalCreateFoldersComponent);
  }

}
