<div class="container-fluid px-0" *ngIf="artist"> 
    <div class="row">
        <div class="col-lg-12 px-0">
            <div class="d-flex">
                <a *ngIf="artist.contact_information.blog != null && artist.contact_information.blog != 0" [href]="[artist.contact_information.blog]" class="btn p-0 cursor-pointer" target="_blank">
                    <img src="/assets/icons/rede.png" alt="web" style="width: 70px;">
                </a>
                <a *ngIf="artist.contact_information.instagram != null && artist.contact_information.instagram != 0" [href]="[artist.contact_information.instagram]" class="btn p-0 cursor-pointer" target="_blank"> 
                    <img src="/assets/icons/instagram.png" alt="instagram" style="width: 70px;">
                </a>
                <a *ngIf="artist.contact_information.facebook != null && artist.contact_information.facebook != 0" [href]="[artist.contact_information.facebook]" class="btn p-0 cursor-pointer" target="_blank">
                    <img src="/assets/icons/facebook.png" alt="facebook" style="width: 70px;"> 
                </a>
                <a *ngIf="artist.contact_information.twitter != null && artist.contact_information.twitter != 0" [href]="[artist.contact_information.twitter]" class="btn p-0 cursor-pointer" target="_blank">
                    <img src="/assets/icons/twitter.png" alt="twitter" style="width: 70px;"> 
                </a>
            </div>
        </div>
    </div>
</div>