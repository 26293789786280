import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-preview-artwork',
  templateUrl: './preview-artwork.component.html',
  styleUrls: ['./preview-artwork.component.scss']
})
export class PreviewArtworkComponent implements OnInit {
  @Input() preview: any;
  @Input() img: any;
  @Input() type: any;
  @Input() styleImage: string = '';
  @Input() styleBox: string = '';
  @Input() class: any = '';
  @Input() link: any;
  @Input() showPlay: boolean = false;
  public show: boolean = false
  
  constructor(
    public helper: HelperService,
    public auth: AuthService,
    ) { }
    
    ngOnInit(): void {
    }
    
    showVideo(){
      if(this.show){
        this.show = false
      }else{
        this.show = true
      }
    }
  }
  