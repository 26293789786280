import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalAddArtworkComponent } from 'src/app/modals/modal-add-artwork/modal-add-artwork.component';
import { ModalCreateListComponent } from 'src/app/modals/modal-create-list/modal-create-list.component';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import * as Highcharts from 'highcharts';
import { HighChartsService } from 'src/app/services/highcharts.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public viewMode: any;
  public lists: any;
  public items: any;
  public versions: any;
  public total: any = 0;
  public buy: any;
  public sale: any;
  public Highcharts: typeof Highcharts = Highcharts;
  public type: any = 'dashboard-collector';
  public dashboard: any = 'dashboard-collector'
  public chartOptions: Highcharts.Options = {};
  public dataChart: any[] = [];

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public modalService: NgbModal,
    public api: ApiService,
    public hcSercive: HighChartsService,
    public auth: AuthService
    ) {
      router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          this.auth.updateSubscriptionData().then(value=>{
            // console.log(value)
          })
          this.viewMode = this.route.snapshot.params.viewMode
          if (this.viewMode == 'collections') {
            this.api.get('artworks/lists').then((response) => {
              this.lists = response})
          } else {

            this.api.get('collector/artworks').then(async (response) => {
              this.items = response
            })

            this.api.get('artworks').then(async (response) => {
              this.versions = response.owner;
              var dataChart =  []
              this.total = 0;
              for await (let version of this.versions){
                this.total += version.price / 100
                let dateCreated = new Date(version.updated_at)
                const result = dataChart.find(data => data.x === (dateCreated.getMonth()+1)+'/'+ dateCreated.getFullYear());
                if(result){
                  result.y += version.price / 100
                }else{
                  dataChart.push({x: (dateCreated.getMonth()+1)+'/'+ dateCreated.getFullYear(),y: version.price / 100})
                }
              }
              this.chartOptions = this.hcSercive.populateSplineChart(dataChart);
            })

          }
        }
      });
      
    }
    ngOnInit(): void {
    }
    
    openCreateArtwork() {
      if(this.auth.subscriptionActive.getValue()){
        this.auth.switchProfileView('creator')
      }else if(this.auth.institutionActive.getValue()){
        this.auth.switchProfileView('institution')
      }else{
        window.location.href = "/subscribe"
      }
    }
    openAddArtworks() {
      const modalRef = this.modalService.open(ModalAddArtworkComponent);
    }
    
    openCreateList() {
      const modalRef = this.modalService.open(ModalCreateListComponent);
    }
  }
  