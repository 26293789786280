import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-institution-artwork-request-management',
  templateUrl: './modal-institution-artwork-request-management.component.html',
  styleUrls: ['./modal-institution-artwork-request-management.component.scss']
})
export class ModalInstitutionArtworkRequestManagementComponent implements OnInit {
  
  @Input() artwork: any;
  
  constructor(
    public modalService: NgbModal,
    public router: Router,
    public api: ApiService,
    ) { }
    
    ngOnInit(): void {
    }

    requestManagementArtworks(){
      this.api.get('artworks/requestManegement/'+ this.artwork.id).then(response => {
        Swal.fire(
          'Request sent!',
          'Your request has been sent successfully.',
          'success',
          )
          .then((response) => {
            if (response.isConfirmed) {
              this.buttonClose()
            }
          })
        })
      }
      

    buttonClose(){
      const modalRef = this.modalService.dismissAll();
    }
  }
  