// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  URL_BASE: 'https://group-chat-nft.alphaa.io',
  apiUrl: 'https://beta-api.alphaa.io/v1/',

  // URL_BASE: 'https://alphaa.io',
  // apiUrl: 'https://api.alphaa.io/v1/',

  // URL_BASE: 'http://localhost:4200',
  // apiUrl: 'http://localhost:3333/v1/',
  IMAGE_SIZES: [],
  URL_ASSETS: 'https://media.alphaa.io/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
