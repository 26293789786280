import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CountryISO, PhoneNumberFormat, SearchCountryField, } from "ngx-intl-tel-input";
import { interval } from "rxjs";
import { ApiService } from "src/app/services/api.service";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-modal-two-factor",
  templateUrl: "./modal-two-factor.component.html",
  styleUrls: ["./modal-two-factor.component.scss"],
})
export class ModalTwoFactorComponent implements OnInit {
  public twoFactorStep: number = 1;
  public selectedCountry: number;
  public separateDialCode = false;
  public SearchCountryField = SearchCountryField;
  public CountryISO = CountryISO;
  public phoneNumberFormat = PhoneNumberFormat;
  public preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom,];
  public formNumber: FormGroup;
  public data: number = 120;

  @Output() clickevent = new EventEmitter<string>();
  
  constructor(
    public modalService: NgbModal, 
    public activeModal: NgbActiveModal,
    public auth: AuthService,
    public api: ApiService,
    public formBuilder: FormBuilder,
    ) {
      this.createFormNumber()
    }
    
    ngOnInit(): void {
        const obs$ = interval(1000);
        obs$.subscribe(() => {
          if(this.data > 0){
            this.data = this.data - 1;
          }else if(this.data == 0){
            this.data = 0
          }
        })
    }

    buttonClose() {
      const modalRef = this.modalService.dismissAll();
    }
    
    createFormNumber(){
      this.formNumber = this.formBuilder.group({
        phone_number: [this.auth.userData ? this.auth.userData.phone_number: null, [Validators.required, Validators.minLength(3)]],
      })
    }
    
    btnAskMeLater(askmelater: string) {
      const modalRef = this.modalService.dismissAll()
      this.clickevent.emit(askmelater);
    }
    
    btnNext({ valid, value }: { valid: boolean; value: any }) {
      if (valid) {
        if (this.twoFactorStep == 1) {
          const payload = {
            phone_number: value.phone_number.e164Number
          }
          this.api.put('users/twoFactorPhone', payload).then(res => {
            this.twoFactorStep = 2;
          })
        } else if (this.twoFactorStep == 2) {


                      
          let twoFactor: any = value.input1 + '' + value.input2 + '' + value.input3 + '' + value.input4 + '' + value.input5 + '' + value.input6
          let payload = {
            twoFactor: twoFactor
          }
          this.api.put('users/confirmTwoFactorMobile', payload).then(res => {
            this.auth.updateSubscriptionData().then(res => {
              this.twoFactorStep = 3;
            })
          }).catch(e => {
            this.twoFactorStep = 4;
          })
        }
      }
    }
    
    btnBack() {
      if (this.twoFactorStep == 2) {
        this.twoFactorStep = 1;
      } else if (this.twoFactorStep == 4) {
        this.twoFactorStep = 1;
      }
    }
    
    previusDigit(num, id) {
      let prevEl = document.getElementById(id + '-' + (num));
      prevEl.focus();
    }
    
    nextDigit(num, id, evt: any = false) {
      if(evt.keyCode == 8){
        let prevEl = document.getElementById(id + '-' + (num - 1));
        if (prevEl) {
          prevEl.focus();
        }
      }else if (num === null && evt != false && evt.type == 'paste') {
        evt.preventDefault();
        let pastedData = evt.clipboardData?.getData('Text');
        for (let i in pastedData) {
          if (parseInt(i) >= 6) break;
          let el = document.getElementById(id + '-' + (parseInt(i) + 1));
          this.auth.formTwoFactor.controls['input' + (parseInt(i) + 1)].patchValue(pastedData[i]);
        }
        if (id === 'two-factor-phone') {
          let call = this.btnNext(this.auth.formTwoFactor);
          // this.onSubmit.emit({promise: call, form: 'two-factor'});
        }
        
      } else {
        let nextEl = document.getElementById(id + '-' + (num + 1));
        
        if (!this.auth.formTwoFactor.controls['input' + num].value) {
          return false
        }
        if (nextEl) {
          nextEl.focus();
        } else if (num === 6) {
          if (id === 'two-factor-phone') {
            let call = this.btnNext(this.auth.formTwoFactor);
            // this.onSubmit.emit({promise: call, form: 'two-factor'});
          }
          
        }
      }
      return false;
    }
    
  }