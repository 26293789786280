<section id="your-smart">
    <div class="text-center pb-4 container">
        <div class="row">
            <div class="col-lg-12 pt-4 px-0">
                <h4 class="text-secondary font-weight-bold mb-0">Your Smart Contract</h4>
                <hr>
            </div>
        </div>
        <button (click)="buttonClose()" class="btn btn-outline position-absolute cursor-pointer right-0 top-0 p-2">
            <img src="/assets/icons/close.svg">
        </button>
        <div class="row">
            <div class="col-lg-6 col-md-6 text-justify-center">
                <div>
                    <img [src]="version.artwork.img" style="width: 15em;border-radius: 12px;">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div>
                    <h1 class="text-secondary font-weight-bold text-left mb-0">{{version.artwork.title}}</h1>
                    <p class="text-grey text-left font-weight-light">By {{version.artwork.artist.full_name}}</p>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-lg-12 col-md-12 text-sm-left" *ngIf="version">
                    <div class="col-lg-12 col-md-6">
                        <div class="row">
                            <div class="col-lg-6">
                                <p class="text-secondary font-weight-bold mb-2">Creation date</p>
                                <p class="text-secondary font-weight-bold mb-2">Size</p>
                                <p class="text-secondary font-weight-bold mb-2">Edition</p>
                            </div>
                            <div class="col-lg-6">
                                <p class="text-secondary mb-2">{{helper.formatDate(version.created_at)}}</p>
                                <p class="text-secondary mb-2">{{version.artwork.width}} x {{version.artwork.height}} {{version.artwork.unit}}</p>
                                <p class="text-secondary mb-2">{{version.version}} / {{version.artwork.edition}}</p>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="col-lg-12 col-md-6">
                        <div class="row">
                            <div class="col-lg-6">
                                <!-- <p class="text-secondary font-weight-bold">Buyer</p> -->
                                <p class="text-secondary font-weight-bold mb-2">Transaction Date</p>
                            </div>
                            <div class="col-lg-6">
                                <p class="text-secondary mb-2" *ngIf="!version.owner">{{version.artwork.artist.full_name}}</p>
                                <p class="text-secondary mb-2" *ngIf="version.owner">{{version.owner.full_name}}</p>
                                <p class="text-secondary mb-2">{{helper.formatDate(version.created_at)}}</p>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="col-lg-12 col-md-6">
                        <div class="row">
                            <div class="col-lg-6">
                                <p class="text-secondary font-weight-bold mb-2">Royalties</p>
                                <p class="text-grey font-weight-light mb-2">Applied on every trade
                            </div>
                            <div class="col-lg-6">
                                <p class="text-secondary mb-2">{{version.artwork.author_fee / 100}}% artist</p>
                                <p class="text-secondary mb-2">{{version.artwork.institution_fee / 100}}% institution</p>
                                <p class="text-secondary mb-2">{{version.artwork.platform_fee / 100}}% Alpha’a platform</p>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="col-lg-12 col-md-6">
                        <div class="row">
                            <div class="col-lg-6">
                                <p class="text-secondary mb-2 font-weight-bold">Actual Price</p>
                            </div>
                            <div class="col-lg-6">
                                <h4 class="text-primary font-weight-normal mb-2">$ {{version.price / 100}}</h4>
                                <p class="text-grey font-weight-light mb-2">Updated on today at {{helper.formatDate(version.updated_at)}}</p>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
    <!-- <hr>
    <div class="p-3 text-left">
        <button type="button" href="#" class="btn btn-outline pl-4 pr-4 text-primary">Download PDF <img src="/assets/icons/pdf.png">
        </button>
        <button type="button" href="#" class="btn btn-primary font-weight-bold pl-5 pr-5" style="width: 10em;">Done</button>
    </div> -->
</section>