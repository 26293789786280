<div *ngIf="InstitutionEditStep == 0">
    <div class="modal-header justify-content-center">
        <h2 class="text-secondary font-weight-bold mt-2">Change price/royalties</h2>
        <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0 p-2">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
    <div class="container modal-body">
        <div class="row justify-content-end pr-4">
            <div class="col-lg-4">
                <p class="font-weight-regular text-secondary invited-p mb-0">Current Price</p>
                <h4 class="text-grey-light font-weight-light pr-2">{{ helper.amountCurrencyFormat(artwork.original_price) }}</h4>
            </div>
        </div>
        <div class="row d-flex justify-content-center mt-3">
            <div class="col-lg-11">
                <p class="text-secondary font-weight-bold mb-0 invited-p">New Price</p>
                <input [(ngModel)]="newPrice" class="bg-light border-0 w-100 inputPrice text-right" placeholder="USD" type="text" required checked>
            </div>
        </div>
        <div class="row d-flex justify-content-center mt-3">
            <div class="col-lg-11">
                <p class="text-secondary font-weight-bold mb-0 invited-p">Royalties</p>
                <div class="d-flex justify-content-between mt-3">
                    <p class="text-grey-link font-weight-light mb-0 Institution-p cursor-pointer">Artist royalty
                        <a>
                            <img src="/assets/icons/transaction.png">
                        </a>
                    </p>
                    <p class="font-weight-bold text-primary mb-0"> {{ artwork.institution_fee }}%</p>
                </div>
                <div class="w-100">
                    <input type="range" min="0" max="10" [(ngModel)]="artwork.institution_fee" [value]="" step="1" class="form-range w-100">
                </div>
                <div class="d-flex justify-content-between text-porcent">
                    <p class="font-weight-light text-grey-light institution-number mb-0">00</p>
                    <p class="font-weight-light text-grey-light institution-number mb-0">10%</p>
                </div>
                <div class="d-flex justify-content-between mt-3">
                    <p class="text-grey-link font-weight-light mb-0 Institution-p">Institution royalty <a><img src="/assets/icons/transaction.png"></a></p>
                    <p class="font-weight-bold text-primary mb-0"> {{ artwork.author_fee }}%</p>
                </div>
                <div class="w-100">
                    <input type="range" min="0" max="10" [(ngModel)]="artwork.author_fee" [value]="" step="1" class="form-range w-100">
                </div>
                <div class="d-flex justify-content-between text-porcent">
                    <p class="font-weight-light text-grey-light institution-number mb-0">00</p>
                    <p class="font-weight-light text-grey-light institution-number mb-0">10%</p>
                </div>
            </div>
        </div>
        <div class="container-fluid mt-4 px-0">
            <div class="row modal-footer d-flex justify-content-center">
                <div class="col-lg-6">
                    <button type="submit" class="w-100 btn btn-lg btn-primary font-weight-regular btn-invite" (click)="changePrice()">Change</button>
                </div>
            </div>
        </div>
    </div>
</div>