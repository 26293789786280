import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-track-modal',
  templateUrl: './add-track-modal.component.html',
  styleUrls: ['./add-track-modal.component.scss']
})
export class AddTrackModalComponent implements OnInit {

  constructor(
    public modalService: NgbModal

  ) { }

  ngOnInit(): void {
  }

  buttonClose(){
    const modalRef = this.modalService.dismissAll();
   }
}
