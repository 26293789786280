import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-trade-buyer-qr',
  templateUrl: './modal-trade-buyer-qr.component.html',
  styleUrls: ['./modal-trade-buyer-qr.component.scss']
})
export class ModalTradeBuyerQrComponent implements OnInit {
  
  public cardName: string
  public cardNumber: number
  public cardCvv: number
  public cardYear: number
  public cardMonth: number
  public version: any = '';
  public success: any = '';
  public tradeBuyerStep: number = 1;
  public authFormMode: string = 'login';
  public transaction: any;
  public total: any;
  
  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private api: ApiService
    ) { }
    
    ngOnInit(): void {
      if (this.success === true) {
        this.tradeBuyerStep = 3
      }
      this.total = ((this.version.price/100) + 
      ((this.version.artwork.institution_fee/100) * (this.version.price/100) / 100) + 
      ((this.version.artwork.author_fee/100) * (this.version.price/100) / 100) + 
      ((this.version.artwork.platform_fee/100) * (this.version.price/100) / 100)+
      ((4.5) * (this.version.price/100) / 100))
      
      if(this.version.owner_id == null){
        this.total = this.total - ((this.version.artwork.author_fee/100) * (this.version.price/100) / 100)
        this.total = this.total - ((this.version.artwork.platform_fee/100) * (this.version.price/100) / 100)
        this.total = this.total - ((this.version.artwork.institution_fee/100) * (this.version.price/100) / 100)
      }
    }
    
    paymentTransaction(data) {
      const payload = {
        email: this.auth.userData.email,
        first_name: this.auth.userData.full_name.split(' ')[0],
        last_name: this.auth.userData.full_name.split(' ')[1],
        typeTrade: 'platform',
        qrCode: true
      }
      
      this.api.post('artworks/' + this.version.id + '/transfer', payload).then((response) => {
        let payload = {
          name: this.cardName,
          number: this.cardNumber,
          month: this.cardMonth,
          year: this.cardYear,
          cvv: this.cardCvv
        }
        this.transaction = response
        this.api.put('transfers/' + this.transaction.hash_token + '/capture', payload).then((response) => {
          if(response){
            if(!response.error){
              Swal.fire(
                'Accepted!',
                'Your transanction has been accepted.',
                'success',
                )
                .then((response) => {
                  if (response.isConfirmed) {
                    window.location.href = "/dashboard/artworks"
                  }
                })
              }else{
                Swal.fire(
                  'Opss..',
                  response.message,
                  'error',
                  )
                  .then((response) => {
                    if (response.isConfirmed) {
                      // window.location.href = "/dashboard/artworks"
                    }
                  })
                }
              }
            })
          });
        }
        
        submitForm(evt) {
          console.log(evt)
          switch (evt.form) {
            case 'two-factor':
            this.twoFactor(evt);
            break;
            case 'authenticate-register':
            this.twoFactor(evt);
            break;
          }
        }
        
        
        twoFactor(evt) {
          evt.promise.then((response) =>{
            this.route.queryParams.subscribe(params => {
              this.api.get('versions/getByNftToken/' + params['tradeQr']).then((data) => {
                this.tradeBuyerStep = 1;
                this.version = data;
              });
            })
          })
        }
        
        buttonClose() {
          const modalRef = this.modalService.dismissAll();
        }
      }
      