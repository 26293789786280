import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "src/app/services/api.service";
import { AuthService } from "src/app/services/auth.service";
import { HelperService } from "src/app/services/helper.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-modal-trade-buyer",
  templateUrl: "./modal-trade-buyer.component.html",
  styleUrls: ["./modal-trade-buyer.component.scss"],
})
export class ModalTradeBuyerComponent implements OnInit {
  public cardName: string;
  public cardNumber: string;
  public cardCvv: number;
  public cardYear: number;
  public cardExp: any;
  public cardMonth: number;
  public version: any = "";
  public success: any = "";
  public transaction: any = {};
  public tradeBuyerStep: number = 1;
  public total: any = 0;
  public gatewayFee: any = 0;
  public authFormMode: string = "login";
  public img: any = "";
  public style: any = "";
  public replace: any;

  constructor(
    private auth: AuthService,
    private helper: HelperService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private api: ApiService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.success = params.success;
    });
    if (this.transaction.status === 4) {
      this.tradeBuyerStep = 4;
    }
    if (this.transaction.status === 7) {
      this.tradeBuyerStep = 3;
    }
    if (this.transaction.status === 5) {
      this.tradeBuyerStep = 3;
    }

    if (this.success === "true") {
      this.tradeBuyerStep = 4;
      this.transaction.status = 4;
      this.api
        .put("transfers/" + this.transaction.id, this.transaction)
        .then((response) => {
          if (!response.code) {
            this.transaction = response;
            if (this.transaction.status === 4) {
              this.tradeBuyerStep = 4;
            }
            if (this.transaction.status === 7) {
              this.tradeBuyerStep = 3;
            }
            if (this.transaction.status === 5) {
              this.tradeBuyerStep = 3;
            }
          }
        });
    }
  }

  paymentTransactionWithPaypal(data) {
    this.api
      .put("transfers/" + data.hash_token + "/capture", {})
      .then((response) => {
        console.log(response);
        if (response === true) {
          Swal.fire(
            "Accepted!",
            "Your transanction has been accepted.",
            "success"
          ).then((response) => {
            if (response.isConfirmed) {
              window.location.href = "/dashboard/artworks";
            }
          });
        } else {
          document.location.href = response.links[1].href;
        }
      });
  }

  paymentTransactionWithStripe(data) {
    var payload: any = {};
    
    if (this.cardNumber) {
      let cardNumber = this.cardNumber.replace(/\s+/g, "");
      let cardExp = this.cardExp.split(" / ");
      this.cardMonth = cardExp[0];
      this.cardYear = cardExp[1];
      payload = {
        name: this.cardName,
        number: cardNumber,
        month: this.cardMonth,
        year: this.cardYear,
        cvv: this.cardCvv,
      };
    }

    this.api
      .put("transfers/" + data.hash_token + "/capture", payload)
      .then((response) => {
        if (response) {
          if (!response.error) {
            Swal.fire(
              "Accepted!",
              "Your transanction has been accepted.",
              "success"
            ).then((response) => {
              if (response.isConfirmed) {
                window.location.href = "/dashboard/artworks";
              }
            });
          } else {
            Swal.fire("Opss..", response.message, "error").then((response) => {
              if (response.isConfirmed) {
                // window.location.href = "/dashboard/artworks"
              }
            });
          }
        }
      });
  }

  submitForm(evt) {
    switch (evt.form) {
      case "two-factor":
        this.twoFactor(evt);
        break;
      case "authenticate-register":
        this.twoFactor(evt);
        break;
    }
  }

  twoFactor(evt) {
    evt.promise.then((response) => {
      this.route.queryParams.subscribe((params) => {
        this.api.get("transfers/" + params["trade"]).then((data) => {
          this.tradeBuyerStep = 1;
          this.transaction = data;
        });
      });
    });
  }

  goToArtwork() {
    window.location.href =
      "/versions/" + this.transaction.artwork_version_id + "/record";
  }

  buttonClose() {
    const modalRef = this.modalService.dismissAll();
  }
}
