import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-modal-versions-artwork-to-institution',
  templateUrl: './modal-versions-artwork-to-institution.component.html',
  styleUrls: ['./modal-versions-artwork-to-institution.component.scss']
})
export class ModalVersionsArtworkToInstitutionComponent implements OnInit {

  constructor(
    public modalService: NgbModal,
    public router: Router,
    public route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  }


  buttonClose(){
    const modalRef = this.modalService.dismissAll();
   }
}
