import { Component, Input, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {  NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from "ngx-file-drop";
import Swal from "sweetalert2";
import { AuthService } from "src/app/services/auth.service";
import { HelperService } from "src/app/services/helper.service";

@Component({
  selector: "app-modal-upload-image",
  templateUrl: "./modal-upload-image.component.html",
  styleUrls: ["./modal-upload-image.component.scss"],
})
export class ModalUploadImageComponent implements OnInit {
  
  @Input() public typeUpload: any;
  @Input() public user: any;
  public uid: any;
  
  public fileList: any[] = [];
  public files: NgxFileDropEntry;
  
  constructor(
    public auth: AuthService, 
    public helper: HelperService, 
    public api: ApiService, 
    public modalService: NgbModal
    ) {}
    
    ngOnInit(): void {
      this.uid = this.auth.userData.id
      if(this.user){
        this.uid = this.user.id
      }
    }
    
    public async dropped(files: NgxFileDropEntry[]) {
      this.files = files[0];
      this.fileList = [];
      this.api.loading = true
      if( this.files.fileEntry.isFile) {
        const fileEntry =  this.files.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          const reader = new FileReader();
          fileEntry.file(file => {
            reader.readAsDataURL(file);
            reader.onload = () => {
              this.fileList.push({ relativePath:  this.files.relativePath, file: file, formatedSize: (file.size / 1000 / 1000).toFixed(1) + 'Mb', imageUrl: reader.result, blob: this.helper.sanitize(window.URL.createObjectURL(file))  });
              if((file.size / 1000 / 1000) > 100){
                this.api.loading = false
                Swal.fire('Opss!','The maximum size allowed is 100Mb, send an email to hello@alphaa.io','warning' )
                return false
              }
              this.api.loading = false
            };
          });
        });
      }
    }
    
    public fileOver(event) {
      console.log(event);
    }
    
    public fileLeave(event) {
      console.log(event);
    }
    
    buttonClose() {
      const modalRef = this.modalService.dismissAll();
    }
    
    saveImage(){
      let formData = new FormData();
      if(this.typeUpload == 'avatar'){
        formData.append('avatar', this.fileList[0].file);
        this.api.put('users/updateAvatar/'+this.uid, formData).then(async (res) => {
          if(!this.user){
            await this.auth.currentAuthenticatedUser('user/'+this.uid).then(async res => {
              await this.auth.updateSubscriptionData().then(val => {
                location.reload();
              })
            })
          }else{
            location.reload();
          }
        })
      }else{
        formData.append('cover', this.fileList[0].file);
        this.api.put('users/updateCover/'+this.uid, formData).then(async (res) => {
          if(!this.user){
            await this.auth.currentAuthenticatedUser('user/'+this.uid).then(async res => {
              await this.auth.updateSubscriptionData().then(val => {
                location.reload();
              })
            })
          }else{
            location.reload();
          }
        })
      }
    }
    
  }
  