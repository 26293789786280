import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-data-artists',
  templateUrl: './event-data-artists.component.html',
  styleUrls: ['./event-data-artists.component.scss']
})
export class EventDataArtistsComponent implements OnInit {

  @Input() artwork : any = {}
  
  constructor() {}

  ngOnInit(): void {}

}
