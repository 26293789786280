<div *ngIf="shareMode == 'shareUrlArtwork'" ngbDropdown placement="top-end" class="d-inline-block">
    <button type="button" class="btn d-flex btn-share p-0" id="dropdownBasic2" ngbDropdownToggle>
        <img class="p-0" src="/assets/icons/share-outline.png" alt="share-outline">
        <!-- <img *ngIf="mode == 'profile'" class="p-0" src="/assets/icons/share-outline.png" alt="share-outline"> -->
        <!-- <img *ngIf="mode == 'artist'" class="p-0" src="/assets/icons/share-outline-grey.png" alt="share-outline"> -->
    </button>
    <div id="share-outline-blue" ngbDropdownMenu aria-labelledby="dropdownBasic2">
        <div *ngFor="let social of socials">
            <a target="_blank" href="{{ socialData[social].href }} {{urlBase}}{{currentURL}}" ngbDropdownItem class="d-flex justify-content-center align-items-center p-0">
                <img src="{{ socialData[social].src }}" alt="">
            </a>
        </div>
    </div>
    <p></p>
    <!-- <div *ngIf="mode == 'artist'" id="share-outline-grey" ngbDropdownMenu aria-labelledby="dropdownBasic2">
        <div *ngFor="let social of socials">
            <a target="_blank" href="{{ socialData[social].href }} {{urlBase}}/artist-detail/{{auth.userData.slug}}" ngbDropdownItem class="d-flex justify-content-center align-items-center p-0">
                <img src="{{ socialData[social].src }}" alt="">
            </a>
        </div>
    </div> -->
</div>



<div *ngIf="shareMode == 'sharePerfil'" ngbDropdown placement="top-end" class="d-inline-block">
    <button type="button" class="btn d-flex btn-share p-0" id="dropdownBasic2" ngbDropdownToggle>
        <img *ngIf="mode == 'profile'" class="p-0" src="/assets/icons/share-outline.png" alt="share-outline">
        <img *ngIf="mode == 'artist'" class="p-0" src="/assets/icons/share-outline-grey.png" alt="share-outline">
    </button>
    <div *ngIf="mode == 'profile'" id="share-outline-blue" ngbDropdownMenu aria-labelledby="dropdownBasic2">
        <div *ngFor="let social of socials">
            <a target="_blank" href="{{ socialData[social].href }} {{urlBase}}/artist-detail/{{auth.userData.slug}}" ngbDropdownItem class="d-flex justify-content-center align-items-center p-0">
                <img src="{{ socialData[social].src }}" alt="">
            </a>
        </div>
    </div>
    <div *ngIf="mode == 'artist'" id="share-outline-grey" ngbDropdownMenu aria-labelledby="dropdownBasic2">
        <div *ngFor="let social of socials">
            <a target="_blank" href="{{ socialData[social].href }} {{urlBase}}/artist-detail/{{auth.userData.slug}}" ngbDropdownItem class="d-flex justify-content-center align-items-center p-0">
                <img src="{{ socialData[social].src }}" alt="">
            </a>
        </div>
    </div>
</div>
