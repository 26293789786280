<form [formGroup]="formProfile" (ngSubmit)="updateProfile(formProfile)">
  <div class="row" id="cover-image" style="background-image: url({{coverImage}})">
    <div class="col-md-6 d-flex align-items-end pb-3" style="background-color: rgb(255 255 255 / 33%);">
      <h1 class="font-weight-bold text-secondary display-4 mx-2">{{auth.userData.full_name}}</h1>
    </div>
    <div class="col-md-6 d-flex justify-content-end align-items-end pb-3" style="background-color: rgb(255 255 255 / 33%);">
      <button type="button" class="btn btn-primary" (click)="openModalUploadCoverImage()">Change Cover</button>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="col-lg-12 px-3 mx-3">
        <h3 class="font-weight-bold text-secondary mt-5 mb-0">Profile Information</h3>
      </div>
      <div class="row d-md-flex justify-content-sm-between align-items-center px-sm-3 mx-2">
        <div class="col-lg-9 personal-information d-lg-flex justify-content-between align-items-center px-0">
          <div class="col-lg-8 mt-4 px-lg-2">
            <div class="d-flex input-group mb-2">
              <input (keyup)="onKey($event)" formControlName="full_name" type="text" class="form-control bg-light" id="inlineFormInputGroup" placeholder="Name" required>
              <div class="input-group-prepend">
                <button (click)="copyToClipboard()" class="input-group-text">
                  <img src="/assets/icons/trade-transfer.png" alt="">
                </button>
              </div>
            </div>
            <small *ngIf="auth.profileView == 'institution'" class="px-lg-2">Your Alphaa URL: <span id="url" >{{urlBase}}/institution/{{auth.userData.slug}}/</span></small>
            <small *ngIf="auth.profileView == 'creator'" class="px-lg-2">Your Alphaa URL: <span id="url" >{{urlBase}}/artist-detail/{{auth.userData.slug}}/</span></small>
          </div>
          <div class="col-lg-4 d-flex align-items-center px-3">
            <app-share-button [mode]="mode" [socials]="'all'" [shareMode]="'sharePerfil'"></app-share-button>
            <app-embed-button [mode]="mode" class="mb-2"></app-embed-button>
          </div>
          <div class="col-lg-4 d-flex flex-column flex-wrap-reverse align-content-center align-items-center" style="margin-top: -35px;">
            <app-profile-avatar></app-profile-avatar>
            <a class="cursor-pointer mt-3" (click)="portalSubscription()">Manage Subscription</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row d-flex align-items-baseline mt-3 px-sm-3 mx-1">
    <div class="col-lg-6 d-flex flex-column">
      <input class="border-0 bg-light form-control border-radius-default w-100" formControlName="phone_number" type="tel" placeholder="Phone number two-factor" required>
      <small *ngIf="errorFormatNumberTwoFactor" class="text-danger">&nbsp;Use "+[country code][number with area code]" exemple: "+16467634523"</small>
    </div>
    <div class="col-lg-5 d-flex align-items-center ml-sm-3">
      <div class="form-check">
        <input class="form-check-input" formControlName="confirm_two_factor_mobile" type="checkbox" role="switch" id="flexSwitchCheckDefault">
        <label class="form-check-label" for="flexSwitchCheckDefault">Use Two-Factor Mobile</label>
      </div>
    </div>
  </div>
  <hr style="margin-top: 74px; margin-bottom: 29px;">
  <div class="">
    <div class="col-lg-12 px-3 mx-3">
      <h3 class="font-weight-bold text-secondary mt-4 mb-0">Contact Information</h3>
    </div>
    <div class="row d-flex align-items-center px-sm-3 mx-1">
      <div class="col-lg-8 d-sm-flex px-0">
        <div class="col">
          <div class="mt-4 pt-1">
            <input class="border-0 bg-light form-control border-radius-default text-grey w-100" type="date" formControlName="found_date" placeholder="Found date" required>
          </div>
        </div>
        <div class="col">
          <div class="mt-4 pt-1">
            <ng-select formControlName="gender" [items]="genres" bindLabel="name" bindValue="name" placeholder="Select gender"></ng-select>
          </div>
        </div>
        <!-- <div class="col">
            <div class="mt-4 pt-1">
              <input class="border-0 bg-light form-control border-radius-default text-grey w-100" formControlName="state" type="text" placeholder="State" required>
            </div>
          </div> -->
        <div class="col">
          <div class="mt-4 pt-1">
            <ng-select formControlName="race" [items]="races" bindLabel="name" bindValue="name" placeholder="Select race"></ng-select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr style="margin-top: 74px; margin-bottom: 29px;">
  <div class="">
    <div class="col-lg-12 px-3 mx-3">
      <h3 class="font-weight-bold text-secondary mt-4 mb-0">Contact Information</h3>
    </div>
    <div class="row d-flex align-items-center px-sm-3 mx-1">
      <div class="col-lg-4">
        <div class="mt-4 pt-1">
          <input class="border-0 bg-light form-control border-radius-default w-100" formControlName="email" type="email" placeholder="Your email" required>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="mt-4 pt-1">
          <input class="border-0 bg-light form-control border-radius-default text-grey w-100" formControlName="phone" type="tel" placeholder="Your phone number" required>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="mt-4 pt-1">
          <input class="border-0 bg-light form-control border-radius-default text-grey w-100" formControlName="street" type="text" placeholder="Your Street" required>
        </div>
      </div>
      <div class="col-lg-8 d-sm-flex px-0">
        <div class="col">
          <div class="mt-4 pt-1">
            <input class="border-0 bg-light form-control border-radius-default text-grey w-100" formControlName="zip_code" type="text" placeholder="ZIP Code" required>
          </div>
        </div>
        <div class="col">
          <div class="mt-4 pt-1">
            <input class="border-0 bg-light form-control border-radius-default text-grey w-100" formControlName="city" type="text" placeholder="City" required>
          </div>
        </div>
        <div class="col">
            <div class="mt-4 pt-1">
              <input class="border-0 bg-light form-control border-radius-default text-grey w-100" formControlName="state" type="text" placeholder="State" required>
            </div>
          </div>
        <div class="col">
          <div class="mt-4 pt-1">
            <ng-select formControlName="country" [items]="countries" bindLabel="name" bindValue="name" placeholder="Select Country"></ng-select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr class="mt-5">
  <div class="col-lg-12 px-0 mt-4 pt-2">
    <h3 class="font-weight-bold text-secondary px-3 mx-3">Social</h3>
    <div class="row d-flex align-items-center px-sm-3 mx-1">
      <div class="col-lg-8 d-sm-flex px-0">
        <div class="col">
          <div class="mt-4 pt-1">
            <input class="border-0 bg-light form-control border-radius-default text-grey w-100" formControlName="instagram" value="instagram" type="text" placeholder="Instagram" required>
          </div>
        </div>
        <div class="col">
          <div class="mt-4 pt-1">
            <input class="border-0 bg-light form-control border-radius-default text-grey w-100" formControlName="twitter" type="text" placeholder="Twitter" required>
          </div>
        </div>
        <div class="col">
          <div class="mt-4 pt-1">
            <input class="border-0 bg-light form-control border-radius-default text-grey w-100" formControlName="facebook" type="text" placeholder="Facebook" required>
          </div>
        </div>
      </div>
      <div class="col-lg-8 d-sm-flex px-0">
        <div class="col">
          <div class="mt-4 pt-1">
            <input class="border-0 bg-light form-control border-radius-default text-grey w-100" formControlName="whatsapp" type="text" placeholder="WhatsApp" required>
          </div>
        </div>
        <div class="col">
          <div class="mt-4 pt-1">
            <input class="border-0 bg-light form-control border-radius-default text-grey w-100" formControlName="linkedin" type="text" placeholder="Linkedin" required>
          </div>
        </div>
        <div class="col">
          <div class="mt-4 pt-1">
            <input class="border-0 bg-light form-control border-radius-default text-grey w-100" formControlName="blog" type="text" placeholder="Blog" required>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr class="my-5">
  <div class="">
    <div class="col-lg-12 px-3 mx-3 mb-2">
      <h3 class="font-weight-bold text-secondary mt-4 mb-0">Professional Information</h3>
    </div>
    <div class="row d-flex align-items-center px-sm-3 mx-2">
      <!-- <div class="col-lg-8 d-sm-flex px-0">
          <div class="col">
            <div class="">
              <div ngbDropdown class="d-inline-block w-100">
                <p class="text-grey-link font-weight-light mb-1">Styles</p>
                <button type="button" class="btn text-left bg-light form-control border-radius-default text-grey w-100 d-flex justify-content-between align-items-center buttonDropDown cursor-pointer" [disabled]="artistId" id="dropdownBasic-artist" ngbDropdownToggle>
                  <h5 class="font-weight-light mb-0">Style</h5>
                  <div class="mt-1"><img src="/assets/icons/arrow.png" alt=""></div>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic-artist" class="boxMenuDropdrown">
                  <a class="dropDownContent text-grey cursor-pointer" style="border-radius: 7px;" ngbDropdownItem>Contemporary</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="mt-4 pt-1">
              <div ngbDropdown class="d-inline-block w-100">
                <button type="button" class="btn text-left bg-light form-control border-radius-default text-grey w-100 d-flex justify-content-between align-items-center buttonDropDown cursor-pointer" [disabled]="artistId" id="dropdownBasic-artist" ngbDropdownToggle>
                  <h5 class="font-weight-light mb-0">Style</h5>
                  <div class="mt-1"><img src="/assets/icons/arrow.png" alt=""></div>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic-artist" class="boxMenuDropdrown">
                  <a class="dropDownContent text-grey cursor-pointer" style="border-radius: 7px;" ngbDropdownItem>Minimalist</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="mt-4 pt-1">
              <input class="border-0 bg-light form-control border-radius-default text-grey w-100" type="text" placeholder="Other">
            </div>
          </div>
        </div>
        <div class="col-lg-3 d-sm-flex px-0">
          <div class="col px-0">
            <div class="mt-4 pt-1">
              <a class="nav-link cursor-pointer">
                <img src="/assets/icons/delete.svg">
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-8 d-sm-flex px-0">
          <div class="col">
            <div class="mt-4 pt-1">
              <div ngbDropdown class="d-inline-block w-100">
                <button type="button" class="btn text-left bg-light form-control border-radius-default text-grey w-100 d-flex justify-content-between align-items-center buttonDropDown cursor-pointer" [disabled]="artistId" id="dropdownBasic-artist" ngbDropdownToggle>
                  <h5 class="font-weight-light mb-0">Style</h5>
                  <div class="mt-1"><img src="/assets/icons/arrow.png" alt=""></div>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic-artist" class="boxMenuDropdrown">
                  <a class="dropDownContent text-grey cursor-pointer" style="border-radius: 7px;" ngbDropdownItem>Contemporary</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="mt-4 pt-1">
              <div ngbDropdown class="d-inline-block w-100">
                <button type="button" class="btn text-left bg-light form-control border-radius-default text-grey w-100 d-flex justify-content-between align-items-center buttonDropDown cursor-pointer" [disabled]="artistId" id="dropdownBasic-artist" ngbDropdownToggle>
                  <h5 class="font-weight-light mb-0">Style</h5>
                  <div class="mt-1"><img src="/assets/icons/arrow.png" alt=""></div>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic-artist" class="boxMenuDropdrown">
                  <a class="dropDownContent text-grey cursor-pointer" style="border-radius: 7px;" ngbDropdownItem>Other</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="mt-4 pt-1">
              <input class="border-0 bg-light form-control border-radius-default text-grey w-100" type="text" placeholder="Extra description here">
            </div>
          </div>
        </div>
        <div class="col-lg-3 d-sm-flex px-0">
          <div class="col px-0">
            <div class="mt-4 pt-1">
              <a class="nav-link cursor-pointer">
                <img src="/assets/icons/delete.svg">
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-12 d-sm-flex px-0">
          <div class="col px-0">
            <div class="mt-4 pt-1">
              <a class="nav-link cursor-pointer d-flex align-items-center">
                <img src="/assets/icons/button-add-blue.png" class="mx-1" style="width: 20px;">
                Add style
              </a>
            </div>
          </div>
        </div> -->
      <div class="col-lg-8 d-sm-flex">
        <div class="col px-0">
          <div class="mt-5">
            <ckeditor formControlName="about" class="w-100" [editor]="Editor" (ready)="onReady($event)"></ckeditor>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer row justify-content-between align-items-center bg-white pb-0 pt-4 mt-5 mx-0">
    <div class="pl-3">
      <!-- <button type="button" class="deleteAccount btn text-primary font-weight-regular py-3 px-0 cursor-pointer">Delete my account</button> -->
    </div>
    <div class="px-0">
      <!-- <button type="button" class="btn btn-lg btn-outline-primary mr-2 cursor-pointer mb-2" style="border: 2px solid;width: 154px;height: 50px;">Cancel</button> -->
      <button type="submit" class="saveChanges btn btn-lg btn-primary cursor-pointer mb-2 mr-2">Save changes</button>
    </div>
  </div>
</form>