<div class="p-4 box">
    <div class="row">
        <div class="col-lg-12 px-0">
            <div class="d-sm-flex justify-content-between">
                <div class="d-flex align-items-center trade-progress">
                    <img class="mr-2" src="/assets/icons/progress.png" alt="Progress">                                
                    <h5 class="text-secondary font-weight-bold text-nowrap m-0">Trade in progress</h5>
                </div>
                <div class="d-flex align-items-center">
                    <button type="button" (click)="openAddShipping()"  class="btn text-grey px-0 cursor-pointer"><img src="/assets/icons/transaction.png"> Transaction Data</button>
                </div>
            </div>
        </div>
    </div>
    
    <app-trade-status-progress></app-trade-status-progress>

    <div class="row px-lg-5">
        <div class="col-lg-3 mb-3">
            <h6 class="font-weight-bold m-0">Initiatied</h6>
            <small>18/01/2021, 11:35:41 AM</small>
        </div>
        <div class="col-lg-3 mb-3">
            <h6 class="font-weight-bold m-0">Fees payment</h6>
            <small>18/01/2021</small>
        </div>
        <div class="col-lg-3 mb-3">
            <h6 class="font-weight-bold m-0">Shipping</h6>
        </div>
        <div class="col-lg-3 mb-3">
            <h6 class="font-weight-bold m-0">Delivery</h6>
        </div>
    </div>

    <div class="d-sm-flex justify-content-center">
        <div class="d-sm-flex">
            <div class="mr-sm-5">
                <p>Done through <br> Alpha'a plaform</p>
            </div>
            <div class="mt-3">
                <a href="">Add a tracking number</a>
            </div>
        </div>
    </div>
</div>