import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
// import { url } from 'inspector';


@Component({
  selector: 'app-modal-edit-list',
  templateUrl: './modal-edit-list.component.html',
  styleUrls: ['./modal-edit-list.component.scss']
})
export class ModalEditListComponent implements OnInit {
  public formEditList: FormGroup;
  @Input() public list;

  constructor(
    public router: Router,
    public api: ApiService,
    public route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public modalService: NgbModal

  ) { }

  ngOnInit(): void {
    this.editFormList()
  }


  async editList({ valid, value }: { valid: boolean; value: any }) {
    if (valid) {

      let payload = {
        title: value.title,
        description: value.description
      }

      this.api.put('artworks/lists/' + this.list.id, payload).then((response) => {
        const modalRef = this.modalService.dismissAll();
        location.reload()
      })
    }
  }


  private editFormList() {
    this.formEditList = this.formBuilder.group({
      title: [this.list.title, Validators.required],
      description: [this.list.description, Validators.required]
    })
  }


  acceptDeleteList(list_id: number): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#252dff',
      cancelButtonColor: '#c00303',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.api.delete('artworks/lists/' + list_id).then((response) => {
          Swal.fire(
            'Deleted!',
            'Your list has been deleted.',
            'success',
          )
            .then((response) => {
              if (response.isConfirmed) {
                window.location.href = "/lists"
              }
            })
        })
      }
    })
  }

  buttonClose(){
    const modalRef = this.modalService.dismissAll();
   }
}
