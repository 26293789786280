<ng-container [ngSwitch]="form">
    <ng-container *ngSwitchCase="'register'">
        <form id="formRegister" [formGroup]="auth.formRegister" (ngSubmit)="_onSubmit()">
            <div class="row form-group">
                <div class="col-lg-6 pr-2">
                    <input formControlName="first_name" name="first_name" class="form-control bg-light" type="text" placeholder="First name" minlength="2" maxlength="50" required checked>
                    <small *ngIf="auth.formRegister.controls.first_name.value" [hidden]="auth.formRegister.controls.first_name.valid" class="text-danger"> Required* </small>
                </div>
                <div class="col-lg-6 pr-2">
                    <input formControlName="last_name" name="last_name" class="form-control bg-light" type="text" placeholder="Last name" minlength="2" maxlength="50" required checked>
                    <small *ngIf="auth.formRegister.controls.last_name.value" [hidden]="auth.formRegister.controls.last_name.valid" class="text-danger"> Required* </small>
                </div>
            </div>
            <div class="row form-group mt-3">
                <!-- <div class="col-lg-6 pr-2">
                    <ngx-intl-tel-input
                    [cssClass]="'form-control bg-light'"
                    [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                    [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="true"
                    [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                    [selectFirstCountry]="false"
                    [selectedCountryISO]="CountryISO.UnitedStates"
                    [minLength]="3"
                    [maxLength]="50"
                    [phoneValidation]="true"
                    [inputId]="my-input-id"
                    formControlName="phone_number"
                    name="phone_number"
                    type="tel"
                    ></ngx-intl-tel-input>
                    <small *ngIf="auth.formRegister.controls.phone_number.value" [hidden]="auth.formRegister.controls.phone_number.valid" class="text-danger"> Required* </small>
                </div> -->
                <div class="col-lg-12 pr-2">
                    <input formControlName="email" name="email" class="form-control bg-light" type="email" placeholder="Your Email" required checked>
                    <small *ngIf="auth.formRegister.controls.email.value" [hidden]="auth.formRegister.controls.email.valid" class="text-danger"> Required* </small>
                </div>
            </div>

            <div class="form-group row mt-3">
                <div class="col-lg-12 pr-2">
                    <input formControlName="password" name="password" id="passwordRegister" class="form-control bg-light"  [type]="passwordLoginType" type="password" placeholder="Your password" minlength="8" maxlength="50" required checked>
                    <button class="easy-password d-flex flex-column justify-content-center cursor-pointer border-0" (click)="showPasswordLogin()"><img src="/assets/icons/olho.png"></button>
                </div>
                <small *ngIf="auth.formRegister.controls.password.value" [hidden]="auth.formRegister.controls.password.valid" class="text-danger text-small"> Required* </small>
            </div>

            <div class="form-group row mt-3">
                <div class="col-lg-12 pr-2">
                    <input formControlName="password_confirmation" id="passwordRegister" name="password_confirmation" class="form-control bg-light" [type]="confirmPasswordType" type="password" placeholder="Confirm your password" minlength="" maxlength="50" required checked>
                    <button class="easy-password d-flex flex-column justify-content-center cursor-pointer border-0" (click)="showConfirmPassword()"><img src="/assets/icons/olho.png"></button>
                </div>
                <small *ngIf="auth.formRegister.controls.password_confirmation.value" [hidden]="auth.formRegister.controls.password_confirmation.valid" class="text-danger text-small"> Required* </small>
            </div>

            <div class="row d-flex justify-content-center mt-5">
                <div class="col-lg-12 col-md-6 d-flex justify-content-center align-items-center pr-2">
                    <button type="button" (click)="goToLogin()" class="btn-lg btn btn-outline-primary font-weight-regular w-100 mr-1" style="border: 2px solid;">Back</button>
                    <button  type="submit" [disabled]="auth.formRegister.invalid" class="btn-lg btn btn-primary text-white font-weight-regular w-100 ml-1">Continue</button>
                </div>
            </div>
        </form>
    </ng-container>
    
    <ng-container *ngSwitchCase="'login'">
        <form id="formLogin" [formGroup]="auth.formLogin" (ngSubmit)="_onSubmit()">
            <div class="text-center">
                <h1 class="font-weight-bold text-primary">Access your Chatty Kathy</h1>
                <div class="mt-5">
                    <input formControlName="email" name="email" class="bg-light form-control bg-light border-0" type="email" placeholder="Your e-mail" required minlength="5" maxlength="50">
                </div>
                <div class="d-flex mt-4 pt-2 mb-2">
                    <input formControlName="password" name="password" id="senha" class="bg-light form-control border-0 input-one" [type]="passwordLoginType" placeholder="Your password">
                    <button type="button"  class="d-flex flex-column justify-content-center cursor-pointer" (click)="showPasswordLogin()"><img src="/assets/icons/olho.png"></button>
                </div>
                <div class="row">
                    <div class="col-12 mt-4 pt-2">
                        <button  type="submit" class="btn btn-lg btn-primary text-white font-weight-bold nav-link w-100">Access</button>
                    </div>
                </div>
                <div class="mt-4 pt-1">
                    <button  type="submit" (click)="goToFogotPassword()" class="btn pb-0 text-primary ">Forgot your password?</button>
                    <div class="d-flex justify-content-center align-items-center">
                        <p class="mb-0 text-grey-light font-weight-light">Don't have an account?</p>
                        <a (click)="goToRegister()" class="text-primary btn px-1">Register</a>
                    </div>
                </div>
            </div>
        </form>
        
    </ng-container>
    
    <ng-container *ngSwitchCase="'forgot-password'">
        <form [formGroup]="auth.formForgotPassword" class="col-sm-10 px-1 mx-sm-5" (ngSubmit)="_onSubmit()">
            <div class="form-group mb-5">
                <label for="exampleInputEmail1">
                    <h2 class="font-weight-bold mt-4">Forgot Password</h2>
                </label>
                <input formControlName="email" name="email" class="bg-light form-control border-0 mt-4" type="email" placeholder="Email" minlength="5" maxlength="50" required checked>
                <div class="row">
                    <div class="col-12 mt-4 pt-2">
                        <button  type="submit" class="btn btn-lg btn-primary text-white font-weight-regular nav-link w-100">Send</button>
                    </div>
                </div>
            </div>
        </form>
    </ng-container>
    
    <ng-container *ngSwitchCase="'recovery-password'">
        <form [formGroup]="auth.formRecoveryPassword" (ngSubmit)="_onSubmit()">
            <div class="form-group mt-5 pt-5">
                <label for="exampleInputEmail1">
                    <h1 class="font-weight-bold text-primary mt-4 pt-2 mb-1">New password</h1>
                </label>
                <input formControlName="password" type="password" class="bg-light form-control border-0 ng-untouched ng-pristine ng-invalid mt-5" aria-describedby="emailHelp" placeholder="New password">
            </div>
            <div class="form-group mt-4">
                <input formControlName="password_confirmation" type="password" class="bg-light form-control border-0" placeholder="Confirm new password">
            </div>
            <div class="row">
                <div class="col-12 mt-4">
                    <button  type="submit" class="btn btn-lg btn-primary text-white font-weight-regular nav-link w-100">Send</button>
                </div>
            </div>
        </form>
    </ng-container>
    
    <ng-container *ngSwitchCase="'authenticate-register'">
        <form class="pt-5 mt-5" [formGroup]="auth.formTwoFactor" (ngSubmit)="_onSubmit()">
            <div class="text-center mt-3">
                <h2 class="text-primary font-weight-bold">Two-step authentication</h2>
                <div class="row justify-content-center align-items-center">
                    <div class="col-sm-7 col-md-7 col-lg-4 px-1">
                        <p class="font-weight-light text-grey-light">We just sent a code to your email address. <br> Please enter it here.</p>
                    </div>
                </div>
            </div>
            
            <div class="row d-flex justify-content-center mt-2" id="authenticate-register">
                <div style="max-width: 25em !important;" class="col-12 justify-content-center">
                    <div class="row justify-content-center px-2 mt-4">
                        <div class="col-lg-2 col-xl-2 col-2 p-1 input-auth">
                            <input (paste)="nextDigit(null, 'confirm-email-input', $event)" (keyup)="nextDigit(1, 'confirm-email-input', $event)" maxlength="1" formControlName="input1" name="input1" id="confirm-email-input-1" type="tel" placeholder="0" class="form-control bg-light text-center" style="width: 53px;height: 50px;">
                        </div>
                        <div class="col-lg-2 col-xl-2 col-2 p-1 input-auth">
                            <input (paste)="nextDigit(null, 'confirm-email-input', $event)" (keyup)="nextDigit(2, 'confirm-email-input', $event)" maxlength="1" formControlName="input2" name="input2" id="confirm-email-input-2" type="tel" placeholder="0" class="form-control bg-light text-center" style="width: 53px;height: 50px;">
                        </div>
                        <div class="col-lg-2 col-xl-2 col-2 p-1 input-auth">
                            <input (paste)="nextDigit(null, 'confirm-email-input', $event)" (keyup)="nextDigit(3, 'confirm-email-input', $event)" maxlength="1" formControlName="input3" name="input3" id="confirm-email-input-3" type="tel" placeholder="0" class="form-control bg-light text-center" style="width: 53px;height: 50px;">
                        </div>
                        <div class="col-lg-2 col-xl-2 col-2 p-1 input-auth">
                            <input (paste)="nextDigit(null, 'confirm-email-input', $event)" (keyup)="nextDigit(4, 'confirm-email-input', $event)" maxlength="1" formControlName="input4" name="input4" id="confirm-email-input-4" type="tel" placeholder="0" class="form-control bg-light text-center" style="width: 53px;height: 50px;">
                        </div>
                        <div class="col-lg-2 col-xl-2 col-2 p-1 input-auth">
                            <input (paste)="nextDigit(null, 'confirm-email-input', $event)" (keyup)="nextDigit(5, 'confirm-email-input', $event)" maxlength="1" formControlName="input5" name="input5" id="confirm-email-input-5" type="tel" placeholder="0" class="form-control bg-light text-center" style="width: 53px;height: 50px;">
                        </div>
                        <div class="col-lg-2 col-xl-2 col-2 p-1 input-auth">
                            <input (paste)="nextDigit(null, 'confirm-email-input', $event)" (keyup)="nextDigit(6, 'confirm-email-input', $event)" maxlength="1" formControlName="input6" name="input6" id="confirm-email-input-6" type="tel" placeholder="0" class="form-control bg-light text-center" style="width: 53px;height: 50px;">
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ng-container>
    
    <ng-container *ngSwitchCase="'two-factor'">
        <form class="mt-sm-5 pt-sm-5" [formGroup]="auth.formTwoFactor" autocomplete="off" (ngSubmit)="_onSubmit()">
            <div class="row">
                <div class="col-12 text-center mt-4">
                    <h2 class="text-primary font-weight-bold">Two-Factor Authentication <img src="assets/icons/padlock.png" alt="padlock" class="mb-1">
                    </h2>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <p class="text-grey">We have just sent a code to your email or mobile. Please enter it here.</p>
                </div>
            </div>
            <div class="row mt-4 d-flex justify-content-center">
                <div class="col-sm-10 col-md-12 col-lg-6 px-0 mt-1" id="authenticate-register">
                    <div class="d-flex justify-content-center">
                        <input autocomplete="off" (paste)="nextDigit(null, 'two-factor-input', $event)" (keyup)="nextDigit(1, 'two-factor-input', $event)" maxlength="1" formControlName="input1" name="input1" id="two-factor-input-1" type="tel" placeholder="0" class="form-control bg-light text-center input-two-factor m-1" style="width: 53px;height: 50px;">
                        <input autocomplete="off" (paste)="nextDigit(null, 'two-factor-input', $event)" (keyup)="nextDigit(2, 'two-factor-input', $event)" maxlength="1" formControlName="input2" name="input2" id="two-factor-input-2" type="tel" placeholder="0" class="form-control bg-light text-center input-two-factor m-1" style="width: 53px;height: 50px;">
                        <input autocomplete="off" (paste)="nextDigit(null, 'two-factor-input', $event)" (keyup)="nextDigit(3, 'two-factor-input', $event)" maxlength="1" formControlName="input3" name="input3" id="two-factor-input-3" type="tel" placeholder="0" class="form-control bg-light text-center input-two-factor m-1" style="width: 53px;height: 50px;">
                        <input autocomplete="off" (paste)="nextDigit(null, 'two-factor-input', $event)" (keyup)="nextDigit(4, 'two-factor-input', $event)" maxlength="1" formControlName="input4" name="input4" id="two-factor-input-4" type="tel" placeholder="0" class="form-control bg-light text-center input-two-factor m-1" style="width: 53px;height: 50px;">
                        <input autocomplete="off" (paste)="nextDigit(null, 'two-factor-input', $event)" (keyup)="nextDigit(5, 'two-factor-input', $event)" maxlength="1" formControlName="input5" name="input5" id="two-factor-input-5" type="tel" placeholder="0" class="form-control bg-light text-center input-two-factor m-1" style="width: 53px;height: 50px;">
                        <input autocomplete="off" (paste)="nextDigit(null, 'two-factor-input', $event)" (keyup)="nextDigit(6, 'two-factor-input', $event)" maxlength="1" formControlName="input6" name="input6" id="two-factor-input-6" type="tel" placeholder="0" class="form-control bg-light text-center input-two-factor m-1" style="width: 53px;height: 50px;">
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 d-flex flex-column text-center">
                    <!-- <a href="#" class="text-primary font-weight-regular p-0">I didn't receive the code</a> -->
                    <!-- <a href="#" class="text-primary font-weight-regular p-0">Use backup code</a> -->
                </div>
            </div>
            <div class="row d-flex justify-content-center mt-5">
                <div class="col-lg-8 col-md-6 d-flex justify-content-center">
                    <button type="button" (click)="goToLogin()" class="btn-lg btn btn-outline-primary font-weight-regular w-100 mx-2" style="border: 2px solid; font-size: 18;">Back</button>
                    <button  type="submit" class="btn-lg btn btn-primary text-white font-weight-regular w-100 mx-2" style="font-size: 18;">Continue</button>
                </div>
            </div>
        </form>
    </ng-container>
    
</ng-container>