import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from 'src/app/services/helper.service';
import { ModalTradeDigitalComponent } from  'src/app/modals/modal-trade-digital/modal-trade-digital.component';

@Component({
  selector: 'app-modal-transaction',
  templateUrl: './modal-transaction.component.html',
  styleUrls: ['./modal-transaction.component.scss']
})
export class ModalTransactionComponent implements OnInit {
    
  @Input() public version;
  @Input() public artwork;
  // @Input() public histories;

  constructor(
    public router: Router,
    public api: ApiService,
    public route: ActivatedRoute,
    public helper: HelperService,
    public modalService: NgbModal
  ) { }

  ngOnInit(): void {
  }

  openTradeDigital() {
    const modalRef = this.modalService.open(ModalTradeDigitalComponent,
      {
        windowClass: 'myModalTradeDigital'
      });
  }
  
  buttonClose(){
    const modalRef = this.modalService.dismissAll();
   }

}
