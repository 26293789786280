<div ngbDropdown class="d-inline-block">
    <button type="button" class="btn bg-light text-grey font-weight-light p-0 px-3" id="dropdownBasic1" style="height: 37px !important; border-radius: 12px !important;" ngbDropdownToggle>Order by <img class="pl-2" src="../../../assets/icons/orderby.png" alt=""></button>
<div ngbDropdownMenu="dropdownBasic" class="boxMenuDropdrown mt-1">
    <a ngbDropdownItem (click)="setValue('-price')" class="dropDownContent borderRadiusUpBox text-grey-light font-weight-light cursor-pointer">Lowest Price</a>
    <hr class="m-0 p-0 gray">
    <a ngbDropdownItem (click)="setValue('price')" class="dropDownContent text-grey-light font-weight-light cursor-pointer">Highest Price</a>
    <hr class="m-0 p-0 gray">
    <a ngbDropdownItem (click)="setValue('-title')" class="dropDownContent text-grey-light font-weight-light cursor-pointer">Alphabetical</a>
    <hr class="m-0 p-0 gray">
    <a ngbDropdownItem (click)="setValue('created_at')" class="dropDownContent text-grey-light font-weight-light cursor-pointer">Date uploaded</a>
    <hr class="m-0 p-0 gray">
    <a ngbDropdownItem (click)="setValue('feature')" class="dropDownContent borderRadiusDownBox text-grey-light font-weight-light cursor-pointer">Most Popular</a>
</div>