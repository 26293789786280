<form [formGroup]="formCreateFolders" (ngSubmit)="createFolders(formCreateFolders)">
    <div class="modal-header justify-content-center">
        <h2 class="text-secondary font-weight-bold">Create folder</h2>
        <button type="button" (click)="buttonClose()" aria-label="Close"  class="btn btn-outline position-absolute right-0 top-0 cursor-pointer">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
    <div class="modal-body text-center">
        <p class="mt-2 text-grey font-weight-light ml-3">You can edit then anme and description for this folder.</p>
        <div class="col-12">
            <input formControlName="title" type="text" class="form-control bg-light" placeholder="Collection name" />
        </div>
        <div class="col-12">
            <textarea formControlName="description" class="form-control bg-light mt-3" rows="4" placeholder="Description"></textarea>
        </div>
    </div>
    <div class="modal-footer justify-content-center">
        <button  type="submit" class="w-75 btn btn-lg btn-primary btn-create font-weight-bold mb-4">Create</button>
    </div>
</form>