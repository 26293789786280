<div *ngIf="tradeBuyerStep == 0">
    <div class="modal-header">
        <h4 class="modal-title font-weight-bold" id="modal-basic-title">You must login</h4>
        <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
    <div class="modal-body px-5">
        <app-auth-forms [form]="authFormMode" [redirect]="false" (onSubmit)="submitForm($event)"></app-auth-forms>
    </div>
</div>

<div *ngIf="tradeBuyerStep == 1">
    <div class="modal-header d-flex justify-content-center">
        <h4 class="modal-title font-weight-bold" id="modal-basic-title">1. Invite sent by {{ invite.institution.full_name }}</h4>
        <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
    <ngb-progressbar type="primary" [value]="tradeBuyerStep * 33" style="height: 0.3em"></ngb-progressbar>
    <div class="modal-body px-5">
        <h4>Do you want to be part of the {{ invite.institution.full_name }} institution as an artist?</h4>
    </div>
    <div class="modal-footer d-flex justify-content-center">
        <button type="button"  class="btn-lg btn btn-danger cursor-pointer" (click)="refuseInvite()">Refuse</button>
        <button type="button"  class="btn-lg btn btn-primary cursor-pointer" (click)="acceptInvite()">Accept</button>
    </div>
</div>