import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-icons-redes',
  templateUrl: './icons-redes.component.html',
  styleUrls: ['./icons-redes.component.scss']
})
export class IconsRedesComponent implements OnInit {
  @Input() artist: any;

  constructor(
    public auth: AuthService,
  ) { 
  }
  
  ngOnInit(): void {
    console.log(this.artist)
  }

}
