<div class="container-fluid">
    <div class="row">
        <div class="col-lg-5">
            <div>
                <p class="text-secondary font-weight-bold mb-2">Tokens Available</p>
                <h1 *ngIf="auth.userData.subscription" class="text-grey-light font-weight-regular d-flex display-4">
                    <span class="text-primary font-weight-regular">
                        {{ (this.auth.userData.subscription?.token - this.auth.userData.subscription?.token_used) > 200 ? '∞' : (this.auth.userData.subscription?.token - this.auth.userData.subscription?.token_used)}}
                    </span>
                    /{{ this.auth.userData.subscription?.token > 1000000 ? '∞' : this.auth.userData.subscription?.token }}
                </h1>
                <div *ngIf="!auth.userData.subscription">
                    <h1 class="text-grey-light font-weight-regular d-flex display-4">
                        <span class="text-primary  font-weight-regular">
                            0
                        </span> /0
                    </h1>
                </div>
            </div>
            <div class="mt-5">
                <p class="font-weight-bold mb-2 text-secondary">Total Sales</p>
                <h1 class="text-primary font-weight-regular d-flex">${{ total.toFixed(2) }}</h1>
            </div>
        </div>
        
        <div class="col-lg-7" *ngIf="chartOptions.chart">
            <p  class="font-weight-bold mb-2 text-secondary">Annual sales</p>
            <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions" style="width: 100%; height: 300px; display: block;"></highcharts-chart>
        </div>
    </div>
</div>