<form *ngIf="twoFactorStep == 1" [formGroup]="formNumber" (ngSubmit)="btnNext(formNumber)">
    <div class="modal-header justify-content-center pt-4 mt-1">
        <h2 class="text-secondary font-weight-bold text-center">Two-Factor Authentification</h2>
        <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0 p-2">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
    <div class="modal-body justify-content-center">
        <div class="row">
            <div class="col-lg-12">
                <h2 class="text-primary font-weight-bold text-center mt-3">Add your phone</h2>
            </div>
        </div>
        <div class="row d-flex justify-content-center align-items-center my-4">
            <div>
                <ngx-intl-tel-input 
                [cssClass]="'form-control bg-light'" 
                [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]" 
                [enableAutoCountrySelect]="true" 
                [enablePlaceholder]="true" 
                [searchCountryFlag]="true" 
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" 
                [selectFirstCountry]="false" 
                [selectedCountryISO]="CountryISO.UnitedStates" 
                [minLength]="3" 
                [maxLength]="50" 
                [phoneValidation]="true" 
                [inputId]="my-input-id" 
                formControlName="phone_number" 
                name="phone_number" 
                type="tel">
                </ngx-intl-tel-input>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10">
                <p class="text-grey-light font-weight-light text-center px-3">Alphaa.io allows artists and Art collectors to authenticate, certificate and transfer ownership of Digital and Physical Art.</p>
            </div>
        </div>
    </div>
    <div class="modal-footer d-block">
        <div class="d-flex justify-content-center align-items-center mt-3">
            <div class="col-lg-9 col-md-9 px-sm-4">
                <button class="btn btn-lg btn-primary d-flex justify-content-center align-items-center btn-two-factor w-100" type="submit">Send code of verification</button>
            </div>
        </div>
        <div class="d-flex justify-content-center align-items-center my-3">
            <button type="button" class="btn text-primary btn-two-factor" (click)="btnAskMeLater('askmelater')">Ask me later</button>
        </div>
    </div>
</form>

<form *ngIf="twoFactorStep == 2"  [formGroup]="auth.formTwoFactor" (ngSubmit)="btnNext(auth.formTwoFactor)">
    <div class="modal-header justify-content-center pt-4 mt-1">
        <h2 class="text-secondary font-weight-bold text-center">Two-Factor Authentification</h2>
        <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0 p-2">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10">
                <h2 class="text-primary font-weight-bold text-center mt-3">Confirm your phone</h2>
                <p class="text-grey-light font-weight-light text-center">We just sent a code to your phone number. Please enter it here.</p>
            </div>
        </div>
        <div class="row d-flex justify-content-center align-items-center mt-3">
            <div class="col-lg-9 px-2">
                <p class="font-weight-light text-grey-light mb-0">{{ data }} This may take a few seconds...</p>
            </div>
        </div>
        <div class="row d-flex justify-content-center align-items-center my-2">
            <div style="max-width: 25em !important;" class="col-12 justify-content-center">
                <div class="row justify-content-center px-2">
                    <input autocomplete="off" (paste)="nextDigit(null, 'two-factor-phone', $event)" (keyup)="nextDigit(1, 'two-factor-phone', $event)" maxlength="1" formControlName="input1" name="input1" id="two-factor-phone-1" type="tel" placeholder="0" class="form-control bg-light text-center input-two-factor m-1" style="width: 53px;height: 50px;">
                    <input autocomplete="off" (paste)="nextDigit(null, 'two-factor-phone', $event)" (keyup)="nextDigit(2, 'two-factor-phone', $event)" maxlength="1" formControlName="input2" name="input2" id="two-factor-phone-2" type="tel" placeholder="0" class="form-control bg-light text-center input-two-factor m-1" style="width: 53px;height: 50px;">
                    <input autocomplete="off" (paste)="nextDigit(null, 'two-factor-phone', $event)" (keyup)="nextDigit(3, 'two-factor-phone', $event)" maxlength="1" formControlName="input3" name="input3" id="two-factor-phone-3" type="tel" placeholder="0" class="form-control bg-light text-center input-two-factor m-1" style="width: 53px;height: 50px;">
                    <input autocomplete="off" (paste)="nextDigit(null, 'two-factor-phone', $event)" (keyup)="nextDigit(4, 'two-factor-phone', $event)" maxlength="1" formControlName="input4" name="input4" id="two-factor-phone-4" type="tel" placeholder="0" class="form-control bg-light text-center input-two-factor m-1" style="width: 53px;height: 50px;">
                    <input autocomplete="off" (paste)="nextDigit(null, 'two-factor-phone', $event)" (keyup)="nextDigit(5, 'two-factor-phone', $event)" maxlength="1" formControlName="input5" name="input5" id="two-factor-phone-5" type="tel" placeholder="0" class="form-control bg-light text-center input-two-factor m-1" style="width: 53px;height: 50px;">
                    <input autocomplete="off" (paste)="nextDigit(null, 'two-factor-phone', $event)" (keyup)="nextDigit(6, 'two-factor-phone', $event)" maxlength="1" formControlName="input6" name="input6" id="two-factor-phone-6" type="tel" placeholder="0" class="form-control bg-light text-center input-two-factor m-1" style="width: 53px;height: 50px;">
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center mt-3">
            <a class="cursor-pointer text-primary">I didn’t receive the code</a>
        </div>
    </div>
    <div class="modal-footer d-block">
        <div class="d-flex justify-content-center align-items-center mt-3">
            <div class="col-lg-9 col-md-9 px-sm-4">
                <button class="btn btn-lg btn-primary d-flex justify-content-center align-items-center btn-two-factor w-100" type="submit">Continue</button>
            </div>
        </div>
        <div class="d-flex justify-content-center align-items-center my-3">
            <button class="btn text-primary btn-two-factor" type="button" (click)="btnBack()">Back</button>
        </div>
    </div>
</form>

<form *ngIf="twoFactorStep == 3">
    <div class="modal-header justify-content-center pt-4 mt-1">
        <h2 class="text-secondary font-weight-bold text-center">Two-Factor Authentification</h2>
        <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0 p-2">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10">
                <div class="d-flex justify-content-center mt-3">
                    <img src="/assets/icons/check-outline-green.png" alt="check-outline-green">
                </div>
                <h2 class="text-secondary font-weight-light text-center my-3">Successful confirmation</h2>
                <p class="text-grey-light font-weight-light text-center my-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec placerat vitae enim vel imperdiet. Integer lectus urna.</p>
            </div>
        </div>
    </div>
    <div class="modal-footer d-block mt-4">
        <div class="d-flex justify-content-center align-items-center mt-3">
            <div class="col-lg-9 col-md-9 px-sm-4">
                <button class="btn btn-lg btn-primary d-flex justify-content-center align-items-center btn-two-factor w-100" (click)="buttonClose()">Continue</button>
            </div>
        </div>
    </div>
</form>

<form *ngIf="twoFactorStep == 4">
    <div class="modal-header justify-content-center pt-4 mt-1">
        <h2 class="text-secondary font-weight-bold text-center">Two-Factor Authentification</h2>
        <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0 p-2">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10">
                <div class="d-flex justify-content-center mt-3">
                    <img src="/assets/icons/incorrect-outline-red.png" alt="incorrect-outline-red">
                </div>
                <h2 class="text-secondary font-weight-light text-center my-3">Incorrect code</h2>
                <p class="text-grey-light font-weight-light text-center mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec placerat vitae enim vel imperdiet. Integer lectus urna.</p>
                <div class="d-flex justify-content-center">
                    <a class="cursor-pointer text-primary">I need help </a>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer d-block mt-2">
        <div class="d-flex justify-content-center align-items-center mt-3">
            <div class="col-lg-9 col-md-9 px-sm-4">
                <button class="btn btn-lg btn-primary d-flex justify-content-center align-items-center btn-two-factor w-100" type="button" (click)="btnBack()">Send again</button>
            </div>
        </div>
        <div class="d-flex justify-content-center align-items-center my-3">
            <button class="btn text-primary btn-two-factor" type="button" (click)="btnAskMeLater('askmelater')">Ask me later</button>
        </div>
    </div>
</form>