<div class="container-fluid px-0 ">
    <div class="row mt-4 pt-1">
        <ng-container>
            <div class="box-artists p-0">
                <div class="mb-5 pb-2" *ngFor="let invite of invites" style="position:relative">
                    <div class="px-0 col-12">
                        <app-dropdown-institution-artists [invite]="invite" *ngIf="auth.userData.id == invite.institution_id" class="app-dropdown-institution-artists"></app-dropdown-institution-artists>
                        <a class="d-flex justify-content-center" [routerLink]="['/artist-detail', invite.creator.slug]">
                            <div >
                                <img class="p-0 image-size" *ngIf="invite.creator.avatar" [src]="invite.creator.avatar" alt="">
                                <img class="p-0 image-size" *ngIf="!invite.creator.avatar" src="/assets/img/avatar.png" alt="">
                            </div>
                        </a>
                        <div class="text-center info-artist">
                            <p class="text-secondary font-weight-bold mt-3 mb-0">{{ invite.creator.full_name }}</p>
                            <p *ngIf="invite.creator.contact_information" class="text-grey font-weight-light mb-0">{{ invite.creator.contact_information.city }} - {{ invite.creator.contact_information.country }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>