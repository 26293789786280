<div>
    <div class="modal-header justify-content-center">
        <h2 class="text-secondary font-weight-bold">What is the edition?</h2>
        <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0 p-1">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
    <div class="container modal-body text-center">
        <div class="row">
            <div class="col-lg-12 mt-2">
                <img src="/assets/icons/edition.png" alt="edition">
                <h3 class="font-weight-normal text-secondary mt-2">The amount of versions of your artwork</h3>
                <p class="text-grey-light font-weight-light mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Donec placerat vitae enim vel imperdiet. Integer lectus urna.</p>
            </div>
        </div>
        <div class="row modal-footer d-flex justify-content-center mt-5">
            <div class="col-lg-7">
                <div>
                    <button  type="submit" class="btn btn-lg btn-primary font-weight-regular w-100">Understood</button>
                </div>
            </div>
        </div>
    </div>
</div>