<form [formGroup]="formEditFolder" (ngSubmit)="editFolder(formEditFolder)">
    <div class="card-header text-center p-4">
        <h3 class="text-secondary font-weight-bold mb-0">Edit this folder</h3>
        <button type="button" (click)="buttonClose()" aria-label="Close"  class="btn btn-outline position-absolute right-0 top-0 cursor-pointer">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
    <div class="card-body text-center p-3">
        <p class="text-grey font-weight-light mr-3">You can edit then anme and description for this folder.</p>
        <div class="row">
            <div class="col-lg-12 px-5">
                <input formControlName="title" type="text" class="bg-light w-100" placeholder="Collection name" />
            </div>
            <div class="col-lg-12 px-5">
                <textarea formControlName="description" class="bg-light mt-4 w-100" rows="4" placeholder="Description" style=" height: 8em;"></textarea>
            </div>
        </div>
    </div>
    <div class="card-footer py-3 px-1">
        <div class="row">
            <div class="col-lg-8">
                <div class="text-left d-flex">
                    <button type="button" *ngIf="folder.id" (click)="acceptDeleteFolder(folder.id)"  class="ml-1 btn text-primary cursor-pointer">Delete folder</button>
                    <button  type="submit" class="btn-lg btn btn-primary font-weight-regular w-50">Done</button>
                </div>
            </div>
        </div>
    </div>
</form>

