import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { ModalAddToFolderComponent } from 'src/app/modals/modal-add-to-folder/modal-add-to-folder.component';
import { ModalTradeDigitalComponent } from 'src/app/modals/modal-trade-digital/modal-trade-digital.component';
import { ModalInstitutionEditArtworkComponent } from 'src/app/modals/modal-institution-edit-artwork/modal-institution-edit-artwork.component';
import { ModalInstitutionArtworkRequestManagementComponent } from 'src/app/modals/modal-institution-artwork-request-management/modal-institution-artwork-request-management.component';
import { ModalInstitutionArtworkAddToFolderComponent } from 'src/app/modals/modal-institution-artwork-add-to-folder/modal-institution-artwork-add-to-folder.component';
import { ModalInstitutionArtworkChangePriceComponent } from 'src/app/modals/modal-institution-artwork-change-price/modal-institution-artwork-change-price.component';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-create-dropdown-options-artworks',
  templateUrl: './create-dropdown-options-artworks.component.html',
  styleUrls: ['./create-dropdown-options-artworks.component.scss']
})
export class CreateDropdownOptionsArtworksComponent implements OnInit {
  @Input() public artwork_id: number;
  @Input() artwork: any;
  public type: any;  
  public institutionActive = false;

  constructor(
    public auth: AuthService,
    public route: ActivatedRoute,
    public router: Router,
    public modalService: NgbModal,
    public api: ApiService,
  ) { }

  ngOnInit(): void {
    this.institutionActive = this.auth.institutionActive.getValue()

  }

  openAddtoFolder(artwork_id: number) {
    const modalRef = this.modalService.open(ModalAddToFolderComponent);
    modalRef.componentInstance.artwork_id = artwork_id;
  }

  requestNewPrice(artwork_id: number) {

  }

  viewArtwork(artwork_id: number) {
    this.router.navigateByUrl('/artworks/' + artwork_id);
  }
  openTransaction() {
    const modalRef = this.modalService.open(ModalTradeDigitalComponent);
    modalRef.componentInstance.artwork_id = this.artwork_id;
  }

  EditArtwork() {
    const modalRef = this.modalService.open(ModalInstitutionEditArtworkComponent, {
      windowClass: 'myCustomModalClass'
    });
    modalRef.componentInstance.artwork = this.artwork
  }


  RequestManagement() {
    const modalRef = this.modalService.open(ModalInstitutionArtworkRequestManagementComponent, {
      windowClass: 'myCustomModalClass'
    });
    modalRef.componentInstance.artwork = this.artwork

  }


  AddFolder() {
    const modalRef = this.modalService.open(ModalInstitutionArtworkAddToFolderComponent, {
      windowClass: 'myCustomModalClass'
    });
    modalRef.componentInstance.artwork = this.artwork
  }
 
 
 
  ChangePrice() {
    const modalRef = this.modalService.open(ModalInstitutionArtworkChangePriceComponent, {
      windowClass: 'myCustomModalClass'
    });
    modalRef.componentInstance.artwork = this.artwork
  }
}
