import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalVideoTrackComponent } from 'src/app/modals/modal-video-track/modal-video-track.component';


interface Collecion {
  title: string;
  url: string;
  duracao: number;
  img: string;

}


const VIDEOS: Collecion[] = [
  {
    title: 'Alphaa.io NFT Series 1',
    url:'https://www.youtube.com/embed/nDQCU-G5U3I',
    duracao: 1.11,
    img:'/assets/img/thumb-1.png', 
  },
  {
    title: 'Alphaa.io NFT Series 2',
    url: 'https://www.youtube.com/embed/2HtiSZ_eJFw',
    duracao: 0.52,
    img:'/assets/img/thumb-2.png', 

  },
  {
    title: 'Alphaa.io NFT Series 3',
    url:'https://www.youtube.com/embed/IDrSBw2SUFY',
    duracao: 1.01,
    img:'/assets/img/thumb-3.png', 

  },
  
];


@Component({
  selector: 'app-video-track',
  templateUrl: './video-track.component.html',
  styleUrls: ['./video-track.component.scss']
})
export class VideoTrackComponent implements OnInit {

  @Input() videos = VIDEOS;

  constructor(
    public modalService: NgbModal

  ) { }

  ngOnInit(): void {
  }


  openVideo(title:string, url: string){
    const modalRef = this.modalService.open(ModalVideoTrackComponent);
   modalRef.componentInstance.title = title,
   modalRef.componentInstance.url = url 

  }
}
