import { Component, Input, OnInit } from '@angular/core';
import { ArtworksListComponent } from '../artworks-list/artworks-list.component';

@Component({
  selector: 'app-artworks-track',
  templateUrl: './artworks-track.component.html',
  styleUrls: ['./artworks-track.component.scss']
})
export class ArtworksTrackComponent implements OnInit {
  
  @Input() lists: any[] = [];
  @Input() folders: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
