import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-artworks-lists-box',
  templateUrl: './artworks-lists-box.component.html',
  styleUrls: ['./artworks-lists-box.component.scss']
})
export class ArtworksListsBoxComponent implements OnInit {
  @Input() artworks: any[] = [];
  @Input() versions: any[] = [];
  @Input() list: any[] = [];
  @Input() folder: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
