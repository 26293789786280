import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalTradeDisputeComponent } from 'src/app/modals/modal-trade-dispute/modal-trade-dispute.component';
import { ModalTradeRatingComponent } from 'src/app/modals/modal-trade-rating/modal-trade-rating.component';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { ModalTradeBuyerComponent } from '../../modals/modal-trade-buyer/modal-trade-buyer.component';

@Component({
  selector: 'app-trade-item',
  templateUrl: './trade-item.component.html',
  styleUrls: ['./trade-item.component.scss']
})
export class TradeItemComponent implements OnInit {
  @Input() transfer: any;
  @Input() showDispute: boolean = false;
  @Input() showPay: boolean = false;
  @Input() showReminder: boolean = false;
  @Input() showFeedBack: boolean = false;
  @Input() status: any;
  
  constructor(
    public modalService: NgbModal,
    public api: ApiService,
    public auth: AuthService,
    public helper: HelperService
    ) { }
    
    ngOnInit(): void {
    }
    
    openModalDispute(transfers: any, buyer: any){
      let modal = this.modalService.open(ModalTradeDisputeComponent)
      modal.componentInstance.transaction = transfers;
      modal.componentInstance.buyer = buyer;
    }

    openModalFeedback(transfers: any, buyer: any){
      let modal = this.modalService.open(ModalTradeRatingComponent)
      modal.componentInstance.transaction = transfers;

    }
    
    openModalBuyer(transfer: any){
      let modal = this.modalService.open(ModalTradeBuyerComponent)
      modal.componentInstance.transaction = transfer;
    }
  }
  