import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  
  public isMenuCollapsed = true;
  public institutionActive = true;
  
  constructor(
    public auth: AuthService,
    public router: Router
    ) {}
    
    ngOnInit(): void {
      this.institutionActive = this.auth.institutionActive.getValue()
    }
    
  }
  