import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router,NavigationEnd  } from '@angular/router';
import { Location } from "@angular/common";

@Component({
  selector: 'app-last-trades',
  templateUrl: './last-trades.component.html',
  styleUrls: ['./last-trades.component.scss']
})
export class LastTradesComponent implements OnInit {
  public buy: any;
  public sale: any;
  public routeAllTrade: any;
  public id: any;
  
  
  constructor(
    private router: Router,
    public api: ApiService,
    public location: Location,
    ) { 
      
    }
    
    async ngOnInit()  {
      if (this.location.path() === "/dashboard/artworks") {
        this.routeAllTrade = '/trades';
      }else if(this.location.path() === "/dashboard-creator/overview") {
        this.routeAllTrade = '/dashboard-creator/trades';
      }     
      
      await this.api.get('transfers/buy').then((response) => {
        this.buy = response
      })
      
     await this.api.get('transfers/sale').then((response) => {
        this.sale = response
      })
    }
    
  }
  