<div class="container-fluid">
    <div class="row ml-2 mr-2">
        <div class="col-12 mt-4">
            <p class="mb-1 text-grey">Your Vault / Collection name</p>
            <h1 class="text-primary font-weight-bold mb-1">Artwork Name</h1>
            <p class="text-grey mb-5">Artists Name</p>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row ml-2 mr-2 pb-5">
        <div class="col-md-4 col-lg-3 mb-5">
            <app-versionSpecs></app-versionSpecs>
        </div>
        <div class="col-md-8 col-lg-9">
            <div class="card">
                <div class="row card-header bg-transparent">
                    <div class="col-sm-8 col-md-12 col-lg-5 mt-2 mb-2">
                        <a href="" id="titles-card-artworks" class="text-grey font-weight-normal h4 mr-3 card-title">Value</a>
                        <a href="" id="titles-card-artworks" class="text-grey font-weight-normal h4 mr-3 card-title">Record</a>
                        <a href="" id="titles-card-artworks" class="text-grey font-weight-normal h4 card-title">About</a>
                    </div>
                    <div class="col-sm-4 col-md-12 offset-lg-3 col-lg-4 mt-2">
                        <img src="../../../assets/img/qrcode.jpeg" class="mr-3" width="40px" alt="">
                        <button type="button"  class="btn btn-primary pl-4 pr-4 cursor-pointer">Trade</button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h4 class="text-secondary">Details</h4>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-lg-12 col-md-6">
                            <div class="row">
                                <div class="col-lg-4">
                                    <p class="text-secondary">Privacy setting</p>
                                </div>
                                <div class="col-lg-8">
                                    <p class="text-secondary">Private</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-6">
                            <div class="row">
                                <div class="col-lg-4">
                                    <p class="text-secondary">Artist</p>
                                </div>
                                <div class="col-lg-8">
                                    <p class="text-secondary mb-2">Victoria López</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-6">
                            <div class="row">
                                <div class="col-lg-4">
                                    <p class="text-secondary">Blockchain Adress</p>
                                </div>
                                <div class="col-lg-8">
                                    <p class="text-secondary mb-2">0xf821aa465dc5b53e53eb</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-6">
                            <div class="row">
                                <div class="col-lg-4">
                                    <p class="text-secondary">Hash Token</p>
                                </div>
                                <div class="col-lg-8">
                                    <p class="text-secondary mb-2">5b575a339f9aca9cce72117</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-lg-12 mt-4">
                            <h5 class="text-secondary">Recent</h5>
                            <p class="text-grey"> Lorem ipsun dolor sit amet Lorem ipsin dolor sit amet.</p>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-3 mt-2">
                                    <img src="assets/img/update.png" alt="update">
                                </div>
                                <div class="col-9">
                                    <h5 class="mb-0 mt-3 text-secondary">Updated by <span class="font-weight-bold">Erica Hill</span></h5>
                                    <p class="text-grey">January 20th, 2021 - 11:35:41 AM</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <button type="button" href="" class="border-0 rounded p-1 text-grey"><i class="border rounded p-1">i</i> Transaction Data</button>
                        </div>
                    </div>
                    <hr>
                    <div class="row mt-3">
                        <div class="col-lg-12 mt-4">
                            <h5 class="text-secondary">History</h5>
                            <p class="text-grey"> Alphaa.io allows artists and Art collectors to authenticate.</p>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-8">
                            <div class="row">
                                <div class="col-3 mt-2">
                                    <img src="assets/img/update.png" alt="update">
                                </div>
                                <div class="col-9">
                                    <h5 class="mb-0 mt-3 text-secondary">Updated by <span class="font-weight-bold">Erica Hill</span></h5>
                                    <p class="text-grey">January 20th, 2021 - 11:35:41 AM</p>
                                </div>
                            </div>
                        </div>
                        <div class=" col-4 mt-3">
                            <a href="" class="text-grey"><i class="border rounded p-1">i</i> Transaction Data</a>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-8">
                            <div class="row">
                                <div class="col-3 mt-2">
                                    <img src="assets/img/purchased.png" alt="purchased">
                                </div>
                                <div class="col-9">
                                    <h5 class="mb-0 mt-3 text-secondary">Purchased by <span class="font-weight-bold">Erica Hill</span></h5>
                                    <p class="text-grey">January 20th, 2018 - 11:35:41 AM</p>
                                </div>
                            </div>
                        </div>
                        <div class=" col-4 mt-3">
                            <a href="" class="text-grey"><i class="border rounded p-1">i</i> Transaction Data</a>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-8">
                            <div class="row">
                                <div class="col-3 mt-2">
                                    <img src="assets/img/create.png" alt="create">
                                </div>
                                <div class="col-9">
                                    <h5 class="mb-0 mt-3 text-secondary">Created by <span class="font-weight-bold">Victoria López</span></h5>
                                    <p class="text-grey">January 20th, 2018 - 11:35:41 AM</p>
                                </div>
                            </div>
                        </div>
                        <div class=" col-4 mt-3">
                            <a href="" class="text-grey"><i class="border rounded p-1">i</i> Transaction Data</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>