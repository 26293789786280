import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { ModalCreateArtworkComponent } from 'src/app/modals/modal-create-artwork/modal-create-artwork.component';
import { ModalVersionsArtworkComponent } from 'src/app/modals/modal-versions-artwork/modal-versions-artwork.component';
import { ModalCreateFoldersComponent } from 'src/app/modals/modal-create-folders/modal-create-folders.component';
import { CreateDropdownOptionsArtworksComponent } from 'src/app/components/create-dropdown-options-artworks/create-dropdown-options-artworks.component';
import { AuthService } from 'src/app/services/auth.service';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as Highcharts from 'highcharts';
import { HighChartsService } from 'src/app/services/highcharts.service';
import { HelperService } from 'src/app/services/helper.service';
import Swal from 'sweetalert2';
import { ModalArtistRoyaltiesComponent } from 'src/app/modals/modal-artist-royalties/modal-artist-royalties.component';
import { ModalPlatformFeeComponent } from 'src/app/modals/modal-platform-fee/modal-platform-fee.component';
import { ModalInstitutionRoyaltiesComponent } from 'src/app/modals/modal-institution-royalties/modal-institution-royalties.component';

@Component({
  selector: 'app-dashboard-creator',
  templateUrl: './dashboard-creator.component.html',
  styleUrls: ['./dashboard-creator.component.scss']
})


export class DashboardCreatorComponent implements OnInit {
  changeText: boolean;
  selectedStyleId: boolean;
  public id: number
  public artworkCreateMode: string = '';
  public defaultArtistRoyalty: string = '5';
  public defaultInstitutionRoyalty: string = '5';
  public typeActive: any = 'monthly';
  public showUpload: boolean = false;
  public disabledMode: boolean = false;
  public artworkCreateStep: number = 1;
  public files: NgxFileDropEntry[] = [];
  public fileList: any[] = [];
  public styles: any[] = [];
  public formArtwork: FormGroup;
  public plans: any[] = [];
  public seletedPlan: any
  public artworkLastCreated: any
  
  viewMode: any = "overview";
  activeArtworkStatusFilter: any = "all";
  artworkSearchFilter: any = "";
  artworks: any;
  showPage: any = false;
  plansYearly: any;
  plansMonthly: any;
  overviewMode: any = 'artworks';
  formArtworkArray: FormArray;
  totalArtworksCreate: any = 0;
  type: any = 'dashboard';
  dashboard: any = 'dashboard-creator';
  
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public modalService: NgbModal,
    public helper: HelperService,
    private formBuilder: FormBuilder,
    public api: ApiService,
    public auth: AuthService,
    
    ) {
      this.changeText = false;
      this.selectedStyleId = false;
      router.events.subscribe(async (val) => {
        if (val instanceof NavigationEnd) {
          this.viewMode =  typeof this.route.snapshot.params.viewMode !== 'undefined' ? this.route.snapshot.params.viewMode: 'overview';
          this.id = this.route.snapshot.params.viewMode
          this.auth.currentAuthenticatedUser('user/'+this.auth.userData.id).then(async res => {
            this.auth.updateSubscriptionData().then(value=>{
              if(value == '/subscribe'){    
                window.location.href = '/subscribe'          
              }
              if(value == 'active'){              
                this.disabledMode = false;
                if (this.viewMode === 'overview') {
                  this.api.get('creators/artworks').then((response) => {
                    if(response.data.length == 0) {
                      if(!this.auth.userData.invites){
                        this.viewMode = 'artworks-create'
                      }
                    }
                    this.artworks = response
                    this.artworks.data.filter((value: any)=>{
                      if(!this.artworkLastCreated || this.artworkLastCreated.id < value.id) {
                        this.artworkLastCreated = value;
                      }
                    })
                  })
                }
                this.showPage = true;
              }
            })
          })
        }
      });
      
    }
    
    async ngOnInit() {
    }
    
    setOverviewMode(mode: any) {
      this.overviewMode = mode
      if (this.overviewMode === 'artworks') {
        this.api.get('artworks/overview').then((response) => {
          this.artworks = response.author
        })
      } 
    }
    
  }
  
  