import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-modal-create-list',
  templateUrl: './modal-create-list.component.html',
  styleUrls: ['./modal-create-list.component.scss']
})
export class ModalCreateListComponent implements OnInit {
  public formCreateList: FormGroup;
  modalReference: any;
  closeResult: string;

  constructor(
    public router: Router,
    public api: ApiService,
    public route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.createFormList()
  }

  async createList({ valid, value }: { valid: boolean; value: any }) {
    if (valid) {

      let payload = {
        title: value.title,
        description: value.description
      }

      this.api.post('artworks/lists', payload).then((response) => {
        const modalRef = this.modalService.dismissAll();
        location.reload()
      })
    }
  }


  private createFormList() {
    this.formCreateList = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required]
    })
  }

  buttonClose(){
   const modalRef = this.modalService.dismissAll();
  }
}
