import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalInstitutionRequestManagementComponent } from 'src/app/modals/modal-institution-request-management/modal-institution-request-management.component';
@Component({
  selector: 'app-dropdown-institution-artworks-from-artist',
  templateUrl: './dropdown-institution-artworks-from-artist.component.html',
  styleUrls: ['./dropdown-institution-artworks-from-artist.component.scss']
})
export class DropdownInstitutionArtworksFromArtistComponent implements OnInit {
  @Input() invite: any
  
  constructor(
    public modalService: NgbModal,
  ) { }

  ngOnInit(): void {
  }

  requestManagement(){
    const modalRef = this.modalService.open(ModalInstitutionRequestManagementComponent)
  }
}
