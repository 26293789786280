import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-artists-dashboard-institution',
  templateUrl: './artists-dashboard-institution.component.html',
  styleUrls: ['./artists-dashboard-institution.component.scss']
})
export class ArtistsDashboardInstitutionComponent implements OnInit {


  public invites: any = [];
  public activeArtists: any = 0
  public searchArtist: any;
  public invitesData: any;
  public disabledMode: boolean = false;

  constructor(
  public api: ApiService,
  ) { }

  ngOnInit(): void {
      this.api.get('institutions/artists').then((response) => {
        this.invites = response
        this.invitesData = response
      })
  }

  filterArtists(status: any){
    this.activeArtists = status
    this.invites = this.invitesData
    if(status > 0){
      this.invites = this.invitesData.filter((value: any)=>{if(value.status == status)return value;})
    }
  }

  searchArtists(){
    this.activeArtists = 0
    this.invites = this.invitesData
    if(this.searchArtist != '' && typeof this.searchArtist != 'undefined'){
      this.invites = this.invitesData.filter((value: any)=>{if(value.creator.full_name.indexOf(this.searchArtist) > -1)return value;})
    }
  }
}
