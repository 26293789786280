import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-artist-royalties-to-institution',
  templateUrl: './modal-artist-royalties-to-institution.component.html',
  styleUrls: ['./modal-artist-royalties-to-institution.component.scss']
})
export class ModalArtistRoyaltiesToInstitutionComponent implements OnInit {

  constructor(
    public modalService: NgbModal,
    public router: Router,
  ) { }

  ngOnInit(): void {
  }


  buttonClose(){
    const modalRef = this.modalService.dismissAll();
   }
}
