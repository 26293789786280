<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12 text-center bg-primary py-5" id="alpha-learn">
            <div class="mt-4">
                <h1 class="font-weight-bold text-white">All about Art and NFTs</h1>
                <p class="font-weight-light text-white mt-3">Learn answers to frequently asked questions on Alphaa.io.</p>
            </div>
            <!-- <div class="my-4">
                <input id="search" type="text" class="rounded border-0 px-4 pt-2"
                    placeholder="What do you want to learn?">
                <button type="button"  class="btn-lg btn btn-secondary text-white cursor-pointer">Search</button>
            </div> -->
        </div>
    </div>
</div>
<div class="container-fluid px-sm-5">
    <div class="row">
        <div class="col-lg-12">
            <div>
                <h2 class="text-secondary font-weight-bold mt-5">Our Latest videos</h2>
            </div>
            <div>
                <app-video-track></app-video-track>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid px-sm-5 py-5">
    <div class="row">
        <div class="col-lg-12">
            <h2 class="text-secondary font-weight-bold mt-5">Popular Questions</h2>
            <p class="text-grey-light font-weight-light mt-3">Explore our FAQs and find out what collectors, artists and institutions have asked before</p>
        </div>
    </div>
    <div class="row learn-info mt-4">
        <div class="col-lg-6 col-md-6 col-sm-12">
            <h4 class="text-primary font-weight-bold mb-3">Collectors</h4>
            <ul class="menu-sanfona p-0" *ngFor="let collectors of texts1">
                <li tabindex="0"  class="text-secondary font-weight-bold cursor-pointer">{{ collectors.title }}
                    <ul class="p-0">
                        <li class="text-grey font-weight-light nav-link p-0">{{ collectors.text}}</li>
                    </ul>
                </li>
            </ul>
        </div>


        <div class="col-lg-6 col-md-6 col-sm-12">
            <h4 class="text-primary font-weight-bold mb-3">Creators</h4>
            <ul class="menu-sanfona p-0" *ngFor="let creators of texts2">
                <li tabindex="0"  class="text-secondary font-weight-bold cursor-pointer" data-toggle="collapse">{{ creators.title }}
                    <ul class="p-0">
                        <li class="text-grey font-weight-light nav-link p-0">{{ creators.text}}</li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
    <div class="row learn-info mt-4">
        <div class="col-lg-6 col-md-6 col-sm-12">
            <h4 class="text-primary font-weight-bold mb-3">Institutions</h4>
            <ul class="menu-sanfona p-0" *ngFor="let institutions of texts3">
                <li tabindex="0"  class="text-secondary font-weight-bold cursor-pointer" data-toggle="collapse">{{ institutions.title }}
                    <ul class="p-0">
                        <li class="text-grey font-weight-light nav-link p-0">{{ institutions.text}}</li>
                    </ul>
                </li>
            </ul>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
            <h4 class="text-primary font-weight-bold mb-3">General</h4>
            <ul class="menu-sanfona p-0" *ngFor="let general of texts4">
                <li tabindex="0"  class="text-secondary font-weight-bold cursor-pointer" data-toggle="collapse">{{ general.title }}
                    <ul class="p-0">
                        <li class="text-grey font-weight-light nav-link p-0">{{ general.text}}</li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>
