import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-modal-import-artworks',
  templateUrl: './modal-import-artworks.component.html',
  styleUrls: ['./modal-import-artworks.component.scss']
})
export class ModalImportArtworksComponent implements OnInit {

  constructor(
    public modalService: NgbModal,

  ) { }

  ngOnInit(): void {
  }

  buttonClose(){
    const modalRef = this.modalService.dismissAll();
   }

}
