<footer class="container-fluid bg-white">
    <div class="row mx-5 my-5 pt-4">
        <div class="col-sm-6 col-md-6 col-lg-3 mb-4">
            <div>
                <a [routerLink]="['/home']" class="d-flex">
                    <img src="/assets/img/new-logo.jpg" alt="Logo alphaa.io">
                </a>
            </div>
            <div class="mt-5 d-flex socials">
                <a class="text-secondary cursor-pointer" href="https://www.instagram.com/alphaaio/" target="_blank"><img src="/assets/icons/icon-instagram.svg"></a>
                <a class="text-secondary cursor-pointer" href="https://twitter.com/geracaoalpha" target="_blank"><img src="/assets/icons/icon-twitter.svg"></a>
                <a class="text-secondary cursor-pointer" href="https://www.facebook.com/search/top?q=alpha%27a%20inc." target="_blank"><img src="/assets/icons/icon-facebook.svg"></a>
                <a class="text-secondary cursor-pointer" href="https://www.youtube.com/channel/UC6NO1zr_NObuhfnYUeOfpGg" target="_blank"><img src="/assets/icons/icon-YT.svg"></a>
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3 mb-4">
            <div class="">
                <h5 class="text-primary font-weight-bold">Alphaa Inc.</h5>
                <div>
                    <p class="text-secondary font-weight-regular mb-0">157 Columbus Avenue</p>
                    <p class="text-secondary font-weight-regular">New York, NY 10069</p>
                </div>
                <div class="mt-4 d-flex flex-column">
                    <a href="mailto:contato@alphaainc.com?subject=" class="text-secondary font-weight-regular cursor-pointer mb-0">contato@alphaainc.com</a>
                    <a class="text-secondary font-weight-regular" href="tel:+1 646 7634523">+1 646 7634523</a>
                </div>
            </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-2">
            <h5 class="text-primary font-weight-bold">The Platform</h5>
            <div class="d-flex flex-column">
                <a class="text-secondary font-weight-regular mb-3" [routerLink]="['/home-creator']">Creators</a>
                <!-- <a class="text-secondary font-weight-regular mb-3">Institutions</a> -->
                <a class="text-secondary font-weight-regular mb-3" [routerLink]="['/home-collect']">Collectors</a>
                <!-- <a class="text-secondary font-weight-regular mb-3">Content</a> -->
                <!-- <a class="text-secondary font-weight-regular mb-3">Integrations</a> -->
            </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-2">
            <h5 class="text-primary font-weight-bold">Learn</h5>
            <div class="d-flex flex-column">
                <a class="text-secondary font-weight-regular mb-3" [routerLink]="['/learn']">NFTs</a>
                <a class="text-secondary mb-3" [routerLink]="['/privacy-policy']">Privacy Policy</a>
                <!-- <a class="text-secondary mb-3 font-weight-regular">Subject</a> -->
                <!-- <a class="text-secondary mb-3 font-weight-regular">Subject</a> -->
            </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-2">
            <h5 class="text-primary font-weight-bold">Company</h5>
            <div class="d-flex flex-column">
                <a class="text-secondary mb-3" [routerLink]="['/about']">About Us</a>
                <!-- <a class="text-secondary mb-3">Careers</a> -->
                <!-- <a class="text-secondary mb-3">Team</a> -->
                <!-- <a class="text-secondary mb-3">Contact Us</a> -->
            </div>
        </div>
    </div>
    <div class="row mx-5 mb-5">
        <div class="col-lg-12">
            <p class="text-secondary small">&copy; 2022 Alphaa Inc. All rights reserved.</p>
        </div>
    </div>
</footer>