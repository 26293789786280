import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-modal-institution-artwork-change-price',
  templateUrl: './modal-institution-artwork-change-price.component.html',
  styleUrls: ['./modal-institution-artwork-change-price.component.scss']
})
export class ModalInstitutionArtworkChangePriceComponent implements OnInit {
  
  @Input() artwork: any;
  public InstitutionEditStep: number = 0;
  public newPrice: any;
  
  constructor(
    public modalService: NgbModal,
    public router: Router,
    public helper: HelperService,
    public api: ApiService,
    ) { }
    
    ngOnInit(): void {
      console.log(this.artwork)
      this.newPrice = this.artwork.original_price/100
      this.artwork.institution_fee = this.artwork.institution_fee/100
      this.artwork.author_fee = this.artwork.author_fee/100
    }
    
    changePrice(){
      this.artwork.original_price = this.newPrice*100
      this.artwork.institution_fee = this.artwork.institution_fee
      this.artwork.author_fee = this.artwork.author_fee
      delete this.artwork.tags
      this.api.put('artworks/'+this.artwork.id, this.artwork).then(response =>{
        Swal.fire(
          'Changed!',
          'Your art has been successfully changed',
          'success',
          )
          .then((response) => {
            if (response.isConfirmed) {
              this.buttonClose()
            }
          })
      })
    }
    
    buttonClose(){
      const modalRef = this.modalService.dismissAll();
    }  
  }
  