<div class="container-fluid gif-chat-group">
    <div class="row d-flex justify-content-start ml-md-5 pl-md-4">
        <div class="col-lg-6 col-md-8 d-flex flex-column align-items-start px-md-0">
            <div class="card card-chat bg-secondary w-100">
                <h1 class="text-white mt-4">CHATTY KATHY NFT WHITELIST</h1>
                <h4 class="text-white mt-1">SIGN UP HERE TO BE FIRST ON THE DROP</h4>
                <div class="pt-4 mt-1">
                    <a class="btn join-whitelist cursor-pointer">JOIN THE WHITELIST</a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid px-0">
    <div class="row mx-0">
        <div class="col-lg-12 px-0">
            <div class="banner-chat-group">
                <div class="card card-form mx-3">
                    <h2>Join our Whitelist</h2>
                    <div class="d-sm-flex">
                        <div>
                            <div>
                                <label>Name</label>
                            </div>
                            <input type="text" class="input-name">
                        </div>
                        <div>
                            <div>
                                <label>#of NFTs</label>
                            </div>
                            <input type="text" class="input-nft">
                        </div>
                        <div>
                            <div>
                                <label>Email*</label>
                            </div>
                            <input type="text" class="input-email">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 d-flex justify-content-end align-items-center">
                            <a class="btn-light cursor-pointer btn-whitelist">Join the Whitelist</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="container-fluid px-0 bg-info pb-5">
    <div class="row mx-0">
        <div class="col-lg-12 px-0">
            <div class="video-card mx-sm-3 mt-5">
                <iframe src="https://www.youtube.com/embed/hbyh_Ni_5ks" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    </div>
    <div class="row d-flex justify-content-center bg-images mx-0">
        <div class="col-lg-12 text-center px-0 element3">
            <div class="container d-flex justify-content-center">
                <div class="person-1">
                    <img #element1 src="/assets/img/person-3.webp" alt="">
                </div>
                <div class="person-2">
                    <img #element2 src="/assets/img/person-2.webp" alt="">
                </div>
                <div class="person-3">
                    <img #element3 src="/assets/img/person-1.webp" alt="">
                </div>
            </div>
            <div class="col-lg-12 d-flex justify-content-flex-start align-items-flex-start med-card-promotion">
                <div class="card card-promotion bg-secondary" #element4>
                    <div class="text-center">
                        <h4>CHATTY KATHY FUND</h4>
                        <h1>10%</h1>
                        <h4 class="description">of mint goes into a fund for <br> start-ups and entrepreneurs</h4>
                    </div>
                    <div class="pt-4 mt-1">
                        <a class="btn join-whitelist cursor-pointer">JOIN THE WHITELIST</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 d-flex justify-content-flex-start align-items-flex-start px-0 mt-5 pt-5">
                <div class="banner-group mt-sm-5 pt-sm-5">
                    <div class="d-flex justify-content-center align-items-center mt-sm-5 pt-5">
                        <h1 class="text-white mt-sm-4" style="position: relative; z-index: 5;">CHATTY KATHY NFT <br> WHITEPAPER</h1>
                    </div>
                    <div class="mt-xl-5 pt-4">
                        <a class="btn read-now cursor-pointer">READ NOW</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center align-items-center">
        <div class="col-lg-12">
            <p class="text-white text-center mt-5 mb-0" style="font-size: 13px;">© 2023 by Group Chat. Proudly powered by Dropt</p>
        </div>
    </div>
</div>