import { Component, HostListener, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalTradeDigitalComponent } from 'src/app/modals/modal-trade-digital/modal-trade-digital.component';
import { ModalTransactionComponent } from 'src/app/modals/modal-transaction/modal-transaction.component';
import { YourSmartContractComponent } from 'src/app/modals/your-smart-contract/your-smart-contract.component';
import * as Highcharts from 'highcharts';
import { environment } from '../../../environments/environment';

import { HighChartsService } from 'src/app/services/highcharts.service';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-artwork',
  templateUrl: './artwork.component.html',
  styleUrls: ['./artwork.component.scss']
})
export class ArtworkComponent implements OnInit {
  @Input() mode: any;
  @Input() public socials: any;
  @Input() public currentURL: string = "";

  public id: number;
  public status: any = {
    1: "Request",
    2: "Accepted",
    3: "Recused",
    4: "Payment Pending",
    5: "Payed",
    6: "Cancelled",
    7: "Purchased",
  };
  public artwork: any;
  public histories: any;
  public viewMode: any;
  public institutionPermission: boolean = false;
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};
  public descriptionArtworkDesktop: any = 'descriptionArtworkDesktop'
  public descriptionArtworkMobile: any = 'descriptionArtworkMobile'
  public innerWidth: any;

  constructor(
    public router: Router,
    public modalService: NgbModal,
    public route: ActivatedRoute,
    public hcSercive: HighChartsService,
    public api: ApiService,
    public auth: AuthService,
    public helper: HelperService,
    
    ) {
      
      router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          this.viewMode = this.route.snapshot.params.viewMode ? this.route.snapshot.params.viewMode : 'value'
          this.id = this.route.snapshot.params.id
          this.api.get('artworks/' + this.id).then((response) => {
            this.artwork = response
            console.log(this.artwork)
            for(let invite of this.artwork.artist.invites){
              if(invite.institution_id == auth.userData.id){
                this.institutionPermission = true
              }
            }
            response.chartData = [
              { x: 'Apr/21', y: 250 },
              { x: 'May/21', y: 500 },
              { x: 'Jun/21', y: 400 },
              { x: 'Jul/21', y: 750 },
              { x: 'Aug/21', y: 1000 },
              { x: 'Sep/21', y: 2000 },
              { x: 'Oct/21', y: 3000 },
              { x: 'Nov/21', y: 2000 },
              { x: 'Dec/21', y: 2500 },
              { x: 'Jan/22', y: 3500 }
            ];
            this.chartOptions = this.hcSercive.populateSplineChart(response.chartData);
            
            this.api.get('transfers/' + this.artwork.id + '/history').then((response) => {
              this.histories = response
            })
          })
        }
      });
    }
    
    ngOnInit(): void {
      this.innerWidth = window.innerWidth;
      
      if(this.innerWidth < 767.98){
        this.helper.footer = false
      }

      this.currentURL = this.router.url;
      console.log(`${this.router.url}`);
    }
    
    @HostListener('window:resize', ['$event'])
    onResize(event) {
      this.innerWidth = window.innerWidth;
      if(this.innerWidth < 767.98){
        this.helper.footer = false
      }
    }
    
    public textSubject(history: any) {
      if (history.status === 1) {
        return history.seller.full_name
      } else {
        return history.purchaser.full_name
      }
    }
    
    public textStatus(status: any) {
      return this.status[status]
    }
    
    buy(url) {
      window.location.href =  environment.URL_BASE+'/artworks/'+this.artwork.id+'/'+this.viewMode+url
    }
    
    openTransaction() {
      const modalRef = this.modalService.open(ModalTradeDigitalComponent);
      modalRef.componentInstance.artwork = this.artwork;
    }
    
    openTransactionTwo(data: any = '') {
      const modalRef = this.modalService.open(ModalTransactionComponent);
      modalRef.componentInstance.data = data;
    }
    
    openYourSmartContract() {
      const modalRef = this.modalService.open(YourSmartContractComponent);
      modalRef.componentInstance.artwork = this.artwork;
    }
    
    share(){
      if (navigator.share !== undefined) {
        navigator.share({
          title: '',
          text: '',
          url: 'https://www.alphaa.io/artworks/'+this.artwork.id,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
      }
    }
  }
  