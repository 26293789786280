import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { ClipboardService } from 'ngx-clipboard'
import Swal from 'sweetalert2';
@Component({
  selector: 'app-modal-embed-profile',
  templateUrl: './modal-embed-profile.component.html',
  styleUrls: ['./modal-embed-profile.component.scss']
})
export class ModalEmbedProfileComponent implements OnInit {

  public showCode: boolean = false;
  public urlBase = environment.URL_BASE;
  public urlSafeProfile: SafeResourceUrl;
  public urlSafeList: SafeResourceUrl;
  private urlProfile: string
  private urlList: string
  public codeProfile: string;
  public codeList: string;
  
  constructor(
    public modalService: NgbModal, 
    public auth: AuthService,
    public sanitizer: DomSanitizer,
    private _clipboardService: ClipboardService,

  ) {
   }

  ngOnInit(): void {
    this.codeProfile = `<iframe src="'https://s3.amazonaws.com/embed.alphaa.io/profile.html?id=${this.auth.userData.id} width="316" height="313" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>`
    this.codeList = `<iframe src="'https://s3.amazonaws.com/embed.alphaa.io/artist-list.html?id=${this.auth.userData.id} width="316" height="313" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>`


    this.urlProfile = `https://s3.amazonaws.com/embed.alphaa.io/profile.html?id=${this.auth.userData.id}`
    this.urlList = `https://s3.amazonaws.com/embed.alphaa.io/artist-list.html?id=${this.auth.userData.id}`

    this.urlSafeProfile = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlProfile)
    this.urlSafeList = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlList)
  }

  btnShowCode(){
    if(this.urlSafeProfile === this.urlSafeProfile){
      this.codeProfile = this.codeProfile;
    }if (this.urlSafeList === this.urlSafeList){
      this.codeList = this.codeList;
    }
  }

  copyEmbed(){
    if (this.urlSafeProfile){
      this._clipboardService.copy(this.codeProfile)
      Swal.fire({
        icon: 'success',
        title: "Copied profile embed link",
        text: "Copy this link into your social media, website etc."
      })
    } if(this.urlSafeList){
      this._clipboardService.copy(this.codeList)
      Swal.fire({
        icon: 'success',
        title: "Copied list embed link",
        text: "Copy this link into your social media, website etc for a free template to sell your artworks."
      })
    }
  }

  buttonClose() {
    const modalRef = this.modalService.dismissAll();
  }
}
