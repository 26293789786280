import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-event-track-artworks',
  templateUrl: './event-track-artworks.component.html',
  styleUrls: ['./event-track-artworks.component.scss']
})
export class EventTrackArtworksComponent implements OnInit {

  @Input() artwork : any = {}
  public similar : any = []
  public img: any = '';
  public style: any = '';

  constructor(
    public api: ApiService
  ) { }

  ngOnInit(): void {
    this.api.get('artworks/similar/'+this.artwork.style_id).then(response => {
      this.similar = response
    })
  }

}
