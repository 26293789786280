import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-trade-dispute',
  templateUrl: './modal-trade-dispute.component.html',
  styleUrls: ['./modal-trade-dispute.component.scss']
})


export class ModalTradeDisputeComponent implements OnInit {
  public transaction: any = {};
  public buyer: boolean = true;
  public payed: boolean = false;
  public shipped: boolean = false;
  public delivered: boolean = false;
  public comment: any;
  
  constructor(
    public modalService: NgbModal,
    public api: ApiService
    ) { }
    
    ngOnInit(): void {
    }
    
    
    send(){
      this.api.post('disputes', {payed: this.payed, shipped: this.shipped, delivered: this.delivered, comment: this.comment, transaction_id: this.transaction.id}).then((response) => {
        Swal.fire(
          'Ok!',
          'Your Feedback has been sent successfully.',
          'success',
        )
          .then((response) => {
            if (response.isConfirmed) {
              this.modalService.dismissAll();
            }
          })
      });
    }
    
    buttonClose(){
      const modalRef = this.modalService.dismissAll();
    }
  }
  