<ng-container *ngIf="link == 'artworks'">
    <a class="cursor-pointer" *ngIf="artwork">
        <app-preview-artwork [showPlay]="true" [link]="'/'+link+'/'+artwork.id" [preview]="artwork.preview" [img]="artwork.img" [class]="classStyle"></app-preview-artwork>
        <p *ngIf="title" [routerLink]="['/'+link+'/'+artwork.id]" class="text-secondary font-weight-bold mt-1 mb-0 width-13" [title]="artwork.title">{{ artwork.title }}</p>
        <p *ngIf="title" [routerLink]="['/'+link+'/'+artwork.id]" class="text-secondary font-weight-bold mt-1 mb-0">{{ artwork.original_price/100 | currency }}</p>
        <small *ngIf="title" [routerLink]="['/'+link+'/'+artwork.id]" class="font-weight-light text-grey">Uploaded {{helper.formatDate(artwork.created_at)}}</small>
    </a>
</ng-container>

<ng-container *ngIf="link == 'versions'">
    <a class="cursor-pointer" [routerLink]="['/'+link+'/'+version.id]">
        <app-preview-artwork [showPlay]="true" [link]="'/'+link+'/'+version.id" [preview]="artwork.preview" [img]="artwork.img" [class]="classStyle"></app-preview-artwork>
        <p *ngIf="title" [routerLink]="['/'+link+'/'+version.id]" class="text-secondary font-weight-bold mt-1 mb-0 width-13" [title]="artwork.title">{{ artwork.title }}</p>
        <p *ngIf="title" [routerLink]="['/'+link+'/'+version.id]" class="text-secondary font-weight-bold mt-1 mb-0">{{ version.price/100 | currency }}</p>
        <small *ngIf="title" [routerLink]="['/'+link+'/'+version.id]" class="font-weight-light text-grey">Uploaded {{helper.formatDate(artwork.created_at)}}</small>
    </a>
</ng-container>