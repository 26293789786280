import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-modal-create-artwork',
  templateUrl: './modal-create-artwork.component.html',
  styleUrls: ['./modal-create-artwork.component.scss']
})
export class ModalCreateArtworkComponent implements OnInit {
  viewMode: any = 'step-one';
  step: any;
  isDigital: any = null;
  
  
  constructor(
    public route: ActivatedRoute,
    public modalService: NgbModal,
    ) { }
    
    ngOnInit(): void {
    }
    
    selectTypeArt(isDigital: any) {
      this.isDigital = isDigital
    }
    
    goToStepTwo() {
      if(this.isDigital != null){
        this.viewMode = "step-two"
      }
    }
    
    buttonClose(){
      const modalRef = this.modalService.dismissAll();
     }
    
  }