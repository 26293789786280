
<div *ngIf="artwork">
    <div *ngIf="showImage">
        <app-preview-artwork [img]="artwork?.img" [preview]="artwork?.img" [styleImage]="'width: 100%; border-radius: 9px; margin-bottom: 5px'"></app-preview-artwork>
        <ngb-progressbar *ngIf="loading" class="mt-2" type="info" [value]="progress_bar_value" height="1.2em">
            <p class="text-white small mb-0">Loading...</p>
        </ngb-progressbar>
        <div *ngIf="!loading">
            <a class="text-grey-link mt-2 download-my-nft" *ngIf="auth.isAuthenticated.getValue() && auth.userData.id == artwork.institution_id || auth.isAuthenticated.getValue() && auth.userData.id == artwork.artist_id || auth.isAuthenticated.getValue() && auth.userData.id == version?.owner_id" href="javascript:void(0)" (click)="download()" title="{{artwork?.title}}">Download my NFT</a>
        </div>
    </div>
    <div class="row" *ngIf="descriptionArtworkDesktop && innerWidth > 767.98">
        <div class="col-lg-12 mt-4">
            <hr class="m-0">
            <div class="row mt-2 p-1">
                <div class="col-lg-6">
                    <p class="font-weight-bold text-secondary">Size</p>
                </div>
                <div class="offset-lg-1 col-lg-5">
                    <p class="text-grey">{{ artwork?.width }} x {{ artwork?.height }} ({{ artwork?.unit }})</p>
                </div>
            </div>

            <hr class="m-0">
            <div class="row mt-2 p-1">
                <div class="col-lg-6">
                    <p class="font-weight-bold text-secondary">Medium</p>
                </div>
                <div class="offset-lg-1 col-lg-5">
                    <p class="text-grey">{{ artwork?.medium }}</p>
                </div>
            </div>
            <hr class="m-0">
            <div class="row mt-2 p-1">
                <div class="col-lg-6">
                    <p class="font-weight-bold text-secondary">Style</p>
                </div>
                <div class="offset-lg-1 col-lg-5">
                    <p class="text-grey">{{ artwork?.style.title }}</p>
                </div>
            </div>
            <hr class="m-0">
            <div class="row mt-2 p-1">
                <div class="col-lg-6">
                    <p class="font-weight-bold text-secondary">Type</p>
                </div>
                <div class="offset-lg-1 col-lg-5">
                    <p class="text-grey">{{ artwork?.digital ? 'Digital': 'Physical' }}</p>
                </div>
            </div>
            <hr class="m-0">
            <div class="row mt-2 p-1">
                <div class="col-lg-6">
                    <p class="font-weight-bold text-secondary">Production Date</p>
                </div>
                <div class="offset-lg-1 col-lg-5">
                    <p class="text-grey">{{ artwork?.year }}</p>
                </div>
            </div>
            <hr class="m-0">
            <div class="row mt-2 p-1">
                <div class="col-lg-6">
                    <p class="font-weight-bold text-secondary">Editions</p>
                </div>
                <div *ngIf="version" class="offset-lg-1 col-lg-5">
                    <p class="text-grey">{{ version?.version }}/{{ artwork?.edition }}</p>
                </div>
                <div *ngIf="!version" class="offset-lg-1 col-lg-5">
                    <p *ngIf="artwork?.version != 'sold_out'" class="text-grey">{{ artwork?.version.version }}/{{ artwork?.edition }}</p>
                    <p *ngIf="artwork?.version == 'sold_out'" class="text-grey">Sold out - {{ artwork?.edition }}/{{ artwork?.edition }}</p>
                </div>
            </div>
            <hr class="m-0">
            <div *ngIf="artwork?.tags.length > 0" class="row mt-2 p-1">
                <div class="col-lg-6 col-md-5">
                    <p class="font-weight-bold text-secondary">Tags</p>
                </div>
                <div class="offset-lg-1 col-lg-5">
                    <p class="text-grey d-inline-block float-left mr-1" *ngFor="let tag of artwork?.tags">
                        <span class="bg-light p-2" style="border-radius: 9px;">{{ tag.title }}</span>
                    </p>
                </div>
            </div>
            <hr class="m-0">
        </div>
    </div>
    
    <div class="row" *ngIf="descriptionArtworkMobile && innerWidth < 767.98">
        <div class="col-12">
            <div class="details-artwork">
                <hr class="m-0">
                <div class="row mt-2 p-1">
                    <div class="col-5">
                        <p class="font-weight-bold text-secondary mb-2 pl-3">Size</p>
                    </div>
                    <div class="offset-1 col-5">
                        <p class="text-grey mb-2">{{ artwork?.width }} x {{ artwork?.height }} ({{ artwork?.unit }})</p>
                    </div>
                </div>
                <hr class="m-0">
                <div class="row mt-2 p-1">
                    <div class="col-5">
                        <p class="font-weight-bold text-secondary mb-2 pl-3">Medium</p>
                    </div>
                    <div class="offset-1 col-5">
                        <p class="text-grey mb-2">{{ artwork?.medium }}</p>
                    </div>
                </div>
                <hr class="m-0">
                <div class="row mt-2 p-1">
                    <div class="col-5">
                        <p class="font-weight-bold text-secondary mb-2 pl-3">Style</p>
                    </div>
                    <div class="offset-1 col-5">
                        <p class="text-grey mb-2">{{ artwork?.style.title }}</p>
                    </div>
                </div>
                <hr class="m-0">
                <div class="row mt-2 p-1">
                    <div class="col-5">
                        <p class="font-weight-bold text-secondary mb-2 pl-3">Type</p>
                    </div>
                    <div class="offset-1 col-5">
                        <p class="text-grey mb-2">{{ artwork?.digital ? 'Digital': 'Physical' }}</p>
                    </div>
                </div>
                <hr class="m-0">
                <div class="row mt-2 p-1">
                    <div class="col-5">
                        <p class="font-weight-bold text-secondary mb-2 pl-3">Production Date</p>
                    </div>
                    <div class="offset-1 col-5">
                        <p class="text-grey mb-2">{{ artwork?.year }}</p>
                    </div>
                </div>
                <hr class="m-0">
                <div class="row mt-2 p-1">
                    <div class="col-5">
                        <p class="font-weight-bold text-secondary mb-2 pl-3">Editions</p>
                    </div>
                    <div *ngIf="version" class="offset-1 col-5">
                        <p class="text-grey mb-2">{{ version?.version }}/{{ artwork?.edition }}</p>
                    </div>
                    <div *ngIf="!version" class="offset-1 col-5">
                        <p *ngIf="artwork?.version != 'sold_out'" class="text-grey mb-2">{{ artwork?.version.version }}/{{ artwork?.edition }}</p>
                        <p *ngIf="artwork?.version == 'sold_out'" class="text-grey mb-2">Sold out - {{ artwork?.edition }}/{{ artwork?.edition }}</p>
                    </div>
                </div>
                <hr class="m-0">
                <div *ngIf="artwork?.tags.length > 0" class="row mt-2 p-1">
                    <div class="col-5">
                        <p class="font-weight-bold text-secondary mb-2 pl-3">Tags</p>
                    </div>
                    <div class="offset-1 col-5">
                        <p class="text-grey d-inline-block float-left mr-1" *ngFor="let tag of artwork?.tags">
                            <span class="bg-light p-2" style="border-radius: 9px;">{{ tag.title }}</span>
                        </p>
                    </div>
                </div>
                <hr class="m-0">
            </div>
        </div>
    </div>
</div>