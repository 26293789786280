import { ApiService } from './../../services/api.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-latest-artwork-created',
  templateUrl: './latest-artwork-created.component.html',
  styleUrls: ['./latest-artwork-created.component.scss']
})
export class LatestArtworkCreatedComponent implements OnInit {
  @Input() public artworkLastCreated: any;
  @Input() public link: any;
  
  constructor(
    public api: ApiService,
  ) { }

  ngOnInit(): void {
    console.log(this.link)
  }

}
