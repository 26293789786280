<main id="bg-subscribe">
    <section>
        <div class="container-fluid px-sm-5">
            <div class="row px-sm-4 pt-3">
                <div class="col-lg-12">
                    <p class="mb-0 text-grey font-weight-light mt-sm-5">Your Vault</p>
                    <h1 class="font-weight-bold text-primary mb-0">Welcome, {{ auth.userData.full_name }}</h1>
                </div>
            </div>
        </div>

        <app-subscripton-plans [type]="type"></app-subscripton-plans>
        
    </section>
</main>