<div class="row my-3 border-light">
  <div class="col-lg-12">
    <div class="row titles-card-trade d-flex border-bottom ml-1">
        <a class="h1 text-grey font-weight-light nav-link py-2 mb-0 px-0 mr-4 cursor-pointer" [class.selected]="tradeMode == 'buying'" (click)="changeMode('buying')">Buying</a>
        <a class="h1 text-grey font-weight-light nav-link py-2 mb-0 px-0 mr-4 cursor-pointer" [class.selected]="tradeMode == 'selling'" (click)="changeMode('selling')">Selling</a>
    </div>
  </div>
</div>
<div>
  <app-trade-lists *ngIf="buy" [progress]="buy.in_progress" [confirm]="buy.bought" [label]="'Bought'"></app-trade-lists>
  <app-trade-lists *ngIf="sale" [progress]="sale.in_progress" [confirm]="sale.sold" [label]="'Sold'"></app-trade-lists>
</div>