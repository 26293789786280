<div class="container-fluid" id="profile-image" *ngIf="!user">
    <div class="row">
        <div class="col-auto" >
            <div id="div" class="profile-avatar" (mouseover)="changeText=true" style="background: url({{auth.userData.avatar ? auth.userData.avatar : '/assets/img/avatar.png'}}); background-position: top;background-size: 100%; background-repeat: no-repeat;" >
                <span *ngIf="changeText" class="avatar-show cursor-pointer" (mouseout)="changeText=false" (click)="uploadAvatar()"> Upload your image</span>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid" id="profile-image" *ngIf="user">
    <div class="row">
        <div class="col-auto" >
            <div id="div" class="profile-avatar" (mouseover)="changeText=true" style="background: url({{user.avatar ? user.avatar : '/assets/img/avatar.png'}}); background-position: top;background-size: 100%; background-repeat: no-repeat;" >
                <span *ngIf="changeText" class="avatar-show cursor-pointer" (mouseout)="changeText=false" (click)="uploadAvatar()"> Upload your image</span>
            </div>
        </div>
    </div>
</div>