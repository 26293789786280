import { Component, OnInit, ElementRef, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ApiService } from 'src/app/services/api.service';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { AnimateService } from 'src/app/services/animate.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    if (this.element1?.nativeElement) {
      this.animate.setAnimationPercentage(this.element1.nativeElement, { opacity: { initial: 0, amount: 1 }, translateX: { initial: -130, amount: 130, unit: 'px' } })
    }
    if (this.element2?.nativeElement) {
      this.animate.setAnimationPercentage(this.element2.nativeElement, { opacity: { initial: 0, amount: 1 }, translateX: { initial: 100, amount: -100, unit: 'px' } })
    }
    if (this.element3?.nativeElement) {
      this.animate.setAnimationPercentage(this.element3.nativeElement, { opacity: { initial: 0, amount: 1 }, translateY: { initial: 100, amount: -100, unit: 'px' } })
    }
    if (this.element4?.nativeElement) {
      this.animate.setAnimationPercentage(this.element4.nativeElement, { opacity: { initial: 0, amount: 1 }, translateY: { initial: 100, amount: -100, unit: 'px' } })
    }
  }
  @ViewChild('element1') element1: ElementRef
  @ViewChild('element2') element2: ElementRef
  @ViewChild('element3') element3: ElementRef
  @ViewChild('element4') element4: ElementRef

  constructor(
    public route: ActivatedRoute,
    public api: ApiService,
    public auth: AuthService,
    public animate: AnimateService,
  ) { }
  isInViewport(element: HTMLElement) {
    this.animate.setAnimationPercentage(this.element1.nativeElement, { opacity: { min: 0, max: 1 }, left: { min: -130, max: 30, unit: 'px' } })
  }

  ngOnInit(): void {
  }

}