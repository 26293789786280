import { PipeTransform, Pipe } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Pipe({
  name: 'callback',
  pure: false
})
export class CallbackPipe implements PipeTransform {
  constructor(public auth: AuthService){}
  
  transform(items: any[], callback:(item: any, user: any) => boolean): any {
    if (!items || !callback) {
      return items;
    }
    return items.filter(item => callback(item, this.auth.userData));
  }
}