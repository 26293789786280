<form [formGroup]="formEditList" (ngSubmit)="editList(formEditList)">
    <div class="card-header text-center p-4">
        <h3 class="text-secondary font-weight-bold mb-0">Edit this list</h3>
        <button type="button" (click)="buttonClose()" aria-label="Close"  class="btn btn-outline position-absolute right-0 top-0 p-2 cursor-pointer">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
    <div class="card-body text-center p-3">
        <p class="text-grey-light font-weight-light ml-3 text-left">Edit the title and/or description for this list</p>
        <div class="col-lg-12">
            <input formControlName="title" type="text" class="form-control bg-light w-100" placeholder="Collection name" />
        </div>
        <div class="col-lg-12">
            <textarea formControlName="description" class="form-control bg-light mt-4 w-100" rows="4" placeholder="Description" style=" height: 8em;"></textarea>
        </div>
    </div>
    <div class="card-footer bg-white py-3 px-1">
        <div class="row">
            <div class="col-lg-12">
                <div class="text-left d-flex">
                    <button type="button" *ngIf="list.id" (click)="acceptDeleteList(list.id)" 
                        class="btn text-primary font-weight-regular px-0 mx-2 ml-sm-4">Delete list</button>
                    <button  type="submit" class="btn-lg btn btn-primary font-weight-regular w-50 ml-4">Done</button>
                </div>
            </div>
        </div>
    </div>
</form>