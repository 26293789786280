<div>
    <div class="text-center container" *ngIf="step === 1">
        <div class="row">
            <div class="col-lg-12 text-right">
                <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0 full-z-index cursor-pointer">
                    <img src="/assets/icons/close.svg">
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <h4 class="text-secondary font-weight-bold">1. Type of trading</h4>
            </div>
        </div>
        <div class="progress my-2" style="height: 3px;">
            <div class="progress-bar" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="row">
            <div class="col-lg-12 text-justify px-5">
                <p class="text-grey-light font-weight-light px-4">Nice! You are about to initiate the transfer of your artwork. Please select the type of artwork you are trading.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="d-sm-flex justify-content-center">
                    <div>
                        <a (click)="setIsDigital(true)" class="nav-link cursor-pointer">
                            <div class="card p-2" [class.activeClick]="isDigital === true" style="width: 13rem;height: 9.2rem;">
                                <div class="mt-3">
                                    <h4 class="card-title text-secondary font-weight-bold">Digital</h4>
                                    <p class="text-grey-light font-weight-light">Digital artworks are works that only exist in digital form such as jpegs, mpegs, etc</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div>
                        <a (click)="setIsDigital(false)" class="nav-link cards-options cursor-pointer">
                            <div class="card p-2" [class.activeClick]="isDigital === false" style="width: 13rem;height: 9.2rem;">
                                <div class="mt-3">
                                    <h4 class="card-title text-secondary font-weight-bold">Physical</h4>
                                    <p class="text-grey-light font-weight-light">Physical artworks are actual art pieces that have a physical form such as paintings and sculptures</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="mt-5 mb-4 text-left">
            <a href="/dashboard" class="btn btn-outline pl-4 pr-4 text-primary">Go to Dashboard</a>
            <a (click)="nextStep()" class="btn btn-primary font-weight-bold pl-5 pr-5" style="width: 10em;">Next</a>
        </div>
    </div>


    <div class="text-center" *ngIf="step === 2">
        <div class="row">
            <div class="col-lg-12 text-right">
                <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0 full-z-index cursor-pointer">
                    <img src="/assets/icons/close.svg">
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <h4 class="text-secondary font-weight-bold py-4 mb-0">1. Who are you trading with?</h4>
            </div>
        </div>
        <div class="progress my-2" style="height: 3px;">
            <div class="progress-bar" role="progressbar" style="width: 35%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="row">
            <div class="col-lg-12 text-justify px-5">
                <p class="text-grey-light font-weight-light py-3">Nice! you are about to initiate the transer of your artwork. Insert the person's information to start thre process.
                </p>
            </div>
        </div>
        <div class="row px-4">
            <div class="col-md-6 mb-3">
                <input class="form-control bg-light border-0 col" [(ngModel)]="first_name" placeholder="First Name" type="text" required checked>
            </div>
            <div class="col-md-6 mb-3">
                <input class="form-control bg-light border-0 col" [(ngModel)]="last_name" placeholder="Last Name" type="text" required checked>
            </div>
            <div class="col-md-12 mb-3">
                <input class="form-control bg-light border-0 col" [(ngModel)]="email" placeholder="e-mail" type="text" required checked>
            </div>
            <div class="col-md-12 mb-3">
                <input class="form-control bg-light border-0 col" [(ngModel)]="confirm_email" placeholder="Confirm e-mail" type="text" required checked>
            </div>
        </div>
        <hr>
        <div class="row pb-4">
            <div class="col-lg-3 col-md-2">
                <div class="text-left">
                    <button type="button" class="btn btn-outline text-primary mt-2 cursor-pointer" (click)="buttonClose()">Cancel</button>
                </div>
            </div>
            <div class="col-lg-6 col-md-4 px-4">
                <button type="button" (click)="nextStep()" class="btn-lg btn btn-primary font-weight-regular w-100 cursor-pointer">Next step</button>
            </div>
        </div>
    </div>

    <div class="text-center" *ngIf="step == 3">
        <div class="row">
            <div class="col-lg-12 text-right">
                <button type="button" (click)="buttonClose()" aria-label="Close" style="cursor: pointer; z-index: 999999 !important;" class="btn btn-outline position-absolute right-0 top-0 full-z-index cursor-pointer">
                    <img src="/assets/icons/close.svg">
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <h4 class="text-secondary font-weight-bold py-3 mb-0">2. Price</h4>
            </div>
        </div>
        <div class="progress my-2" style="height: 3px;">
            <div class="progress-bar" role="progressbar" style="width: 45%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="row">
            <div class="col-lg-12 text-justify">
                <p class="text-grey-light font-weight-light text-left px-4 py-3 mb-0">Set the price of the artwork you are trading</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 px-4">
                <h5 class="text-secondary text-left font-weight-bold my-2 px-3">Price</h5>
                <div class="col-lg-12">
                    <input [(ngModel)]="price" class="form-control bg-light border-0 w-100" placeholder="USD" type="number" style="width: 27;height: 4.5em;" required checked>
                </div>
                <a class="text-primary font-weight-regular cursor-pointer">Set price with market value</a>
            </div>
        </div>
        <div class="row px-4 d-flex justify-content-center aling-items-center">
            <div class="col-lg-12 mt-3 px-4 text-left ml-4" style="font-size: 12px;">
                <small class="text-grey-light font-weight-light">Welcome to Alphaa.io, owned and operated by Alpha’a Inc. These Terms of Service regulate your access and application of the Alphaa.io website, our APIs, mobile app, and any other features, tools, software, or services provided by Alphaa.io. This includes but is not limited to using our services to create, trade, and view NFTs and using our technology to connect directly with others to buy, trade, or transfer NFTs on public blockchains.
                    NFT in these Terms refers to any blockchain-tracked, non-fungible token that can be sold and traded. Types of NFT data units include: physical artworks associated to digital files such as photos, videos, and digital artwork.
                    By signing up you are agreeing with our terms of services and acknowledging that the information that you provide on our services is accurate and that you are acting on behalf of yourself.
                    Alphaa.io does not make any claims about the identity, legitimacy, functionality, or authenticity of our users or the NFTs on our services. It is your responsibility to verify any information about the NFTs and the third-party seller you buy and trade with.</small>
            </div>
        </div>
        <hr>
        <div class="row pb-4">
            <div class="col-lg-3 col-md-2">
                <div class="text-left">
                    <button type="button" class="btn btn-outline text-primary mt-2 cursor-pointer" (click)="buttonClose()">Cancel</button>
                </div>
            </div>
            <div class="col-lg-6 col-md-4 pl-sm-4 px-0">
                <button type="button" (click)="nextStep()" class="btn-lg btn btn-primary font-weight-regular w-100 cursor-pointer">Next
                    step</button>
            </div>
        </div>
    </div>

    <div class="text-center" *ngIf="step == 4">
        <div class="row">
            <div class="col-lg-12 text-right">
                <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0 full-z-index cursor-pointer">
                    <img src="/assets/icons/close.svg">
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <h4 class="text-secondary font-weight-bold py-3 mb-0">3. Payment method</h4>
            </div>
        </div>
        <div class="progress my-2" style="height: 3px;">
            <div class="progress-bar" role="progressbar" style="width: 45%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="row">
            <div class="col-lg-12 text-justify">
                <p class="text-grey-light font-weight-light px-4 py-3 mb-0">How would you like to set up payment for this artwork?</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="text-left">
                    <a (click)="setTypeTrade('platform')" class="nav-link cursor-pointer">
                        <div class="card p-3 w-100" [class.activeClick]="typeTrade === 'platform'">
                            <div class="mt-2">
                                <h5 class="card-title text-secondary font-weight-bold mb-1">Use Alpha’a Secure Payments
                                    Platform</h5>
                                <p class="text-grey-light font-weight-light mb-0">We offer peace of mind with our Secure
                                    Payments Platform.</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="px-0 text-left">
                    <a (click)="setTypeTrade('external')" class="nav-link cursor-pointer">
                        <div class="card p-3 w-100" [class.activeClick]="typeTrade === 'external'">
                            <div class="mt-2">
                                <h5 class="card-title text-secondary font-weight-bold mb-1">Already payed through an
                                    external method or donation</h5>
                                <p class="text-grey-light font-weight-light mb-0">I received the payment through an
                                    external payment method.</p>
                            </div>
                        </div>
                    </a>
                </div>
                <!-- <div class="px-0 text-left">
                    <a (click)="setTypeTrade('donation')" class="nav-link cursor-pointer">
                        <div class="card p-3 w-100" [class.activeClick]="typeTrade === 'donation'">
                            <div class="mt-2">
                                <h5 class="card-title text-secondary font-weight-bold mb-1">This is a donation</h5>
                                <p class="text-grey-light font-weight-light mb-0">This trade is a donation, only fees will
                                    be applied.</p>
                            </div>
                        </div>
                    </a>
                </div> -->
            </div>
        </div>
        <hr>
        <div class="row pb-4">
            <div class="col-lg-3 col-md-2">
                <div class="text-left">
                    <button type="button" class="btn btn-outline text-primary mt-2 cursor-pointer" (click)="buttonClose()">Cancel</button>
                </div>
            </div>
            <div class="col-lg-6 col-md-4 pl-sm-4 px-0">
                <button type="button" (click)="nextStep()" class="btn-lg btn btn-primary font-weight-regular w-100 cursor-pointer">Next
                    step</button>
            </div>
        </div>
    </div>


    <div class="text-center" *ngIf="step == 5">
        <div class="row">
            <div class="col-lg-12 text-right">
                <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0 full-z-index cursor-pointer">
                    <img src="/assets/icons/close.svg">
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <h4 class="text-secondary font-weight-bold py-4 mb-0">3. You are about to start a trade!</h4>
            </div>
        </div>
        <div class="progress my-2" style="height: 3px;">
            <div class="progress-bar" role="progressbar" style="width: 75%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="d-sm-flex">
                    <div class="mt-3 ml-5 text-left">
                        <p class="font-weight-bold text-secondary mb-1">First name</p>
                        <p class="font-weight-normal text-grey-light">{{ first_name }}</p>
                    </div>
                    <div class="mt-3 ml-5 text-left">
                        <p class="font-weight-bold text-secondary mb-1">Last name</p>
                        <p class="font-weight-normal text-grey-light">{{ last_name }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 text-left">
                <div class="mt-1 ml-5">
                    <p class="font-weight-bold text-secondary mb-1">e-mail</p>
                    <p class="font-weight-normal text-grey-light">{{ email }}</p>
                </div>
                <div class="mt-3 ml-5" *ngIf="typeTrade == 'donation' && !firstTransaction">
                    <p class="font-weight-bold text-secondary mb-1">Payment Status</p>
                    <p class="font-weight-normal text-grey-light">Donation (But still in progress using the Alpha'a payments platform to payment fees)
                    </p>
                </div>
                <div class="mt-3 ml-5" *ngIf="typeTrade == 'donation' && firstTransaction">
                    <p class="font-weight-bold text-secondary mb-1">Payment Status</p>
                    <p class="font-weight-normal text-grey-light">Donation
                    </p>
                </div>
                <div class="mt-3 ml-5" *ngIf="typeTrade === 'platform'">
                    <p class="font-weight-bold text-secondary mb-1">Payment Status</p>
                    <p class="font-weight-normal text-grey-light">Not paid (In progress using Alpha’a Payments Platform)
                    </p>
                </div>
                <div class="mt-3 ml-5" *ngIf="typeTrade === 'external' && !firstTransaction">
                    <p class="font-weight-bold text-secondary mb-1">Payment Status</p>
                    <p class="font-weight-normal text-grey-light">External Paid (But still in progress using the Alpha'a payments platform to payment fees)
                    </p>
                </div>
                <div class="mt-3 ml-5" *ngIf="typeTrade === 'external' && firstTransaction">
                    <p class="font-weight-bold text-secondary mb-1">Payment Status</p>
                    <p class="font-weight-normal text-grey-light">External Paid
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 px-0">
                <div class="mt-3 ml-5">
                    <img src="/assets/icons/stop.png">
                </div>
            </div>
            <div class="col-lg-10 px-0">
                <div class="mt-3 ml-4 text-left">
                    <p class="text-grey-light font-weight-light" style="font-size: 0.8em;">Please, pay attention. Once the transfer
                        isinitiatied and the artwork is paid, there’s no way to cancel the trading.</p>
                </div>
            </div>
        </div>
        <hr>
        <div class="row pb-4">
            <div class="col-lg-3 col-md-2">
                <div class="text-left">
                    <button type="button" class="btn btn-outline text-primary mt-2 cursor-pointer" (click)="buttonClose()">Cancel</button>
                </div>
            </div>
            <div class="col-lg-6 col-md-4 pl-sm-4 px-0">
                <button type="button" (click)="nextStep()" class="btn-lg btn btn-primary font-weight-regular w-100 cursor-pointer">Next
                    step</button>
            </div>
        </div>
    </div>


    <div class="text-center" *ngIf="step == 6">
        <div class="row">
            <div class="col-lg-12 text-right">
                <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0 full-z-index cursor-pointer">
                    <img src="/assets/icons/close.svg">
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <h4 class="text-secondary font-weight-bold py-4 mb-0">Trade in progress</h4>
            </div>
        </div>
        <div class="progress my-2" style="height: 3px;">
            <div class="progress-bar" role="progressbar" style="width: 100%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="row">
            <div class="col-lg-12 mt-4">
                <img src="/assets/icons/progress.png">
                <h3 *ngIf="typeTrade == 'donation'" class="text-secondary font-weight-bold">Waiting for confirmation</h3>
                <h3 *ngIf="typeTrade != 'donation'" class="text-secondary font-weight-bold">Waiting for payment</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 text-left px-5 mt-4 mb-3">
                <p class="mb-1 trading">Temporary Trading Signature</p>
                <input class="bg-signature w-100" [value]="temporaryTradingSignature" type="text" required checked>
            </div>
        </div>
        <hr>
        <div class="mt-4 mb-4">
            <a (click)="nextStep()" class="btn-lg btn btn-primary font-weight-bold" style="width: 19em;">Done
            </a>
        </div>
    </div>

</div>