<div class="container-fluid folders-initial px-sm-5" *ngIf="viewMode === 'folder'">
    <div class="row px-2">
        <div class="col-lg-10 mt-4 pt-1 ">
            <p class="text-grey font-weight-light mb-0">Your Vault</p>
            <h1 class="text-primary font-weight-bold">Folders</h1>
        </div>
        <div class="col-lg-2 col-md-5 mt-5 text-right px-0">
            <button type="button" (click)="openCreateFolders()"  class="btn btn-outline-primary py-2 cursor-pointer" style="border: 2px solid;" data-toggle="modal" data-target="#exampleModalCenter">
                <h5 class="font-weight-regular mb-0"> Create a Folder</h5>
            </button>
        </div>
    </div>
    <hr>
    <app-artworks-track *ngIf="folders.length > 0" [folders]="folders"></app-artworks-track>
    <div *ngIf="folders.length == 0" class="text-center my-5 py-5">
        <h1>Create your first Folder</h1>
        <p>Use folders to organize or save artworks by client, room, etc. All folders are private unless you share them.</p>
        <button type="button" class="btn btn-primary" (click)="openCreateFolders()">Create Folder</button>
    </div>

</div>




<div class="container-fluid folders-initial px-sm-5" *ngIf="viewMode === 'details' && folder">
    <div class="row pt-1 px-3">
        <div class="col-lg-12 d-sm-flex justify-content-between mt-4">
            <div class="">
                <p class="text-grey font-weight-light mb-0">Your Vault / Lists</p>
                <h1 class="text-primary font-weight-bold mb-0">{{folder.title}}</h1>
                <small class="text-grey-light">{{folder.description}} </small>
                <p class="text-grey-light">items</p>
            </div>
            <div class="d-flex align-items-center">
                <button type="button"  (click)="openEditFolder(folder)" class="btn btn-outline-primary mr-3 py-sm-2 cursor-pointer" style="border: 2px solid;">Edit folder</button>
                <button *ngIf="auth.subscriptionActive.getValue()" type="button" routerLink="/dashboard-creator/artworks" class="btn btn-primary font-weight-regular py-2">Add an artwork</button>
                <button *ngIf="auth.institutionActive.getValue()" type="button" routerLink="/dashboard-institution/artworks" class="btn btn-primary font-weight-regular py-2">Add an artwork</button>
            </div>
        </div>
    </div>
    <hr>
    <app-artworks-lists-box [artworks]="folder.artworks" [folder]="folder"></app-artworks-lists-box>
</div>