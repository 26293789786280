import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class StorageService {
  constructor(
  ) {
  }
  get(key: any) {
    return new Promise((res, rej) => {
      res(typeof (localStorage) != 'undefined' ? localStorage.getItem(key) : false);
    })
  }
  set(key: any, value: any) {
    return new Promise((res, rej) => {
      res(typeof (localStorage) != 'undefined' ? localStorage.setItem(key, value) : false);
    })
  }
  delete(key: any) {
    return new Promise((res, rej) => {
      res(typeof (localStorage) != 'undefined' ? localStorage.removeItem(key) : false);
    })
  }
}
