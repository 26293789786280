<div>
    <div class="modal-header justify-content-center">
        <h2 class="text-secondary font-weight-bold mt-2">Resquest management</h2>
        <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0 p-1">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
    <div class="container modal-body">
        <div class="row justify-content-center mt-3">
            <p class="text-grey-light font-weight-light text-center p-3">Request an editor profile so you <br> can seamlessly edit your artist profiles. </p>
        </div>
        <div class="row d-flex justify-content-center align-items-center">
            <input class="mr-2" type="radio" id="css" name="fav_language" value="CSS">
            <label class="text-center text-grey-light font-weight-light mt-2" for="css">Don't ask me again for this artist</label>
        </div>
    </div>
    <div class="container mt-4">
        <div class="row modal-footer d-flex justify-content-start px-0">
            <div class="col-lg-3 px-0">
                <button type="submit" class="w-100 btn btn-md text-primary font-weight-regular text-left" (click)="buttonClose()">Cancel</button>
            </div>
            <div class="col-lg-4 px-0 ml-4">
                <button type="button" (click)="requestManagement()" class="w-100 btn btn-lg btn-primary font-weight-regular">Request</button>
            </div>
        </div>
    </div>
</div>