import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-modal-video-track',
  templateUrl: './modal-video-track.component.html',
  styleUrls: ['./modal-video-track.component.scss']
})
export class ModalVideoTrackComponent implements OnInit {
  @Input() title: string;
  public url: string;
  urlSafe: SafeResourceUrl;



  constructor(
    public modalService: NgbModal,
    public sanitizer: DomSanitizer
  ) {
   }

  ngOnInit(): void {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }


  buttonClose(){
    const modalRef = this.modalService.dismissAll();
   }
}
