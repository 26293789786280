<div class="container-fluid mt-5 pt-5">
    <div class="row d-sm-flex justify-content-between align-items-center px-4">
        <h2 class="text-secondary font-weight-bold ml-lg-3">Artworks</h2>
        <a *ngIf="this.auth.isAuthenticated.getValue() && verifyInsitituioOfArtist()" (click)="submitArtworkForThisArtist()" class="btn btn-sm btn-outline-primary btnSubmitArtwork my-3 mr-2">Submit artwork for this artist</a>
    </div>
    <div class="row d-flex px-4 mt-3">
        <ng-container>
            <div class="d-flex align-items-center flex-wrap box-artists mx-sm-3">
                <div *ngFor="let artwork of artworks" style="position: relative;margin-right: 36px;">
                    <div>
                        <!-- <app-dropdown-institution-artworks-from-artist class="app-dropdown-institution-artworks-from-artist"></app-dropdown-institution-artworks-from-artist> -->
                        <a href="/artworks/{{ artwork.id }}">
                            <img src="{{artwork.img}}" class="border-radius-default image-size">
                        </a>
                        <div class="text-left">
                            <p class="text-secondary font-weight-bold mt-1 mb-0">{{artwork.title}}</p>
                            <p class="text-secondary font-weight-regular mb-0" style="margin-top: -5px;">{{ helper.amountCurrencyFormat(artwork.original_price)}}</p>
                            <p class="text-grey-light font-weight-light small mb-4 pb-2" style="margin-top: -8px;">Uploaded {{ helper.formatDate(artwork.created_at)}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>