import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-launch',
  templateUrl: './event-launch.component.html',
  styleUrls: ['./event-launch.component.scss']
})
export class EventLaunchComponent implements OnInit {

  @Input() event: any 
  
  constructor() { }

  ngOnInit(): void {
  }

}
