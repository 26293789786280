<div class="container-fluid px-0 ">
    <div class="mt-4 ">
        <div class="card p-3 ">
            <div class="d-flex flex-wrap overflow">
                <ng-container>
                    <div class=" col-lg col-sm-6 col-md-3 d-flex p-0">
                            <div *ngFor="let collection of videos" style="position:relative">
                            <div class="col p-sm-3">
                                <button type="button" class="btn p-0" (click)="openVideo(collection.title, collection.url)"> <img src="{{collection.img}}" style=" border-radius: 9px; height: 150px; width: 150px; object-fit: cover;" alt=""></button>
                                <p class="text-secondary font-weight-bold mb-0 mt-2">{{ collection.title}}</p>
                                <p class="text-grey-light font-weight-light mb-0">{{ collection.duracao}}</p>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

