<div>
    <div class="modal-header justify-content-center rounded-0">
        <h2 class="text-secondary font-weight-bold text-center m-0">Insert this Artist</h2>
        <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0 p-2">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
    <div class="modal-body justify-content-center px-0 pb-0">
        <div class="row justify-content-center">
            <div class="col-lg-12 px-4 mt-2 mb-3">
                <h5 class="text-grey font-weight-light text-center px-3">
                    Use lists to organize or save artworks by client, room, etc. All lists are private unless you share them.
                </h5>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-12 px-4">
                    <ul ngbNav #nav="ngbNav" class="nav-tabs mb-2">
                        <li ngbNavItem *ngIf="urlSafeCropped">
                            <a ngbNavLink class="text-center">Cropped</a>
                            <ng-template ngbNavContent>
                                <div class="card mt-4 pb-3 mb-5">
                                    <iframe [src]="urlSafeCropped" scrolling="no" frameBorder="0" width="316" height="400"></iframe>
                                </div>
                            </ng-template>
                        </li>
                        <li ngbNavItem *ngIf="urlSafeNoCropped">
                            <a ngbNavLink class="text-center">No Cropped</a>
                            <ng-template ngbNavContent>
                                <div class="card mt-4 mb-5">
                                    <iframe [src]="urlSafeNoCropped" scrolling="no" frameBorder="0" width="316" height="400"></iframe>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" *ngIf="urlSafeNoCropped || urlSafeCropped"></div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center align-items-center show-code mx-0" >
            <div class="col-lg-10 py-4" *ngIf="urlSafeCropped && showCode">
                <p class="text-secondary font-weight-light mb-0">{{codeCropped}}</p>
            </div>
            <div class="col-lg-10 py-4" *ngIf="urlSafeNoCropped && showCode">
                <p class="text-secondary font-weight-light mb-0">{{codeNoCropped}}</p>
            </div>
        </div>
    </div>
    <div class="modal-footer d-block pb-0">
        <div class="d-sm-flex justify-content-between align-items-baseline py-3">
            <div class="d-flex px-sm-0 justify-content-sm-between ml-3">
                <label class="switch">
                    <input [(ngModel)]="showCode" type="checkbox" checked>
                    <span class="slider round"></span>
                </label>
                <p class="text-secondary font-weight-regular ml-2">Show code</p>
            </div>
            <div class="col-lg-5 col-md-5 px-sm-2">
                <button class="btn btn-lg btn-copy btn-primary w-100" (click)="copyEmbed()">Copy</button>
            </div>
        </div>
    </div>
</div>