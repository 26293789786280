import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';


interface collectors {
  title: string;
  text: string;
}
interface creators {
  title: string;
  text: string;
}
interface institutions {
  title: string;
  text: string;
}
interface general {
  title: string;
  text: string;
}


const textS1: collectors[] = [
  {
    title: 'What is a unique identifier?',
    text: 'The purchaser of an artwork automatically receives a unique identifier code that provides single-owner access to that artwork\’s token'
  },
  {
    title: 'How does blockchain protect ownership?',
    text: 'Our blockchain-secured verification mechanism creates a trust system in which an artwork and its digital  token must have the same owner in order to have any trading value.'
  },
  {
    title: 'Can I access my complete collection?',
    text: 'Yes. You can access your entire body of work in one place. Alphaa.io provides you with everything you need to know about each artwork’s provenance.'
  },
  {
    title: 'How do I transfer ownership?',
    text: 'Alphaa.io offers easy trading by transferring ownership in three steps: requesting payment, receiving payment and verification through a two factor authentication that ensures the transaction is secure.'
  }
];

const textS2: creators[] = [
  {
    title: 'How can I track the value of my artwork once I sell it?',
    text: 'Once your artworks are certified through Alphaa.io, you can access your entire body of work in one place. Alphaa.io provides you with everything you need to know about each artwork’s provenance. You can trace a work’s full chain of custody and track where it is in the world with a few simple clicks.'
  },
  {
    title: 'What is the resale royalty?',
    text: 'Artists receive a percentage of sales from all secondary market sales. Instead of auction houses and collectors keeping all revenue from resale transactions, blockchain-verification and tracking ensure that   artists always receive royalties from secondary market sales.'
  },
  {
    title: 'If I already have my original work in a gallery, can I still sell print editions through blockchain?',
    text: 'Yes, because Alphaa.io is a certification tool, you can sell original works or prints if you want to be part of alphaa inc (add button)'
  },
  {
    title: 'How do I upload an artwork?',
    text: 'For physical works, artworks that have a physical form such as paintings and sculptures, please upload a high res digital version of the item which will be converted into an NFT. For digital works, artworks that only exist in digital form such as jpegs, mpegs, etc., please upload the final art which will be converted into an NFT.'
  },
  {
    title: 'How are payments and fees handled?',
    text: 'Artist and gallery commissions are all built into each contract, so payment will be transferred automatically to the appropriate party once a sale is made. The contract has also recorded the artist’s resale royalties percentage, which will be paid upon sale on the secondary market.'
  },

];


const textS3: institutions[] = [
  {
    title: 'How are NFTs benefitting museums?',
    text: 'Institutions can also build in resale royalties for participating artists to add an additional source of revenue.'
  },
  {
    title: 'If my artist leaves for a bigger gallery do I still receive resale royalties?',
    text: 'Yes, you still receive resale royalties from any NFT by the artist that you sold while they were represented by your gallery.'
  },
  {
    title: 'Can I apply resale royalties to another party besides the gallery and the artists?',
    text: 'Yes, we offer the option to include a resale royalty to support any charity or nonprofit of your choice.'
  },
  {
    title: 'How do institution royalties work?',
    text: 'Institution royalties involve you in your artist\'s career for the long term. Instead of auction houses and collectors keeping all revenue from resale transactions, blockchain-verification and tracking ensure that artists and institutions always receive royalties from secondary market sales.'
  },
  {
    title: 'What percentage can institutions set for royalties?',
    text: 'Institutions can set a royalty of up to ten percent to receive a percentage of sales from all secondary market sales.'
  },

];



const textS4: general[] = [
  {
    title: 'How does Alphaa.io differ from a traditional NFT platform?',
    text: 'Alphaa.io offers a unique, hybrid model, creating digital clones associated with original, physical artworks.'
  },
  {
    title: 'How does currency work on the platform?',
    text: 'Alphaa.io does not require any  complicated meta wallets. Art assets are linked to a Collector\’s Digital Art Vault and pricing of the artwork is offered in fiat currency (such as USD), instead of crypto. (NOTE: I would insert which specific cryptocurrencies and fiat currencies are currently accepted).'
  },
  {
    title: 'What does one token get me on the platform?',
    text: ''
  },
  {
    title: 'Does Alphaa.io accept crypto payments?',
    text: 'Yes, we currently accept every currency. '
  },
  {
    title: 'What is the purpose of the QR code?',
    text: 'A QR code on the back of an edition links back to the Alphaa.io platform for the artist or collector who owns the work  to transfer ownership.'
  },
  {
    title: 'What is a nonfungible token?',
    text: 'A nonfungible token means it is unique to one specific owner'
  },
  {
    title: 'What is a fungible token?',
    text: 'Bitcoin is a fungible token, you can switch one for another and have the same thing.'
  },
  {
    title: 'What is a semi-fungible token?',
    text: 'Editions are an example of a semi-fungible token.  Semi-fungible tokens are a game changer for the entire collectibles industry because they can be traded via fractional ownership. For instance,if you have something that is unique but evenly certified or semi-unique, then you can have fractional ownership of assets.'
  },
  {
    title: 'Are our NFTs associated with crypto currency?',
    text: 'No, it has nothing to do with cryptocurrency. Our platform is disconnecting currency from cryptocurrency because we want the currency to be a stable source of value. Even though there are a lot of NFTs that are running on Ethereum, currently the biggest cryptocurrency that is priced in crypto, an NFT can be associated with any fiat currency (ie USD, etc).'
  },
  {
    title: 'What is blockchain and how does it work?',
    text: 'Blockchain is a system of recording information through a method that makes it impossible to alter, hack, or cheat the system. Each block in the chain is unique and contains a number of transactions, and each time a new transaction occurs on the blockchain, a record of that transaction is added to each participant\'s ledger. Blockchain can be used as a ledger for certification, for provenance, and for cataloging artworks. The blockchain is a system that can only move forward, it can never move backwards in a transaction. If you start developing your different implications on the blockchain, they function like the apps on a phone: some apps generate images, other apps can provide fractional ownership.'
  },
  {
    title: 'Do NFTs extend past the art world?',
    text: 'Yes, NFTs can apply to any unit that is unique and non-interchangeable. Alphaa.io is only the starting point of our rollout, we are beginning to white-label our proprietary tech to other collectible industries and brands. Despite art frequently being seen as a niche, standalone sector, the conversation about blockchain and the way it is implemented within this industry may have extensive implications for the rest of the economy.'
  },
  {
    title: 'How are NFTs transforming the art world?',
    text: 'Example: Urs Fisher’s NFT.'
  },
  // {
  //   title: 'How do NFTs add value?',
  //   text: ''
  // },
  // {
  //   title: 'How do NFTs add transparency?',
  //   text: ''
  // },
  // {
  //   title: 'How does blockchain log the full chain of ownership?',
  //   text: ''
  // },
  {
    title: 'Can anyone join the platform?',
    text: 'Yes, anyone can join.'
  },
  {
    title: 'Can anyone make an NFT?',
    text: 'Yes, you can make an NFT if you are an established artist or if it is your first time selling an artwork.'
  },
  {
    title: 'What are Physical Artworks?',
    text: 'Physical artworks are actual art pieces that have a physical form such as paintings and sculptures.'
  },
  {
    title: 'What are Digital Artworks?',
    text: 'Digital artworks are works that only exist in digital form such as jpegs, mpegs, etc.'
  },
  {
    title: 'What is a Digital Token?',
    text: 'Each artwork uploaded is linked to a digital token that verifies its identity. An artwork and its digital token must have the same owner in order to have any trading value. When someone purchases your artwork, they automatically receive a unique identifier code that provides single-owner access to that artwork’s token.'
  },

];






@Component({
  selector: 'app-learn',
  templateUrl: './learn.component.html',
  styleUrls: ['./learn.component.scss']
})
export class LearnComponent implements OnInit {
  texts1 = textS1;
  texts2 = textS2;
  texts3 = textS3;
  texts4 = textS4;
  // public searchLearn: any;
  // invites: any;
  // invitesData: any;
  filtro: string = ''

  constructor(
    public api: ApiService,

  ) { }

  ngOnInit(): void {
    
  }

  // searchLearns(): void{
  //   this.invites = this.invitesData
  //   if(this.searchLearn != '' && typeof this.searchLearn != 'undefined'){
  //     this.invites = this.invitesData.filter((value: any)=>{if(value.creator.full_name.indexOf(this.searchLearn) > -1)return value;})
  //   }
  // }
}
