import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-modal-add-to-folder',
  templateUrl: './modal-add-to-folder.component.html',
  styleUrls: ['./modal-add-to-folder.component.scss']
})
export class ModalAddToFolderComponent implements OnInit {

  @Input() public artwork_id: number;
  public folders: any;
  public selectedFolder: any;
  public addTofolder: any;
  public id: number;

  constructor(
    public router: Router,
    public api: ApiService,
    public route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.api.get('artworks/folders').then((response) => {
      this.folders = response
    })
  }

  async createAddtoFolder(folder_id: number, artwork_id:number) {
      let payload = {
        artwork_id: artwork_id,
      }

      this.api.post('artworks/folders/'+folder_id, payload).then((response) => {
          const modalRef = this.modalService.dismissAll();
          location.reload()
      })
    }
 
    buttonClose(){
      const modalRef = this.modalService.dismissAll();
     }
}