export const countries = [
  { name: "Afghanistan" },
  { name: "Albania" },
  { name: "Algeria" },
  { name: "Andorra" },
  { name: "Angola" },
  { name: "Antigua" },
  { name: "Argentina" },
  { name: "Armenia" },
  { name: "Australia" },
  { name: "Austria" },
  { name: "Azerbaijan" },
  { name: "Bahamas" },
  { name: "Bahrain" },
  { name: "Bangladesh" },
  { name: "Barbados" },
  { name: "Belarus" },
  { name: "Belgium" },
  { name: "Belize" },
  { name: "Benin" },
  { name: "Bhutan" },
  { name: "Bolivia" },
  { name: "Bosnia" },
  { name: "Botswana" },
  { name: "Brazil" },
  { name: "Brunei" },
  { name: "Bulgaria" },
  { name: "Burkina" },
  { name: "Burundi" },
  { name: "Côte" },
  { name: "Cabo" },
  { name: "Cambodia" },
  { name: "Cameroon" },
  { name: "Canada" },
  { name: "Central" },
  { name: "Chad" },
  { name: "Chile" },
  { name: "China" },
  { name: "Colombia" },
  { name: "Comoros" },
  { name: "Congo" },
  { name: "Costa" },
  { name: "Croatia" },
  { name: "Cuba" },
  { name: "Cyprus" },
  { name: "Czechia" },
  { name: "Democratic" },
  { name: "Denmark" },
  { name: "Djibouti" },
  { name: "Dominica" },
  { name: "Dominican" },
  { name: "Ecuador" },
  { name: "Egypt" },
  { name: "El" },
  { name: "Equatorial" },
  { name: "Eritrea" },
  { name: "Estonia" },
  { name: "Eswatini" },
  { name: "Ethiopia" },
  { name: "Fiji" },
  { name: "Finland" },
  { name: "France" },
  { name: "Gabon" },
  { name: "Gambia" },
  { name: "Georgia" },
  { name: "Germany" },
  { name: "Ghana" },
  { name: "Greece" },
  { name: "Grenada" },
  { name: "Guatemala" },
  { name: "Guinea" },
  { name: "Guinea" },
  { name: "Guyana" },
  { name: "Haiti" },
  { name: "Holy" },
  { name: "Honduras" },
  { name: "Hungary" },
  { name: "Iceland" },
  { name: "India" },
  { name: "Indonesia" },
  { name: "Iran" },
  { name: "Iraq" },
  { name: "Ireland" },
  { name: "Israel" },
  { name: "Italy" },
  { name: "Jamaica" },
  { name: "Japan" },
  { name: "Jordan" },
  { name: "Kazakhstan" },
  { name: "Kenya" },
  { name: "Kiribati" },
  { name: "Kuwait" },
  { name: "Kyrgyzstan" },
  { name: "Laos" },
  { name: "Latvia" },
  { name: "Lebanon" },
  { name: "Lesotho" },
  { name: "Liberia" },
  { name: "Libya" },
  { name: "Liechtenstein" },
  { name: "Lithuania" },
  { name: "Luxembourg" },
  { name: "Madagascar" },
  { name: "Malawi" },
  { name: "Malaysia" },
  { name: "Maldives" },
  { name: "Mali" },
  { name: "Malta" },
  { name: "Marshall" },
  { name: "Mauritania" },
  { name: "Mauritius" },
  { name: "Mexico" },
  { name: "Micronesia" },
  { name: "Moldova" },
  { name: "Monaco" },
  { name: "Mongolia" },
  { name: "Montenegro" },
  { name: "Morocco" },
  { name: "Mozambique" },
  { name: "Myanmar" },
  { name: "Namibia" },
  { name: "Nauru" },
  { name: "Nepal" },
  { name: "Netherlands" },
  { name: "New" },
  { name: "Nicaragua" },
  { name: "Niger" },
  { name: "Nigeria" },
  { name: "North" },
  { name: "North" },
  { name: "Norway" },
  { name: "Oman" },
  { name: "Pakistan" },
  { name: "Palau" },
  { name: "Palestine" },
  { name: "Panama" },
  { name: "Papua" },
  { name: "Paraguay" },
  { name: "Peru" },
  { name: "Philippines" },
  { name: "Poland" },
  { name: "Portugal" },
  { name: "Qatar" },
  { name: "Romania" },
  { name: "Russia" },
  { name: "Rwanda" },
  { name: "Saint" },
  { name: "Saint" },
  { name: "Saint" },
  { name: "Samoa" },
  { name: "San" },
  { name: "Sao" },
  { name: "Saudi" },
  { name: "Senegal" },
  { name: "Serbia" },
  { name: "Seychelles" },
  { name: "Sierra" },
  { name: "Singapore" },
  { name: "Slovakia" },
  { name: "Slovenia" },
  { name: "Solomon" },
  { name: "Somalia" },
  { name: "South" },
  { name: "South" },
  { name: "South" },
  { name: "Spain" },
  { name: "Sri" },
  { name: "Sudan" },
  { name: "Suriname" },
  { name: "Sweden" },
  { name: "Switzerland" },
  { name: "Syria" },
  { name: "Tajikistan" },
  { name: "Tanzania" },
  { name: "Thailand" },
  { name: "Timor" },
  { name: "Togo" },
  { name: "Tonga" },
  { name: "Trinidad" },
  { name: "Tunisia" },
  { name: "Turkey" },
  { name: "Turkmenistan" },
  { name: "Tuvalu" },
  { name: "Uganda" },
  { name: "Ukraine" },
  { name: "United" },
  { name: "United" },
  { name: "United" },
  { name: "Uruguay" },
  { name: "Uzbekistan" },
  { name: "Vanuatu" },
  { name: "Venezuela" },
  { name: "Vietnam" },
  { name: "Yemen" },
  { name: "Zambia" },
  { name: "Zimbabwe" },
]

export const shortCountries = [
  {country: "AF"	},
  {country: "AL"	},
  {country: "DZ"	},
  {country: "AS"	},
  {country: "AD"	},
  {country: "AO"	},
  {country: "AI"	},
  {country: "AQ"	},
  {country: "AG"	},
  {country: "AR"	},
  {country: "AM"	},
  {country: "AW"	},
  {country: "AU"	},
  {country: "AT"	},
  {country: "AZ"	},
  {country: "BS"	},
  {country: "BH"	},
  {country: "BD"	},
  {country: "BB"	},
  {country: "BY"	},
  {country: "BE"	},
  {country: "BZ"	},
  {country: "BJ"	},
  {country: "BM"	},
  {country: "BT"	},
  {country: "BO"	},
  {country: "BQ"	},
  {country: "BA"	},
  {country: "BW"	},
  {country: "BV"	},
  {country: "BR"	},
  {country: "IO"	},
  {country: "BN"	},
  {country: "BG"	},
  {country: "BF"	},
  {country: "BI"	},
  {country: "CV"	},
  {country: "KH"	},
  {country: "CM"	},
  {country: "CA"	},
  {country: "KY"	},
  {country: "CF"	},
  {country: "TD"	},
  {country: "CL"	},
  {country: "CN"	},
  {country: "CX"	},
  {country: "CC"	},
  {country: "CO"	},
  {country: "KM"	},
  {country: "CD"	},
  {country: "CG"	},
  {country: "CK"	},
  {country: "CR"	},
  {country: "HR"	},
  {country: "CU"	},
  {country: "CW"	},
  {country: "CY"	},
  {country: "CZ"	},
  {country: "CI"	},
  {country: "DK"	},
  {country: "DJ"	},
  {country: "DM"	},
  {country: "DO"	},
  {country: "EC"	},
  {country: "EG"	},
  {country: "SV"	},
  {country: "GQ"	},
  {country: "ER"	},
  {country: "EE"	},
  {country: "SZ"	},
  {country: "ET"	},
  {country: "FK"	},
  {country: "FO"	},
  {country: "FJ"	},
  {country: "FI"	},
  {country: "FR"	},
  {country: "GF"	},
  {country: "PF"	},
  {country: "TF"	},
  {country: "GA"	},
  {country: "GM"	},
  {country: "GE"	},
  {country: "DE"	},
  {country: "GH"	},
  {country: "GI"	},
  {country: "GR"	},
  {country: "GL"	},
  {country: "GD"	},
  {country: "GP"	},
  {country: "GU"	},
  {country: "GT"	},
  {country: "GG"	},
  {country: "GN"	},
  {country: "GW"	},
  {country: "GY"	},
  {country: "HT"	},
  {country: "HM"	},
  {country: "VA"	},
  {country: "HN"	},
  {country: "HK"	},
  {country: "HU"	},
  {country: "IS"	},
  {country: "IN"	},
  {country: "ID"	},
  {country: "IR"	},
  {country: "IQ"	},
  {country: "IE"	},
  {country: "IM"	},
  {country: "IL"	},
  {country: "IT"	},
  {country: "JM"	},
  {country: "JP"	},
  {country: "JE"	},
  {country: "JO"	},
  {country: "KZ"	},
  {country: "KE"	},
  {country: "KI"	},
  {country: "KP"	},
  {country: "KR"	},
  {country: "KW"	},
  {country: "KG"	},
  {country: "LA"	},
  {country: "LV"	},
  {country: "LB"	},
  {country: "LS"	},
  {country: "LR"	},
  {country: "LY"	},
  {country: "LI"	},
  {country: "LT"	},
  {country: "LU"	},
  {country: "MO"	},
  {country: "MG"	},
  {country: "MW"	},
  {country: "MY"	},
  {country: "MV"	},
  {country: "ML"	},
  {country: "MT"	},
  {country: "MH"	},
  {country: "MQ"	},
  {country: "MR"	},
  {country: "MU"	},
  {country: "YT"	},
  {country: "MX"	},
  {country: "FM"	},
  {country: "MD"	},
  {country: "MC"	},
  {country: "MN"	},
  {country: "ME"	},
  {country: "MS"	},
  {country: "MA"	},
  {country: "MZ"	},
  {country: "MM"	},
  {country: "NA"	},
  {country: "NR"	},
  {country: "NP"	},
  {country: "NL"	},
  {country: "NC"	},
  {country: "NZ"	},
  {country: "NI"	},
  {country: "NE"	},
  {country: "NG"	},
  {country: "NU"	},
  {country: "NF"	},
  {country: "MP"	},
  {country: "NO"	},
  {country: "OM"	},
  {country: "PK"	},
  {country: "PW"	},
  {country: "PS"	},
  {country: "PA"	},
  {country: "PG"	},
  {country: "PY"	},
  {country: "PE"	},
  {country: "PH"	},
  {country: "PN"	},
  {country: "PL"	},
  {country: "PT"	},
  {country: "PR"	},
  {country: "QA"	},
  {country: "MK"	},
  {country: "RO"	},
  {country: "RU"	},
  {country: "RW"	},
  {country: "RE"	},
  {country: "BL"	},
  {country: "SH"	},
  {country: "KN"	},
  {country: "LC"	},
  {country: "MF"	},
  {country: "PM"	},
  {country: "VC"	},
  {country: "WS"	},
  {country: "SM"	},
  {country: "ST"	},
  {country: "SA"	},
  {country: "SN"	},
  {country: "RS"	},
  {country: "SC"	},
  {country: "SL"	},
  {country: "SG"	},
  {country: "SX"	},
  {country: "SK"	},
  {country: "SI"	},
  {country: "SB"	},
  {country: "SO"	},
  {country: "ZA"	},
  {country: "GS"	},
  {country: "SS"	},
  {country: "ES"	},
  {country: "LK"	},
  {country: "SD"	},
  {country: "SR"	},
  {country: "SJ"	},
  {country: "SE"	},
  {country: "CH"	},
  {country: "SY"	},
  {country: "TW"	},
  {country: "TJ"	},
  {country: "TZ"	},
  {country: "TH"	},
  {country: "TL"	},
  {country: "TG"	},
  {country: "TK"	},
  {country: "TO"	},
  {country: "TT"	},
  {country: "TN"	},
  {country: "TR"	},
  {country: "TM"	},
  {country: "TC"	},
  {country: "TV"	},
  {country: "UG"	},
  {country: "UA"	},
  {country: "AE"	},
  {country: "GB"	},
  {country: "UM"	},
  {country: "US"	},
  {country: "UY"	},
  {country: "UZ"	},
  {country: "VU"	},
  {country: "VE"	},
  {country: "VN"	},
  {country: "VG"	},
  {country: "VI"	},
  {country: "WF"	},
  {country: "EH"	},
  {country: "YE"	},
  {country: "ZM"	},
  {country: "ZW"	},
  {country: "AX"	},
]