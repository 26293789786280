<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12 px-0" id="home-collect">
            <div class="img-collect collect d-sm-flex flex-sm-column justify-content-center align-items-center">
                <div class="mt-sm-5 pt-4">
                    <h1 class="text-white font-weight-light text-center mb-0 mx-lg-5 px-lg-5 display-2">The Easiest Way to Buy and Sell Digital Art</h1>
                </div>
                <div class="text-center px-lg-5 mx-lg-5 mb-3 mt-4">
                    <p class="text-white px-lg-5 mx-sm-5 mx-1 font-weight-light your-digital">Purchase, authenticate, manage and trade digital art assets. Guarantee <br> artwork ownership, track full transactional history and <br>easily trade digital artworks using cash or crypto.</p>
                </div>
                <div class="justify-content-center d-flex">
                    <button *ngIf="!auth.isAuthenticated.getValue()" type="button" [routerLink]="['/auth/register']" class="btn btn-lg btn-primary d-flex align-items-center justify-content-center btn-start">Start your collection</button>
                    <button *ngIf="auth.isAuthenticated.getValue()" type="button" [routerLink]="['/dashboard']" class="btn btn-lg btn-primary d-flex align-items-center justify-content-center btn-start">Start your collection</button>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid digital-art">
    <div class="row">
        <div class="col-lg-6 mt-sm-5 pt-sm-4 pb-0 px-sm-4">
            <div class="home-collect collect">
                <div>
                    <h2 class="text-secondary mt-xl-5 pt-5 pr-xl-5 mx-xl-5 display-4" style="font-weight: 400;">Your Personalized, Digital Art Vault</h2>
                </div>
                <div class="mr-5">
                    <p class="text-grey-link mt-3 px-xl-5 your-digital">
                        Securely store, authenticate and trade your digital artworks in your Digital Art Vault. Our signature platform guarantees edition ownership, displays full transactional history and provides easy trading using cash or crypto. Our blockchain technology
                        makes the entire transaction secure.
                    </p>
                </div>
                <div class="px-xl-5 my-5">
                    <div class="media">
                        <img src="assets/icons/check.png" style="width: 1.5em;">
                        <p class="ml-4 text-primary font-weight-bold mb-2" style="font-size: 15px !important;">Directly trade with other collectors</p>
                    </div>
                    <div class="media">
                        <img src="assets/icons/check.png" style="width: 1.5em;">
                        <p class="ml-4 text-primary font-weight-bold mb-2" style="font-size: 15px !important;">Purchase and sell works without the reliance on cryptocurrency</p>
                    </div>
                    <div class="media">
                        <img src="assets/icons/check.png" style="width: 1.5em;">
                        <p class="ml-4 text-primary font-weight-bold mb-2" style="font-size: 15px !important;">Use our real time market value to track your net worth</p>
                    </div>
                    <div class="media">
                        <img src="assets/icons/check.png" style="width: 1.5em;">
                        <p class="ml-4 text-primary font-weight-bold mb-2" style="font-size: 15px !important;">Manage your entire collection in one space</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 px-0">
            <div>
                <img class="bg-cofre" src="/assets/img/cofre-two.png" style="width: 100%; filter: brightness(0.6);">
            </div>
        </div>

    </div>
</div>

<div class="container-fluid px-sm-5">
    <div class="row mt-5">
        <div class="col-lg-6 mt-xl-5 pt-xl-5 px-0 ml-sm-5 d-flex justify-content-center">
            <img class="img-card" src="/assets/img/card-img.png">
        </div>
        <div class="col-lg-5 my-xl-5 py-5 ml-sm-4 pl-5">
            <div class="mt-xl-5 pt-xl-5">
                <h4 class="font-weight-bold text-secondary-light mt-5">All in one place</h4>
            </div>
            <div class="collect">
                <h2 class="text-secondary display-4 mb-4" style="font-weight: 400;">Real-time <br> Market Data</h2>
                <p class="text-grey-link your-digital pr-xl-5 mr-xl-5">Once your artworks are certified through Alphaa.io, you can access your entire body of work in one place. Alphaa.io provides you with everything you need to know about each artwork’s provenance. You can trace a work’s full chain of custody
                    and track where it is in the world with a few simple clicks. </p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid d-flex justify-content-center px-sm-5 trading">
    <div class="row mt-lg-5">
        <div class="col-lg-5 mt-xl-5 pt-xl-5 px-sm-0 home-collect-two ml-lg-5">
            <div>
                <div class="mt-xl-5 pt-xl-5 ml-xl-5">
                    <h4 class="font-weight-bold text-secondary-light mt-sm-3 pt-4">Trading</h4>
                </div>
                <div class="collect">
                    <h2 class="text-secondary display-4 mb-4 ml-xl-5" style="font-weight: 400;">Three Clicks <br> to Buy or Sell</h2>
                    <p class="text-grey-link your-digital ml-xl-5 pr-xl-5 mr-sm-5">Alphaa.io offers easy trading through transferring ownership in three steps: requesting payment, receiving payment and verification via a two factor authentication that ensures the transaction is secure.</p>
                </div>
            </div>
        </div>
        <div class="col-lg-6 my-xl-5 py-sm-4 my-3">
            <img src="/assets/img/trade.png" style="object-fit: none;" class="mt-xl-5 pt-xl-5 w-100">
        </div>
    </div>
</div>
<div class="container-fluid pb-5 px-0">
    <div class="col-lg-12 text-center pb-5 px-0">
        <div class="mt-sm-5 pt-sm-5">
            <h4 class="font-weight-bold text-secondary-light mt-5 mb-0">Collector Dashboard</h4>
        </div>
        <div class="collect">
            <h2 class="text-secondary display-4" style="font-weight: 400;">Track Your Collection's Worth</h2>
            <p class="text-grey-link text-center font-weight-regular">
                Portfolio management with real time market value for your artworks helps you track <br> your net worth and make informed decisions about when to buy and sell works.
            </p>
        </div>
        <div>
            <img class="w-100" src="/assets/img/grafico-home.png" alt="gráfico">
        </div>
    </div>
</div>

<div class="container-fluid bg-primary footer-home">
    <div class="row justify-content-center py-4">
        <div class="col-lg-5 my-md-5 py-md-5">
            <div class="mt-xl-5 pt-xl-5">
                <h3 class="font-weight-bold text-white">Alpha’a.io</h3>
            </div>
            <div>
                <h4 class="text-white font-weight-light">NFTs for REAL</h4>
                <p class="text-white font-weight-light pr-sm-5 mr-sm-5">Alpha'a is an art authenticity verification platform, guaranteeing edition ownership and transactional history, as well as ensuring easy trading. Buy, collect, store and trade with confidence.</p>
            </div>
            <div class="pt-3">
                <a [routerLink]="['/about']" class="btn btn-lg btn-white border-0 text-primary font-weight-bold cursor-pointer btn-home-about">About us</a>
            </div>
        </div>

        <div class="col-lg-4" id="alpha-platform">
            <img src="/assets/img/laptop.png" class="laptop" alt="notbook" style="width: 143.7%;margin-top: -23px;">
        </div>
    </div>
</div>