<div class="container-fluid dash-initial px-sm-5" *ngIf="viewMode === 'list'">
    <div class="row pt-1 px-2">
        <div class="col-lg-12 d-sm-flex justify-content-between">
            <div class="mt-4 ml-1">
                <p class="mb-0 text-grey-light font-weight-light">Your Vault</p>
                <h1 class="text-primary font-weight-bold">Lists</h1>
            </div>
            <div class="mt-sm-5 ml-sm-5 d-flex align-items-center">
                <button type="button" (click)="openCreateList()"  class="btn btn-outline-primary py-2 cursor-pointer" style="border: 2px solid;" data-toggle="modal" data-target="#exampleModalCenter">
                    <h5 class="font-weight-regular mb-0"> Create a List</h5>
                </button>
            </div>
        </div>
    </div>
    <hr>
    <app-artworks-track *ngIf="lists.length > 0" [lists]="lists"></app-artworks-track>
    <div *ngIf="lists.length == 0" class="text-center py-5">
        <h1>Create your first List</h1>
        <p>Use lists to organize or save artworks by client, room, etc. All lists are private unless you share them.</p>
        <button type="button" class="btn btn-primary" (click)="openCreateList()">Create List</button>
    </div>
</div>

<div class="container-fluid dash-initial px-sm-5" *ngIf="viewMode === 'details'">
    <div class="row pt-1 px-3">
        <div class="col-lg-12 d-sm-flex justify-content-between mt-4">
            <div class="">
                <p class="text-grey-light font-weight-light mb-0">Your Vault / Lists</p>
                <h1 class="text-primary font-weight-bold mb-0">{{ list.title }}</h1>
                <small class="text-grey-light font-weight-light">{{ list.description }}</small>
                <p class="text-grey-light font-weight-light my-1">{{ list.versions.length }} items</p>
            </div>
            <div class="d-sm-flex align-items-center">
                <button type="button" (click)="openEditList(list)"  class="btn btn-outline-primary mr-3 py-sm-2 cursor-pointer" style="border: 2px solid;font-size: 18px;">Edit list</button>
                <button type="button" routerLink="/purchased-works" class="btn btn-primary font-weight-regular py-2" style="width: 194px; font-size: 18px;">Add an artwork</button>
            </div>
        </div>
    </div>
    <hr>
    <app-artworks-lists-box [versions]="list.versions" [list]="list"></app-artworks-lists-box>
</div>