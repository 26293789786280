<div>
    <div class="modal-header justify-content-center">
        <h2 class="text-secondary font-weight-bold py-2">Institution royalties</h2>
        <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0 p-1">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
    <div class="container modal-body text-center">
        <div class="row">
            <div class="col-lg-12 mt-2">
                <img src="/assets/icons/edition.png" alt="edition">
                <h3 class="font-weight-normal text-secondary mt-2">What are and how they work?</h3>
                <p class="text-grey-light font-weight-light mt-4">Institution royalties involve you in your artist's career for the long term. Instead of auction houses and collectors keeping all revenue from resale transactions, blockchain-verification and tracking ensure that artists and institutions always receive royalties from secondary market sales. Each time you sell an artwork, you will receive a percentage of the sale, with no limit on potential royalties made on collector-to-collector sales. You can set a percentage of up to 10%. The standard percentage institutions usually set is 2%.</p>
            </div>
        </div>
        <div class="row modal-footer d-flex justify-content-center mt-5">
            <div class="col-lg-7">
                <div>
                    <button  type="submit" class="btn btn-lg btn-primary font-weight-regular w-100">Understood</button>
                </div>
            </div>
        </div>
    </div>
</div>