<div ngbDropdown class="d-inline-block">
    <button type="button" class="btn bg-light text-grey font-weight-light mt-1 p-0 px-3" id="dropdownBasic1" style="height: 32px !important; border-radius: 9px !important;" ngbDropdownToggle>Order by <img class="pl-2" src="../../../assets/icons/orderby.png" alt=""></button>
<div ngbDropdownMenu="dropdownBasic" class="boxMenuDropdrown mt-1">
    <a ngbDropdownItem class="dropDownContent borderRadiusUpBox text-grey-link">institution-1</a>
    <hr class="m-0 p-0 gray">
    <a ngbDropdownItem class="dropDownContent text-grey-link">institution-2</a>
    <hr class="m-0 p-0 gray">
    <a ngbDropdownItem class="dropDownContent text-grey-link">institution-3</a>
    <hr class="m-0 p-0 gray">
    <a ngbDropdownItem class="dropDownContent text-grey-link">institution-4</a>
    <hr class="m-0 p-0 gray">
    <a ngbDropdownItem class="dropDownContent borderRadiusDownBox text-grey-link">institution-5</a>
</div>