import { Component, Input, OnInit } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-home-creator',
  templateUrl: './home-creator.component.html',
  styleUrls: ['./home-creator.component.scss']
})
export class HomeCreatorComponent implements OnInit {
  
  @Input() viewMode: any;
  @Input() id: number;
  @Input() plansMonthly: any;
  @Input() plansYearly: any;
  @Input() seletedPlan: any;
  @Input() card: any = {};
  @Input() years: [] = [];
  @Input() months: [] = [];

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public api: ApiService,
    public auth: AuthService,

    ) {}
    
    ngOnInit(): void {}
    
  }