import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-auth',
  templateUrl: './modal-auth.component.html',
  styleUrls: ['./modal-auth.component.scss']
})
export class ModalAuthComponent implements OnInit {

  constructor(
    public modalService: NgbModal

  ) { }

  ngOnInit(): void {
  }

  buttonClose(){
    const modalRef = this.modalService.dismissAll();
   }
}
