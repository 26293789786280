import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-share-button',
  templateUrl: './share-button.component.html',
  styleUrls: ['./share-button.component.scss']
})
export class ShareButtonComponent implements OnInit {
  public urlBase = environment.URL_BASE;
  @Input() public socials: any;
  @Input() mode: any;
  @Input() shareMode: string;
  @Input() currentURL: string;


  socialData = {
    instagram: {
      "src": "/assets/icons/icon-instagram.png",
      "href": "",
    },
    twitter: {
      "src": "/assets/icons/icon-twitter.png",
      "href": "https://twitter.com/intent/tweet?text=",
    },
    facebook: {
      "src": "/assets/icons/icon-facebook.png",
      "href": "https://www.facebook.com/sharer/sharer.php?u="
    },
    whatsapp: {
      "src": "/assets/icons/icon-whatsapp.png",
      "href": "https://api.whatsapp.com/send?text="
    },
    linkedin: {
      "src": "/assets/icons/icon-in.png",
      "href": "https://www.linkedin.com/shareArticle?mini=true&url="
    }
  }

  constructor(public auth: AuthService) { }

  ngOnInit(): void {
    if(this.socials == 'all'){
     this.socials = ['facebook', 'whatsapp', 'twitter'] 
    }
  }

}