import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-button-institution-progress',
  templateUrl: './button-institution-progress.component.html',
  styleUrls: ['./button-institution-progress.component.scss']
})
export class ButtonInstitutionProgressComponent implements OnInit {

  constructor(
    public api: ApiService,
  ) { }

  ngOnInit(): void {
  }

}
