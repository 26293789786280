import { Component, OnInit } from '@angular/core';

interface Institution {
  name: string;
  price: string;
  date: string;
  img: string;
}
const OBRAS: Institution[] = [
  {
    name: 'Title',
    price: 'Los Angeles, USA',
    date: '03/09/2021',
    img:'/assets/img/arte-4.png', 

  },
  {
    name: 'Title',
    price: 'Madri',
    date: '03/09/2021',
    img:'/assets/img/arte-5.png', 
  
  },
  {
    name: 'Title',
    price: 'Argentina',
    date: '03/09/2021',
    img:'/assets/img/arte-6.png', 
  },

];


@Component({
  selector: 'app-artworks-track-institution',
  templateUrl: './artworks-track-institution.component.html',
  styleUrls: ['./artworks-track-institution.component.scss']
})
export class ArtworksTrackInstitutionComponent implements OnInit {


  artists = OBRAS;

  constructor() { }

  ngOnInit(): void {
  }

  
}
