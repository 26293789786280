import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trade-status-progress',
  templateUrl: './trade-status-progress.component.html',
  styleUrls: ['./trade-status-progress.component.scss']
})
export class TradeStatusProgressComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
