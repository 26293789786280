
<div class="container-fluid">
    <div class="row mt-4 pt-3">
        <div class="col-lg-12 px-0 ml-1">
            <h2 class="text-secondary font-weight-bold mb-0">In progress</h2>
            <hr>
        </div>
    </div>
</div>
<div class="container-fluid" *ngIf="progress.length == 0">
    <div class="row my-3">
        <div class="col-lg-12 p-0">
            <h5 class="mb-5 text-grey">There is no transaction in progress</h5>
        </div>
    </div>
</div>
<div class="container-fluid px-3" *ngIf="label === 'Sold' && progress.length > 0">
    <div class="row my-3">
        <div class="col-lg-12 px-0">
            <app-trade-item *ngFor="let item of progress"  [showDispute]="true" [showReminder]="true" [showPay]="false" [transfer]="item" [status]="'In Progress'" class="row py-3 border-bottom border-light"></app-trade-item>
        </div>
    </div>
</div>
<div class="container-fluid px-0" *ngIf="label !== 'Sold' && progress.length > 0">
    <div class="row my-3">
        <div class="col-lg-12">
            <app-trade-item *ngFor="let item of progress" [showDispute]="true" [showReminder]="false" [showPay]="true" [transfer]="item" [status]="'In Progress'" class="row py-3 border-bottom border-light"></app-trade-item>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row my-3">
        <div class="col-lg-12 px-0">
            <h2 class="text-secondary font-weight-bold mb-0">{{ label }}</h2>
            <hr class="mb-0">
        </div>
    </div>
</div>
<div class="container-fluid" *ngIf="confirm.length == 0">
    <div class="row my-3">
        <div class="col-lg-12 px-0">
            <h5 class="mb-5 text-grey">There is no transaction {{ label }}</h5>
        </div>
    </div>
</div>
<div class="container-fluid px-0" *ngIf="confirm.length > 0">
    <div class="row my-3">
        <div class="col-lg-12">
            <app-trade-item *ngFor="let item of confirm" [showFeedBack]="true" [showReminder]="false" [showDispute]="false"  [showPay]="false" [transfer]="item" [status]="'Complete'" class="row py-3 border-bottom border-light"></app-trade-item>
        </div>
    </div>
</div>