<div class="container-fluid px-0">
    <div class="mt-2">
        <div class="d-flex flex-wrap justify-content-start px-sm-0">
            <ng-container *ngFor="let artwork of artworks">
                <div class="box-artwork py-3 pr-2" style="position: relative;" *ngIf="filterArtworks(artwork)">
                    <app-create-dropdown-options-artworks [artwork]='artwork' [artwork_id]="artwork.id" class="dropdown-to-artworks"></app-create-dropdown-options-artworks>
                    <app-preview-artwork [img]="artwork.img" [preview]="artwork.preview" [class]="'square'" [routerLink]="['/artworks/' + artwork.id]"></app-preview-artwork>
                    <p class="text-secondary font-weight-bold mt-1 mb-0">{{ artwork.title }}</p>
                    <p class="text-secondary font-weight-regular mb-0">US$ {{(artwork.original_price/100).toFixed(2) }}</p>
                    <small class="font-weight-light text-grey">Uploaded {{ helper.formatDate(artwork.created_at) }}</small>
                </div>
                <div class="row d-flex justify-content-center mt-4" *ngIf="artworks.lenght > 25">
                    <div class="col-lg-2 col-md-3">
                        <button type="button" class="btnLoad btn btn-lg btn-outline-primary w-100">Load More</button>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>