<header class="container-fluid">
    <div class="row">
        <div class="col-12 px-0">
            <p class="mb-0">< Back to artist overview</p>
            <h1 class="font-weight-bold text-secondary">"Artist Name" Profile</h1>
        </div>
    </div>
</header>
<main>
    <section class="container-fluid">
        <div class="row personal-information">
            <div class="col-md-8 col-lg-7 px-2">
                <div class="row">
                    <div class="col-12 px-2">
                        <h3 class="text-secondary font-weight-bold mt-5">Personal Information</h3>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-6 p-2">
                        <input class="bg-light border-0 text-grey w-100" type="text" placeholder="First Name">
                    </div>
                    <div class="col-6 p-2">
                        <input class="bg-light border-0 text-grey w-100" type="text" placeholder="Last Name">
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 p-2">
                        <input class="bg-light border-0 text-grey w-100" type="date" placeholder="First Name">
                    </div>
                    <div class="col-4 p-2">
                        <div ngbDropdown class="d-inline-block w-100">
                            <button  type="button" class="btn text-left bg-light text-grey border-radius-default w-100 d-flex justify-content-between align-items-center" id="dropdownBasic-unit" ngbDropdownToggle>
                                <p class="btn-gender mb-0">Gender</p>
                                <img src="/assets/icons/arrow.png" alt="arrow">
                            </button>
                            <div ngbDropdownMenu aria-labelledby="dropdownBasic-unit" class="boxMenuDropdrown">
                                <a class="dropDownContent text-grey" ngbDropdownItem>Inches</a>
                                <a class="dropDownContent text-grey" ngbDropdownItem>Centimeters</a>
                                <a class="dropDownContent text-grey" ngbDropdownItem>Pixels</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 p-2">
                        <div ngbDropdown class="d-inline-block w-100">
                            <button type="button" class="btn text-left bg-light text-grey border-radius-default w-100 d-flex justify-content-between align-items-center" id="dropdownBasic-unit" ngbDropdownToggle>
                                <p class="mb-0 btn-race">race</p>
                                <img src="/assets/icons/arrow.png" alt="arrow">
                            </button>
                            <div ngbDropdownMenu aria-labelledby="dropdownBasic-unit" class="boxMenuDropdrown">
                                <a class="dropDownContent text-grey" ngbDropdownItem>Inches</a>
                                <a class="dropDownContent text-grey" ngbDropdownItem>Centimeters</a>
                                <a class="dropDownContent text-grey" ngbDropdownItem>Pixels</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ml-4 col-3 d-flex flex-column justify-content-end">
                <img class="img-profile" src="/assets/img/cto-about.png" alt="haroldo">
            </div>
        </div>
    </section>
    <hr>
    <section class="container-fluid">
        <div class="row">
            <div class="col-md-8 col-lg-7 px-2">
                <div class="row">
                    <div class="col-12 px-2">
                        <h3 class="text-secondary font-weight-bold mt-5">Contact Information</h3>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-6 p-2">
                        <input class="bg-light border-0 text-grey w-100" type="text" placeholder="email">
                    </div>
                    <div class="col-6 p-2">
                        <input class="bg-light border-0 text-grey w-100" type="text" placeholder="phone number">
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-12 p-2">
                        <input class="bg-light border-0 text-grey w-100 mb-2" type="text" placeholder="Street">
                    </div>
                    <div class="col-12 p-2">
                        <input class="bg-light border-0 text-grey w-100" type="text" placeholder="Street">
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 p-2">
                        <input class="bg-light border-0 text-grey w-100" type="text" placeholder="ZIP Code">
                    </div>
                    <div class="col-4 p-2">
                        <input class="bg-light border-0 text-grey w-100" type="date" placeholder="City">
                    </div>
                    <div class="col-4 p-2">
                        <div ngbDropdown class="d-inline-block w-100">
                            <button type="button" class="btn text-left bg-light text-grey border-radius-default w-100 d-flex justify-content-between align-items-center" id="dropdownBasic-unit" ngbDropdownToggle>
                                <p class="mb-0 btn-country">Country</p>
                                <img src="/assets/icons/arrow.png" alt="arrow">
                            </button>
                            <div ngbDropdownMenu aria-labelledby="dropdownBasic-unit" class="boxMenuDropdrown">
                                <a class="dropDownContent text-grey" ngbDropdownItem>Inches</a>
                                <a class="dropDownContent text-grey" ngbDropdownItem>Centimeters</a>
                                <a class="dropDownContent text-grey" ngbDropdownItem>Pixels</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <hr>
    <section class="container-fluid">
        <div class="row">
            <div class="col-md-8 col-lg-7 px-2">
                <div class="row">
                    <div class="col-12 px-2">
                        <h3 class="text-secondary font-weight-bold mt-5">Social</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 p-2">
                        <input class="bg-light border-0 text-grey w-100" type="text" placeholder="Facebook">
                    </div>
                    <div class="col-4 p-2">
                        <input class="bg-light border-0 text-grey w-100" type="date" placeholder="Instagram">
                    </div>
                    <div class="col-4 p-2">
                        <input class="bg-light border-0 text-grey w-100" type="text" placeholder="Pinterest">
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 p-2">
                        <input class="bg-light border-0 text-grey w-100" type="text" placeholder="Twitter">
                    </div>
                    <div class="col-4 p-2">
                        <input class="bg-light border-0 text-grey w-100" type="text" placeholder="Blog">
                    </div>
                    <div class="col-4 p-2">
                        <input class="bg-light border-0 text-grey w-100" type="text" placeholder="Web">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <hr>
    <section class="container-fluid">
        <div class="row">
            <div class="col-md-8 col-lg-7">
                <div class="row">
                    <div class="col-12 px-0">
                        <h3 class="text-secondary font-weight-bold mt-5">Professional Information</h3>
                        <p class="mb-0">Techniques</p>
                    </div>
                </div>
                <div class="row d-flex mb-2 ">
                    <div class="mb-2" style="width: 151px;">
                        <button class="btn d-flex align-items-center justify-content-center bg-light mr-3 border-0 text-grey" style="height: 32px; font-size: 15px;">Technique 1</button>
                    </div>
                    <div class="mb-2" style="width: 151px;">
                        <button class="btn d-flex align-items-center justify-content-center bg-light mr-3 border-0 text-grey" style="height: 32px; font-size: 15px;">Technique 2</button>
                    </div>
                    <div class="mb-2" style="width: 151px;">
                        <button class="btn d-flex align-items-center justify-content-center bg-light mr-3 border-0 text-grey" style="height: 32px; font-size: 15px;">Technique 3</button>
                    </div>
                    <div class="mb-2" style="width: 151px;">
                        <button class="btn d-flex align-items-center justify-content-center bg-light mr-3 border-0 text-grey" style="height: 32px; font-size: 15px;">Technique 4</button>
                    </div>
                    <div class="mb-2" style="width: 151px;">
                        <button class="btn d-flex align-items-center justify-content-center bg-light mr-3 border-0 text-grey" style="height: 32px; font-size: 15px;">Technique 5</button>
                    </div>
                </div>
                <div class="row d-flex mb-2">
                    <div class="mb-2" style="width: 151px;">
                        <button class="btn d-flex align-items-center justify-content-center bg-light mr-3 border-0 text-grey" style="height: 32px; font-size: 15px;">Technique 6</button>
                    </div>
                    <div class="mb-2" style="width: 151px;">
                        <button class="btn d-flex align-items-center justify-content-center bg-light mr-3 border-0 text-grey" style="height: 32px; font-size: 15px;">Technique 7</button>
                    </div>
                    <div class="mb-2" style="width: 151px;">
                        <button class="btn d-flex align-items-center justify-content-center bg-light mr-3 border-0 text-grey" style="height: 32px; font-size: 15px;">Technique 8</button>
                    </div>
                    <div class="mb-2" style="width: 151px;">
                        <button class="btn d-flex align-items-center justify-content-center bg-light mr-3 border-0 text-grey" style="height: 32px; font-size: 15px;">Technique 9</button>
                    </div>
                    <div class="mb-2" style="width: 151px;">
                        <button class="btn d-flex align-items-center text-left bg-light mr-3 border-0 text-grey" style="width: 211px; height: 32px; font-size: 15px;">Other</button>
                    </div>
                </div>
                <div class="row">
                    <textarea class="border-0 background-light p-3" name="" id="" cols="96" rows="10" placeholder="BIO"></textarea>
                </div>
            </div>
        </div>
    </section>
</main>
<hr class="my-4">
<footer class="btns-footer d-flex flex-wrap justify-content-end">
    <button class="btn btn-lg text-primary border-primary px-5 py-3 mr-3 mb-2" href="">Cancel</button>
    <button class="btn btn-lg bg-primary text-white px-5 py-3" href="">Save changes</button>
</footer>