<div class="row mb-5">
    <div class="col-sm-12 col-md-12 col-lg-6 mt-5">
        <div class="d-sm-flex justify-content-between align-item-start">
            <div>
                <p class="font-weight-light text-grey mb-0">About the artist</p>
                <h1 class="font-weight-bold text-secondary"><a href="javascript:void(0)" [routerLink]="['/artist-detail/'+artwork.artist.slug]">{{ artwork.artist.full_name }}</a></h1>
                <h5 class="font-weight-light text-grey mr-3" [innerHTML]="artwork.artist.about"></h5>
            </div>
            <div>
                <img class="img-artist" *ngIf="artwork.artist.avatar" [src]="artwork.artist.avatar" alt="img artist">
            </div>
        </div>
    </div>
</div>