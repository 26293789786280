<div *ngIf="tradeBuyerStep == 0">
    <div class="modal-header">
        <h4 class="modal-title font-weight-bold" id="modal-basic-title">You must login</h4>
        <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
    <div class="modal-body">
        <app-auth-forms [form]="authFormMode" [redirect]="false" (onSubmit)="submitForm($event)"></app-auth-forms>
    </div>
</div>

<div *ngIf="tradeBuyerStep == 1">
    <div class="modal-header">
        <h4 class="modal-title font-weight-bold" id="modal-basic-title">1. Trade initiated by {{ transaction.seller.full_name }}</h4>
        <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
    <ngb-progressbar type="primary" [value]="tradeBuyerStep * 33" style="height: 0.3em"></ngb-progressbar>
    <div class="modal-body px-0">
        <p class="font-weight-light text-grey-light px-5">Nice! Someone initiated a trade with you. Please make sure all information is correct before continuing to payment.</p>
        <div class="d-flex justify-content-between px-5">
            <div class="col-md-5">
                <div>
                    <h5 class="font-weight-bold mb-0">Artwork</h5>
                    <p class="font-weight-light text-grey-light">{{transaction.artwork_version.artwork.title}}</p>
                </div>
                <div>
                    <h5 class="font-weight-bold mb-0">Artist</h5>
                    <p class="font-weight-light text-grey-light">{{transaction.seller.full_name}}</p>
                </div>
                <div>
                    <h5 class="font-weight-bold mb-0">Type</h5>
                    <p class="font-weight-light text-grey-light">{{transaction.artwork_version.artwork.digital ? 'Digital' : 'Physical'}}</p>
                </div>
            </div>
            <div class="col-md-7">
                <app-preview-artwork [img]="transaction.artwork_version.artwork.img" [preview]="transaction.artwork_version.artwork.img" [styleBox]="'width: 100%;max-height: auto;height: auto'" [styleImage]="'width: 100%;max-height: auto;height: auto;border-radius: 9px; object-fit: cover'" ></app-preview-artwork>
            </div>
        </div>
        <hr class="p-0">
        <div class="px-5">
            <div class="d-flex justify-content-between">
                <h5 class="font-weight-bold">Price</h5>
                <p class="font-weight-light text-grey-light" [class.text-decoration-line-through]="transaction.type_trade == 'external' || transaction.type_trade == 'donation'">US$ {{(transaction.amount/100).toFixed(2)}}</p>
            </div>
            <div *ngIf="transaction.artwork_version.owner_id != null" class="d-flex justify-content-between">
                <h5 class="font-weight-bold">Artist royalties</h5>
                <p class="font-weight-light text-grey-light">US$ {{(transaction.author_fee_amount/100).toFixed(2)}}</p>
            </div>
            <div class="d-flex justify-content-between" *ngIf="transaction.institution_fee_amount > 0 && transaction.artwork_version.owner_id != null">
                <h5 class="font-weight-bold">Institution royalties</h5>
                <p class="font-weight-light text-grey-light">US$ {{(transaction.institution_fee_amount/100).toFixed(2)}}</p>
            </div>
            <div *ngIf="transaction.artwork_version.owner_id != null" class="d-flex justify-content-between">
                <h5 class="font-weight-bold">Platform fees</h5>
                <p class="font-weight-light text-grey-light">US$ {{(transaction.platform_fee_amount/100).toFixed(2)}}</p>
            </div>
            <div *ngIf="transaction.type_trade == 'platform' || (transaction.type_trade == 'donation' && transaction.artwork_version.owner_id != null)" class="d-flex justify-content-between">
                <h5 class="font-weight-bold">Payment Gateway fees</h5>
                <p class="font-weight-light text-grey-light">US$ {{(transaction.gateway_fee_amount/100).toFixed(2)}}</p>
            </div>
            <div class="d-flex justify-content-between">
                <h5 class="font-weight-bold text-primary">Total</h5>
                <p class="font-weight-bold text-primary">US$ {{ (transaction.total/100).toFixed(2)}}</p>
            </div>
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-start">
        <a (click)="buttonClose()" class="text-primary"> Cancel trade</a>
        <button type="button" *ngIf="transaction.total == 0" class="btn-lg btn btn-primary ml-4 cursor-pointer" (click)="paymentTransactionWithStripe( transaction )">Continue to Accept</button>
        <button type="button" *ngIf="transaction.total > 0 && !transaction.author_fee_amount" class="btn-lg btn btn-primary ml-4 cursor-pointer" (click)="tradeBuyerStep = 2">Continue to payment</button>
        <button type="button" *ngIf="transaction.total > 0 && transaction.author_fee_amount" class="btn-lg btn btn-primary ml-4 cursor-pointer" (click)="paymentTransactionWithPaypal(transaction)">Continue to payment</button>
    </div>
</div>

<div *ngIf="tradeBuyerStep == 2">
    <div class="modal-header d-flex justify-content-center">
        <h3 class="font-weight-bold text-secondary">2. Payment <span class="font-weight-bold text-primary"> US$ {{ (transaction.total/100).toFixed(2)}}</span></h3>
        <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0 p-2">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
    <ngb-progressbar type="primary" [value]="tradeBuyerStep * 33" style="height: 0.3em"></ngb-progressbar>
    <div class="modal-body px-0">
        <div>
            <div class="col d-flex justify-content-center">
                <button type="button" class="btn btn-secondary text-white font-weight-regular px-4">Card</button>
            </div>
            <div class="col mt-4">
                <input class="form-control ínput-price bg-light border-0 w-100" ccNumber #ccNumber="ccNumber" [(ngModel)]="cardNumber" placeholder="Card number" type="tel" style="height: 50px; font-size: 18px;" required checked>
            </div>
            <div class="d-flex mt-4">
                <div class="col">
                    <input class="form-control ínput-price bg-light border-0 w-100" [(ngModel)]="cardExp" id="cc-exp-date" autocomplete="cc-exp" ccExp placeholder="MM / YYYY" type="tel" style="height: 50px; font-size: 18px;" required checked>
                </div>
                <div class="col">
                    <input class="form-control ínput-price bg-light border-0 w-100" [(ngModel)]="cardCvv" autocomplete="off" ccCVC placeholder="CVV" type="tel" style="height: 50px; font-size: 18px;" required checked>
                </div>
            </div>
            <div class="w-100 my-4 col">
                <input class="form-control ínput-price bg-light border-0 w-100" [(ngModel)]="cardName" placeholder="Card name" type="text" style="height: 50px; font-size: 18px;" required checked>
            </div>
            <div class="modal-footer d-flex justify-content-start">
                <div class="col-md-4 d-flex justify-content-start">
                    <a class="text-primary" (click)="tradeBuyerStep = 1">Back</a>
                </div>
                <div class="col-md-4 d-flex justify-content-start px-0">
                    <button type="button" (click)="paymentTransactionWithStripe(transaction)" class="btn-lg btn btn-primary cursor-pointer w-100">Confirm</button>
                </div>
            </div>
            
            <div class="d-flex align-items-center justify-content-center">
                <img src="/assets/icons/shield-check.png" alt="secure payment">
                <p class="font-weight-light text-center mb-0">Secure Payment</p>
            </div>
        </div>
    </div>
</div>


<div *ngIf="tradeBuyerStep == 3">
    <div class="modal-header">
        <h4 class="modal-title font-weight-bold ml-5 px-5" id="modal-basic-title">Payment successful!</h4>
        <a class="close cursor-pointer" aria-label="Close" (click)="buttonClose()">
            <img src="/assets/icons/close.svg" alt="">
        </a>
    </div>
    <ngb-progressbar type="primary" [value]="tradeBuyerStep * 33" style="height: 0.3em"></ngb-progressbar>
    <div class="modal-body px-5">
        <div class="text-center ">
            <img src="/assets/img/purchased-green.png" alt="">
            <h4>Artwork transfered.</h4>
            <h4>Trade complete.</h4>
            <p>{{ helper.formatDateTime(transaction.updated_at) }}</p>
        </div>
        <div class="">
            <p>Transaction Signature</p>
            <input type="text" value="{{ transaction.hash_token }}" class="form-control">
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-center">
        <button type="button" (click)="goToArtwork()" class="btn btn-primary cursor-pointer">See your artwork</button>
    </div>
</div>

<div *ngIf="tradeBuyerStep == 4">
    <div class="modal-header">
        <h4 class="modal-title font-weight-bold ml-5 px-5" id="modal-basic-title">Payment successful!</h4>
        <a class="close cursor-pointer" aria-label="Close" (click)="buttonClose()">
            <img src="/assets/icons/close.svg" alt="">
        </a>
    </div>
    <ngb-progressbar type="primary" [value]="tradeBuyerStep * 33" style="height: 0.3em"></ngb-progressbar>
    <div class="modal-body px-5">
        <div class="text-center ">
            <img src="/assets/img/update.png" alt="">
            <h4>Trade processing.</h4>
            <p>{{ helper.formatDateTime(transaction.updated_at) }}</p>
        </div>
        <div class="">
            <p>Transaction Signature</p>
            <input type="text" value="{{ transaction.hash_token }}" class="form-control">
        </div>
    </div>
    <div *ngIf="transaction.status != 4" class="modal-footer d-flex justify-content-center">
        <button type="button" (click)="goToArtwork()" class="btn btn-primary cursor-pointer">See your artwork</button>
    </div>
</div>