<div *ngFor="let list of lists" class="container-fluid border-light">
    <div class="row">
        <div class="col-lg-3 mt-sm-5 pt-sm-3 pl-3">
            <h4 class="mb-2 font-weight-bold text-secondary">{{ list.title }}</h4>
            <h5 class="mb-2 text-grey-light font-weight-regular">{{ list.versions?.length }} Artworks</h5>
            <button type="button" [routerLink]="['/lists/'+list.id]" class="d-sm-flex align-items-center justify-content-center btn-view btn btn-lg btn-primary font-weight-light mt-2">View</button>
        </div>
        <div class="col-lg-9 px-0">
            <div class="w-100 overflow mt-3 pl-lg-3 pr-lg-0" *ngIf="list.versions?.length > 0">
                <div *ngFor="let version of list.versions" class="col-auto p-0">
                    <app-dropdown-options-remove-trade [version]="version" [list]="list" class="dropdown-options-remove-trade"></app-dropdown-options-remove-trade>
                    <!-- <img src="{{ version.artwork.img }}" alt="{{ version.artwork.title }}" style="height: 228px; width: 100%" class="line-art"> -->
                    <app-preview-artwork  [link]="'/versions/'+version.id"  [showPlay]="true" [preview]="version.artwork.preview" [img]="version.artwork.img" [styleImage]="'height: 228px; width: 100%; object-fit: cover;'"></app-preview-artwork>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngFor="let folder of folders" class="container-fluid border-light pr-sm-0">
    <div class="row">
        <div class="col-md-3 mt-md-5 pt-md-3 foldersDescription">
            <h4 class="mb-0 font-weight-bold text-secondary">{{ folder.title }}</h4>
            <h5 class="mb-0 text-grey-light font-weight-light" style="margin-top: -6px;">{{ folder.artworks?.length }} Artworks</h5>
            <button type="button" [routerLink]="['/folders/'+folder.id]" class="d-sm-flex align-items-center justify-content-center btn btn-primary btn-view mt-2">View</button>
        </div>
        <div class="col-md-9 pr-0">
            <div class="w-100 overflow mt-3" *ngIf="folder?.artworks.length > 0">
                <div class="col-auto px-1 cursor-pointer" *ngFor="let artwork of folder.artworks">
                    <app-dropdown-options-remove-trade [artwork]="artwork" [folder]="folder" class="dropdown-options-remove-trade"></app-dropdown-options-remove-trade>
                    <!-- <img [src]="artwork.img" [alt]="artwork.title" style="height: 228px; width: 100%; object-fit: cover;" class="line-art"> -->
                    <app-preview-artwork [link]="'/artworks/'+artwork.id" [showPlay]="true" [preview]="artwork.preview" [img]="artwork.img" [styleImage]="'height: 228px; width: 100%; object-fit: cover;'"></app-preview-artwork>
                </div>
            </div>
        </div>
    </div>
    <hr class="my-3">
</div>