<div class="container px-md-0 px-0 mt-5">
    <hr class="artistsBetween" *ngIf="creatorTopSelling?.length == 0">
    <h4 class="text-secondary font-weight-bold mt-5" *ngIf="creatorTopSelling?.length == 0">There are no top selling artists</h4>
    
    <h4 class="text-secondary font-weight-bold" *ngIf="creatorTopSelling?.length > 0">Top selling Artists</h4>
    <div class="row mt-2 px-3" *ngIf="creatorTopSelling?.length > 0">
        <div class="w-100 overflow d-flex">
            <div class="col d-flex">
                <a [routerLink]="['/artist-detail', artist?.slug]" *ngFor="let artist of creatorTopSelling" class="nav-link cursor-pointer d-flex flex-column flex-nowrap align-content-center justify-content-center align-items-center mr-4">
                    <img *ngIf="artist?.avatar" [src]="artist.avatar" class="image-size">
                    <img *ngIf="!artist?.avatar" src="/assets/img/avatar.png" class="image-size">
                    <div class="text-center mt-2">
                        <p class="font-weight-bold text-secondary mb-0">{{ artist.full_name }}</p>
                        <p *ngIf="artist?.city" class="text-secondary font-weight-regular description mb-0">{{ artist.city }}</p>
                        <p *ngIf="artist?.country" class="text-secondary font-weight-regular description">{{ artist.country }}</p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>
