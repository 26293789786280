<main id="bg-subscribe">
    <section>
        <div class="container-fluid px-sm-5">
            <div class="row px-sm-4 pt-3">
                <div class="col-lg-12">
                    <p class="mb-0 text-grey font-weight-light  mt-sm-5">Your Vault</p>
                    <h1 class="font-weight-bold text-primary mb-0">Congratulations, {{ auth.userData.full_name }}</h1>
                </div>
            </div>
        </div>
        <section>
            <div class="container-fluid px-sm-5">
                <div class="row px-sm-4">
                    <div class="col-lg-12">
                        <br>
                        <h1 class="font-weight-bold text-secondary mb-5">You can now receive payments from your sales and transactions from your NFTs</h1>
                        <p class="font-weight-bold text-secondary mb-5">To check the Stripe payment schedule for your region, <a href="https://stripe.com/docs/payouts#payout-speed" target="_blank">click here</a></p>
                    </div>
                </div>
                <div class="row mt-5 pt-5">
                    <div class="col-lg-12 mt-5 pt-5">
                        <div class="text-right">
                            <img src="/assets/img/digital-2.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</main>
