<div class="container-fluid p-0">
    <div class="d-md-flex">
        <div *ngIf="buy?.in_progress.length > 0 || sale?.in_progress.length > 0" class="col-lg-4 mb-2 p-0">
            <h5 class="font-weight-bold">Trades in progress</h5>
            <div class="d-flex align-items-center">
                <h1 class="mr-3 display-4 text-primary">{{ buy?.in_progress.length + sale?.in_progress.length }}</h1>
                <div>
                    <p class="text-secondary m-0">{{ buy?.in_progress.length }} buying / {{ sale?.in_progress.length }} selling</p>
                    <a [routerLink]="[routeAllTrade]">View all</a>
                </div>
            </div>
        </div>
        <div *ngIf="buy?.bought.length > 0 || sale?.sold.length > 0" [class.col-lg-12]="buy?.in_progress.length == 0 && sale?.in_progress.length == 0" class="col-lg-8 pl-0">
            <h5 class="font-weight-bold">Latest trades</h5>
            <div class="row">
                <div class="col-md-3 mb-2 d-flex" *ngFor="let version of buy?.bought">
                    <app-preview-artwork [img]="version.artwork_version.artwork.img" [preview]="version.artwork_version.artwork.preview" [styleImage]="'max-height: 3em; border-radius: 12px; object-fit: cover;'" [styleBox]="'max-height: 3em; border-radius: 12px;'"></app-preview-artwork>
                    <div>
                        <h6 class="font-weight-bold m-0">{{ version.artwork_version.artwork.title }}</h6>
                        <p class="text-secondary font-weight-normal m-0">{{ version.artwork_version.artwork.artist.full_name }}</p>
                        <a [routerLink]="['/versions/'+version.artwork_version.id]">View</a>
                    </div>
                </div>
                <div class="col-md-3 mb-2 d-flex" *ngFor="let version of sale?.sold">
                    <app-preview-artwork [img]="version.artwork_version.artwork.img" [preview]="version.artwork_version.artwork.preview" [styleImage]="'max-height: 3em; border-radius: 12px; object-fit: cover;'" [styleBox]="'max-height: 3em; border-radius: 12px;'"></app-preview-artwork>
                    <div>
                        <h6 class="font-weight-bold m-0">{{ version.artwork_version.artwork.title }}</h6>
                        <p class="text-secondary font-weight-normal m-0">{{ version.artwork_version.artwork.artist.full_name }}</p>
                        <a [routerLink]="['/versions/'+version.artwork_version.id]">View</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>