import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-institution-details-artists',
  templateUrl: './institution-details-artists.component.html',
  styleUrls: ['./institution-details-artists.component.scss']
})
export class InstitutionDetailsArtistsComponent implements OnInit {
  @Input() artist: any;
  @Input() mode: string = 'artist';


  constructor() { }

  ngOnInit(): void {
  }

}
