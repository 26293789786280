import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router,NavigationEnd  } from '@angular/router';
import { Location } from "@angular/common";

@Component({
  selector: 'app-trades-progress-institution',
  templateUrl: './trades-progress-institution.component.html',
  styleUrls: ['./trades-progress-institution.component.scss']
})
export class TradesProgressInstitutionComponent implements OnInit {
  public buy: any;
  public sale: any;
  public routeAllTrade: any;
  public id: any;

  constructor(
    private router: Router,
    public api: ApiService,
    public location: Location,
  ) { }

  ngOnInit(): void {
    if (this.location.path() === "/dashboard/artworks") {
      this.routeAllTrade = '/trades';
    }else if(this.location.path() === "/dashboard-creator/overview") {
      this.routeAllTrade = '/dashboard-creator/trades';
    }     
    
    this.api.get('transfers/buy').then((response) => {
      this.buy = response
    })
    
    this.api.get('transfers/sale').then((response) => {
      this.sale = response
    })
  }

}
