import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-platform-fee',
  templateUrl: './modal-platform-fee.component.html',
  styleUrls: ['./modal-platform-fee.component.scss']
})
export class ModalPlatformFeeComponent implements OnInit {

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
  }


  buttonClose(){
    const modalRef = this.modalService.dismissAll();
   }
}
