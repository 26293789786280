<div class="row">
    <div class="col-lg-12 text-center p-3">
        <h4 class="text-secondary font-weight-bold">Add to list</h4>
        <p class="mt-4 text-grey-light font-weight-light">You can then add a list or delete one</p>
        <button type="button" (click)="buttonClose()" aria-label="Close" 
            class="btn btn-outline position-absolute right-0 top-0">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
</div>
<div class="col-sm-9 col-12 mx-auto p-0 px-3">
    <ng-select [(ngModel)]="selectedList" >
        <ng-option *ngFor="let list of lists" [value]="list.id">{{list.title}}</ng-option>
    </ng-select>
</div>
<div class="row d-flex justify-content-center px-5">
    <div class="col-lg-7 col-md-7 pb-3">
        <div class="mt-4">
            <button type="button" (click)="createAddtoList(selectedList, artwork_version_id)"
                class="btn-lg btn btn-primary font-weight-regular w-100">Add</button>
        </div>
    </div>
</div>