import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class AnimateService {

  public animatePercentages: any = {}
  public transformProps = ['matrix', 'matrix3d', 'perspective', 'rotate', 'rotate3d', 'rotateX', 'rotateY', 'rotateZ', 'translate', 'translate3d', 'translateX', 'translateY', 'translateZ', 'scale', 'scale3d', 'scaleX', 'scaleY', 'scaleZ', 'skew', 'skewX', 'skewY'];
  public filterProps = ['blur', 'brightness', 'contrast', 'drop-shadow', 'grayscale', 'hue-rotate', 'invert', 'opacity', 'saturate', 'sepia']





  constructor(
  ) {
  }


  setAnimationPercentage(element: HTMLElement, props: any) {
    let isVisible = this.isInViewport(element);
    console.log(isVisible)
    let animatePercentage = 0;
    if (isVisible) {
      const rect = element.getBoundingClientRect();
      animatePercentage = -1 * ((rect.top - window.innerHeight) / 400)
      if (animatePercentage > 1) animatePercentage = 1
      console.log(animatePercentage)
    }
    for (let i in props) {
      let prop = props[i];
      if (!prop.unit) prop.unit = '';
      let value = prop.initial + (animatePercentage * prop.amount);
      if (this.transformProps.indexOf(i) >= 0) {
        element.style.transform = i + '(' + value + prop.unit + ')';
      } else if (this.filterProps.indexOf(i) >= 0) {
        element.style.filter = i + '(' + value + prop.unit + ')';
      } else {
        element.style[i] = value + prop.unit;
      }
    }
  }


  isInViewport(element: HTMLElement, full: boolean = false, axis: 'x' | 'y' | 'xy' = 'y') {
    const rect = element.getBoundingClientRect();
    let h = window.innerHeight || document.documentElement.clientHeight
    let w = window.innerWidth || document.documentElement.clientWidth
    switch (axis) {
      case 'xy':
        return full ? rect.left > 0 && rect.right <= w : rect.right - w <= 0 && full ? rect.top > 0 && rect.bottom <= h : rect.top - h <= 0
        return
      case 'x':
        return full ? rect.left > 0 && rect.right <= w : rect.right - w <= 0
      case 'y':
      default:
        return full ? rect.top > 0 && rect.bottom <= h : rect.top - h <= 0
    }
  }

}
