<main>
    <div class="container-fluid" id="bg-register" *ngIf="viewMode === 'register'">
        <div class="row h-100">
            <div class="col-lg-6 p-sm-5 my-5">
                <div class="row">
                    <div class="col-11 my-2">
                        <h1 class="text-primary font-weight-bold">Create your Art Vault</h1>
                        <p class="text-grey-light font-weight-light"> Securely authenticate, store and trade digital and physical <br> art, whether you're a collector or an artist</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-11">
                        <app-auth-forms form="register"></app-auth-forms>
                    </div>
                </div>
                <div class="row">
                    <div class="col-11 mt-5">
                        <small class="text-grey-light font-weight-light">Welcome to Alphaa.io, owned and operated by Alpha’a Inc. These Terms of Service regulate your access and application of the Alphaa.io website, our APIs, mobile app, and any other features, tools, software, or services provided by Alphaa.io. This includes but is not limited to using our services to create, trade, and view NFTs and using our technology to connect directly with others to buy, trade, or transfer NFTs on public blockchains.</small>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 d-flex flex-column justify-content-center align-items-center" id="bg-digital-alpha">
                <div class="row">
                    <div class="py-5">
                        <div class="px-2 mx-1">
                            <h3 class="text-white font-weight-bold">What your secure, personalized <br> Art Vault can do: </h3>
                        </div>
                        <div class="media mt-5 px-2">
                            <img class="mr-4" src="assets/icons/certo.png" alt="">
                            <p class="text-light font-weight-bold">Register digital and physical artworks and receive <br> blockchain-secured certification.</p>
                        </div>
                        <div class="media mt-1 px-2">
                            <img class="mr-4" src="assets/icons/certo.png" alt="">
                            <p class="text-light font-weight-bold">Automatic market-tracking means you have access <br> to real-time data on your artworks</p>
                        </div>
                        <div class="media mt-1 px-2">
                            <img class="mr-4" src="assets/icons/certo.png" alt="">
                            <p class="text-light font-weight-bold">Complimentary access to Alphaa-vetted art advisors <br> and other experts, via end-to-end encrypted messaging.</p>
                        </div>
                        <div class="media mt-1 px-2">
                            <img class="mr-4" src="assets/icons/certo.png" alt="">
                            <p class="text-light font-weight-bold">Search all public price information for free.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid" id="bg-auth-register" *ngIf="viewMode === 'authenticate-register'">
        <div class="row h-100">
            <div class="col-lg-12 py-5 mb-5">

                <app-auth-forms form="authenticate-register"></app-auth-forms>

                <div class="mb-5 text-center">
                    <!-- <a href="#" class="nav-link text-primary font-weight-regular">I didn't receive the code</a> -->
                </div>
                <div class="row d-flex justify-content-center">
                    <div class="col-lg-3 px-sm-0 col-sm-5">
                        <button type="submit" class="btn btn-primary btn-lg font-weight-regular w-100">Continue</button>
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <button type="button" (click)="goBack()" class="btn mt-2 font-weight-regular text-primary btn-md nav-link">Back</button>
                </div>
                <div class="text-center mt-3 mb-5">
                    <small class="text-grey-light mb-5">Welcome to Alphaa.io, owned and operated by Alpha’a Inc. These Terms of Service regulate your <br> access and application of the Alphaa.io website, our APIs, mobile app, and any other features, tools, <br> software, or services provided by Alphaa.io. This includes but is not limited to using our services to <br>create, trade, and view NFTs and using our technology to connect directly with others to buy, trade, <br>or transfer NFTs on public blockchains.</small>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid" id="bg-auth" *ngIf="viewMode === 'login'">
        <div class="row h-100">
            <div class="col-lg-6 col-sm-12 px-0 porta-cofre">
                <img width="100%" src="/assets/img/bg-login.webp" alt="">
            </div>
            <div id="access" class="col-lg-6 col-sm-12 d-flex align-items-center justify-content-center pt-2">
                <app-auth-forms form="login"></app-auth-forms>
            </div>
        </div>
    </div>

    <div class="container-fluid" id="bg-auth" *ngIf="viewMode === 'authenticate-login'">
        <div class="row h-100">
            <div class="col-lg-6 col-sm-12 p-0">
                <img width="100%" src="/assets/img/porta-cofre.jpeg" alt="">
            </div>
            <div class="col-lg-6 col-sm-12 my-5 py-5">
                <app-auth-forms form="two-factor"></app-auth-forms>
            </div>
        </div>
    </div>

    <div class="container-fluid" id="bg-auth" *ngIf="viewMode === 'forgotPassword'">
        <div class="row h-100">
            <div class="col-lg-6 col-sm-12  px-0">
                <img width="100%" src="/assets/img/porta-cofre.jpeg" alt="">
            </div>
            <div id="access" class="col-lg-6 col-sm-12 pt-5 mt-5">
                <div class="d-flex align-items-center justify-content-center pt-5 mt-5">
                    <app-auth-forms form="forgot-password"></app-auth-forms>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid" id="bg-auth" *ngIf="viewMode === 'recoveryPassword'">
        <div class="row h-100">
            <div class="col-lg-6 col-sm-12 px-0">
                <img width="100%" src="/assets/img/porta-cofre.jpeg" alt="">
            </div>
            <div id="access" class="col-md-6 col-sm-12 d-flex justify-content-center mt-5 pt-5">
                <app-auth-forms form="recovery-password"></app-auth-forms>
            </div>
        </div>
    </div>

    <div class="container-fluid" id="bg-auth" *ngIf="viewMode === 'sendRecoveryPassword'">
        <div class="row h-100">
            <div class="col-lg-6 col-sm-12 px-0">
                <img width="100%" src="/assets/img/porta-cofre.jpeg" alt="">
            </div>
            <div id="access" class="col-lg-6 col-sm-12 d-flex flex-column justify-content-center align-items-center p-5">
                <h1 class="font-weight-bold text-primary text-center mb-3">Success!</h1>
                <h5 class="font-weight-light text-grey-light text-center">In a few moments you will receive an</h5>
                <h5 class="font-weight-light text-grey-light text-center">email with further instructions</h5>
                <div class="row">
                    <button (click)="goToLogin()" class="btn-lg btn btn-primary text-white font-weight-regular cursor-pointer mt-4 w-100"> Back to Login</button>
                </div>
            </div>
        </div>
    </div>
</main>