import { Component, Input, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-event-specs',
  templateUrl: './event-specs.component.html',
  styleUrls: ['./event-specs.component.scss']
})
export class EventSpecsComponent implements OnInit {
  
  @Input() artwork: any
  @Input() event: any
  
  constructor(
    public helper: HelperService,
    ) { }
    
    ngOnInit(): void {
    }
    
    
    buy(url) {
      if(this.artwork.version.external_source != null){
        window.location.href = this.artwork.version.external_source
      }else{
        window.location.href =  environment.URL_BASE+'/event/'+this.event.slug+'/'+this.artwork.id+url
      }
    }
    
  }
  