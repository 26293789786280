<div class="container-fluid px-0" *ngIf="artworks.length > 0">
  <div class="row">
    <div class="col-lg-12">
      <div class="d-flex flex-wrap mt-3">
        <div *ngFor="let artwork of artworks" class="col-auto p-0 cursor-pointer">
          <app-dropdown-options-remove-trade [artwork]="artwork" [folder]="folder" class="dropdown-options-remove-trade"></app-dropdown-options-remove-trade>
          <!-- <img src="{{ artwork.img }}" class="rounded" style="width: 276px; height: 217px;" alt="{{ artwork.title }}"> -->
          <app-preview-artwork [link]="'/artworks/'+artwork.id" [showPlay]="true" [preview]="artwork.preview" [img]="artwork.img" [styleImage]="' height: 217px;'"></app-preview-artwork>
          <p class="mb-0 font-weight-bold text-secondary">{{ artwork.title }} - ({{ artwork.edition }}) editions</p>
          <p class="text-grey-light font-weight-light">{{ artwork.artist.full_name }}</p>
        </div>
      </div>
    </div>
  </div>
  
  <div class="col-12 text-center mt-5 mb-5" *ngIf="artworks.length > 25">
    <a href="#" class="btn btn-outline-primary font-weight-bold pl-3 pr-3 mr-3 load-more">Load more</a>
  </div>
</div>

<div class="container-fluid px-0" *ngIf="versions.length > 0">
  
  <div class="row">
    <div class="col-lg-12 mx-3">
      <div class="row flex-wrap mt-3">
        <div *ngFor="let version of versions" class="col-auto p-0">
          <app-dropdown-options-remove-trade [version]="version" [list]="list" class="dropdown-options-remove-trade"></app-dropdown-options-remove-trade>
          <!-- <img src="{{ version.artwork.img }}" class="rounded" style="width: 276px; height: 217px;" alt="{{ version.artwork.title }}"> -->
          <app-preview-artwork [link]="'/versions/'+version.id" [showPlay]="true" [preview]="version.artwork.preview" [img]="version.artwork.img" [styleImage]="' height: 217px;'"></app-preview-artwork>
          <p class="mt-1 mb-0 font-weight-bold text-secondary">{{ version.artwork.title }} - ({{ version.version }}/{{ version.artwork.edition }}) editions</p>
          <p class="text-grey-light font-weight-light">{{ version.artwork.artist.full_name }}</p>
        </div>
      </div>
    </div>
  </div>
  
  <div class="col-lg-12 text-center mt-5 mb-5" *ngIf="versions.length > 25">
    <a href="#" class="btn btn-outline-primary font-weight-bold pl-3 pr-3 mr-3 load-more">Load more</a>
  </div>
</div>