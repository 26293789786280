import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-trade-digital',
  templateUrl: './modal-trade-digital.component.html',
  styleUrls: ['./modal-trade-digital.component.scss']
})
export class ModalTradeDigitalComponent implements OnInit {
  @Input() public artwork;
  @Input() public artwork_id;
  @Input() public artwork_version_id;
  @Input() public version;

  constructor(
    public modalService: NgbModal,
    public auth: AuthService,
    public route: ActivatedRoute,
    public helper: HelperService,
    public api: ApiService,
  ) { }

  public step: number = 2
  public isDigital: any = 0;
  public first_name: any;
  public last_name: any;
  public email: any;
  public price: any;
  public confirm_email: any;
  public temporaryTradingSignature: any;
  public typeTrade: any = 0;
  public firstTransaction: any = false;

  ngOnInit(): void {
    if (this.artwork_id) {  
      this.api.get('artworks/' + this.artwork_id).then((data) => {
        console.log(data)
        this.artwork = data;
        this.price = data.version.price/100
      })
    }

    if(this.artwork){
      this.price = this.artwork.version.price/100
    }

    if (this.artwork_version_id) {
      console.log(this.price)

      this.api.get('versions/' + this.artwork_version_id).then((data) => {
        this.version = data;
        this.price = data.price/100
        console.log(this.price)
      })
    }

    if(this.version){
      this.price = this.version.price/100
      if(this.version.owner_id == null){
        this.firstTransaction = true
      }
    }
  }

  setIsDigital(isDigital: boolean) {
    this.isDigital = isDigital
  }
  setTypeTrade(typeTrade: boolean) {
    this.typeTrade = typeTrade
  }

  previousStep() {
    this.step -= 1
  }

  nextStep() {
    this.email.toLowerCase()
    this.confirm_email.toLowerCase()
    if (this.step === 1) {
      if (this.isDigital === 0) { 
        Swal.fire({
          title: 'Ops!',
          text: "it is mandatory to select an option!",
          icon: 'warning',
        })
        return false
      }
    }
    if (this.step === 2) {
      // if(!this.artwork_version_id){
      //   this.step += 2
      //   return true
      // }
      if (typeof this.first_name == 'undefined' || typeof this.last_name == 'undefined' || typeof this.email == 'undefined' || typeof this.confirm_email == 'undefined') {
        Swal.fire({
          title: 'Ops!',
          text: "it is mandatory to select an option!",
          icon: 'warning',
        })
        return false
      } else if (this.email != this.confirm_email) {
        Swal.fire({
          title: 'Ops!',
          text: "email confirmation do not match",
          icon: 'warning',
        })
        return false
      }else if(this.email == this.auth.userData.email){
        Swal.fire({
          title: 'Ops!',
          text: "it is not possible to transact a artwork for yourself",
          icon: 'warning',
        })
        return false
      }
    }
    if (this.step === 4) {
      if (this.typeTrade === 0) {
        Swal.fire({
          title: 'Ops!',
          text: "it is mandatory to select an option!",
          icon: 'warning',
        })
        return false
      }
      
    }
    if (this.step === 5) {
      let payload = {
        email: this.email.toLowerCase(),
        first_name: this.first_name,
        last_name: this.last_name,
        typeTrade: this.typeTrade,
        price: this.price,
      }
      if (typeof this.version !== 'undefined') {
        this.api.post('artworks/' + this.version.id + '/transfer', payload).then((response) => {
          this.temporaryTradingSignature = response.hash_token
        })
      } else {
        this.api.post('artworks/' + this.artwork.id + '/transferGetVersion', payload).then((response) => {
          this.temporaryTradingSignature = response.hash_token
        })
      }

    }
    if (this.step === 6) {
      this.modalService.dismissAll();
    }
    this.step += 1

  }

  

  buttonClose(){
    this.step = 2
    const modalRef = this.modalService.dismissAll();
   }
  

}
