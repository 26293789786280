<div class="row">
  <div class="col-12">
    <div ngbDropdown class="d-inline-block">
      <button  type="button" class="buttonDropDown" id="dropdownBasic1" ngbDropdownToggle><img src="../../../assets/icons/threepoints.png" alt=""></button>
        <div ngbDropdownMenu="dropdownBasic" class="boxMenuDropdrown">
          <a ngbDropdownItem class="dropDownContent borderRadiusUpBox text-grey-link font-weight-light cursor-pointer" (click)="AddFolder()">Add to folder</a>
          <hr class="m-0 p-0 gray">
          <a ngbDropdownItem *ngIf="artwork.allowed_institution !== auth.userData.id && artwork.allowed_institution !== null" class="dropDownContent text-grey-link font-weight-light cursor-pointer" (click)="RequestManagement()">Request management</a>
          <hr class="m-0 p-0 gray">
          <a ngbDropdownItem (click)="openShareEmbed()" class="dropDownContent text-grey-link font-weight-light cursor-pointer">Share embed</a>
          <hr class="m-0 p-0 gray">
          <a ngbDropdownItem [disabled]="artwork.allowed_institution !== auth.userData.id && artwork.artist_id !== auth.userData.id" [class.disabled]="artwork.allowed_institution !== auth.userData.id && artwork.artist_id !== auth.userData.id" class="dropDownContent borderRadiusDownBox text-grey-link font-weight-light cursor-pointer" (click)="EditArtwork()">Edit</a>
          <!-- <hr class="m-0 p-0 gray"> -->
          <!-- <a ngbDropdownItem [disabled]="artwork.allowed_institution !== auth.userData.id && artwork.artist_id !== auth.userData.id" [class.disabled]="artwork.allowed_institution !== auth.userData.id && artwork.artist_id !== auth.userData.id" class="dropDownContent borderRadiusDownBox text-grey-link font-weight-light cursor-pointer" (click)="ChangePrice()">Change price/royalties</a> -->
      </div>
    </div>
  </div>
</div>