<div>
    <div class="modal-header justify-content-center">
        <h2 class="text-secondary font-weight-bold mt-2">Remove artist</h2>
        <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0 p-1">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
    <div class="container modal-body">
        <div class="row justify-content-center mt-3">
            <p class="text-grey-light font-weight-light invited-p text-center">Are you sure you want to remove <br> this artist from your artist dashboard?</p>
        </div>
        <div class="row d-flex justify-content-center mt-4">
            <div>
                <img src="{{ invite.creator.avatar }}" style="width: 79px;height: 78.6px;object-fit: cover;border-radius: 50%;">
            </div>
            <div class="ml-4 mt-2">
                <p class="text-secondary font-weight-bold invited-p mb-0">{{ invite.creator.full_name }}</p>
                <p class="text-grey-light font-weight-light invited-p mb-0">{{ invite.creator.contact_information.city }}</p>
                <p class="text-grey-light font-weight-light invited-p mb-0">{{ invite.creator.contact_information.country }}</p>
            </div>
        </div>
    </div>
    <div class="container mt-4">
        <div class="row modal-footer d-flex justify-content-start px-0">
            <div class="col-lg-3 px-0">
                <button type="submit" class="w-100 btn btn-md text-primary font-weight-regular text-left" (click)="buttonClose()">Cancel</button>
            </div>
            <div class="col-lg-4 px-0 ml-5">
                <button type="button" (click)="removeInviteCreator()" class="w-100 btn btn-lg btn-primary font-weight-regular btn-invite">Remove</button>
            </div>
        </div>
    </div>
</div>