<div class="modal-header d-flex justify-content-center align-items-center p-3">
    <div class="text-center">
        <h4 class="modal-title font-weight-bold" id="modal-basic-title">Add a shipping method and tracking</h4>
        <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0 p-1">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
</div>
<div class="modal-body p-3">
    <p class="px-3">Add your preferred shipping and tracking information here to complete the transaction.</p>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div ngbDropdown class="d-inline-block w-100">
                    <button   type="button" class="btn text-left bg-light border-radius-default text-grey w-100 d-flex justify-content-between align-items-center buttonDropDown cursor-pointer" id="dropdownBasic-unit" ngbDropdownToggle>
                    <p class="text-center m-0">select shipping/delivery method</p>
                        <div class="mt-1"><img src="/assets/icons/arrow.png" alt=""></div>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic-unit" class="boxMenuDropdrown">
                        <a  class="dropDownContent text-grey cursor-pointer" style="border-radius: 7px;" ngbDropdownItem>Inches</a>
                        <a  class="dropDownContent text-grey cursor-pointer" style="border-radius: 7px;" ngbDropdownItem>Centimeters</a>
                        <a  class="dropDownContent text-grey cursor-pointer" style="border-radius: 7px;" ngbDropdownItem>Pixels</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 mt-4">
                <input placeholder="Insert tracking number" formControlName="height" class="bg-light border-0 w-100" type="text" required checked>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer d-flex justify-content-start p-3">
    <div class=" d-flex">
        <button type="button"  class="btn text-primary font-weight-light cursor-pointer"><small>Back</small></button>
        <button type="button"  class="btn btn-lg btn-primary w-100 px-5 cursor-pointer" >Done, notify buyer</button>
    </div>
</div>