export const races = [
    {name: "Hispanic or Latino"},
    {name: "White"},
    {name: "Black or African American"},
    {name: "Asian"},
    {name: "American Indian or Alaska Native"},
    {name: "Native Hawaiian or Other Pacific Islander"},
]



