import { Component, Input, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-institution-from-artworks',
  templateUrl: './institution-from-artworks.component.html',
  styleUrls: ['./institution-from-artworks.component.scss']
})
export class InstitutionFromArtworksComponent implements OnInit {
  @Input() versions: any[] = [];
  @Input() artworks: any[];
  @Input() activeArtworkStatusFilter: any;
  @Input() artworkSearchFilter: any;
  @Input() type: any;
  public institutionActive = true;

  constructor(
    public helper: HelperService,
    public auth: AuthService,

  ) {}

  filterArtworks(artwork) {
    if (this.artworkSearchFilter) {
      if (artwork.title.toLocaleLowerCase().indexOf(this.artworkSearchFilter.toLocaleLowerCase()) < 0) return false
    }
    if (this.activeArtworkStatusFilter === 'all') return true;
    if (this.activeArtworkStatusFilter == 'digital' && artwork.digital == true) return true;
    if (this.activeArtworkStatusFilter == 'physical' && artwork.digital == false) return true;
    return false;
  }

  ngOnInit(): void {
    this.institutionActive = this.auth.institutionActive.getValue()
  }

}
