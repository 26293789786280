import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { ModalAddToListComponent } from 'src/app/modals/modal-add-to-list/modal-add-to-list.component'
import { ModalTradeBuyerComponent } from 'src/app/modals/modal-trade-buyer/modal-trade-buyer.component';
import { ModalTradeDigitalComponent } from 'src/app/modals/modal-trade-digital/modal-trade-digital.component';

@Component({
  selector: 'app-dropdown-to-artworks',
  templateUrl: './dropdown-to-artworks.component.html',
  styleUrls: ['./dropdown-to-artworks.component.scss']
})
export class DropdownToArtworksComponent implements OnInit {
  @Input() public artwork_version_id: number;

  constructor(
    public route: ActivatedRoute,
    public modalService: NgbModal,
    public api: ApiService,
  ) { }

  ngOnInit(): void { 
    console.log(this.artwork_version_id)
  }


  openAddtoList(artwork_version_id: number) {
    const modalRef = this.modalService.open(ModalAddToListComponent);
    modalRef.componentInstance.artwork_version_id = artwork_version_id;
  }
  openTransaction() {
    const modalRef = this.modalService.open(ModalTradeDigitalComponent);
    modalRef.componentInstance.artwork_version_id = this.artwork_version_id;
  }
}
