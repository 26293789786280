<div *ngIf="artwork" class="container-fluid">
    <div class="row mx-sm-2">
        <div class="col mt-4 pt-2">
            <p class="mb-1 text-grey font-weight-light">Your Vault / Collection name</p>
            <div class="d-flex">
                <h1 class="text-primary font-weight-bold mb-0 mr-2">{{ artwork?.title }}</h1>
                <app-share-button [currentURL]="currentURL" [socials]="'all'" [shareMode]="'shareUrlArtwork'"></app-share-button>
            </div>
            <p class="text-grey font-weight-light mb-4 pb-2">{{ artwork?.artist.full_name }}</p>
        </div>
    </div>
</div>
<div *ngIf="artwork" class="container-fluid padding-mobile">
    <div class="row ml-md-2 mr-md-2 pb-5">
        <div class="col-md-4 col-lg-3 mb-5">
            <app-versionSpecs [artwork]="artwork" [descriptionArtworkDesktop]="descriptionArtworkDesktop"></app-versionSpecs>
        </div>
        <div class="offset-lg-1 col-lg-8" *ngIf="innerWidth > 767.98">
            <div class="card">
                <div class="row card-header bg-transparent d-flex justify-content-between pb-2">
                    <div class="mt-2 d-flex align-items-baseline titles-version">
                        <a [routerLink]="['/artworks/'+artwork?.id+'/value']" routerLinkActive="selected" id="titles-card-versions" class="text-grey font-weight-light h4 card-title nav-link">Value</a>
                        <a [routerLink]="['/artworks/'+artwork?.id+'/record']" routerLinkActive="selected" id="titles-card-versions" class="text-grey font-weight-light h4 card-title nav-link pl-1">Record</a>
                        <a [routerLink]="['/artworks/'+artwork?.id+'/about']" routerLinkActive="selected" id="titles-card-artworks" class="text-grey font-weight-light h4 card-title nav-link pl-1">About</a>
                        <div *ngIf="artwork?.artist_id !== auth.userData.id && artwork?.version != 'sold_out'" class="d-flex text-right justify-content-end align-items-center">
                            <button (click)="buy('?tradeQr='+artwork.version.fake_token)" class="btn btn-primary ml-2 px-4 cursor-pointer">Buy</button>
                        </div>
                    </div>
                    <div *ngIf="auth.userData.id && (artwork?.artist_id === auth.userData.id || institutionPermission) && artwork.version !== 'sold_out'" class="d-flex mt-2 text-right justify-content-end align-items-center mr-3">
                        <qrcode *ngIf="artwork?.version" [qrdata]="'https://www.alphaa.io/?tradeQr='+artwork?.version.fake_token" [width]="70" [errorCorrectionLevel]="'M'" [elementType]="'svg'"></qrcode>
                        <button (click)="openTransaction()" class="btn btn-primary cursor-pointer ml-2" style="width: 120px; border-radius: 9px !important;">Trade</button>
                    </div>
                </div>
                <div class="card-body" *ngIf="viewMode === 'value'">
                    <div>
                        <h4 *ngIf="artwork?.version == 'sold_out'" class="font-weight-bold text-secondary">Original Value</h4>
                        <h4 *ngIf="artwork?.version != 'sold_out'" class="font-weight-bold text-secondary">Current Value</h4>
                        <h1 *ngIf="artwork?.version != 'sold_out'" class="text-primary font-weight-normal"> {{ helper.amountCurrencyFormat(artwork?.version.price) }}</h1>
                        <h1 *ngIf="artwork?.version == 'sold_out'" class="text-primary font-weight-normal"> {{ helper.amountCurrencyFormat(artwork?.original_price) }}</h1>
                    </div>
                    <!-- <div class="mt-5">
                        <h4 class="font-weight-bold text-secondary">Fluctuation</h4>
                        <highcharts-chart *ngIf="chartOptions.chart" [Highcharts]="Highcharts" [options]="chartOptions" style="width: 100%; height: 300px; display: block;"></highcharts-chart>
                    </div> -->
                </div>

                <div class="card-body" *ngIf="viewMode === 'record'">
                    <div class="row">
                        <div class="col-12">
                            <h4 class="text-secondary font-weight-bold">Details</h4>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-lg-3 pr-md-0">
                                    <p class="text-secondary font-weight-bold mb-2">Privacy setting</p>
                                </div>
                                <div class="col-lg-8 px-xl-0">
                                    <p class="text-secondary mb-2">Private</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-lg-3 pr-md-0">
                                    <p class="text-secondary font-weight-bold mb-2">Artist</p>
                                </div>
                                <div class="col-lg-8 px-xl-0">
                                    <p class="text-secondary mb-2">{{ artwork.artist.full_name }}</p>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="artwork?.version != 'sold_out'" class="col-lg-12">
                            <div class="row">
                                <div class="col-lg-3 pr-md-0">
                                    <p class="text-secondary font-weight-bold mb-2">Hash Token</p>
                                </div>
                                <div class="col-lg-8 px-xl-0">
                                    <p *ngIf="artwork?.version" class="text-secondary mb-2 text-break">{{ artwork?.version.fake_token }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-4 pt-3 px-0">
                        <h4 class="text-secondary font-weight-bold mb-0">Recent </h4>
                        <!-- <p class="text-grey font-weight-light"> Lorem ipsun dolor sit amet Lorem ipsin dolor sit amet.</p> -->
                        <hr>
                        <div class="row align-items-center">
                            <div class="col-md-8 col-lg-8">
                                <div class="row">
                                    <div class="col-lg-2 pr-0">
                                        <img src="/assets/img/purchased-green.png" style="width: 3em;" alt="update">
                                    </div>
                                    <div class="col-lg-9 pl-0">
                                        <p class="text-secondary mb-0" *ngIf="artwork.version.owner">Purchased by you</p>
                                        <p class="text-secondary mb-0" *ngIf="!artwork.version.owner">Created by you</p>
                                        <p class="text-secondary font-weight-bold mb-0" *ngIf="artwork.version.owner">( {{ artwork.version.owner.full_name }} )</p>
                                        <p class="text-secondary font-weight-bold mb-0" *ngIf="!artwork.version.owner">({{ artwork.artist.full_name }})</p>
                                        <p class="text-grey font-weight-light">{{ helper.formatDate(artwork.version.updated_at) }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-lg-4">
                                <button type="button" (click)="openYourSmartContract()" class="border-0 text-grey smart-contract">
                                    <img src="/assets/icons/transaction.png">
                                    Smart Contract
                                </button>
                            </div>
                        </div>
                        <hr>
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-12 mt-5">
                            <h4 class="text-secondary font-weight-bold mb-0">History</h4>
                            <p class="text-grey font-weight-light">Through alphaa.io's digital NFT certificates the full artwork history <br> and transaction log can be easily viewed</p>
                            <hr>
                        </div>
                    </div>
                    <div *ngFor="let history of histories">
                        <div class="row align-items-baseline">
                            <div class="col-md-8">
                                <div class="row mt-2">
                                    <div class="col-md-3 col-lg-1 mt-2">
                                        <img src="assets/img/purchased.png" alt="purchased">
                                    </div>
                                    <div class="col-md-9">
                                        <h5 class="mb-0 text-secondary">{{ textStatus(history.status) }} by <span class="font-weight-bold">{{ textSubject(history) }}</span></h5>
                                        <p class="text-grey">{{ helper.formatDate(history.created_at) }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 d-flex justify-content-md-end">
                                <button type="button" (click)="openTransactionTwo(history)" class="btn pl-0 text-grey cursor-pointer"><img src="/assets/icons/transaction.png"> Transaction Data</button>
                            </div>
                        </div>
                        <hr>
                    </div>
                    <div>
                        <div class="row">
                            <div class="col-md-8">
                                <div class="row align-items-baseline mt-2">
                                    <div class="col-lg-2 mt-2">
                                        <img src="assets/img/create.png" alt="create">
                                    </div>
                                    <div class="col-md-9">
                                        <h5 class="mb-0 text-secondary">Created by <span class="font-weight-bold">{{ artwork?.artist.full_name }}</span></h5>
                                        <p class="text-grey">{{ helper.formatDate(artwork?.created_at) }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 d-flex justify-content-md-end">
                                <button type="button" (click)="openTransactionTwo(artwork)" class="btn pl-0 text-grey cursor-pointer"><img src="/assets/icons/transaction.png"> Transaction Data</button>
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
                <div class="card-body" *ngIf="viewMode === 'about'">
                    <div class="row">
                        <div class="col-lg-12">
                            <h4 class="text-secondary font-weight-bold">About This Artwork</h4>
                            <div class="row">
                                <div class="col-9 mt-2">
                                    <b>More about this artwork</b>
                                    <p [innerHTML]="artwork.about" class="text-grey"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-12 px-0" id="details-artwork" *ngIf="innerWidth < 767.98">
            <div class="d-flex justify-content-between titles-details-artwork border-bottom">
                <div class="d-flex titles-version mt-2 px-2">
                    <a [routerLink]="['/artworks/'+artwork?.id+'/value']" routerLinkActive="selected" id="titles-card-versions" class="text-grey font-weight-light h4 card-title nav-link px-1">Value</a>
                    <a [routerLink]="['/artworks/'+artwork?.id+'/record']" routerLinkActive="selected" id="titles-card-versions" class="text-grey font-weight-light h4 card-title nav-link px-1 ml-3">Record</a>
                    <a [routerLink]="['/artworks/'+artwork?.id+'/about']" routerLinkActive="selected" id="titles-card-artworks" class="text-grey font-weight-light h4 card-title nav-link px-1 ml-3">About</a>
                </div>
            </div>
            <div class="pt-4" *ngIf="viewMode === 'value'">
                <div class="px-3">
                    <h4 *ngIf="artwork?.version == 'sold_out'" class="font-weight-bold text-secondary">Original Value</h4>
                    <h4 *ngIf="artwork?.version != 'sold_out'" class="font-weight-bold text-secondary">Current Value</h4>
                    <h1 *ngIf="artwork?.version != 'sold_out'" class="text-primary font-weight-light"> {{ helper.amountCurrencyFormat(artwork?.version.price) }}</h1>
                    <h1 *ngIf="artwork?.version == 'sold_out'" class="text-primary font-weight-light"> {{ helper.amountCurrencyFormat(artwork?.original_price) }}</h1>
                </div>
                <div class="mt-4 pt-2 px-3">
                    <!-- <h4 class="font-weight-bold text-secondary">Fluctuation</h4> -->
                    <!-- <highcharts-chart *ngIf="chartOptions.chart" [Highcharts]="Highcharts" [options]="chartOptions" class="mb-4" style="width: 100%; height: 300px; display: block;"></highcharts-chart> -->
                    <hr>
                </div>
                <div class="px-3">
                    <ngb-accordion #acc="ngbAccordion">
                        <ngb-panel id="toggle-1" title="Artwork information">
                            <ng-template ngbPanelContent>
                                <app-versionSpecs [artwork]="artwork" [showImage]="false" [descriptionArtworkMobile]="descriptionArtworkMobile"></app-versionSpecs>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>
            </div>
            <div class="pt-4 px-3" *ngIf="viewMode === 'record'">
                <div class="col-12 px-0">
                    <h4 class="text-secondary font-weight-bold">Details</h4>
                    <!-- <p class="text-grey-light font-weight-light">Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet t Lorem ipsum dolor</p> -->
                </div>
                <div class="col-12 px-0">
                    <div class="row">
                        <div class="col-12 pr-md-0">
                            <p class="text-secondary font-weight-bold mb-0">Privacy setting</p>
                            <p class="text-secondary mb-2">Private</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 px-0">
                    <div class="row">
                        <div class="col-12 pr-md-0">
                            <p class="text-secondary font-weight-bold mb-0">Artist</p>
                            <p class="text-secondary mb-2">{{ artwork?.artist.full_name }}</p>
                        </div>
                    </div>
                </div>
                <div *ngIf="artwork?.version != 'sold_out'" class="col-12 px-0">
                    <div class="row">
                        <div class="col-12 pr-md-0">
                            <p class="text-secondary font-weight-bold mb-0">Hash Token</p>
                            <p *ngIf="artwork?.version" class="text-secondary mb-2  text-break">{{ artwork?.version.fake_token }}</p>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="col-12 mt-4 pt-3 px-0">
                    <h4 class="text-secondary font-weight-bold mb-0">Recent Activity</h4>
                </div>
                <div class="col-12 artwork-description">
                    <div class="row">
                        <div class="col-2">
                            <img src="/assets/icons/purchased-green-icon.png" class="purchased-icon" alt="update">
                        </div>
                        <div class="col-9">
                            <p class="text-secondary mb-0" *ngIf="artwork.version.owner">Purchased by you</p>
                            <p class="text-secondary mb-0" *ngIf="!artwork.version.owner">Created by you</p>
                            <p class="text-secondary font-weight-bold mb-0" *ngIf="artwork.version.owner">( {{ artwork.version.owner.full_name }} )</p>
                            <p class="text-secondary font-weight-bold mb-0" *ngIf="!artwork.version.owner">({{ artwork.artist.full_name }})</p>
                            <p class="text-grey font-weight-light">{{ helper.formatDate(artwork.version.updated_at) }}</p>
                            <button (click)="openYourSmartContract()" class="view-smart-contract d-flex align-items-center btn btn-outline-primary cursor-pointer">
                                <img src="/assets/icons/transaction-blue.png">
                                <p class="text-primary font-weight-regular mb-0 pl-2">View Smart Contract</p>
                            </button>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="col-12 mt-4 px-0">
                    <h4 class="text-secondary font-weight-bold mb-0">History</h4>
                </div>
                <div *ngFor="let history of histories">
                    <div class="col-12 artwork-description">
                        <div class="row mt-2">
                            <div class="col-2">
                                <img class="history-icon" src="assets/icons/history-purchased.png" alt="purchased">
                            </div>
                            <div class="col-9">
                                <p class="mb-0 text-secondary">{{ textStatus(history.status) }} by <span class="font-weight-bold">{{ textSubject(history) }}</span></p>
                                <p class="text-grey font-weight-light mb-0">{{ helper.formatDate(history.created_at) }}</p>
                                <button (click)="openTransactionTwo(history)" class="btn d-flex align-items-center cursor-pointer" style="padding: 2px 0px;">
                                    <img src="/assets/icons/transaction.png">
                                    <p class="text-grey-light font-weight-light mb-0 pl-1">Transaction Data</p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="col-12 artwork-description">
                        <div class="row">
                            <div class="col-2">
                                <img class="history-icon" src="assets/icons/history-create.png" alt="create">
                            </div>
                            <div class="col-9">
                                <p class="mb-0 text-secondary">Created by <span class="font-weight-bold">{{ artwork?.artist.full_name }}</span></p>
                                <p class="text-grey font-weight-light mb-0">{{ helper.formatDate(artwork?.created_at) }}</p>
                                <button (click)="openTransactionTwo(artwork)" class="btn text-grey font-weight-light d-flex align-items-center cursor-pointer px-0">
                                    <img src="/assets/icons/transaction.png">
                                    <p class="text-grey-light font-weight-light mb-0 pl-1">Transaction Data</p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pt-4" *ngIf="viewMode === 'about'">
                <div class="col-12">
                    <h4 class="text-secondary font-weight-bold mb-0">About This Artwork</h4>
                    <div class="row">
                        <div class="col-12 mt-2">
                            <b>More about this artwork</b>
                            <p [innerHTML]="artwork.about" class="text-grey"></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header p-3">
                    <div class="d-flex justify-content-between align-items-center">
                        <div *ngIf="artwork?.version !== 'sold_out'">
                            <qrcode *ngIf="artwork?.version" [qrdata]="'https://www.alphaa.io/?tradeQr='+artwork?.version.fake_token" [width]="50" [errorCorrectionLevel]="'M'" [elementType]="'svg'"></qrcode>
                        </div>
                        <div *ngIf="artwork?.artist_id !== auth.userData.id && artwork?.version != 'sold_out'" class="col-9 px-0">
                            <button (click)="buy('?tradeQr='+artwork.version.fake_token)" class="btn btn-primary cursor-pointer w-100" style="height: 40px;">Buy</button>
                        </div>
                        <div *ngIf="auth.userData.id && (artwork?.artist_id === auth.userData.id || institutionPermission) && artwork.version !== 'sold_out'"  class="col-9 px-0">
                            <button (click)="openTransaction()" class="btn btn-primary cursor-pointer w-100" style="height: 40px;">Trade</button>
                        </div>
                        <div>
                            <a class="cursor-pointer" (click)="share()">
                                <img src="/assets/icons/share-icon.png" alt="">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>