<div class="row" *ngIf="list">
    <div class="col">
        <div ngbDropdown class="d-inline-block">
            <button  type="button" class="buttonDropDown" id="dropdownBasic1" ngbDropdownToggle><img src="../../../assets/icons/threepoints.png" alt=""></button>
            <div ngbDropdownMenu="dropdownBasic" class="boxMenuDropdrown">
                <a (click)="acceptDeleteList(version.id, list.id)" ngbDropdownItem class="dropDownContent borderRadiusUpBox text-grey-light font-weight-light cursor-pointer">Remove from list</a>
                <a (click)="openTransactionList(version.id)" ngbDropdownItem class="dropDownContent borderRadiusDownBox text-grey-light font-weight-light cursor-pointer">Trade</a>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="folder">
    <div class="col">
        <div ngbDropdown class="d-inline-block">
            <button  type="button" class="buttonDropDown" id="dropdownBasic1" ngbDropdownToggle><img src="../../../assets/icons/threepoints.png" alt=""></button>
            <div ngbDropdownMenu="dropdownBasic" class="boxMenuDropdrown">
                <a (click)="acceptDeleteFolder(artwork.id, folder.id)" ngbDropdownItem class="dropDownContent borderRadiusUpBox text-grey-light font-weight-light cursor-pointer">Remove from folder</a>
                <a (click)="openTransactionFolder(artwork.id)" ngbDropdownItem class="dropDownContent borderRadiusDownBox text-grey-light font-weight-light cursor-pointer">Trade</a>
            </div>
        </div>
    </div>
</div>