import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-artist-detail",
  templateUrl: "./artist-detail.component.html",
  styleUrls: ["./artist-detail.component.scss"],
})
export class ArtistDetailComponent implements OnInit {
  public slug: any;
  public artist: any;
  public artworks: any = [];

  constructor(
    public api: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    public auth: AuthService
  ) {
    this.route.params.subscribe((params) => (this.slug = params["slug"]));
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.api.get("user/" + this.slug).then((responde) => {
          this.artist = responde;
          this.api
            .get("artworks/getbyUserId/" + this.artist.id)
            .then((response) => {
              this.artworks = response;
            });
        });
      }
    });
  }

  ngOnInit(): void {}

  submitArtworkForThisArtist() {
    this.router.navigate(["dashboard-institution/artworks-create"], {
      queryParams: { artist_id: this.artist.id },
    });
  }

  verifyInsitituioOfArtist() {
    for (let invite of this.artist.invites) {
      if (invite.institution_id == this.auth.userData.id) {
        return true;
      }
    }
    return false;
  }
}
