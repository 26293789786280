import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { HighChartsService } from 'src/app/services/highcharts.service';

@Component({
  selector: 'app-info-dashboard',
  templateUrl: './info-dashboard.component.html',
  styleUrls: ['./info-dashboard.component.scss']
})
export class InfoDashboardComponent implements OnInit {
  @Input() public mode: any
  public total: any = 0
  public dataChart: any[] = [];
  Highcharts: typeof Highcharts = Highcharts;
  
  public chartOptions: Highcharts.Options = {};
  
  constructor(
    public hcSercive: HighChartsService,
    public auth: AuthService,
    public api: ApiService,
    ) { }
    
    ngOnInit(): void {
      if(this.mode == 'institution'){
        this.total = 0
        this.api.get('institutions/info/sales').then(async (response) => {
          for await (let transaction of response){
            this.total += transaction.institution_fee_amount / 100
            this.total += transaction.institution_escrow_amount / 100
            let dateCreated = new Date(transaction.paid_at ? transaction.paid_at : transaction.created_at)
            const result = this.dataChart.find(data => data.x === (dateCreated.getMonth()+1)+'/'+ dateCreated.getFullYear());
            if(result){
              result.y += transaction.institution_fee_amount / 100
              result.y += transaction.institution_escrow_amount / 100
            }else{
              this.dataChart.push({x: (dateCreated.getMonth()+1)+'/'+ dateCreated.getFullYear(), y: ((transaction.institution_escrow_amount / 100)+(transaction.institution_fee_amount/100))})
            }
          }
          this.chartOptions = this.hcSercive.populateColumnChart(this.dataChart);
        })
      }else if(this.mode == 'creator'){
        this.total = 0
        this.api.get('creators/info/sales').then(async (response) => {
          for await (let transaction of response){
            this.total += transaction.author_fee_amount == 0 ? transaction.amount / 100 : transaction.author_fee_amount / 100
            let dateCreated = new Date(transaction.paid_at ? transaction.paid_at : transaction.created_at)
            const result = this.dataChart.find(data => data.x === (dateCreated.getMonth()+1)+'/'+ dateCreated.getFullYear());
            if(result){
              result.y += transaction.author_fee_amount == 0 ? transaction.amount / 100 : transaction.author_fee_amount / 100
            }else{
              let yData = transaction.author_fee_amount == 0 ? transaction.amount / 100 : transaction.author_fee_amount / 100
              this.dataChart.push({x: (dateCreated.getMonth()+1)+'/'+ dateCreated.getFullYear(),y: yData})
            }
          }
          this.chartOptions = this.hcSercive.populateColumnChart(this.dataChart);
        })
      }
    }
  }
  