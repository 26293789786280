<div class="row">
    <div class="col-lg-12 text-center p-3">
        <h4 class="text-secondary font-weight-bold">Add a shipping method and tracking</h4>
        <p class="mt-4 text-grey-light font-weight-light">Please enter your shipping method and tracking number below so your buyer can track their order.</p>
        <button type="button" (click)="buttonClose()" aria-label="Close" 
            class="btn btn-outline position-absolute right-0 top-0">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
</div>
<div class="col-sm-9 col-12 mx-auto p-0 px-3">
    <ng-select>
        <ng-option>teste</ng-option>
    </ng-select>
</div>
<div class="col-sm-9 col-12 mx-auto p-0">
    <input type="text" class="form-control bg-light" placeholder="Insert tracking number"/>
</div>
<div class="row d-flex justify-content-center px-5">
    <div class="col-lg-7 col-md-7 pb-3">
        <div class="mt-4">
            <a href="#" class="ml-5 btn btn-outline-primary pl-4 pr-4">Back</a>
            <a href="#" class="ml-5 btn btn-primary font-weight-bold pl-4 pr-4">Done, notify buyer</a>
        </div>
    </div>
</div>