

<div class="modal-header justify-content-center">
  <h2 class="text-secondary font-weight-bold">Create Stripe Account</h2>
  <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0 p-2">
    <img src="/assets/icons/close.svg">
  </button>
</div>
<div class="modal-body text-center">
  <p class="mt-2 text-left text-grey-light font-weight-light ml-3">Select Your Country</p>
  <div class="col-12">
    <ng-select [(ngModel)]="country" placeholder="Country&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;">
      <ng-option *ngFor="let option of countries" [value]="option.country">{{option.country}}&nbsp;&nbsp;</ng-option>
    </ng-select>  </div>
 
</div>
<div class="modal-footer justify-content-center">
  <button  (click)="createAccount()" class="w-75 btn btn-lg btn-primary btn-create font-weight-regular">Create</button>
</div>


