<div class="d-inline-block">
    <div class="col-4 d-flex justify-content-center">
        <div ngbDropdown class="d-inline-block">
            <button type="button" class="buttonDropDown" id="dropdownBasic1" ngbDropdownToggle><img src="../../../assets/icons/threepoints.png" alt=""></button>
            <div ngbDropdownMenu="dropdownBasic" class="boxMenuDropdrown">
                <a ngbDropdownItem [disabled]="invite.status == 1" [class.disabled]="invite.status == 1" class="dropDownContent borderRadiusUpBox text-grey-link font-weight-light cursor-pointer" (click)="editArtists()">Edit Artists</a>
                <hr class="m-0 p-0 gray">
                <a ngbDropdownItem [disabled]="invite.status == 1" [class.disabled]="invite.status == 1" class="dropDownContent borderRadiusDownBox text-grey-link font-weight-light cursor-pointer" (click)="removeArtists()">Remove Artist</a>
                <hr *ngIf="invite.status != 2" class="m-0 p-0 gray">
                <a ngbDropdownItem *ngIf="invite.status != 2" class="dropDownContent borderRadiusDownBox text-grey-link font-weight-light cursor-pointer" (click)="requestManager()">Request management</a>
            </div>
        </div>
    </div>
</div>