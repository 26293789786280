<div class="row mt-5">
    <div class="col-12">
        <h1 class="font-weight-bold text-primary">Similar Artworks</h1>
        <div class="box mt-4 pt-3">
            <div class="overflow-x d-flex">
                <div *ngFor="let artwork of similar" class="ml-0 mr-4 my-0 p-0 col-auto">
                    <app-preview-artwork [link]="'/artworks/'+artwork.id+'/record'" [showPlay]="true" [img]="artwork.img" [preview]="artwork.preview" [styleImage]="'height: 219px; border-radius: 6px;'" [styleBox]="'height: 219px; border-radius: 6px;'"></app-preview-artwork>
                    <h5 [routerLink]="['/artworks/'+artwork.id+'/record']" class="cursor-pointer text-secondary font-weight-bold mb-0 mt-2"> {{ artwork.title }}</h5>
                    <h5 [routerLink]="['/artworks/'+artwork.id+'/record']" class="cursor-pointer mb-0">{{ artwork.artist.full_name }}</h5>
                </div>
            </div>
        </div>
    </div>
</div>