<div  class="container-fluid border-light">
    <div class="row">
        <div class="col-lg-3 mt-sm-5 pt-sm-3 pl-3">
            <h4 class="mb-2 font-weight-bold text-secondary">Abstract </h4>
            <h5 class="mb-2 text-grey-light">12 Artworks</h5>
            <button type="button" class="btn btn-primary btn-view d-sm-flex align-items-center px-3 mt-3">View</button>
        </div>
        <div class="col-lg-9 px-0" >
            <div class="w-100 overflow mt-3 pl-sm-3 pr-sm-0">
                <div class="col-lg-4" *ngFor="let institution of artists">
                    <img src="{{institution.img}}" alt="" width="100%" class="line-art">
                </div>
                
            </div>
        </div>
    </div>
    <hr class="mb-1">
</div>