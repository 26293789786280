<div class="row d-flex flex-column mt-5" *ngIf="buy?.in_progress.length == 0 && sale?.in_progress.length == 0">
    <h4 class="text-secondary font-weight-bold">You have no artwork in progress</h4>
</div>
<div class="row d-flex flex-column mt-5" *ngIf="buy?.in_progress.length > 0 || sale?.in_progress.length > 0">
    <div class="col d-flex flex-wrap">
        <div>
            <h4 class="text-secondary font-weight-bold">Trades in progress</h4>
        </div>
        <div class="w-100 overflow">
            <div class="pr-4 mr-3 last-trade" *ngFor="let version of buy?.in_progress">
                <app-button-institution-progress class="button-institution-progress p-0" [version]="version" [buy]="buy"> </app-button-institution-progress>
                <app-preview-artwork [img]="version.artwork_version.artwork.img" [preview]="version.artwork_version.artwork.preview" [class]="'img-trades'" [styleImage]="'height: 124px; width: 130px'" [styleBox]="'height: 124px; width: 130px'"></app-preview-artwork>

                <div class="mt-3">
                    <p class="mb-0 font-weight-light text-grey-light small" style="margin-top: -7px; font-size: 12px;">Physical Artwork</p>
                    <p class="font-weight-bold text-secondary mb-0" style="margin-top: -7px;">{{ version.artwork_version.artwork.title }}</p>
                    <p class="text-secondary font-weight-regular mb-0" style="margin-top: -7px;">{{ version.artwork_version.artwork.artist.full_name }}</p>
                </div>
            </div>
            <div class="pr-4 mr-3 last-trade" *ngFor="let version of sale?.in_progress">
                <app-button-institution-progress class="button-institution-progress p-0" [version]="version" [buy]="sale"> </app-button-institution-progress>
                <app-preview-artwork [img]="version.artwork_version.artwork.img" [preview]="version.artwork_version.artwork.preview" [class]="'img-trades'" [styleImage]="'height: 124px; width: 130px'" [styleBox]="'height: 124px; width: 130px'"></app-preview-artwork>
                <div class="mt-3">
                    <p class="mb-0 font-weight-light text-grey-light small" style="margin-top: -7px; font-size: 12px;">Physical Artwork</p>
                    <p class="font-weight-bold text-secondary mb-0" style="margin-top: -7px;">{{ version.artwork_version.artwork.title }}</p>
                    <p class="text-secondary font-weight-regular mb-0" style="margin-top: -7px;">{{ version.artwork_version.artwork.artist.full_name }}</p>
                </div>
            </div>
        </div>
    </div>
</div>