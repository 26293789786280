<div class="text-center py-4">
  <h4 class="text-secondary">Edit this list</h4>
  <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0">
    <img src="/assets/icons/close.svg">
</button>
  <p class="mt-4 text-grey-light">You can edit then anme and description for this list.</p>
  <div class="col-sm-9 col-12 mx-auto p-0">
      <input type="text" class="form-control bg-light" placeholder="Figurative"  />
  </div>
  <div class="col-sm-9 col-12 mx-auto p-0">
      <textarea class="form-control bg-light mt-4" rows="4" placeholder="Description" ></textarea>
  </div>
  <div class="mt-4">
      <a href="#" class="ml-5 btn btn-outline-primary pl-4 pr-4">Delete list</a>
      <a href="#" class="ml-5 btn btn-primary font-weight-bold pl-4 pr-4">Done</a>
  </div>
</div>